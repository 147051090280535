import { ObservationConcernLevelEnum } from "shared/enums/ObservationConcernLevel.enum";

export const getConcernLevelColor = (concernLevel: ObservationConcernLevelEnum): string => {

    switch (concernLevel) {
        case ObservationConcernLevelEnum.Low:
            return 'green';
        case ObservationConcernLevelEnum.Medium:
            return 'orange';
        case ObservationConcernLevelEnum.High:
            return 'red';
        default:
            return 'black';
    }
}

export const getConcernLevelNumericValue = (concernLevel: ObservationConcernLevelEnum): number => {

    switch (concernLevel) {
        case ObservationConcernLevelEnum.Low:
            return 1;
        case ObservationConcernLevelEnum.Medium:
            return 2;
        case ObservationConcernLevelEnum.High:
            return 3;
        default:
            return 1;
    }
}