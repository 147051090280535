import { useCallback } from 'react';
import { usePermissions, UserPermission, WriteOn, ReadOn } from 'modules/permissions';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { GoodPracticeDisplayEnum } from '../../../enums/good-practice-display.enum';
import { GoodPractice } from '../../../queries/view-good-practice.queries.types';
import {
   StyledStatusBarUpdatedBy,
   StyledSubHeader,
   StyledSubHeaderControl,
   StyledSubHeaderControlContainer,
   StyledSubHeaderGroup,
   StyledSubHeaderLabel,
   StyledSubHeaderSection,
   StyledSubHeaderTabContainer
} from 'shared/styled/SubHeader.styled';
import SubHeaderTab from 'components/SubHeaderTab';
import { useForm } from 'modules/forms';
import { GoodPracticeEntity } from 'shared/types/domain/GoodPracticeEntity';
import DateTimeInput from 'components/controls/DateTimeInput';
import { FontIcon } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

interface GoodPracticeSubHeaderProps {
   goodPractice: GoodPractice | undefined;
   displayMode: GoodPracticeDisplayEnum;
   formHasErrors: boolean;
   goToOverview: () => void;
   goToCompletionDetails: () => void;
   goToAuditLog: () => void;
}

const GoodPracticeSubHeader = ({
   goodPractice,
   displayMode,
   formHasErrors,
   goToOverview,
   goToCompletionDetails,
   goToAuditLog
}: GoodPracticeSubHeaderProps) => {
   const { t } = useTranslation();
   const { updateFields, formErrors } = useForm<GoodPracticeEntity>();
   const { hasPermission } = usePermissions();

   const lastUpdateText = (): string => {
      return `${t('ObservationPage.LastUpdatedOn')} ${t('AuditLog.Date', { date: goodPractice?.lastModified })}`;
   };

   const allowShowCompletionDetails = useCallback(() => {
      return goodPractice?.completion != undefined;
   }, [goodPractice]);

   const handleIncidentDateChange = (incidentDate: string | null | undefined) => {
      updateFields({
         incidentDate: { runValidation: true, value: incidentDate }
      });
   };

   return (
      <StyledSubHeader>
         <StyledSubHeaderSection style={{ maxWidth: '25%' }}>
            <StyledSubHeaderGroup>
               <StyledSubHeaderControlContainer>
                  <StyledSubHeaderLabel>{t('ObservationPage.IncidentDate')}</StyledSubHeaderLabel>
                  <StyledSubHeaderControl>
                     <ConditionalRender condition={hasPermission(WriteOn(UserPermission.IncidentDate))}>
                        <DateTimeInput
                           value={goodPractice?.incidentDate!.toString()}
                           onDateTimeSelect={handleIncidentDateChange}
                           disableFuture={true}
                           errorMessage={formErrors?.incidentDate}
                        />
                     </ConditionalRender>
                     <ConditionalRender condition={!hasPermission(WriteOn(UserPermission.IncidentDate))}>
                        {t('AuditLog.Date', { date: goodPractice?.incidentDate.toString() })}
                     </ConditionalRender>
                  </StyledSubHeaderControl>
               </StyledSubHeaderControlContainer>
            </StyledSubHeaderGroup>
            <StyledSubHeaderGroup>
               <StyledSubHeaderControlContainer>
                  <StyledSubHeaderLabel>{t('ObservationList.Status')}</StyledSubHeaderLabel>

                  <StyledSubHeaderControl>
                     {t(`ObservationFilter.${goodPractice?.observationStatus.toProperCase()}`)}
                  </StyledSubHeaderControl>
               </StyledSubHeaderControlContainer>
            </StyledSubHeaderGroup>
         </StyledSubHeaderSection>

         <StyledSubHeaderSection style={{ flex: '2 1 auto' }}>
            <StyledSubHeaderGroup style={{ justifyContent: 'flex-end' }}>
               <StyledStatusBarUpdatedBy>{lastUpdateText()}</StyledStatusBarUpdatedBy>
            </StyledSubHeaderGroup>

            <StyledSubHeaderGroup style={{ justifyContent: 'flex-end' }}>
               <StyledSubHeaderTabContainer>
                  <SubHeaderTab
                     onClick={goToOverview}
                     isActive={displayMode === GoodPracticeDisplayEnum.Overview}
                     display={t('ObservationPage.Overview')}
                     highlightErrors={formHasErrors}
                  />
                  <ConditionalRender condition={allowShowCompletionDetails}>
                     <SubHeaderTab
                        onClick={goToCompletionDetails}
                        isActive={displayMode === GoodPracticeDisplayEnum.Completion}
                        display={t('ObservationPage.Completion')}
                     />
                  </ConditionalRender>
                  <ConditionalRender condition={hasPermission(ReadOn(UserPermission.Audit))}>
                     <SubHeaderTab
                        onClick={goToAuditLog}
                        isActive={displayMode === GoodPracticeDisplayEnum.Audit}
                        display={<FontIcon iconName='History' />}
                        title={t('ObservationPage.AuditLog')}
                     />
                  </ConditionalRender>
               </StyledSubHeaderTabContainer>
            </StyledSubHeaderGroup>
         </StyledSubHeaderSection>
      </StyledSubHeader>
   );
};

export default GoodPracticeSubHeader;
