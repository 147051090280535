import { FC, useCallback, useEffect, useState } from 'react';
import { SecurityContext } from '../contexts/security-context';
import { useOktaAuth } from '@okta/okta-react';
import { withOkta } from '../hocs/withOkta';
import { User } from '../types/User';
import { AuthStateEnum } from '../enums/AuthStateEnum';
import { SecurityRoute, securityRoutes } from 'modules/routing';

/**
 * Props interface for {@link SecurityProvider}
 */
interface ISecurityProviderProps {}

/**
 * SecurityProvider component
 * @param props {@link ISecurityProviderProps}
 */
const SecurityProviderBase: FC<ISecurityProviderProps> = ({ children }) => {
    const { authState: oktaAuthState, oktaAuth } = useOktaAuth();

    const [authState, setAuthState] = useState<AuthStateEnum>(AuthStateEnum.Pending);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [user, setUser] = useState<User | null>(null);

    const signOut = useCallback(async () => {
        const logoutUrl = window.location.origin + securityRoutes[SecurityRoute.PostLogout]();
        await oktaAuth.signOut({ postLogoutRedirectUri: logoutUrl });
    }, [oktaAuth]);

    /** Load user once authenticated */
    useEffect(() => {
        if (authState === AuthStateEnum.Authenticated) {
            oktaAuth.getUser().then(user => {
                setAccessToken(oktaAuthState.accessToken?.value ?? '');
                setUser(user);
                setAuthState(AuthStateEnum.Successful);

                
            });
        }
    }, [authState, oktaAuth, oktaAuthState]);

    /** Set auth state when okta has finished authenticating */
    useEffect(() => {
        if (oktaAuthState.isAuthenticated && !oktaAuthState.isPending) {
            setAuthState(AuthStateEnum.Authenticated);
        }

        if (!oktaAuthState.isAuthenticated && !oktaAuthState.isPending) {
            setAuthState(AuthStateEnum.Failed);
        }
    }, [oktaAuthState]);

    return (
        <SecurityContext.Provider
            value={{
                accessToken: accessToken,
                authState: authState,
                user: user,
                signOut: signOut
            }}
        >
            {children}
        </SecurityContext.Provider>
    );
};

export const SecurityProvider = withOkta(SecurityProviderBase);