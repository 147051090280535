import { useApolloClient } from "@apollo/react-hooks";
import { ApolloError } from "apollo-client";
import Button, { IconButton } from "components/controls/Button";
import TextInput from "components/controls/TextInput";
import { Toggle } from "components/controls/Toggle";
import { ConditionalRender } from "components/layout/ConditionalRender";
import { ConfirmationDialog } from "components/layout/Dialog";
import VirtualTable, { IVirtualTableColumn } from "components/layout/VirtualTable/Table/components/VirtualTable";
import { cloneDeep } from "lodash";
import { ApolloErrorToastMessage } from "modules/errors";
import { usePermissions, UserPermission, WriteOn } from "modules/permissions";
import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { ConfigurationPage, configurationRouteBases } from "sections/configuration/routes/configuration-page.types";
import { StyledBoldText, StyledConfigurationContainer, StyledGrayInfoText, StyledHeaderText, StyledSection, StyledSubHeader } from "sections/configuration/styled/configuration.styles";
import { useUnload } from "shared/hooks/useUnload";
import { ProfileModel, ProfilePermissionSetModel } from "shared/types/configuration/ProfileModels";
import { User } from "shared/types/configuration/UserModels";
import { DeleteProfile } from "../mutations/view-profile-mutation";
import { GetNextProfileUsers } from "../queries/view-profile-query";
import AssignUser from "../../../components/AssignUser/components/AssignUser";
import UserList from "../../../components/UserList/components/UserList";
import { isNullOrEmpty } from "shared/utils/null.utils";


interface ProfileConfigurationProps {
    profileModel: ProfileModel;
    onSave: (profile: ProfileModel) => void;
    isNewProfile: boolean;
}

const ProfileConfiguration: FunctionComponent<ProfileConfigurationProps> = ({
    profileModel,
    onSave,
    isNewProfile
}) => {
    const { setBlockUnload } = useUnload();
    const [stateProfile, setStateProfile] = useState<ProfileModel>(cloneDeep<ProfileModel>(profileModel));
    const [isOpenAssignUserModal, setOpenAssignUserModal] = useState<boolean>(false);
    const userScrollDiv = useRef<HTMLDivElement>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [isMaxUsers, setMaxUsers] = useState<boolean>(false);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState<boolean>(false);
    const [confirmUpdateDialog, setConfirmUpdateDialog] = useState<boolean>(false);
    const [hasNextUserRan, setHasNextUserRan] = useState<boolean>(false);
    const client = useApolloClient();
    const { addToast } = useToasts();
    const history = useHistory();

    const { hasPermission } = usePermissions();

    const [errorMessages, setErrorMessages] = useState<{ name: string; description: string }>({
        name: '',
        description: ''
    });

    const columns: IVirtualTableColumn<ProfilePermissionSetModel>[] = [
        {
            key: 'column1',
            name: 'Permission Set Name',
            fieldName: 'permissionSetName',
            minWidth: 150,
            maxWidth: 400,
            isResizable: false,
            data: 'string',
            isPadded: true,
            onRender: (item: ProfilePermissionSetModel) => item.permissionSetName
        },
        {
            key: 'column2',
            name: 'Has Access?',
            fieldName: 'hasAccess',
            minWidth: 40,
            maxWidth: 40,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: ProfilePermissionSetModel) =>
                <Toggle
                    name={'hasPermissionSet'}
                    checked={item.hasPermissionSet}
                    onChange={(e, c, n) =>
                        handlePermissionChange(item.permissionSetId, c, n)
                    } />
        }
    ];

    const loadNextUsersAsync = useCallback(async () => {
        if (!isMaxUsers && !isNewProfile) {
            try {
                const res = await client.query<{ profileUsers: User[] }>({
                    query: GetNextProfileUsers,
                    variables: {
                        profileId: profileModel.profile.profileId,
                        currentPage: pageNumber + 1
                    },
                    fetchPolicy: 'network-only'
                });

                if (res.data) {
                    if (res.data.profileUsers) {
                        const newState = { ...stateProfile };
                        newState.users = [...newState.users, ...res.data.profileUsers];
                        setPageNumber(pageNumber + 1);
                        setStateProfile(newState);
                    } else {
                        setMaxUsers(true);
                    }
                }
            } catch (err) {
                addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
            }
        }
    }, [addToast, client, pageNumber, isMaxUsers, profileModel.profile.profileId, stateProfile, isNewProfile]);

    const loadNextUser = useCallback(() => {
        (async function () {
            if (userScrollDiv.current) {
                if (userScrollDiv.current?.clientHeight === userScrollDiv.current?.scrollHeight) {
                    setHasNextUserRan(true);
                    await loadNextUsersAsync();
                }
            }
        })();
    }, [loadNextUsersAsync]);

    useEffect(() => {
        if (!hasNextUserRan) {
            loadNextUser();
        }
        window.addEventListener('resize', loadNextUser);

        return () => {
            window.removeEventListener('resize', loadNextUser);
        };
    }, [profileModel, loadNextUser, hasNextUserRan]);

    const handleNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const newState = { ...stateProfile };
        newState.profile.profileName = newValue ?? '';
        setBlock();
        setStateProfile(newState);
    };

    const handleDescriptionChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        const newState = { ...stateProfile };
        newState.profile.description = newValue ?? '';
        setBlock();
        setStateProfile(newState);
    };

    const handlePermissionChange = (permissionSetId: number, value: boolean | undefined, name: string | undefined) => {
        const newState = { ...stateProfile };
        const perm = newState.profilePermissionSets.find(x => x.permissionSetId === permissionSetId);
        if (perm && name) {
            const updatedPerm = { ...perm, [name]: value };
            const newPerm = [...newState.profilePermissionSets];
            const index = newPerm.findIndex(x => x.permissionSetId === updatedPerm.permissionSetId);
            newPerm[index] = updatedPerm;
            newState.profilePermissionSets = newPerm;
        }
        setBlock();
        setStateProfile(newState);
    };

    const addNewAssignedUsers = (users: User[]) => {
        const newState = { ...stateProfile };
        let addedUsersCount = 0;
        for (const user of users) {
            if (newState.users.find(x => x.employeeId === user.employeeId) === undefined) {
                newState.users.push(user);
                if (!newState.usersToAdd) {
                    newState.usersToAdd = [];
                }
                newState.usersToAdd.push(user);
                if (!newState.usersToRemove) {
                    newState.usersToRemove = [];
                }
                if (newState.usersToRemove.find(x => x.employeeId === user.employeeId) !== undefined) {
                    newState.usersToRemove = newState.usersToRemove.filter(x => x.employeeId !== user.employeeId);
                }
                addedUsersCount++;
            }
        }
        newState.assignedUserCount += addedUsersCount;
        setStateProfile(newState);
        setBlock();
        setOpenAssignUserModal(!isOpenAssignUserModal);
    };

    const removeUserFromProfile = (user: User) => {
        const newState = { ...stateProfile };
        newState.users = newState.users.filter(x => x.employeeId !== user.employeeId);
        newState.assignedUserCount--;
        if (!newState.usersToRemove) {
            newState.usersToRemove = [];
        }
        newState.usersToRemove.push(user);
        if (!newState.usersToAdd) {
            newState.usersToAdd = [];
        }
        if (newState.usersToAdd.find(x => x.employeeId === user.employeeId) !== undefined) {
            newState.usersToAdd = newState.usersToAdd.filter(x => x.employeeId !== user.employeeId);
        }
        setBlock();
        setStateProfile(newState);
    };

    const setBlock = () => {
        setBlockUnload({
            shouldBlock: true,
            message: 'Are you sure you wish to continue? You will lose your unsaved changes.'
        });
    };

    const detectScrollBottom = async () => {
        if (!isMaxUsers) {
            if (userScrollDiv.current) {
                if (
                    userScrollDiv.current.scrollHeight - userScrollDiv.current.scrollTop ===
                    userScrollDiv.current.clientHeight
                ) {
                    await loadNextUsersAsync();
                }
            }
        }
    };

    const deleteProfile = async () => {
        try {
            const res = await client.mutate({
                mutation: DeleteProfile,
                variables: {
                    profileId: profileModel.profile.profileId
                }
            });

            if (res.data.removeProfile) {
                addToast(`${profileModel.profile.profileName} has been removed`, { appearance: 'success' });
                history.push(configurationRouteBases[ConfigurationPage.Profiles]);
            }
        } catch (err) {
            addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
        }
    };

    const saveNewProfileCheck = () => {
        if (isNullOrEmpty(stateProfile.profile.profileName)) {
            setErrorMessages({ name: 'You must enter a profile name', description: '' });
            return;
        }

        if (stateProfile.profile.profileName.length <= 3) {
            setErrorMessages({ name: 'Profile name must be longer than 3 characters', description: '' });
            return;
        }

        if (stateProfile.profile.profileName.length > 75) {
            setErrorMessages({ name: 'Profile name must be less than 75 characters', description: '' });
            return;
        }

        if (isNullOrEmpty(stateProfile.profile.description)) {
            setErrorMessages({ name: '', description: 'You must enter a profile description' });
            return;
        }

        if (stateProfile.profile.description.length > 300) {
            setErrorMessages({ name: '', description: 'Profile description must be less than 300 characters' });
            return;
        }

        if (stateProfile.profile.description.length <= 3) {
            setErrorMessages({ name: '', description: 'Description must be longer than 3 characters' });
            return;
        }

        if (profileModel.users.length > 0) {
            setConfirmUpdateDialog(true);
        }
        else {
            saveNewProfile();
        }
    };

    const saveNewProfile = () => {
        setBlockUnload({ shouldBlock: false });
        onSave(stateProfile);
    }

    return (
        <StyledConfigurationContainer>
            <div style={{ display: 'flex' }}>
                <div>
                    <StyledHeaderText>
                        {stateProfile.profile.profileName.length > 0 ? stateProfile.profile.profileName : 'New Profile'}
                    </StyledHeaderText>
                </div>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                    <Button
                        text={'Save'}
                        onClick={saveNewProfileCheck}
                        disabled={!hasPermission(WriteOn(UserPermission.Profiles))}
                    />
                    <ConditionalRender
                        condition={profileModel.profile.profileId > 0 && profileModel.users.length === 0}
                    >
                        <Button
                            style={{ marginLeft: '15px' }}
                            text={'Remove Profile'}
                            onClick={() => setConfirmDeleteDialog(!confirmDeleteDialog)}
                            disabled={!hasPermission(WriteOn(UserPermission.Profiles))}
                        />
                    </ConditionalRender>
                </div>
            </div>
            <Grid fluid>
                <Row>
                    <Col lg={12} xl={7}>
                        <StyledSection>
                            <StyledSubHeader>Profile Details</StyledSubHeader>
                            <TextInput
                                label={'Name'}
                                onChange={handleNameChange}
                                value={stateProfile.profile.profileName}
                                required={true}
                                errorMessage={errorMessages?.name}
                                placeholder='Profile Name'
                            />

                            <br />
                            <TextInput
                                label={'Description'}
                                onChange={handleDescriptionChange}
                                value={stateProfile.profile.description}
                                placeholder='Profile description'
                                multiline={true}
                                required={true}
                                errorMessage={errorMessages?.description}
                                rows={6}
                            />
                        </StyledSection>
                        <br />
                        <StyledSection>
                            <StyledSubHeader>Permissions</StyledSubHeader>
                            <div style={{ overflow: 'auto', height: 'calc(100vh - 423px)' }}>
                                <VirtualTable<ProfilePermissionSetModel>
                                    columns={columns}
                                    items={stateProfile.profilePermissionSets}
                                    keyFieldName="permissionSetId"
                                />
                            </div>
                        </StyledSection>
                    </Col>
                    <Col lg={12} xl={5}>
                        <StyledSection>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <div>
                                    <StyledSubHeader>Assigned Users</StyledSubHeader>
                                </div>
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                    <IconButton
                                        iconName={'Add'}
                                        onClick={() => setOpenAssignUserModal(!isOpenAssignUserModal)}
                                    />
                                </div>
                            </div>
                        </StyledSection>
                        <div style={{ height: 'calc(100vh - 175px)', display: 'flex', flexDirection: 'column' }}>
                            {stateProfile.users?.length === 0 && (
                                <div>
                                    <StyledBoldText>There are no users assigned to this profile</StyledBoldText>
                                    <StyledGrayInfoText>
                                        You can assign users by clicking the '+' above
                                    </StyledGrayInfoText>
                                </div>
                            )}

                            {stateProfile.users?.length > 0 && (
                                <div
                                    style={{ flex: '1 1 auto', overflowY: 'scroll', overflowX: 'hidden' }}
                                    ref={userScrollDiv}
                                    onScroll={detectScrollBottom}
                                >
                                    <UserList userList={stateProfile.users} removeUser={removeUserFromProfile} />
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Grid>
            <AssignUser
                isOpen={isOpenAssignUserModal}
                onDismiss={() => setOpenAssignUserModal(!isOpenAssignUserModal)}
                onAddUsers={addNewAssignedUsers}
            />

            <ConfirmationDialog
                show={confirmDeleteDialog}
                title={'Remove Profile'}
                subText={'Are you sure you want to delete this profile?'}
                acceptButtonText={'Delete'}
                declineButtonText={'Cancel'}
                onDecline={() => setConfirmDeleteDialog(!confirmDeleteDialog)}
                onAccept={deleteProfile}
            />
            <ConfirmationDialog
                show={confirmUpdateDialog}
                title={'Update Profile'}
                subText={'Profile contains users, are you sure you want to make this change?'}
                acceptButtonText={'Yes'}
                declineButtonText={'No'}
                onDecline={() => setConfirmUpdateDialog(!confirmUpdateDialog)}
                onAccept={saveNewProfile}
            />
        </StyledConfigurationContainer>
    );
};

export default ProfileConfiguration;
