export const closureReasonNotSelected: string = 'CompleteObservation.ClosureReasonNotSelected';

export const actionTakenNotProvided: string = 'CompleteObservation.ActionTakenNotProvided';

export const actionTakenTooLong: string = 'CompleteObservation.ActionTakenTooLong';

export const closureDateNotProvided: string = 'CompleteObservation.ClosureDateNotProvided';

export const closureDateInFuture: string = 'CompleteObservation.ClosureDateInFuture';

export const closureDateBeforeIncident: string = 'CompleteObservation.ClosureDateBeforeIncident';