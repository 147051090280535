import { gql } from 'graphql.macro';

export const GetSheqManagers = gql`
  query getSheqManager($employeeId: Int!) {
    viewSheqManager: sHEQManager(employeeId: $employeeId) {
      uniqueRecordId
      sheqManagers: managers {
        employeeId
        givenName
        displayName
        department
        jobTitle
      }
      depots {
        pcId: pCID
        pc: pC
        pcName
      }
    }
  }
`;