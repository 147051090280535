import React, { useCallback } from 'react';
import { FontIcon } from '@fluentui/react';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import Button from 'components/controls/Button';
import { StyledNoDataRoot } from '../styled/no-data.styled';

interface INoDataProps {
    children: any;
    hasData: boolean;
    iconName?: string;
    onAction?: () => void;
    onActionText?: string;
    onActionIconName?: string;
    label?: string;
    description?: string;
}

const NoData = ({
    children,
    hasData,
    iconName = 'PageData',
    onAction,
    onActionText = 'Create',
    onActionIconName = 'Add',
    label = 'No results to display',
    description
}: INoDataProps) => {
    return (
        <React.Fragment>
            {!hasData && (
                <StyledNoDataRoot>
                    <FontIcon iconName={iconName} />
                    {!isNullOrUndefined(label) && <h1>{label}</h1>}
                    {!isNullOrUndefined(description) && <h2>{description}</h2>}
                    {!isNullOrUndefined(onAction) && (
                        <Button onClick={onAction} text={onActionText} iconName={onActionIconName} />
                    )}
                </StyledNoDataRoot>
            )}
            {hasData && children}
        </React.Fragment>
    );
};

export default NoData;
