import { useApolloClient } from "@apollo/react-hooks";
import Loading from "components/layout/Loading";
import { cloneDeep } from "lodash";
import { ApolloErrorToastMessage } from "modules/errors";
import { FunctionComponent, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { NearMissCategoryCollectionModel } from "shared/types/configuration/NearMissCategoryModel";
import { GetNearMissCategory } from "../queries/view-near-miss-category.queries";
import NearMissCategoryConfiguration from "../../../components/NearMissCategoryConfiguration";
import { UpdateCategory } from "../mutations/ammend-near-miss-categories.mutations";
import ConfigurationPageContainer from "sections/configuration/components/ConfigurationPageContainer";
import { ApolloError } from "apollo-client";
import { CategoryDisplayEnum } from "../enums/category-display.enum";
import CategorySubHeader from "../CategorySubHeader";
import { ConditionalRender } from "components/layout/ConditionalRender";
import CategoryAudit from "../CategoryAudit";
import { ReadOn, usePermissions, UserPermission } from "modules/permissions";
import CategoryHeader from "../CategoryHeader";
import { isNullOrUndefined } from "shared/utils/null.utils";
import { useTranslation } from 'react-i18next';

const ViewNearMissCategoryConfiguration: FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const client = useApolloClient();
  const { addToast } = useToasts();
  const [data, setData] = useState<NearMissCategoryCollectionModel | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [displayMode, setDisplayMode] = useState<CategoryDisplayEnum>(CategoryDisplayEnum.Overview);
  const [category, setCategory] = useState<NearMissCategoryCollectionModel>();
  const { hasPermission } = usePermissions();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (params && isLoading) {
      client.query<{ nearMissCategoryById: NearMissCategoryCollectionModel }>({
        query: GetNearMissCategory,
        variables: {
          'categoryId': parseInt(params.id)
        },
        fetchPolicy: 'network-only'
      }).then(res => {
        if (res.data?.nearMissCategoryById) {
          if (res.data) {
            setData(res.data?.nearMissCategoryById)
          }
        }
      }).catch(err => {
        addToast(<ApolloErrorToastMessage error={err} baseMessage='Issue retrieving near miss category details' />, {
          appearance: 'error'
        });
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [params, addToast, client]);

  const updateCategory = async () => {
    try {
      setLoading(true);
      const result = await client.mutate<{ updateNearMissCategory: boolean }>({
        mutation: UpdateCategory,
        variables: {
          request: {
            nearMissCategoryId: category?.nearMissCategoryId,
            nearMissCategoryName: category?.nearMissCategoryName,
            nearMissCategoryNameDE: category?.nearMissCategoryNameDE,
            isActive: category?.isActive,
            newUsers: category?.usersToAdd?.map(x => x.employeeId),
            deletedUsers: category?.usersToRemove?.map(x => x.employeeId)
          }
        }
      });

      if (result?.data && result?.data.updateNearMissCategory && !isNullOrUndefined(category)) {
        setData(cloneDeep<NearMissCategoryCollectionModel>(category));
        addToast(updateSuccessMessage(), { appearance: 'success' });
      } 

    } catch (err) {
      addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const updateSuccessMessage = (): string => {
    if(i18n.language === 'de')
    return t(`Category.CategoryUpdated`).replace('[[CategoryName]]', data?.nearMissCategoryNameDE ?? '');
    else
    return t(`Category.CategoryUpdated`).replace('[[CategoryName]]', data?.nearMissCategoryName ?? '');
  }

  const goToOverview = (): void => {
    setDisplayMode(CategoryDisplayEnum.Overview);
  }

  const goToAuditLog = (): void => {
    setDisplayMode(CategoryDisplayEnum.Audit);
  }

  return (
    <ConfigurationPageContainer>
      <Loading isLoading={isLoading} message={"Loading"}>
        {data && (
          <CategoryHeader
            categoryModel={data}
            onSave={updateCategory}
          />)}
        <CategorySubHeader
          displayMode={displayMode}
          formHasErrors={false}
          goToOverview={goToOverview}
          goToAuditLog={goToAuditLog}
        />
        <ConditionalRender condition={displayMode == CategoryDisplayEnum.Overview}>
          {data &&
            (<NearMissCategoryConfiguration
              categoryModel={data}
              onChangeCategory={setCategory}
            />)
          }
        </ConditionalRender>

        <ConditionalRender condition={displayMode == CategoryDisplayEnum.Audit && hasPermission(ReadOn(UserPermission.Audit))}>
          {
            data &&
            <CategoryAudit uniqueRecordId={data.uniqueRecordId} />
          }
        </ConditionalRender>
      </Loading>
    </ConfigurationPageContainer>
  );
}

export default ViewNearMissCategoryConfiguration;