import { ObservationAddPhoto } from 'components/ObservationPhoto/types/observation-photo.type';
import { IMapper } from 'shared/types/IMapper';
import { IFile } from 'shared/types/file/IFile';

export class AddPhotoMapper
    implements
    IMapper<IFile, ObservationAddPhoto> {
    map(
        entity: IFile

    ): ObservationAddPhoto {
        return {
            referenceId: entity.id,
            url: URL.createObjectURL(entity.file),
            delete: false
        };
    }
}
