import React, { FunctionComponent } from 'react';
import { Label } from '@fluentui/react';
import { StyledLabelContainer } from '../styled/LabelContainer.styled';
import { StyledLabelContent } from '../styled/LabelContent.styled';

interface ContentLabelProps {
    labelName: string;
    content: string;
}

const ContentLabel: FunctionComponent<ContentLabelProps> = (props) => {

    return (
        <StyledLabelContainer>
            <Label>{props.labelName}</Label>
            <StyledLabelContent>{props.content}</StyledLabelContent>
        </StyledLabelContainer>
    );
};

export default ContentLabel;