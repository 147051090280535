import { UserSearchState } from '../store/state.types';

export const defaultUserSearchState: UserSearchState = {
    searchError: undefined,
    searchQuery: '',
    searchInputValue: '',
    searchInputError: undefined,
    isSaving: false,
    isAddUserModalOpen: false,
    searchResults: [],
    currentPage: 0,
    resultsPerPage: 20,
    totalResultsCount: 0,
    fetchingResults: false
};
