import styled from 'styled-components';

export const StyledConfigurationMenu = styled.div`
    width: 250px;
    max-width: 250px;
    background-color: ${({ theme }) => theme.palette.gray[100]};
    flex: 1 0 auto;
`;

export const StyledConfigurationMenuHeader = styled.h1`
    margin: 15px 25px;
    line-height: 1.2em;
`;
