import Button from 'components/controls/Button';
import {
   StyledMessagedButton,
   StyledMessagePage,
   StyledMessagePageContainer,
   StyledMessagePageLogo,
   StyledMessagePageLogoContainer,
   StyledMessagePageText,
   StyledMessagePageTextContainer
} from 'shared/styled/message-page.styled';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface NewGoodPracticeSuccessProps {
   onCreateAnother: () => void;
}

export const NewGoodPracticeSuccess = ({ onCreateAnother }: NewGoodPracticeSuccessProps) => {
   const history = useHistory();
   const { t } = useTranslation();

   function handleNearMissClick() {
      history.push('/nearmiss/new');
   }

   return (
      <StyledMessagePageContainer>
         <StyledMessagePage>
            <StyledMessagePageLogoContainer>
               <StyledMessagePageLogo />
            </StyledMessagePageLogoContainer>
            <StyledMessagePageTextContainer>
               <StyledMessagePageText>{t('ObservationSucess.GPLine1')}</StyledMessagePageText>
               <StyledMessagePageText>{t('ObservationSucess.GPLine2')}</StyledMessagePageText>
               <StyledMessagePageText>{t('ObservationSucess.GPLine3')}</StyledMessagePageText>
               <StyledMessagePageText>
                  <StyledMessagedButton>
                     <Button text={t('ObservationPage.NewNearMiss')} onClick={handleNearMissClick} />
                  </StyledMessagedButton>
                  <StyledMessagedButton>
                     <Button text={t('ObservationPage.NewGoodPractice')} onClick={onCreateAnother} />
                  </StyledMessagedButton>
               </StyledMessagePageText>
            </StyledMessagePageTextContainer>
         </StyledMessagePage>
      </StyledMessagePageContainer>
   );
};
