import NearMissCategorisation from 'components/NearMissCategorisation';
import NearMissDetails from 'components/NearMissDetails';
import { NewObservationPhotos } from 'components/ObservationPhoto/components/NewObservationPhoto/NewObservationPhotos';
import { GoogleMapsApiKeyProvider } from 'modules/mapping';
import React from 'react';
import NearMissReporter from 'sections/near-miss-management/pages/ViewAmmendNearMiss/components/NearMissReporter';
import {
    StyledPanelGrid,
    StyledPanelRow,
    StyledPanelColumn,
    StyledContainer
} from 'shared/styled/panel.stayled';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { IAddFiles } from 'shared/utils/files/types/IAddFiles';

interface NewNearMissForm {
    handleAddFile: IAddFiles<ObservationFileEntity>;
    handleDeleteFile: (ids: string[]) => void;
}

const NewNearMissForm = ({
    handleAddFile,
    handleDeleteFile
}: NewNearMissForm) => {

    return (
        <React.Fragment>
            <StyledContainer>
                <StyledPanelGrid fluid>
                    <StyledPanelRow>
                        <StyledPanelColumn md={12} lg={4} xl={4}>
                            <GoogleMapsApiKeyProvider>
                                <NearMissCategorisation readOnly={false} />
                            </GoogleMapsApiKeyProvider>
                        </StyledPanelColumn>
                        <StyledPanelColumn md={12} lg={4} xl={4}>
                            <NearMissDetails readOnly={false} />
                            <NewObservationPhotos handleAddFile={handleAddFile} handleDeleteFile={handleDeleteFile} />
                        </StyledPanelColumn>
                        <StyledPanelColumn md={12} lg={4} xl={4}>
                            <NearMissReporter nearMissReadOnly={false} reporterReadOnly={false} />
                        </StyledPanelColumn>
                    </StyledPanelRow>
                </StyledPanelGrid>
            </StyledContainer>
        </React.Fragment>
    );
};

export default NewNearMissForm;