import { gql } from 'graphql.macro';

export const GetDepots = gql`
query observationsDepotsManagers {
  observationsDepotsManagers: observationsDepotsWithManagers {
    pCID
    pC
    pcName
  }
}
`;