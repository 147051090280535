import styled from 'styled-components';

export const StyledTableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
`;

export const StyledTableCell = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    white-space: normal;
    word-break: break-word;
    height: 100%;
`;

export const StyledCommandBar = styled.div`
    display: flex;
`;

export const SearchInputContainer = styled.div`
    flex: 0 1 50%;
    min-width: 400px;
    margin-right: 25px;
`;
