import styled from 'styled-components';

export const StyledAssignUserContainer = styled.div`
  min-width:20vw;
  min-height: 40vh;
  display:flex
`;

export const StyledAssignUserFlexColumn = styled.div`
  display:flex;
  flex-direction: column;
  flex: 1;
  min-width: 400px;
`;

export const StyledAssignUserFlexRow = styled.div`
  display:flex;
  flex-direction: row;
`;

export const StyledAssignUserBody = styled.div`
  flex: 1 1 auto; 
  margin: 15px;
`;

export const StyledAssignUserFooter = styled.div`
  display:flex;
  flex: 1; 
  justify-content: flex-end;
  padding: 15px;
`;