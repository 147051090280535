import { gql } from 'graphql.macro';

export const GetPaginatedAuditLogs = gql`
  query GetPaginatedAuditLogs($referenceId: Uuid!, $page: Int!, $offSet: Int!) {
    auditRecords: paginatedAuditLogs(referenceId: $referenceId, page: $page, offSet: $offSet) {
      auditLogId
      referenceId
      auditType
      date
      employeeId
      serviceId
      employee {
        employeeId: extenstionAttribute1
        givenName
        surname
        displayName
        email
        jobTitle
        businessPhones
      }
      fieldChanges: changeLogs {
        auditLogId
        changeLogId
        fieldName
        friendlyName
        newValue
        oldValue
      }
    }
  }
`;