import { ObservationSafetyCheckHome } from "components/ObservationSafetyCheck/components/ObservationSafetyCheckHome";
import { AppRoute } from "modules/routing/types/AppRoute";
import { NewGoodPractice } from "../pages/GoodPractice/NewGoodPractice";

export const safetyCheckNew = '/observation/new';

export const goodPracticeReportNewRoutes: AppRoute[] = [
    {
        name: 'Safety-Check',
        path: safetyCheckNew,
        exact: true,
        component: ObservationSafetyCheckHome
    }, {
        name: 'New-Good-Practice',
        path: '/goodpractice/new',
        exact: true,
        component: NewGoodPractice
    }
];