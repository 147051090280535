import { gql } from 'graphql.macro';

export const ApplicationUserSearch = gql`
    query applicationUserSearch($displayName: String, $currentPage: Int!, $resultsPerPage: Int!) {
        applicationUserSearch(displayName: $displayName, currentPage: $currentPage, resultsPerPage: $resultsPerPage) {
            users {
                displayName
                employeeId
                userId
                profiles
                serviceCentre: officeLocation
                dateAdded: createdDate
            }
            metaData {
                totalRecords
            }
        }
    }
`;
