import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledConfigurationMenuItem = styled(NavLink)<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    padding: 15px 25px;
    background-color: ${({ theme }) => theme.palette.gray[100]};
    text-decoration: none;
    color: ${({ theme, disabled }) => (disabled ? theme.palette.gray[400] : theme.palette.common.black)};
    font-size: ${({ theme }) => theme.typography.button.fontSize};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    &:hover {
        background-color: ${({ theme, disabled }) => (disabled ? undefined : theme.palette.gray[200])};
    }

    &.active {
        background-color: ${({ theme }) => theme.palette.gray[200]};
    }
`;
