import { FormFieldData } from "modules/forms";
import { ObservationLocationTypeEnum } from "shared/enums/ObservationLocationType.enum";
import { GoodPracticeEntity } from "shared/types/domain/GoodPracticeEntity";
import { IMapper } from "shared/types/IMapper";
import { ConvertToUTC } from "shared/utils/date.utils";
import { keysAreEqual } from "shared/utils/equality.utils";
import { UpdateGoodPracticeRequest } from "../mutations/amend-good-practice.mutations.types";

export class UpdateGoodPracticeRequestMapper implements IMapper<FormFieldData<GoodPracticeEntity>, UpdateGoodPracticeRequest> {
    map(entity: FormFieldData<GoodPracticeEntity>): UpdateGoodPracticeRequest {
        return {
            observationId: entity.observationId,
            companyId: entity.companyId,
            locationType: entity.locationType.toUpperCase(),
            depotPcId: entity.depotPcId,
            customerSiteName: keysAreEqual(entity.locationType, ObservationLocationTypeEnum.CustomerSite) ? entity.customerSiteName : null,
            locationDescription: keysAreEqual(entity.locationType, ObservationLocationTypeEnum.SunbeltDepot) ? null : entity.locationDescription,
            locationLatitude: keysAreEqual(entity.locationType, ObservationLocationTypeEnum.Other) ? entity.locationLatitude : null,
            locationLongitude: keysAreEqual(entity.locationType, ObservationLocationTypeEnum.Other) ? entity.locationLongitude : null,
            observationCategoryId: entity.observationCategoryId,
            whatHappened: entity.whatHappened,
            whoShouldBeCommended: entity.whoShouldBeCommended,
            reporterType: entity.reporterType,
            anonymousEntry: entity.anonymousEntry,
            contactName: entity.reporterContactName,
            contactPhoneNumber: entity.reporterPhoneNumber,
            contactEmail: entity.reporterEmail,
            incidentDate: ConvertToUTC(entity.incidentDate)
        }
    };
}