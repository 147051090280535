
import styled from 'styled-components';

export const StyledDragContainer = styled.div`
  position: relative;
`;

export const StyledDragOverlay = styled.div<{isDragged: boolean;}>`
${({isDragged}) => `
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 10;
  background: rgba(255,255,255,0.9);

  ${isDragged ? 'display: block;' : ''}
`}
`;

export const StyledDragOverlayInner = styled.div`
  ${({theme}) => `
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 25px;
    background: rgba(0,0,0,0.2);
    border: 2px dashed black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    i {
      font-size: 100px !important;
      color: ${theme.palette.primary.main};
    }

    & > h1 {
      font-size: 24px;
      color: ${theme.palette.primary.main};
      padding: 25px;
    }

    & > h2 {
      font-size: 16px;
      color: ${theme.palette.common.black};
    }
  `}
 
`;