import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { ApolloError } from 'apollo-client';
import { useApolloClient } from '@apollo/react-hooks';

import { ApolloErrorToastMessage } from 'modules/errors';

import { ConfirmationDialog } from 'components/layout/Dialog';

import { SheqManagerEntity } from 'shared/types/configuration/SheqManagerEntity';
import { AmmendSheqManagerLoadingState } from '../../../enums/AmmendSheqManagerLoadingState';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import ErrorDialog from 'components/layout/Dialog/components/ErrorDialog';
import { RemoveSheqManager } from '../mutations/remove-sheq-manager.mutations';
import { RemoveSheqManagerRequest } from '../mutations/remove-sheq-manager.mutations.types';
import { ConfigurationPage, configurationRouteBases } from 'sections/configuration/routes/configuration-page.types';

interface RemoveSheqManagerDialogProps {
  sheqManager: SheqManagerEntity;
  showDialog: boolean;
  setLoadingState: (loadingMessage: AmmendSheqManagerLoadingState, isLoading: boolean) => void;
  onHideDialog: () => void;
}

export const RemoveSheqManagerDialog = ({
  sheqManager,
  showDialog,
  setLoadingState,
  onHideDialog
}: RemoveSheqManagerDialogProps) => {

  const client = useApolloClient();
  const { addToast } = useToasts();
  const history = useHistory();

  const hasDepotsAssigned = useCallback((): boolean => {
    return sheqManager.managedDepots.length > 0;
  }, [sheqManager])

  const handleRemoveSheqManager = async () => {

    setLoadingState(AmmendSheqManagerLoadingState.RemovingSheqManager, true);

    try {
      const result = await client.mutate<{ removeSHEQManager: boolean }, RemoveSheqManagerRequest>({
        mutation: RemoveSheqManager,
        variables: {
          managerEmployeeId: sheqManager.employeeId
        }
      });

      if (result?.data && result?.data.removeSHEQManager) {
        history.push(configurationRouteBases[ConfigurationPage.SheqManager]);
        addToast(`${sheqManager?.displayName} has been removed from the SHEQ Manager list`, { appearance: 'success' });
      }
    } catch (err) {
      addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
    } finally {
      setLoadingState(AmmendSheqManagerLoadingState.ReassigningDepots, false);
    }
  };

  return (
    <>
      <ConditionalRender condition={!hasDepotsAssigned()}>
        <ConfirmationDialog
          title='Remove SHEQ Manager'
          subText={`${sheqManager.displayName} will be removed from the SHEQ Manager list. Are you sure?`}
          show={showDialog}
          onAccept={handleRemoveSheqManager}
          onDecline={onHideDialog}
          hidden
        />
      </ConditionalRender>
      <ConditionalRender condition={hasDepotsAssigned}>
        <ErrorDialog
          show={showDialog}
          title='Cannot Remove SHEQ Manager'
          subText='Unable to remove, SHEQ Manager has depots allocated'
          onAccept={onHideDialog}
        />
      </ConditionalRender>
    </>
  );
};
