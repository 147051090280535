import { gql } from 'graphql.macro';

export const GetObservationReporterTypeLookups = gql`

query($empId: Int!){
    nearMissReporters: nearmissenumlookup(request: { type: REPORTERTYPE }) {
            ...nearMissEnum
  }

    observationReporterDetails: azureADUser(employeeId: $empId){
        ...azureADUser
    }
}

fragment nearMissEnum on NearMissEnumLookupResponse {
    id
    name: type
    description
  }

  fragment azureADUser on AzureADUserModel {
        businessPhones
        department
        displayName
        email
        employeeId
        givenName
        jobTitle
        surname
    }

`;
