import React from 'react';
import { Error } from 'styled-icons/boxicons-regular';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import { ApolloError } from 'apollo-client';
import { StyledErrorBoundaryContainer } from '../styled/GraphQlErrorBoundary.styled';
import { getErrorMessages } from 'modules/errors';
import { useTranslation } from 'react-i18next';

interface GraphQlErrorHandlerProps {
  error?: ApolloError;
  children: any;
}



const GraphQlErrorBoundary = ({error, children}: GraphQlErrorHandlerProps) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {!isNullOrUndefined(error)
      ?
        <StyledErrorBoundaryContainer>
          <Error style={{color: '#FED203'}} size="100px" />
          <h1>{t('Error.Oops')}</h1>
          <ul>
            {getErrorMessages(error).map((errorMessage, index) => 
              <li key={index}>{t('Error.NotAuthorised')}</li>  
            )}
          </ul>
        </StyledErrorBoundaryContainer>
      : 
        children
      }
    </React.Fragment>
  )
}

export default GraphQlErrorBoundary;