import { LinkButton } from "components/controls/LinkButton";
import ActionResponseErrorBoundary from "components/layout/ErrorBoundary/components/ActionResponseErrorBoundary";
import { ActionError } from "modules/errors"
import { useHistory } from "react-router-dom";
import { nearMissManagementHome } from "sections/near-miss-management";


export const NearMissForbidden = () => {

    const history = useHistory();

    const error: ActionError<string> = {
        message: 'You do not have permission to view this Near Miss Record'
    } 

    const returnToNearMissList = (): void => {
        history.push(nearMissManagementHome)
    }

    return (
        <ActionResponseErrorBoundary error={error}>
            Click <LinkButton text="Here" onClick={returnToNearMissList} /> to return to the Near Miss management page
        </ActionResponseErrorBoundary>
    )
};