export {};
// eslint-disable-next-line no-extend-native
Object.defineProperties(String.prototype, {
    format: {
        configurable: false,
        enumerable: false,
        value: function format(...args: string[]) {
            let returnVal = '';

            args.forEach((val, i) => (returnVal = this.replace(`{${i}}`, args[i])));

            return returnVal;
        },
        writable: false
    },
    toProperCase: {
        configurable: false,
        enumerable: false,
        value: function toProperCase() {
            return this.replace(/\w\S*/g, function (value: string) {
                return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
            });
        },
        writable: false
    }
});
