import { gql } from 'graphql.macro';

export const GetUserByEmployeeId = gql`
    query GetUserByEmployeeId($empId: Int!) {
        azureADUser(employeeId: $empId){
            businessPhones
            department
            displayName
            email
            employeeId
            givenName
            jobTitle
            surname
        }
    }
`;