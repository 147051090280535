import { useQuery } from '@apollo/react-hooks';
import Button from 'components/controls/Button';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import Loading from 'components/layout/Loading';
import { usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import ConfigurationPageContainer from 'sections/configuration/components/ConfigurationPageContainer';
import {
   NearMissCategoryPage,
   nearMissCategoryRouteParams
} from 'sections/configuration/routes/configuration-page.types';
import { StyledHeaderText, StyledSection, StyledCommandBar } from 'sections/configuration/styled/configuration.styles';
import { NearMissCategories } from 'shared/types/configuration/NearMissCategoryModel';
import { isNullOrUndefined } from 'shared/utils/validation.utils';
import { GetNearMissCategories } from '../queries/near-miss-category-list.queries';
import NearMissCategoryListTable from './NearMissCategoryListTable';
import { useTranslation } from 'react-i18next';

const NearMissCategoryList: FunctionComponent = () => {
   const history = useHistory();
   const { addToast } = useToasts();
   const { data, loading, error } = useQuery<NearMissCategories>(GetNearMissCategories, {
      fetchPolicy: 'network-only'
   });
   const { hasPermission } = usePermissions();
   const { t } = useTranslation();

   useEffect(() => {
      if (!isNullOrUndefined(error)) {
         addToast('Could not load categories.', { appearance: 'warning' });
      }
   }, [data, addToast]);

   const addCategory = () => {
      history.push(nearMissCategoryRouteParams[NearMissCategoryPage.NewCategory]());
   };
      
   return (
      <ConfigurationPageContainer>
         <>
            <StyledHeaderText>{t('ConfigurationMenu.Categories')}</StyledHeaderText>
            <StyledCommandBar>
               <Button
                  style={{ marginLeft: 'auto' }}
                  onClick={addCategory}
                  iconName='Add'
                  text={t('Category.AddCategory')}
                  disabled={!hasPermission(WriteOn(UserPermission.Category))}
               />
            </StyledCommandBar>
            <StyledSection>
               <GraphQlErrorBoundary error={error}>
                  <Loading isLoading={loading} message={t('SheqManager.Loading')}>
                     <NearMissCategoryListTable data={data?.nearMissCategories} />
                  </Loading>
               </GraphQlErrorBoundary>
            </StyledSection>
         </>
      </ConfigurationPageContainer>
   );
};

export default NearMissCategoryList;
