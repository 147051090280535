import styled from "styled-components";

interface StyledNearMissCategoryDataProps {
    isActive: boolean;
}

export const StyledNoResults = styled.h2`
  text-align: center;
  margin: 50px 0;
`;

export const StyledNearMissCategoryData = styled.div<StyledNearMissCategoryDataProps>`
    ${p => !p.isActive && 
        'color: rgb(164, 38, 44)'
    }
`;