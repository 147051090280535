import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useApolloClient } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import { ApolloErrorToastMessage } from 'modules/errors';
import { usePermissions, UserPermission, WriteOn } from 'modules/permissions';

import { ButtonProps } from 'components/controls/Button';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import Loading from 'components/layout/Loading';

import { ConfigurationPage, configurationRouteBases } from 'sections/configuration/routes/configuration-page.types';
import ConfigurationPageContainer from 'sections/configuration/components/ConfigurationPageContainer';
import { AdUserEntity } from 'shared/types/configuration/AdUserEntity';
import { SheqManagerEntity } from 'shared/types/configuration/SheqManagerEntity';
import { DepotEntity } from 'shared/types/domain/DepotEntity';
import SheqManagerConfiguration from '../../../components/SheqManagerConfiguration';
import { GetAdUserForSheqManager } from './GetAdUserForSheqManager/GetAdUserForSheqManager';
import { CreateSheqManager } from '../mutations/new-sheq-manager.mutations';
import { CreateSheqManagerRequest } from '../mutations/new-sheq-manager.mutations.types';
import SheqManagerHeader from '../../ViewSheqManager/SheqManagerHeader';

export const NewSheqManager = () => {

    const client = useApolloClient();
    const { hasPermission } = usePermissions();
    const { addToast } = useToasts();
    const history = useHistory();

    const [sheqManager, setSheqManager] = useState<SheqManagerEntity>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [newDepots, setNewDepots] = useState<DepotEntity[]>([]);

    const createSheqManager = async () => {
        try {
            if (!sheqManager) return;

            setIsLoading(true);
            const result = await client.mutate<{ createSHEQManager: number }, CreateSheqManagerRequest>({
                mutation: CreateSheqManager,
                variables: {
                    request: {
                        managerEmployeeId: sheqManager?.employeeId ?? 0,
                        newDepotPcIds: newDepots.map(x => x.pcId)
                    }
                }
            });

            if (result?.data && result?.data.createSHEQManager) {
                addToast(`${sheqManager?.givenName}'s SHEQ Manager profile has been created`, { appearance: 'success' });
                history.push(configurationRouteBases[ConfigurationPage.SheqManager]);
            }
        } catch (err) {
            addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const selectADUser = (user: AdUserEntity | null | undefined): void => {

        if (!user) return;

        setSheqManager({
            employeeId: user.employeeId,
            displayName: user.displayName,
            givenName: user.givenName,
            department: user.department,
            jobTitle: user.jobTitle,
            uniqueRecordId: '',
            managedDepots: []
        })
    }

    const additionalButtons = (): ButtonProps[] => {
        return [
            {
                text: 'Switch User',
                onClick: () => setSheqManager(undefined),
                disabled: false,
                iconName: 'Back'
            }
        ]
    }

    return (
        <ConfigurationPageContainer>
            <Loading isLoading={isLoading}>
                <ConditionalRender condition={!sheqManager}>
                    <GetAdUserForSheqManager onSelectADUser={selectADUser} />
                </ConditionalRender>
                <ConditionalRender condition={!!sheqManager}>
                    <SheqManagerHeader
                        sheqManagerModel={sheqManager!}
                        allowSave
                        onSave={createSheqManager}
                        additionalButtons={additionalButtons()}
                    />
                    <SheqManagerConfiguration
                        sheqManager={sheqManager!}
                        onChangeNewDepotLinks={setNewDepots}
                        onChangeSurplusDepotLinks={() => { }}
                    />
                </ConditionalRender>
            </Loading>
        </ConfigurationPageContainer>
    );
};