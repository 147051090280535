import { useEffect, useState } from 'react';

import {
    StyledPostLogoutContainer,
    StyledLogo,
    StyledLogoContainer,
    StyledText,
    StyledTextContainer,
    StyledContainer
} from '../styled/PostLogout.styles';

import { useHistory } from 'react-router-dom';
import Button from 'components/controls/Button';
import { clearPostLogout, isPostLogoutSet } from '../utils/post-logout.utils';
import { useTranslation } from 'react-i18next';

/**
 * PostLogout component
 */
export const PostLogout = () => {
    const [shouldRender, setShouldRender] = useState<boolean>(false);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isPostLogoutSet()) history.push('/');
        else {
            setShouldRender(true);
            clearPostLogout();
        }
    }, [history]);

    return (
        <>
            {shouldRender && (
                <StyledPostLogoutContainer>
                    <StyledContainer>
                        <StyledLogoContainer>
                            <StyledLogo />
                        </StyledLogoContainer>
                        <StyledText>
                            <StyledTextContainer>
                                {t('LogOut.SuccessfullyLoggedOut')}
                            </StyledTextContainer>
                            <StyledTextContainer>
                                <Button text= {t('LogOut.Login')} onClick={() => history.push('/')} />
                            </StyledTextContainer>
                        </StyledText>
                    </StyledContainer>
                </StyledPostLogoutContainer>
            )}
        </>
    );
};
