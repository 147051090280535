import styled from 'styled-components';

const StyledInputError = styled.span`
  display: block;
  font-size: 10px;
  font-style: italic;
  color: red;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 10px;
  min-height: 15px;
`;

export { StyledInputError };