import React, { useEffect, useState, ReactNode } from 'react';
import { ConfigContext, defaultConfigContext } from '../contexts/config.context';
import { IConfig } from '../types/IConfig';

interface IConfigProviderProps {
  children: ReactNode;
  /**The path to the config file */
  path: string;
}

export const ConfigProvider = ({
  children,
  path
}: IConfigProviderProps) => {
  const [ config, setConfig ] = useState<IConfig>(defaultConfigContext);
  const [ hasLoaded, setHasLoaded ] = useState<boolean>(false);

  useEffect(() => {
    setHasLoaded(false);

    fetch(path)
      .then((response) => response.json())
      .then((settings)=> {
        setConfig({
          settings
        });

        setHasLoaded(true)
      })
      .catch(error => alert('There was an issue loading the configuration for the application, please contact IT support.'))
  }, [path])

  return (
    <ConfigContext.Provider value={config}>
      {hasLoaded && children}
    </ConfigContext.Provider>
  );
}