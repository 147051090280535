import { useApolloClient } from '@apollo/react-hooks';
import { IDropdownOption, TextField } from '@fluentui/react';
import { ApolloError } from 'apollo-client';
import Select from 'components/controls/Select';
import React, { useCallback, useEffect, useState } from 'react';
import { StyledControlContainer } from 'shared/styled/control.styled';
import {
   StyledContainer,
   StyledPanelColumn,
   StyledPanelGrid,
   StyledPanelRow,
   StyledPanelTitle
} from 'shared/styled/panel.stayled';
import { DayFormat, MonthFormat, renderDate, renderTime, WeekdayFormat, YearFormat } from 'shared/utils/format.utils';
import { mapToDropdownOptions } from 'shared/utils/mapping.utils';
import { GoodPracticeCompletion } from '../../../queries/view-good-practice.queries.types';
import { GetGoodPracticeCompletionLookups } from 'sections/good-practice-management/pages/ViewAmendGoodPractice/queries/good-practice-completion.queries';
import { IClosureReason, IObservationCompletionLookupData } from 'shared/queries/observation-completion.queries.types';
import CompletedByDisplay from 'components/CompletedByDisplay';
import { useTranslation } from 'react-i18next';

interface GoodPracticeCompletionProps {
   goodPracticeCompletion: GoodPracticeCompletion | undefined;
}

export const ViewGoodPracticeCompletion = ({ goodPracticeCompletion }: GoodPracticeCompletionProps) => {
   const client = useApolloClient();
   const { t, i18n } = useTranslation();
   const [loadingClosureReasons, setLoadingClosureReasons] = useState<boolean>(true);
   const [loadingClosureReasonsError, setLoadingClosureReasonsError] = useState<ApolloError>();

   const [closureReasons, setClosureReasons] = useState<IClosureReason[]>();

   useEffect(() => {
      loadReporterTypes();
   }, []);

   const loadReporterTypes = async () => {
      setLoadingClosureReasons(true);

      try {
         let result = await client.query<IObservationCompletionLookupData>({
            query: GetGoodPracticeCompletionLookups,
            variables: {
               request: { type: 'GOODPRACTICECLOSUREREASON', selectedLanguage: i18n.language.toUpperCase() }
            },
            fetchPolicy: 'network-only'
         });

         if (result?.data?.closureReasons != null) {
            setClosureReasons(result.data.closureReasons);
         }
      } catch (e: any) {
         setLoadingClosureReasonsError(e);
      } finally {
         setLoadingClosureReasons(false);
      }
   };

   const renderCompletionDateTime = useCallback(() => {
      const datePart = renderDate(goodPracticeCompletion?.closeDate, {
         day: DayFormat.Numeric,
         month: MonthFormat.Short,
         weekday: WeekdayFormat.Short,
         year: YearFormat.Numeric
      });

      const timePart = renderTime(goodPracticeCompletion?.closeDate?.toLocaleString() ?? '00:00');

      return `${datePart} ${timePart}`;
   }, [goodPracticeCompletion]);

   return (
      <React.Fragment>
         <StyledContainer>
            <StyledPanelGrid fluid>
               <StyledPanelRow>
                  <StyledPanelColumn md={12} lg={6} xl={6}>
                     <StyledPanelTitle>{t('CompleteObservation.CompletionDate')}</StyledPanelTitle>
                     <StyledControlContainer>
                        <TextField label='' value={renderCompletionDateTime()} onChange={(e, v) => ''} disabled />
                     </StyledControlContainer>

                     <StyledPanelTitle>{t('CompleteObservation.CompletedBy')}</StyledPanelTitle>
                     <StyledControlContainer>
                        <CompletedByDisplay completedByEmployeeId={goodPracticeCompletion?.employeeId} />
                     </StyledControlContainer>

                     <StyledPanelTitle>{t('CompleteObservation.ClosureReason')}</StyledPanelTitle>
                     <StyledControlContainer>
                        <Select
                           required
                           selectedKey={goodPracticeCompletion?.closureReason}
                           multiSelect={false}
                           onChange={(e, v) => ''}
                           options={mapToDropdownOptions<IClosureReason, IDropdownOption>(
                              closureReasons,
                              reason => reason.name.toString().toUpperCase(),
                              reason => reason.description
                           )}
                           isLoading={loadingClosureReasons}
                           disabled
                        />
                     </StyledControlContainer>
                  </StyledPanelColumn>
               </StyledPanelRow>
            </StyledPanelGrid>
         </StyledContainer>
      </React.Fragment>
   );
};
