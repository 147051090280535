import ReactDOM from 'react-dom';
import './index.css';

import 'shared/extensions/string.extensions';
import 'shared/extensions/number.extensions';
import 'shared/extensions/array.extensions';
import { setupFluentUiTheme } from 'shared/themes/fluentui.theme';

import App from 'components/App';

setupFluentUiTheme();

ReactDOM.render(<App />, document.getElementById('root'));