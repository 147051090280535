import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
    StyledBoldText,
    StyledGrayInfoText,
    StyledHeaderText,
    StyledSection,
    StyledSubHeader
} from '../../../../../styled/configuration.styles';
import Button, { IconButton } from 'components/controls/Button';
import { Col, Grid, Row } from 'react-flexbox-grid';
import VirtualTable from 'components/layout/VirtualTable/Table/components/VirtualTable';
import {
    UserDepotViewModel,
    UserPermissionEnumValue,
    UserPermissionSetModel,
    UserProfileViewModel,
    UserProfileViewModelQueryType
} from 'sections/configuration/pages/UserConfiguration/types/UserProfileViewModel';
import { IVirtualTableColumn } from 'components/layout/VirtualTable/Table/components/VirtualTable';
import Loading from 'components/layout/Loading';
import Select from 'components/controls/Select';
import { IDropdownOption } from '@fluentui/react';
import ContentLabel from 'components/controls/ContentLabel/components/ContentLabel';
import AssignedProfileList from './AssignedProfileList';
import AddProfileModal from './AddProfileModal';
import { ProfileListViewModel } from 'shared/types/configuration/ProfileModels';
import { useUnload } from 'shared/hooks/useUnload';
import { useApolloClient } from '@apollo/react-hooks';
import { GetUserProfile } from '../queries/user-configuration.queries';
import { GetDepotsLinkedToManager } from '../queries/user-configuration-depots.queries';
import ApolloErrorToastMessage from 'shared/utils/errors/components/ApolloErrorToastMessage';
import { useToasts } from 'react-toast-notifications';
import { cloneDeep } from 'lodash';
import { UpdateUser } from 'sections/configuration/pages/UserConfiguration/types/user-configuration.mutation';
import ConfigurationPageContainer from 'sections/configuration/components/ConfigurationPageContainer';
import { ApolloError } from 'apollo-client';
import { ConfirmationDialog } from 'components/layout/Dialog';
import { ReadOn, usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import { DepotEntity } from 'shared/types/domain/DepotEntity';
import { useArrayState } from 'shared/hooks/useArrayState';
import MultiDepotSearchDialog from './MultiDepotSearchDialog';
import LinkedDepotList from 'shared/components/LinkedDepotList';
import { UpdateUserDepotRequest } from '../mutations/update-user-depots.mutations.types';
import { UpdateUserDepot } from '../mutations/update-user-depots.mutations';
import { DepotListQuery } from './MultiDepotSearchDialog/types/user-configuration-depot-types';
import { ConditionalRender } from 'components/layout/ConditionalRender';

const ViewUserConfiguration: FunctionComponent = () => {
    const { params } = useRouteMatch<{ id: string }>();
    const [user, setUser] = useState<UserProfileViewModel>({} as UserProfileViewModel);
    const [originalPermissionSets, setOriginalPermissionSets] = useState<UserPermissionSetModel[]>([]);
    const [updatedPermissionSets, setUpdatedPermissionSets] = useState<UserPermissionSetModel[]>([]);
    const [originalProfileList, setOriginalProfileList] = useState<ProfileListViewModel[]>([]);
    const [isAddProfileOpen, setAddProfileOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { setBlockUnload } = useUnload();
    const client = useApolloClient();
    const { addToast } = useToasts();
    const [confirmUpdateDialog, setConfirmUpdateDialog] = useState<boolean>(false);
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const { hasPermission } = usePermissions();
    const [loadingError, setLoadingError] = useState<ApolloError>();

    const defaultRequest: DepotListQuery = {
        depotPcId: []
    }

    const [showDepotSearchDialog, setShowDepotSearchDialog] = useState<boolean>(false);
    const [depotRequest, setDepotRequest] = useState<DepotListQuery>(defaultRequest);

    const [
        loadedDepots,
        setLoadedDepots,
    ] = useArrayState<DepotEntity>({ key: 'pcId' });

    const [
        displayDepotLinks,
        setDisplayDepotLinks,
        addToDisplayDepotLinks,
        removeFromDisplayDepotLinks
      ] = useArrayState<DepotEntity>({ key: 'pcId' });
    
      const [
        newDepotLinks,
        setNewDepotLinks,
        addToNewDepotLinks,
        removeFromNewDepotLinks
      ] = useArrayState<DepotEntity>({ key: 'pcId' });
    
      const [
        surplusDepotLinks,
        setSurplusDepotLinks,
        addToSurplusDepotLinks,
        removeFromSurplusDepotLinks
      ] = useArrayState<DepotEntity>({ key: 'pcId' });
    
    useEffect(() => {
        resetAllDepotLists();
      }, [user])
    
    const resetAllDepotLists = () => {
        setDisplayDepotLinks(displayDepotLinks);
        setNewDepotLinks([]);
        setSurplusDepotLinks([]);
      }
    
      const handleAddDepotLink = (depots: DepotEntity[]) => {

          depots.forEach(function (depot) {

              addToDisplayDepotLinks(depot);

              if (!depotIsAssigned(depot)) {
                  addToNewDepotLinks(depot);
              }

              removeFromSurplusDepotLinks(depot);
          });
      };
    
    const handleRemoveDepotLink = (depot: DepotEntity) => {
        if (depotIsAssigned(depot)) {
            addToSurplusDepotLinks(depot);
        }
        removeFromDisplayDepotLinks(depot);
        removeFromNewDepotLinks(depot);
      };
    
    function depotIsAssigned(depot: DepotEntity): boolean {
        return !!(loadedDepots.find(x => x.pcId == depot.pcId));
    }
    
    useEffect(() => {
        if (!hasLoaded) {
            setIsLoading(true);

            if (user.employeeId === undefined) {
                client
                    .query<UserProfileViewModelQueryType>({
                        query: GetUserProfile,
                        variables: {
                            userId: parseInt(params.id)
                        },
                        fetchPolicy: 'network-only'
                    })
                    .then(response => {
                        if (response?.data) {
                            setUser({
                                employeeId: response.data.user.user.employeeId,
                                displayName: response.data.user.user.displayName,
                                serviceCentre: response.data.user.user.officeLocation,
                                email: response.data.user.user.email,
                                jobTitle: response.data.user.user.jobTitle,
                                businessPhones: response.data.user.user.businessPhones,
                                permissionSets: response.data.user.permissionSets,
                                profileList: response.data.user.profileList,
                                userId: parseInt(params.id)
                            });
                            setOriginalPermissionSets(cloneDeep<UserPermissionSetModel[]>(response.data.user.permissionSets));
                            setOriginalProfileList(cloneDeep<ProfileListViewModel[]>(response.data.user.profileList));

                            if (hasPermission(ReadOn(UserPermission.ManageDepots))) {
                                client
                                    .query<UserDepotViewModel>({
                                        query: GetDepotsLinkedToManager,
                                        variables: {
                                            employeeId: response.data.user.user.employeeId
                                        },
                                        fetchPolicy: 'network-only'
                                    })
                                    .then(response => {
                                        if (response?.data) {
                                            setLoadedDepots(
                                                response.data.depot
                                            );
                                        }
                                    })
                                    .catch(err => {
                                        setLoadingError(err as ApolloError);
                                    })
                            }
                        }
                    })
                    .catch(err => {
                        setLoadingError(err as ApolloError);
                    })
                    .finally(() => {
                        setIsLoading(false);
                        setHasLoaded(true);
                    });
            }
        }
    }, [params, addToast, client, hasLoaded]);


    useEffect(() => {

        setDisplayDepotLinks(cloneDeep<DepotEntity[]>(loadedDepots));

    }, [loadedDepots])


    const updateUserDepot = async () => {
        try {

            if (!user) return;

            if (newDepotLinks.length === 0 && surplusDepotLinks.length === 0) return;

            const result = await client.mutate<{ updateUserDepots: boolean }, UpdateUserDepotRequest>({
                mutation: UpdateUserDepot,
                variables: {
                    request: {
                        employeeId: user?.employeeId ?? 0,
                        newDepotPCIds: newDepotLinks.map(x => x.pcId),
                        deletedDepotPCIds: surplusDepotLinks.map(x => x.pcId)
                    }
                }
            });

            if (result?.data && result?.data.updateUserDepots) {
                addToast(`${user?.displayName}'s depot details have been updated`, { appearance: 'success' });
            }
        } catch (err) {
            addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
        }
    };

    const handlePermissionChange = (
        permissionSetId: number,
        permissionName: string,
        option?: IDropdownOption | undefined
    ) => {
        const tmpUser: UserProfileViewModel = { ...user };
        const permission = tmpUser.permissionSets.find(x => x.permissionSetId === permissionSetId);
        if (permission) {
            const opt = options
                .map(x => {
                    return {
                        displayText: x.text,
                        value: x.key === 'true' ? true : x.key === 'false' ? false : null
                    } as UserPermissionEnumValue;
                })
                .find(x => (x.value?.toString() ?? 'null') === option?.key)!;
            if (opt.value == null) {
                const originalOpt = originalPermissionSets.find(x => x.permissionSetId === permissionSetId);
                if (originalOpt) {
                    const val = originalOpt[permissionName as keyof UserPermissionSetModel] as UserPermissionEnumValue;
                    opt.displayText = val.displayText.indexOf('(Inherited)') !== -1 ? val.displayText : originalOpt.permissionData.inheritedDisplayText;
                }
            }
            const updatedPermission = { ...permission, [permissionName]: opt };
            const newPermissions = [...tmpUser.permissionSets];
            const index = newPermissions.findIndex(x => x.permissionSetId === updatedPermission.permissionSetId);
            newPermissions[index] = updatedPermission;
            tmpUser.permissionSets = newPermissions;
            setUpdatedPermissionSets([...updatedPermissionSets, updatedPermission]);
        }
        setBlock();
        setUser(tmpUser);
    };

    const addToProfileList = (selected: ProfileListViewModel[]) => {
        setUser({ ...user, profileList: [...user.profileList, ...selected] });
        setBlock();
        setAddProfileOpen(false);
    };

    const removeProfile = (profile: ProfileListViewModel) => {
        setUser({ ...user, profileList: user.profileList.filter(x => x.profileId !== profile.profileId) });
    };

    const columns: IVirtualTableColumn<UserPermissionSetModel>[] = [
        {
            key: 'column1',
            name: 'Permission Set Name',
            fieldName: 'permissionSetName',
            minWidth: 150,
            maxWidth: 400,
            isResizable: false,
            data: 'string',
            isPadded: false,
            onRender: (item: UserPermissionSetModel) => <div style={{ whiteSpace: 'normal' }}>{item.permissionSetName}</div>
        },
        {
            key: 'column2',
            name: 'Has Access?',
            fieldName: 'hasAccess',
            minWidth: 40,
            maxWidth: 40,
            isResizable: true,
            data: 'string',
            isPadded: false,
            onRender: (item: UserPermissionSetModel) => (
                <Select
                    name={'permissionData'}
                    selectedKey={item.permissionData.value?.toString() ?? 'null'}
                    onRenderTitle={(props, defaultRender) => {
                        if (!item.permissionData.value) {
                            return <span>{item.permissionData.displayText}</span>;
                        }
                        return defaultRender?.call(undefined, props) ?? null;
                    }}
                    options={options}
                    directionalHintNotFixed={true}
                    onChange={(ev, opt) => handlePermissionChange(item.permissionSetId, 'permissionData', opt)}
                />
            )
        }
    ];

    const setBlock = () => {
        setBlockUnload({
            shouldBlock: true,
            message: 'Are you sure you wish to continue? You will lose your unsaved changes.'
        });
    };

    const resetChanges = () => {
        setUser({ ...user, permissionSets: originalPermissionSets, profileList: originalProfileList });
    }

    const saveChangesCheck = () => {
        setConfirmUpdateDialog(true);
    };

    const saveChanges = async () => {
        setIsLoading(true);
        try {
            const res = await client.mutate<boolean>({
                mutation: UpdateUser,
                variables: {
                    userId: user.userId,
                    profileIds: user.profileList.map(x => x.profileId),
                    permissionSets: user.permissionSets.filter(x => x.permissionData.value != null)
                        .map(x => {
                            return {
                                permissionSetId: x.permissionSetId,
                                permissionSetEnabled: x.permissionData.value
                            }
                        })
                }
            });

            if (res) {
                addToast(`User ${user.displayName} has been updated`, { appearance: 'success' });
            }
            setBlockUnload({
                shouldBlock: false
            });
            updateUserDepot();
        } catch (err) {
            addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
        } finally {
            setConfirmUpdateDialog(!confirmUpdateDialog)
            setIsLoading(false);
        }
    };

    return (
        <>
            <Loading isLoading={isLoading} noDelay={true}>
                <GraphQlErrorBoundary error={loadingError}>
                    {user.employeeId && (
                        <ConfigurationPageContainer>
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <StyledHeaderText style={{ display: 'flex', flex: 'auto' }}>{user?.displayName}</StyledHeaderText>
                                    </div>
                                    <div style={{ display: 'flex', flex: 'auto', justifyContent: 'flex-end', marginRight: '5px' }}>
                                        <Button text={'Reset'} onClick={resetChanges} disabled={!hasPermission(WriteOn(UserPermission.User))} />
                                    </div>
                                    <div style={{ display: 'flex', flex: 'initial', justifyContent: 'flex-end' }}>
                                        <Button text={'Save'} onClick={saveChangesCheck} disabled={!hasPermission(WriteOn(UserPermission.User))} />
                                    </div>
                                </div>
                                <Grid fluid>
                                    <Row>
                                        <Col lg={12} xl={8}>
                                            <StyledSection>
                                                <StyledSubHeader>User Details</StyledSubHeader>
                                                <StyledSection>
                                                    <Grid fluid>
                                                        <Row>
                                                            <Col xl={4}>
                                                                <ContentLabel labelName={'Name'} content={user?.displayName} />
                                                                <ContentLabel labelName={'Job Title'}
                                                                    content={user?.jobTitle} />
                                                            </Col>
                                                            <Col xl={4}>
                                                                <ContentLabel labelName={'Email'} content={user?.email} />
                                                                <ContentLabel labelName={'Business Phone(s)'}
                                                                    content={user?.businessPhones.join()} />
                                                            </Col>
                                                            <Col xl={4}>
                                                                <ContentLabel labelName={'Service Centre'}
                                                                    content={user?.serviceCentre} />
                                                            </Col>
                                                        </Row>
                                                    </Grid>
                                                </StyledSection>
                                            </StyledSection>
                                            <br />
                                            <StyledSection>
                                                <StyledSubHeader>Permissions</StyledSubHeader>
                                                <div style={{ overflow: 'auto', maxHeight: 'calc(100vh - 330px)' }}>
                                                    <VirtualTable<UserPermissionSetModel>
                                                        columns={columns}
                                                        items={user.permissionSets}
                                                        keyFieldName='permissionSetId'
                                                    />
                                                </div>
                                            </StyledSection>
                                        </Col>
                                        <Col lg={12} xl={4}>
                                            <StyledSection>
                                                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <div>
                                                        <StyledSubHeader>Assigned Profiles</StyledSubHeader>
                                                    </div>
                                                    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                                        <IconButton
                                                            iconName={'Add'}
                                                            onClick={() => setAddProfileOpen(!isAddProfileOpen)}
                                                        />
                                                    </div>
                                                </div>
                                            </StyledSection>
                                            <div
                                                style={{
                                                    maxHeight: 'calc(100vh - 175px)',
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                {user.profileList.length === 0 && (
                                                    <div>
                                                        <StyledBoldText>
                                                            There are no profiles assigned to this user
                                                        </StyledBoldText>
                                                        <StyledGrayInfoText>
                                                            You can assign users by clicking the '+' above
                                                        </StyledGrayInfoText>
                                                    </div>
                                                )}

                                                <div style={{ flex: '1 1 auto', overflowY: 'auto', overflowX: 'hidden' }}>
                                                    <AssignedProfileList
                                                        profileList={user?.profileList}
                                                        removeProfile={removeProfile}
                                                    />
                                                </div>
                                            </div>
                                            <ConditionalRender condition={!!hasPermission(ReadOn(UserPermission.ManageDepots))}>
                                            <StyledSection>
                                                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <div>
                                                        <StyledSubHeader>Managed Depots</StyledSubHeader>
                                                    </div>
                                                    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                                        <IconButton iconName={'Add'} onClick={() => setShowDepotSearchDialog(true)} />
                                                    </div>
                                                </div>
                                            </StyledSection>
                                            <div style={{ height: 'calc(100vh - 175px)', display: 'flex', flexDirection: 'column' }}>
                                              {displayDepotLinks.length === 0 && (
                                                <div>
                                                  <StyledBoldText>There are no depots assigned to this User</StyledBoldText>
                                                  <StyledGrayInfoText>You can assign depots by clicking the '+' above</StyledGrayInfoText>
                                                </div>
                                                )}
                                              {displayDepotLinks.length > 0 && (
                                                    <div style={{ flex: '1 1 auto', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                        <LinkedDepotList depotList={displayDepotLinks} onRemoveDepot={handleRemoveDepotLink} depotManager={user.employeeId} />
                                                </div>
                                                )}
                                                </div>
                                            </ConditionalRender>
                                        </Col>
                                    </Row>
                                </Grid>
                                <MultiDepotSearchDialog
                                    showDialog={showDepotSearchDialog}
                                    onAddDepot={handleAddDepotLink}
                                    onHideDialog={() => setShowDepotSearchDialog(false)}
                                    includeDepots={surplusDepotLinks}
                                    excludeDepots={displayDepotLinks}
                                    selectedDepots={depotRequest}
                                    setSelectedDepots={setDepotRequest}
                                    employeeId={user.employeeId}
                                />
                                <ConfirmationDialog
                                    show={confirmUpdateDialog}
                                    title={'Save User Profile'}
                                    subText={'Are you sure you want to change the users profile?'}
                                    acceptButtonText={'Yes'}
                                    declineButtonText={'No'}
                                    onDecline={() => setConfirmUpdateDialog(!confirmUpdateDialog)}
                                    onAccept={saveChanges}
                                />
                            </>
                        </ConfigurationPageContainer>
                    )}
                </GraphQlErrorBoundary>
            </Loading>
            {isAddProfileOpen && (
                <AddProfileModal
                    isOpen={isAddProfileOpen}
                    onDismiss={() => setAddProfileOpen(!isAddProfileOpen)}
                    profileList={user?.profileList}
                    onProfilesSelected={addToProfileList}
                />
            )}
        </>
    );
};

const options: IDropdownOption[] = [
    { key: 'true', text: 'Allow' },
    { key: 'false', text: 'Deny' },
    { key: 'null', text: 'Not Set' }
];

export default ViewUserConfiguration;
