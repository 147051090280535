import { useApolloClient } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import Button from 'components/controls/Button';
import TextInput from 'components/controls/TextInput';
import { ConfirmationDialog } from 'components/layout/Dialog';
import ActionResponseErrorBoundary from 'components/layout/ErrorBoundary/components/ActionResponseErrorBoundary';
import Loading from 'components/layout/Loading';
import { IAppConfig, useConfig } from 'modules/config';
import { ActionError } from 'modules/errors';
import { ActionResponse, ActionType } from 'modules/errors/classes/ActionResponse';
import { useNotifications } from 'modules/notifications';
import { useValidation } from 'modules/validation';
import { IValidationResult } from 'modules/validation/types/IValidationResult';
import { useEffect, useMemo, useReducer, useState } from 'react';
import ConfigurationPageContainer from 'sections/configuration/components/ConfigurationPageContainer';
import {
   StyledButtonContainer,
   StyledConfigurationContainer,
   StyledHeaderText,
   StyledHorizontalRule,
   StyledOptionContainer,
   StyledOptionDescription,
   StyledOptionLabel,
   StyledOptionTextContainer,
   StyledSection,
   StyledSubHeader
} from 'sections/configuration/styled/configuration.styles';
import { useUnload } from 'shared/hooks/useUnload';
import { formatNumber } from 'shared/utils/format.utils';
import { isNullOrEmpty, isUndefined } from 'shared/utils/null.utils';
import { tryParseInt } from 'shared/utils/string.utils';
import { GeneralAppSettingEnum } from '../enums/AppSetting.enum';
import {
   mapAppSettingStateToUpdateRequest,
   mapGetAppSettingQueryToInitialisationPayload
} from '../mappers/appsetting-state.mappers';
import { UpdateGeneralAppSettingMutation } from '../mutations/appsetting.mutations';
import {
   UpdateGeneralAppSettingMutationRequest,
   UpdateGeneralAppSettingMutationResponse
} from '../mutations/appsetting.mutations.types';
import { InitialiseAppSettingQuery } from '../queries/near-miss-appsettings.queries';
import { InitialiseAppSettingQueryResponse } from '../queries/near-miss-appsettings.queries.types';
import { defaultGeneralAppSettingState } from '../store/appsetting.state';
import {
   initialiseAppSetting,
   resetChanges,
   saveChanges,
   setArchivePeriod,
   setInitialisationError,
   setIsLoading,
   setIsSaving,
   setValidation
} from '../store/appsettings.actions';
import { generalAppSettingStateReducer } from '../store/appsettings.reducer';
import { useTranslation } from 'react-i18next';

const AppSettings = () => {
   const { setBlockUnload } = useUnload();
   const { validate } = useValidation();
   const apolloClient = useApolloClient();
   const { notifyError, notifySuccess } = useNotifications();
   const [isArchivePeriodFocused, setIsArchivePeriodFocused] = useState<boolean>(false);
   const [archivePeriodWarningMsg, setArchivePeriodWarningMsg] = useState<string>('');
   const [showDiscard, setShowDiscard] = useState<boolean>(false);
   const { settings } = useConfig<IAppConfig>();
   const [{ ui, appSetting, appSettingValidation, persistedAppSetting, errors }, dispatch] = useReducer(
      generalAppSettingStateReducer,
      useMemo(() => defaultGeneralAppSettingState(settings), [settings])
   );
   const { t } = useTranslation();
   /**
    * Initialise the appsetting data
    */
   useEffect(() => {
      dispatch(setIsLoading(true));

      apolloClient
         .query<InitialiseAppSettingQueryResponse>({
            query: InitialiseAppSettingQuery,
            fetchPolicy: 'network-only'
         })
         .then(response => {
            const appsettingPayload = mapGetAppSettingQueryToInitialisationPayload(response.data.NearMissAppSettings);

            dispatch(initialiseAppSetting(appsettingPayload));
         })
         .catch((error: ApolloError) => {
            const actionError: ActionError<ApolloError> = {
               error: error
            };

            dispatch(setInitialisationError(actionError));
         })
         .finally(() => dispatch(setIsLoading(false)));
   }, [apolloClient]);

   /**
    * Handles blocking and unblocking navigation based on whether values have changed
    */
   useEffect(() => {
      if (ui.hasChanged)
         setBlockUnload({
            shouldBlock: true,
            message: t('AppSettings.AreYouSure')
         });
      else setBlockUnload({ shouldBlock: false });
   }, [ui.hasChanged, setBlockUnload]);

   /**
    * Gets the validation result for one of the appsetting settings
    * @param {GeneralAppSettingEnum} appsettingType The appsetting setting to validate
    * @param {any} value The value to validate
    * @returns {IValidationResult}
    */
   const getValidationResult = (appsettingType: GeneralAppSettingEnum, value: unknown): IValidationResult => {
      const validationResult: IValidationResult = { hasError: false };

      const config = appSetting[appsettingType];
      const validationRules = config.validationRules;

      if (isUndefined(validationRules)) return validationResult;

      for (const rule of validationRules) {
         if (isUndefined(rule.condition) || rule.condition(appSetting)) {
            const result = validate(value, rule.name, rule.data);
            if (result.hasError) return result;
         }
      }

      return validationResult;
   };

   /**
    * Sets a validation message for one of the appsetting settings in state
    * @param {GeneralAppSettingEnum} appsettingType The appsetting setting to set validation for
    * @param {any} value The value to validate
    * @returns {boolean} Whether the validation was successful
    */
   const setAppSettingValidation = (appsettingType: GeneralAppSettingEnum, value: unknown): boolean => {
      const validationResult = getValidationResult(appsettingType, value);

      dispatch(
         setValidation({
            [appsettingType]: validationResult.hasError ? validationResult.error?.message : undefined
         })
      );

      return !validationResult.hasError;
   };

   /**
    * Sets a validation message for all of the appsetting settings in state
    * @returns {boolean} Whether the validation was successful
    */
   const setAllValidation = (): boolean => {
      let isValid = true;

      const validationUpdate = Object.values(appSetting).reduce(
         (validationObj, x) => {
            const result = getValidationResult(x.id, x.value);

            if (result.hasError) isValid = false;

            validationObj[x.id as GeneralAppSettingEnum] = result.hasError ? result.error?.message : undefined;

            return validationObj;
         },
         {} as {
            [key in GeneralAppSettingEnum]: string | undefined;
         }
      );

      dispatch(setValidation(validationUpdate));

      return isValid;
   };

   /**
    * Callback to handle the archive period being changed
    * @param {React.FormEvent<HTMLInputElement | HTMLTextAreaElement>} event The triggered event
    * @param {string} newValue The new archive period as a string from an input element
    */
   const handleArchivePeriodChange = (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
   ): void => {
      const archivePeriod = isNullOrEmpty(newValue) ? 0 : tryParseInt(newValue.slice(0, 9)) ?? 0;

      dispatch(setArchivePeriod(archivePeriod));

      setAppSettingValidation(GeneralAppSettingEnum.ArchivePeriod, archivePeriod);
   };

   /**
    * Callback to handle resetting any changes made
    */
   const handleReset = () => {
      dispatch(resetChanges());
   };

   /**
    * Persists any appsetting changes
    * @returns {Promise<ActionResponse<ApolloError>>}
    */
   const saveAppSettingChanges = async (): Promise<ActionResponse<ApolloError>> => {
      const actionResponse = new ActionResponse<ApolloError>(ActionType.GraphQl);

      try {
         const updateRequest = mapAppSettingStateToUpdateRequest(appSetting);

         const response = await apolloClient.mutate<
            UpdateGeneralAppSettingMutationResponse,
            UpdateGeneralAppSettingMutationRequest
         >({
            mutation: UpdateGeneralAppSettingMutation,
            variables: updateRequest
         });

         return response.data?.NearMissSaveAppSettings
            ? actionResponse.set({ successful: true })
            : actionResponse.set({
                 successful: false,
                 error: {
                    message: t('AppSettings.FailedToUpdate')
                 }
              });
      } catch (error: any) {
         return actionResponse.set({
            successful: false,
            error: {
               message: t('AppSettings.FailedToUpdate'),
               error: error
            }
         });
      }
   };

   /**
    * Callback to handle saving changes to the appsettings
    */
   const handleSave = (): void => {
      dispatch(setIsSaving(true));

      const isValid = setAllValidation();

      if (!isValid) {
         dispatch(setIsSaving(false));
         return notifyError(t('AppSettings.FixValidation'));
      }

      let newValue = appSetting[GeneralAppSettingEnum.ArchivePeriod].value;
      let persistedValue = persistedAppSetting[GeneralAppSettingEnum.ArchivePeriod].value;
      if (newValue !== persistedValue) {
         if (newValue > persistedValue) {
            setArchivePeriodWarningMsg(t('AppSettings.AreYouSureChange'));
         } else {
            setArchivePeriodWarningMsg(t('AppSettings.AreYouSureArchive').replace('{newValue}', newValue.toString()));
         }
         setShowDiscard(true);
      } else {
         handleSaveAppSettingChanges();
      }
   };

   const handleSaveAppSettingChanges = () => {
      saveAppSettingChanges()
         .then(response => {
            if (!response.successful) {
               return notifyError(t('AppSettings.IssueUpdating'), response.error);
            }

            dispatch(saveChanges());
            notifySuccess(t('AppSettings.SettingUpdated'));
         })
         .catch(() => {
            notifyError(t('AppSettings.IssueUpdating'));
         })
         .finally(() => {
            dispatch(setIsSaving(false));
         });
   };

   const handleDiscardConfirm = () => {
      handleSaveAppSettingChanges();
      handleDiscardCancel();
   };

   const handleDiscardCancel = () => {
      dispatch(setIsSaving(false));
      setShowDiscard(false);
   };

   return (
      <ConfigurationPageContainer>
         <Loading
            isLoading={ui.isLoading || ui.isSaving}
            message={ui.isLoading ? t('AppSettings.LoadingAppSetting') : t('appsettings.savingappsetting')}
            overlay={ui.isSaving}
            noDelay={ui.isSaving}
         >
            <ActionResponseErrorBoundary error={errors.initialisationError}>
               <StyledConfigurationContainer>
                  <StyledHeaderText>{t('AppSettings.AppSettings')}</StyledHeaderText>

                  <StyledHorizontalRule />

                  <StyledSection>
                     <StyledSubHeader>{t('AppSettings.Archive')}</StyledSubHeader>

                     <StyledOptionContainer>
                        <StyledOptionTextContainer>
                           <StyledOptionLabel>
                              {t(appSetting[GeneralAppSettingEnum.ArchivePeriod].name)}
                              <span> *</span>
                           </StyledOptionLabel>
                           <StyledOptionDescription>
                              {t(appSetting[GeneralAppSettingEnum.ArchivePeriod].description ?? '')}
                           </StyledOptionDescription>
                           <TextInput
                              prefix={t('AppSettings.Days')}
                              onFocus={() => setIsArchivePeriodFocused(true)}
                              onBlur={() => setIsArchivePeriodFocused(false)}
                              value={
                                 !isArchivePeriodFocused
                                    ? formatNumber(appSetting[GeneralAppSettingEnum.ArchivePeriod].value)
                                    : appSetting[GeneralAppSettingEnum.ArchivePeriod].value.toString()
                              }
                              onChange={handleArchivePeriodChange}
                              errorMessage={appSettingValidation[GeneralAppSettingEnum.ArchivePeriod]}
                           />
                        </StyledOptionTextContainer>
                     </StyledOptionContainer>
                  </StyledSection>
                  <ConfirmationDialog
                     title={t('AppSettings.Confirmation')}
                     subText={archivePeriodWarningMsg}
                     hidden={true}
                     show={showDiscard}
                     onAccept={handleDiscardConfirm}
                     onDecline={handleDiscardCancel}
                     acceptButtonText={t('ConfirmationDialog.Yes')}
                     declineButtonText={t('ConfirmationDialog.No')}
                  />
                  <StyledHorizontalRule />

                  <StyledButtonContainer>
                     <Button text={t('InputDialog.Save')} onClick={handleSave} disabled={!ui.hasChanged} />
                     <Button text={t('AppSettings.Reset')} onClick={handleReset} disabled={!ui.hasChanged} />
                  </StyledButtonContainer>
               </StyledConfigurationContainer>
            </ActionResponseErrorBoundary>
         </Loading>
      </ConfigurationPageContainer>
   );
};

export default AppSettings;
