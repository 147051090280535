import { useCallback, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { cloneDeep } from 'lodash';
import { IconButton } from 'components/controls/Button';
import {
    StyledBoldText,
    StyledGrayInfoText,
    StyledSection,
    StyledSubHeader
} from 'sections/configuration/styled/configuration.styles';
import { useArrayState } from 'shared/hooks/useArrayState';
import { onLeaveDepotManagerMessage } from 'shared/constants/onLeaveInProgressMessages';
import { DepotDetails } from './DepotDetails/DepotDetails';
import { StyledContainer } from 'shared/styled/panel.stayled';
import { DepotCollectionModel } from 'shared/types/configuration/DepotModels';
import LinkedUserList from '../../../../NearMissCategory/pages/NewNearMissCategory/components/NearMissCategoryLinkUserList';
import { User } from 'shared/types/configuration/UserModels';
import AssignUser from '../../../../Profile/components/AssignUser/components/AssignUser';

interface DepotsConfigurationProps {
    selectedDepot: DepotCollectionModel;
    onChangeNewManagerLinks: (newManagerLinks: User[]) => void;
    onChangeSurplusManagerLinks?: (surplusManagerLinks: User[]) => void;
}

export const DepotsConfiguration = ({
    selectedDepot,
    onChangeNewManagerLinks,
    onChangeSurplusManagerLinks
}: DepotsConfigurationProps) => {

    const [showManagerSearchDialog, setShowManagerSearchDialog] = useState<boolean>(false);

    const [
        displayManagerLinks,
        setDisplayManagerLinks,
        addToDisplayManagerLinks,
        removeFromDisplayManagerLinks
    ] = useArrayState<User>({ key: 'employeeId' });

    const [
        newManagerLinks,
        setNewManagerLinks,
        addToNewManagerLinks,
        removeFromNewManagerLinks
    ] = useArrayState<User>({ key: 'employeeId' });

    const [
        surplusManagerLinks,
        setSurplusManagerLinks,
        addToSurplusManagerLinks,
        removeFromSurplusManagerLinks
    ] = useArrayState<User>({ key: 'employeeId' });

    useEffect(() => {
        resetAllManagerLists();
    }, [selectedDepot])

    const resetAllManagerLists = () => {
        setDisplayManagerLinks(cloneDeep<User[]>(selectedDepot.managedManagers));
        setNewManagerLinks([]);
        setSurplusManagerLinks([]);
    }

    const handleAddManagerLink = (managers: User[]) => {

        managers.forEach(function (manager) {
            addToDisplayManagerLinks(manager);
            if (!managerIsAssigned(manager)) {
                addToNewManagerLinks(manager);
            }
            removeFromSurplusManagerLinks(manager);
        });
        setNewManagerLinks(managers);
    };

    const handleRemoveManagerLink = (manager: User) => {
        removeFromDisplayManagerLinks(manager);
        removeFromNewManagerLinks(manager);
        if (managerIsAssigned(manager)) addToSurplusManagerLinks(manager);
    };

    const hasChanges = useCallback((): boolean => {
        return anyManagerLinkChanges();
    }, [newManagerLinks, surplusManagerLinks])

    function managerIsAssigned(manager: User): boolean {
        return !!(selectedDepot.managedManagers.find(x => x.employeeId == manager.employeeId));
    }

    function anyManagerLinkChanges(): boolean {

        return newManagerLinks?.length > 0;
    }

    useEffect(() => {
        onChangeNewManagerLinks(newManagerLinks)
    }, [newManagerLinks]);

    useEffect(() => {
        onChangeSurplusManagerLinks?.(surplusManagerLinks)
    }, [surplusManagerLinks]);

    return (
        <StyledContainer style={{ marginTop: 20 }}>
            <Prompt
                when={hasChanges()}
                message={onLeaveDepotManagerMessage}
            />
            <Grid fluid>
                <Row>
                    <Col lg={6} xl={4}>
                        <DepotDetails depot={selectedDepot} />
                    </Col>
                    <Col lg={6} xl={4} xlOffset={4}>
                        <StyledSection>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <div>
                                    <StyledSubHeader>Manage Managers</StyledSubHeader>
                                </div>
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                    <IconButton iconName={'Add'} onClick={() => setShowManagerSearchDialog(true)} />
                                    <IconButton iconName={'Undo'} onClick={resetAllManagerLists} disabled={!hasChanges()} />
                                </div>
                            </div>
                        </StyledSection>
                        <div style={{ height: 'calc(100vh - 175px)', display: 'flex', flexDirection: 'column' }}>
                            {displayManagerLinks.length === 0 && (
                                <div>
                                    <StyledBoldText>There are no managers assigned to this depot</StyledBoldText>
                                    <StyledGrayInfoText>You can assign managers by clicking the '+' above</StyledGrayInfoText>
                                </div>
                            )}

                            {displayManagerLinks.length > 0 && (
                                <div style={{ flex: '1 1 auto', overflowY: 'scroll', overflowX: 'hidden' }}>
                                    <LinkedUserList userList={displayManagerLinks} onRemoveUser={handleRemoveManagerLink} />
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Grid>

            <AssignUser
                isOpen={showManagerSearchDialog}
                onDismiss={() => setShowManagerSearchDialog(false)}
                onAddUsers={handleAddManagerLink}
            />

        </StyledContainer>
    );
};
