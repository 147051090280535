import { createReducer } from 'shared/utils/redux.utils';
import { UserSearchState } from './state.types';
import { UserSearchStateActionEnum } from '../enums/UserSearchStateAction.enum';
import { setSearchQueryHandler } from './actions/set-search-query.action';
import { resetSearchHandler } from './actions/reset-search.action';
import { addSearchResultsHandler } from './actions/add-search-results.action';
import { setPageHandler } from './actions/set-page.action';
import { setSearchErrorHandler } from './actions/set-search-error.action';
import { setFetchingResultsHandler } from './actions/set-fetching-results.action';
import { setIsSavingHandler } from './actions/set-is-saving.action';
import { setSearchInputValueHandler } from '../store/actions/set-search-input-value.action';
import { setSearchInputErrorHandler } from '../store/actions/set-search-input-error.action';
import { setIsAddUserModalOpenHandler } from '../store/actions/set-is-add-user-modal-open.action';

const handlers: ActionHandlerMapObject<UserSearchState, typeof UserSearchStateActionEnum> = {
    [UserSearchStateActionEnum.SetSearchQuery]: setSearchQueryHandler,
    [UserSearchStateActionEnum.SetPage]: setPageHandler,
    [UserSearchStateActionEnum.SetSearchError]: setSearchErrorHandler,
    [UserSearchStateActionEnum.AddSearchResults]: addSearchResultsHandler,
    [UserSearchStateActionEnum.SetFetchingResults]: setFetchingResultsHandler,
    [UserSearchStateActionEnum.ResetSearch]: resetSearchHandler,
    [UserSearchStateActionEnum.SetIsSaving]: setIsSavingHandler,
    [UserSearchStateActionEnum.SetSearchInputValue]: setSearchInputValueHandler,
    [UserSearchStateActionEnum.SetSearchInputError]: setSearchInputErrorHandler,
    [UserSearchStateActionEnum.SetIsAddUserModalOpen]: setIsAddUserModalOpenHandler
};

export const userSearchStateReducer = createReducer<UserSearchState, typeof UserSearchStateActionEnum>(handlers);
