import { FormFieldData } from "modules/forms";
import { ObservationConcernLevelEnum } from "shared/enums/ObservationConcernLevel.enum";
import { NearMissEntity } from "shared/types/domain/NearMissEntity";
import { IMapper } from "shared/types/IMapper";
import { NearMissByIdResponse } from "../queries/view-near-miss.queries.types";

export class NearMissEntityMapper implements IMapper<NearMissByIdResponse, FormFieldData<NearMissEntity>> {
    map(entity: NearMissByIdResponse): FormFieldData<NearMissEntity> {
        return {
            nearMissId: entity.nearMiss.observationId,
            companyId: entity.nearMiss.companyId,
            concernLevel: entity.nearMiss.concernLevel as ObservationConcernLevelEnum,
            locationType: entity.nearMiss.locationType,
            depotPcId: entity.nearMiss.depotPcId,
            customerSiteName: entity.nearMiss.offSiteLocation?.customerSiteName,
            locationDescription: entity.nearMiss.offSiteLocation?.locationDescription,
            locationLatitude: entity.nearMiss.offSiteLocation?.locationLatitude,
            locationLongitude: entity.nearMiss.offSiteLocation?.locationLongitude,
            observationCategoryId: entity.nearMiss.observationCategoryId,
            whatHappened: entity.nearMiss.whatHappened,
            howToStop: entity.nearMiss.howToStop,
            anonymousEntry: entity.nearMiss.anonymousEntry,
            reporterType: entity.nearMiss.reporterType,
            reporterContactName: entity.nearMiss.reportedBy?.reporterContactName,
            reporterPhoneNumber: entity.nearMiss.reportedBy?.reporterPhoneNumber,
            reporterEmail: entity.nearMiss.reportedBy?.reporterEmail,
            incidentDate: entity.nearMiss.incidentDate
        }
    }
}