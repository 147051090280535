import styled from 'styled-components';

export const StyledFieldIcon = styled.div`
    ${({ theme }) => `
        flex: 0;
        user-select: none;
        font-size: 16px;
        color: ${theme.palette.gray[500]}
    `}
`;

export const StyledToggleContainer = styled.div`
    display: flex;
    align-items: center;

    > ${StyledFieldIcon} {
        margin-left: 15px;
    }
`;
