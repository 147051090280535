import { IValidationResult } from "../types/IValidationResult"

export class ValidationResultCreator {
  static pass(): IValidationResult {
    return {
      hasError: false
    }
  }

  static fail(rule: string, message: string): IValidationResult {
    return {
      hasError: true,
      error: {
        rule: rule,
        message: message
      }
    }
  }
}