import React, { forwardRef, useEffect, useRef } from 'react';
import { DropdownSelection } from 'azure-devops-ui/Utilities/DropdownSelection';
import { Dropdown } from 'azure-devops-ui/Dropdown';
import { IDropdownProps } from '../types/IDropdownProps';
import { isNullOrUndefined } from 'shared/utils/null.utils';

const SingleDropdown = forwardRef<Dropdown, IDropdownProps>((props: IDropdownProps, ref) => {
    const selection = useRef<DropdownSelection>(new DropdownSelection());

    useEffect(() => {
        if (!isNullOrUndefined(props.selectedIndex) && props.selectedIndex >= 0) {
            selection.current.select(props.selectedIndex);
        } else {
            selection.current.clear();
        }
    }, [props.selectedIndex]);

    return (
        <div className={props.errorMsg ? 'errorClass' : ''}>
            <Dropdown
                containerClassName='searchableDropDownComponent'
                placeholder={props.placeholder}
                items={props.options as never}
                onSelect={props.onSelect}
                selection={selection.current}
                disabled={props.disabled}
                noItemsText='No items to show'
                calloutContentClassName='calloutContentDropdown'
                ref={ref}
                onCollapse={props.onCollapse}
            />
        </div>
    );
});

export { SingleDropdown };
