/* eslint-disable @typescript-eslint/no-explicit-any */
export {};

// eslint-disable-next-line no-extend-native
Object.defineProperties(Array.prototype, {
    any: {
        configurable: false,
        enumerable: false,
        value: function any<T>(predicateFn: (value: T, index: number, array: T[]) => boolean, thisArg?: any) {
            return this.filter(predicateFn, thisArg).length > 0;
        },
        writable: false
    },
    all: {
        configurable: false,
        enumerable: false,
        value: function all<T>(predicateFn: (value: T, index: number, array: T[]) => boolean, thisArg?: any) {
            return this.filter(predicateFn, thisArg).length === this.length;
        },
        writable: false
    },
    sum: {
        configurable: false,
        enumerable: false,
        value: function sum<T>(predicateFn: (value: T, index: number, array: T[]) => number) {
            const currentArray = this;
            let total = 0;

            currentArray.forEach((x: T, i: number) => {
                let value = predicateFn(x, i, currentArray) ?? 0;

                total += value;
            });

            return total;
        },
        writable: false
    },
    distinct: {
        configurable: false,
        enumerable: false,
        value: function distinct<T>(predicateFn: (value: T, index: number, array: T[]) => any) {
            const currentArray = this;
            const result = [];

            const map = new Map();

            for (let i = 0; i < currentArray.length; i++) {
                const item = currentArray[i];
                const distinctValue = predicateFn(currentArray[i], i, currentArray);

                if (!map.has(distinctValue)) {
                    map.set(distinctValue, true); // set any value to Map

                    result.push({ ...item });
                }
            }

            return result;
        },
        writable: false
    },
    addIfNotExists: {
        configurable: false,
        enumerable: false,
        value: function addIfNotExists<T>(item: T, key: keyof T): void {
            const currentArray: T[] = this;

            const alreadySelected = currentArray.find(x => x[key] == item[key]);

            if (!alreadySelected) currentArray.push(item);
        },
        writable: false,
    },
    removeIfExists: {
        configurable: false,
        enumerable: false,
        value: function removeIfExists<T>(item: T, key: keyof T): void {
            const currentArray: T[] = this;

            const alreadySelected = currentArray.find(x => x[key] == item[key]);

            if (alreadySelected) {
                const index = currentArray.indexOf(alreadySelected);

                currentArray.splice(index, 1);
            }
        },
        writable: false,
    }
});
