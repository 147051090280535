import { useRef, useState } from 'react';
import { useSecurity } from 'modules/security';
import Button from 'components/controls/Button';
import ObservationPhotoDialog from 'components/ObservationPhoto/components/ObservationPhotoDialog';
import ObservationPhotoDisplay from 'components/ObservationPhoto/components/ObservationPhotoDisplay';
import { StyledImageContainer } from 'components/ObservationPhoto/styled/observation-photos.styled';
import { StyledControlContainer } from 'shared/styled/control.styled';
import { StyledPanelTitle } from 'shared/styled/panel.stayled';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import FileUploadZone, { IFileUploadZone } from 'shared/utils/files/components/FileUploadZone';
import { IAddFiles } from 'shared/utils/files/types/IAddFiles';
import { isNullOrUndefined } from 'shared/utils/validation.utils';
import { AddPhotoMapper } from './mappers/AddPhotoMapper';
import { ObservationAddPhoto } from 'components/ObservationPhoto/types/observation-photo.type';
import { AddFileMapper } from 'shared/mappers/AddFileMapper';
import { useTranslation } from 'react-i18next';

interface NewObservationPhotosProps {
   handleAddFile: IAddFiles<ObservationFileEntity>;
   handleDeleteFile: (ids: string[]) => void;
}

export const NewObservationPhotos = ({ handleAddFile, handleDeleteFile }: NewObservationPhotosProps) => {
   const fileRef = useRef<IFileUploadZone>(null);
   const { user } = useSecurity();
   const [images, setImages] = useState<ObservationAddPhoto[]>([]);
   const { t } = useTranslation();
   const [showPhotoDialog, setShowPhotoDialog] = useState<boolean>(false);
   const [photoDialogUrl, setPhotoDialogUrl] = useState<string>();

   const onShowPhotoInDialog = (image: ObservationAddPhoto): void => {
      setPhotoDialogUrl(image.url);
      setShowPhotoDialog(true);
   };

   const onHidePhotoDialog = (): void => {
      setShowPhotoDialog(false);
      setPhotoDialogUrl(undefined);
   };

   const handleFilesAdded = (files: File[]): void => {
      const imageMapper = new AddPhotoMapper();
      const fileMapper = new AddFileMapper();

      const newFiles = files.map(file => {
         return fileMapper.map(file, {
            uploadedById: parseInt(user?.employeeId!),
            uploadedByName: user?.name!
         });
      });

      var newImages = [...images];
      newFiles.forEach(newFile => {
         newImages.push(imageMapper.map(newFile));
         return;
      });

      if (newImages.filter(x => !x.delete).length > 3) {
         alert(t('ObservationPage.CannotAddMoreThan3Images'));
      } else {
         setImages(newImages);
         handleAddFile(newFiles);
      }
   };

   function handleRemoveFile(referenceId: UID): void {
      const index = images!.findIndex(x => x.referenceId === referenceId);
      if (isNullOrUndefined(index)) return;

      let newImages = [...images];
      newImages[index].delete = true;

      setImages(newImages);

      handleDeleteFile([referenceId]);
   }

   return (
      <>
         <ObservationPhotoDialog showPhoto={showPhotoDialog} onDismiss={onHidePhotoDialog} imageUrl={photoDialogUrl} />
         <StyledControlContainer>
            <FileUploadZone
               style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
               }}
               ref={fileRef}
               onFilesAdded={handleFilesAdded}
               dragAndDrop={true}
            >
               <StyledPanelTitle>{t('ObservationPage.Photos')}</StyledPanelTitle>
               <Button
                  onClick={() => fileRef.current?.showDialog()}
                  text={t('ObservationPage.AddPhotos')}
                  iconName='Add'
                  disabled={false}
                  style={{ width: '130px', paddingLeft: 5, paddingRight: 5 }}
               />
               <StyledImageContainer>
                  {images &&
                     images
                        .filter(x => !x.delete)
                        .map((i, key) => (
                           <ObservationPhotoDisplay
                              key={key}
                              photo={i}
                              canDelete
                              onClick={() => onShowPhotoInDialog(i)}
                              onDeleteClick={() => handleRemoveFile(i.referenceId)}
                           />
                        ))}
               </StyledImageContainer>
            </FileUploadZone>
         </StyledControlContainer>
      </>
   );
};
