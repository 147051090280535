import { Dialog, DialogType, IDialogProps, DialogFooter, PrimaryButton } from '@fluentui/react';

interface ErrorDialogProps extends IDialogProps {
    show: boolean;
    title: string;
    subText: string;
    isBlocking?: boolean;
    onAccept: () => void;
    acceptButtonText?: string;
}

const ErrorDialog = ({
    show,
    title,
    subText,
    isBlocking,
    onAccept,
    acceptButtonText = 'OK'
}: ErrorDialogProps) => {
    return (
        <Dialog
            hidden={!show}
            onDismiss={onAccept}
            dialogContentProps={{
                type: DialogType.normal,
                title: title,
                closeButtonAriaLabel: 'Close',
                subText: subText
            }}
            modalProps={{
                isBlocking: isBlocking,
                styles: { main: { maxWidth: 450 } }
            }}
        >
            <DialogFooter>
                <PrimaryButton onClick={onAccept} text={acceptButtonText} />
            </DialogFooter>
        </Dialog>
    );
};

export default ErrorDialog;