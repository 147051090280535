import { gql } from 'graphql.macro';

export const GetNearMissCategory = gql`
    query GetNearMissCategory($categoryId: Short!){
        nearMissCategoryById(categoryId: $categoryId){
            nearMissCategoryId
            nearMissCategoryName
            nearMissCategoryNameDE
            isActive
            uniqueRecordId
            users{
                displayName
                email
                employeeId
                givenName
                surname
                jobTitle
                businessPhones
                department
            }
        }
    }
`;