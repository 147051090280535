import styled from 'styled-components';

export const StyledNoDataRoot = styled.div`
  ${({theme}) => `
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: center;

    & > i {
      font-size: 80px !important;
      color: ${theme.palette.gray[600]};
      padding-bottom: 20px;
    }

    h1 {
      font-size: 20px;
      color: ${theme.palette.primary.main};
      padding: 20px 0;
    }

    h2 {
      font-size: 14px;
      color: ${theme.palette.common.black};
      padding: 20px 0 40px 0;
    }
  `}
`;