import { IVirtualTableColumn } from 'components/layout/VirtualTable/Table/components/VirtualTable';
import { SheqManager } from '../../../queries/sheq-manager-list.query.types';

export const sheqManagerListColumns: IVirtualTableColumn<SheqManager>[] = [
   {
      key: 'column0',
      name: 'SheqManager.SheqManagerSelectable',
      fieldName: 'displayName',
      minWidth: 100,
      maxWidth: 300,
      isResizable: false,
      data: 'string',
      isPadded: true,
      onRender: (item: SheqManager) => <div>{item.displayName}</div>
   },
   {
      key: 'column1',
      name: 'SheqManager.Department',
      fieldName: 'department',
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item: SheqManager) => <div>{item.department}</div>
   },
   {
      key: 'column2',
      name: 'SheqManager.JobTitle',
      fieldName: 'jobTitle',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      data: 'date',
      isPadded: true,
      onRender: (item: SheqManager) => <div>{item.jobTitle}</div>
   }
];
