import { gql } from 'graphql.macro';

export const GetObservationPhotoFiles = gql`
    query GetNearMissPhotoFiles($id: Uuid!) {
        retrieveFileByReference(referenceId: $id) {
            comment
            createdBy
            createdByName
            dateCreated
            documentType
            fileId
            fileName
            fileSize
            mimeType
            referenceId
        }
    }
`;