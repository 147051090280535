import styled from "styled-components";

export const StyledFieldChangesHeading = styled.div`
    padding: 15px 10px;
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.palette.gray['300']};
    border-top: 1px solid ${({ theme }) => theme.palette.gray['300']};

    & > span {
        color: #323130;
        position: absolute;
        top: 13px;
        right: 15px;
    }
`;

export const StyledFieldChangesContainer = styled.div<{ margin: string }>`
    margin: ${props => props.margin};
`;

export const StyledFieldChangesTable = styled.table`
    width: 100%;
    text-align: left;
    margin-top: 15px;

    & tbody:before {
        line-height: 10px;
        content: '\\200C';
        display: block;
    }
    & th {
        font-weight: bold;
        font-size: 14px;
    }
    & td {
        padding-bottom: 15px;
    }
    & td > span {
        padding: 1px 10px 1px 3px;
    }
    & td:nth-child(2) > span {
        background-color: #cce7da;
    }
    & td:nth-child(3) > span {
        background-color: ${({ theme }) => theme.palette.gray['200']};
        text-decoration: line-through;
    }
`;

export const StyledFieldChangesTableHeader = styled.th<{ width: string }>`
    width: ${props => props.width};
`;
