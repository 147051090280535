import { ApolloError } from "apollo-client";
import { ActionError } from "modules/errors";
import { actionCreatorFactory } from "shared/utils/redux.utils";
import { InitialiseAppSettingPayload, SetValidationPayload } from "./appsettings.actions.types";

export enum GeneralAppSettingAction {
  SetIsLoading = 'SET_IS_LOADING',
  SetIsSaving = 'SET_IS_SAVING',
  ResetChanges = 'RESET_CHANGES',
  SaveChanges = 'SAVE_CHANGES',
  SetValidation = 'SET_VALIDATION',
  SetArchivePeriod = 'SET_ARCHIVE_PERIOD',
  InitialiseAppSetting = 'INITIALISE_APPSETTING',
  SetInitialisationError = 'SET_INITIALISATION_ERROR'
}

export const setIsLoading = actionCreatorFactory<typeof GeneralAppSettingAction, boolean>(
  GeneralAppSettingAction.SetIsLoading
);

export const setIsSaving = actionCreatorFactory<typeof GeneralAppSettingAction, boolean>(
  GeneralAppSettingAction.SetIsSaving
);

export const resetChanges = actionCreatorFactory<typeof GeneralAppSettingAction>(
  GeneralAppSettingAction.ResetChanges
);

export const saveChanges = actionCreatorFactory<typeof GeneralAppSettingAction>(
  GeneralAppSettingAction.SaveChanges
);

export const setValidation = actionCreatorFactory<typeof GeneralAppSettingAction, SetValidationPayload>(
  GeneralAppSettingAction.SetValidation
);

export const setArchivePeriod = actionCreatorFactory<typeof GeneralAppSettingAction, number>(
  GeneralAppSettingAction.SetArchivePeriod
);

export const initialiseAppSetting = actionCreatorFactory<
  typeof GeneralAppSettingAction,
  InitialiseAppSettingPayload
>(GeneralAppSettingAction.InitialiseAppSetting);

export const setInitialisationError = actionCreatorFactory<
  typeof GeneralAppSettingAction,
  ActionError<ApolloError> | undefined
>(GeneralAppSettingAction.SetInitialisationError);