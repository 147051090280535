import { ObservationSafetyCheckHome } from "components/ObservationSafetyCheck/components/ObservationSafetyCheckHome";
import { NearMissList } from "sections/near-miss-management"

export const getBaseRoute = (adminUser: boolean): () => JSX.Element => {

    if (adminUser) {
        return NearMissList;
    }

    return ObservationSafetyCheckHome;
}