import { PermissionProvider } from "modules/permissions"
import { Authorising, AuthStateEnum, useSecurity } from "modules/security";
import { AppRouter } from 'modules/routing';

import AppLayout from "./AppLayout";
import AppServicesContainer from "./AppServicesContainer";

export const AppSecureContainer = () => {

    const { authState, user } = useSecurity();

    return (
        <>
            {authState !== AuthStateEnum.Successful && <Authorising authState={authState} />}
            {authState === AuthStateEnum.Successful && (
                <AppServicesContainer>
                    <PermissionProvider user={user}>
                        <AppLayout>
                            <AppRouter />
                        </AppLayout>
                    </PermissionProvider>
                </AppServicesContainer>
            )}
        </>
    )
}