import { gql } from 'graphql.macro';

export const UpdateGoodPractice = gql`
    mutation updateGoodPractice($request: UpdateGoodPracticeRequestModelInput) {
        updateGoodPractice(request: $request)
    }
`;

export const CompleteGoodPractice = gql`
    mutation CompleteGoodPractice($request: CompleteGoodPracticeRequestModelInput) {
      completeGoodPractice(request: $request)
    }
`;

export const ReopenGoodPractice = gql`
mutation ReOpenGoodPractice($nearMissId: Int!) {
  reopenGoodPractice(nearMissId: $nearMissId)
}
`;

export const UpdateFile = gql`
  mutation updateFile($request: FileUpdateRequestInput) {
    updateFile(request: $request)
  }
`;

export const DeleteFile = gql`
  mutation deleteFile($referenceId: Uuid!, $fileId: Int!) {
    deleteFile(referenceId: $referenceId, fileId: $fileId)
  }
`;