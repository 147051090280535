import { FormFieldData } from "modules/forms";
import { ObservationLocationTypeEnum } from "shared/enums/ObservationLocationType.enum";
import { NearMissEntity } from "shared/types/domain/NearMissEntity";
import { IMapper } from "shared/types/IMapper";
import { keysAreEqual } from "shared/utils/equality.utils";
import { CreateNearMissRequest } from "../mutations/create-near-miss.mutations.types";
import { ObservationrecordOriginEnum } from "shared/enums/ObservationrecordOrigin.enum";
import i18next from 'i18next';
import { ObservationLanguageEnum } from "shared/enums/ObservationLanguageEnum";

export class CreateNearMissRequestMapper implements IMapper<FormFieldData<NearMissEntity>, CreateNearMissRequest> {
    map(entity: FormFieldData<NearMissEntity>): CreateNearMissRequest {
        return {
            companyId: entity.companyId,
            concernLevel: entity.concernLevel,
            locationType: entity.locationType.toUpperCase(),
            depotPcId: entity.depotPcId,
            customerSiteName: keysAreEqual(entity.locationType, ObservationLocationTypeEnum.CustomerSite) ? entity.customerSiteName : null,
            locationDescription: keysAreEqual(entity.locationType, ObservationLocationTypeEnum.SunbeltDepot) ? null : entity.locationDescription,
            locationLatitude: keysAreEqual(entity.locationType, ObservationLocationTypeEnum.Other) ? entity.locationLatitude : null,
            locationLongitude: keysAreEqual(entity.locationType, ObservationLocationTypeEnum.Other) ? entity.locationLongitude : null,
            observationCategoryId: entity.observationCategoryId,
            whatHappened: entity.whatHappened,
            howToStop: entity.howToStop,
            reporterType: entity.reporterType.toUpperCase(),
            anonymousEntry: entity.anonymousEntry ?? false,
            contactName: entity.reporterContactName,
            contactPhoneNumber: entity.reporterPhoneNumber,
            contactEmail: entity.reporterEmail,
            createdFrom: ObservationrecordOriginEnum.WebApp,
            selectedLanguage: ObservationLanguageEnum[i18next.language.toUpperCase() as keyof typeof ObservationLanguageEnum]
        }
    };
}