/* eslint-disable react-hooks/exhaustive-deps */
/* TODO: Fix react hook dependencies */

import { useQuery } from '@apollo/react-hooks';
import Button from 'components/controls/Button';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import Loading from 'components/layout/Loading';
import VirtualTable, { IVirtualTableColumn } from 'components/layout/VirtualTable/Table/components/VirtualTable';
import { usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import ConfigurationPageContainer from 'sections/configuration/components/ConfigurationPageContainer';
import { ProfilePage, profileRouteParams } from 'sections/configuration/routes/configuration-page.types';
import {
   StyledCommandBar,
   StyledConfigurationContainer,
   StyledHeaderText,
   StyledSection
} from 'sections/configuration/styled/configuration.styles';
import { ApplicationProfiles, ProfileListViewModel } from 'shared/types/configuration/ProfileModels';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import { GetApplicationProfiles } from '../queries/profile-list-query';
import { useTranslation } from 'react-i18next';

const columns: IVirtualTableColumn<ProfileListViewModel>[] = [
   {
      key: 'column1',
      name: 'Profiles.Name',
      fieldName: 'Name',
      minWidth: 100,
      maxWidth: 400,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item: ProfileListViewModel) => item.profileName
   },
   {
      key: 'column2',
      name: 'Profiles.Description',
      fieldName: 'Description',
      minWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item: ProfileListViewModel) => item.description
   }
];

const ProfileList: FunctionComponent = () => {
   const history = useHistory();
   const { addToast } = useToasts();
   const { data, loading, error } = useQuery<ApplicationProfiles>(GetApplicationProfiles, {
      fetchPolicy: 'network-only'
   });

   const { hasPermission } = usePermissions();
   const { t } = useTranslation();

   useEffect(() => {
      if (!isNullOrUndefined(error)) addToast(t('Profiles.CouldNotLoad'), { appearance: 'warning' });
   }, [data, addToast]);

   const addProfile = () => {
      history.push(profileRouteParams[ProfilePage.NewProfile]());
   };

   const tColumns = columns.map(column => ({
      ...column,
      name: t(column.name)
   }));

   return (
      <ConfigurationPageContainer>
         <StyledConfigurationContainer>
            <StyledHeaderText>{t('Profiles.Profiles')}</StyledHeaderText>
            <StyledCommandBar>
               <Button
                  style={{ marginLeft: 'auto' }}
                  onClick={addProfile}
                  iconName='Add'
                  text={t('Profiles.AddProfile')}
                  disabled={!hasPermission(WriteOn(UserPermission.Profiles))}
               />
            </StyledCommandBar>
            <StyledSection>
               <Loading isLoading={loading} message={'Loading'}>
                  <GraphQlErrorBoundary error={error}>
                     {data && (
                        <VirtualTable<ProfileListViewModel>
                           columns={tColumns}
                           items={data.applicationProfiles}
                           onRowClick={item => {
                              history.push(profileRouteParams[ProfilePage.ViewProfile](item?.profileId.toString()));
                           }}
                           keyFieldName='profileId'
                        />
                     )}
                  </GraphQlErrorBoundary>
               </Loading>
            </StyledSection>
         </StyledConfigurationContainer>
      </ConfigurationPageContainer>
   );
};

export default ProfileList;
