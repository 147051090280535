import { IMapper } from 'shared/types/IMapper';
import { GetFilesByReferenceResponse } from 'shared/types/file/observation-file.type';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { isNullOrUndefined } from 'shared/utils/validation.utils';
import { v4 as uuid } from 'uuid';
import { FileStatus } from 'shared/enums/FileStatus.enum';
import { documentTypes } from 'shared/constants/documentTypes';

export class ObservationFileMapper
  implements IMapper<GetFilesByReferenceResponse, ObservationFileEntity[]> {
  public map(entity: GetFilesByReferenceResponse): ObservationFileEntity[] | null {
    const data = entity.retrieveFileByReference;
    if (isNullOrUndefined(data)) {
      return [];
    }
    return data.map(x => ({
      id: uuid(),
      fileId: x.fileId,
      fileStatus: FileStatus.Persisted,
      mimeType: x.mimeType,
      name: x.fileName,
      size: x.fileSize,
      uploadedById: x.createdBy,
      uploadedByName: x.createdByName,
      uploadedDate: x.dateCreated,
      comment: x.comment,
      type: documentTypes[x.documentType]
    }));
  }
}
