import { FilterSelectDetailCardProps } from '../types/FilterSelectDetailCardProps';
import { Card, ICardTokens } from '@uifabric/react-cards';
import React from 'react';
import { FontIcon, Persona, PersonaSize, Text } from '@fluentui/react';
import { useToasts } from 'react-toast-notifications';
import { ConditionalRender } from 'components/layout/ConditionalRender';

const FilterSelectDetailCard = ({ item, show, position, cancelable, cancel }: FilterSelectDetailCardProps) => {
    const { addToast } = useToasts();
    const cardTokens: ICardTokens = { childrenMargin: 12 };

    const cardStyle: React.CSSProperties = {
        position: 'fixed',
        display: show ? 'block' : 'none',
        top: position.y,
        left: position.x,
        background: '#ffffff',
        zIndex: 1000,
        border: '1px solid #a19f9d'
    };

    function handleCopy(fieldType: string) {
        let field: string;
        if (fieldType === 'Email') {
            field = item.data?.email ?? '';
        } else {
            field = item.data?.businessPhones[0] ?? '';
        }
        navigator.clipboard.writeText(field).then(() => {
            addToast(`${fieldType} copied to clipboard`, { appearance: 'info', autoDismiss: true });
        });
    }

    return (
        <div style={cardStyle} onClick={e => e.stopPropagation()}>
            <Card tokens={cardTokens}>
                <Card.Item>
                    <ConditionalRender condition={cancelable ?? false}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <FontIcon style={{ cursor: 'pointer' }} onClick={cancel} iconName='cancel' />
                        </div>
                    </ConditionalRender>
                    <Persona text={item.text} secondaryText={item.data?.jobTitle} size={PersonaSize.size56} />
                </Card.Item>
                <Card.Item>
                    <hr />
                </Card.Item>
                <Card.Section>
                    <Text style={{ marginTop: '15px' }}>
                        Email:{' '}
                        <span style={{ cursor: 'pointer' }} onClick={() => handleCopy('Email')}>
                            {item.data?.email}
                        </span>
                    </Text>
                    <Text>
                        Phone:{' '}
                        <span style={{ cursor: 'pointer' }} onClick={() => handleCopy('Telephone number')}>
                            {item.data?.businessPhones != null ? item.data?.businessPhones[0] : ''}
                        </span>
                    </Text>
                </Card.Section>
            </Card>
        </div>
    );
};

export { FilterSelectDetailCard };
