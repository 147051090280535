import { gql } from 'graphql.macro';

export const GetNearMissById = gql`
    query GetNearMiss($id: Int!) {
        nearMiss: nearMissById(id: $id) {
            observationId
            uniqueRecordId
            companyId
            concernLevel
            locationType
            offSiteLocation {
                customerSiteName
                locationDescription
                locationLatitude
                locationLongitude
            }
            depotPcId
            observationCategoryId
            observationCategoryName
            whatHappened
            howToStop
            anonymousEntry
            reporterType
            reportedBy {
                reporterContactName
                reporterPhoneNumber
                reporterEmail
            }
            observationStatus
            lastModified
            incidentDate
            emailedFileIds
            completion {
                actionTaken
                closureReason
                closeDate
                employeeId
                hasAttachments
            }
        }
    }
`;
