import { isNullOrUndefined } from 'shared/utils/null.utils';

const postLogoutKey = 'cap-post-logout';

export const setPostLogout = () => {
    window.sessionStorage.setItem(postLogoutKey, 'true');
};

export const isPostLogoutSet = () => {
    return !isNullOrUndefined(window.sessionStorage.getItem(postLogoutKey));
};

export const clearPostLogout = () => {
    window.sessionStorage.removeItem(postLogoutKey);
};
