import { IVirtualTableColumn } from 'components/layout/VirtualTable/Table/components/VirtualTable';
import { StyledNearMissCategoryData } from '../styled/near-miss-category-list-table.styled';
import { NearMissCategoryNumbered } from '../types/NearMissCategoryNumbered';
import i18next from 'i18next';

export const nearMissCategoryColumns: IVirtualTableColumn<NearMissCategoryNumbered>[] = [
   {
      key: 'column0',
      name: '',
      fieldName: 'categoryNumber',
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
      data: 'string',
      isPadded: true,
      onRender: (item: NearMissCategoryNumbered) => (
         <StyledNearMissCategoryData isActive={item.category.isActive}>
            {item.sequence ? item.sequence : '-'}
         </StyledNearMissCategoryData>
      )
   },
   {
      key: 'column1',
      name: 'Category.CategoryName',
      fieldName: 'categoryName',
      minWidth: 300,
      maxWidth: 900,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item: NearMissCategoryNumbered) => (
         <StyledNearMissCategoryData isActive={item.category.isActive}>
            {i18next.resolvedLanguage === 'de'
               ? item.category.nearMissCategoryNameDE
               : item.category.nearMissCategoryName}
         </StyledNearMissCategoryData>
      )
   },
   {
      key: 'column2',
      name: 'ObservationList.Status',
      fieldName: 'isActive',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'date',
      isPadded: true,
      onRender: (item: NearMissCategoryNumbered) => (
         <StyledNearMissCategoryData isActive={item.category.isActive}>
            {item.category.isActive === true ? i18next.t('Category.Enabled') : i18next.t('Category.Disabled')}
         </StyledNearMissCategoryData>
      )
   }
];
