import { usePermissions, UserPermission, ReadOn } from 'modules/permissions';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import {
    StyledSubHeader,
    StyledSubHeaderGroup,
    StyledSubHeaderSection,
    StyledSubHeaderTabContainer
} from 'shared/styled/SubHeader.styled';
import SubHeaderTab from 'components/SubHeaderTab';
import { FontIcon } from '@fluentui/react';
import { DepotDisplayEnum } from '../../enums/depot-display.enum';
import { useTranslation } from 'react-i18next';

interface DepotSubHeaderProps {
    displayMode: DepotDisplayEnum;
    formHasErrors: boolean;
    goToOverview: () => void;
    goToAuditLog: () => void;
}

const DepotSubHeader = ({
    displayMode,
    formHasErrors,
    goToOverview,
    goToAuditLog
}: DepotSubHeaderProps) => {

    const { hasPermission } = usePermissions();
    const { t } = useTranslation();

    return (
        <StyledSubHeader>
            <StyledSubHeaderSection style={{ flex: '2 1 auto' }}>
                <StyledSubHeaderGroup style={{ justifyContent: 'flex-end' }}>
                    <StyledSubHeaderTabContainer>
                        <SubHeaderTab
                            onClick={goToOverview}
                            isActive={displayMode === DepotDisplayEnum.Overview}
                            display={t('Depot.Overview')}
                            highlightErrors={formHasErrors}
                        />
                        <ConditionalRender condition={hasPermission(ReadOn(UserPermission.Audit))}>
                            <SubHeaderTab
                                onClick={goToAuditLog}
                                isActive={displayMode === DepotDisplayEnum.Audit}
                                display={<FontIcon iconName='History' />}
                                title={t('Depot.AuditLog')}
                            />
                        </ConditionalRender>
                    </StyledSubHeaderTabContainer>
                </StyledSubHeaderGroup>
            </StyledSubHeaderSection>
        </StyledSubHeader>
    )
};

export default DepotSubHeader;