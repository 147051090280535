import styled from "styled-components";

export const StyledPhotoFrameDeleteButton = styled.div`
position:absolute;
display:none;
top:0%;
right:0%;

:hover{
    display: block;
}
`;

export const StyledPhotoFrame = styled.div`
width: calc(33% - 8px);
position: relative;
margin: 4px;
border: 2px solid black;
cursor: nwse-resize;

    > img {
        display: block;
        width: 100%;
        height: auto;
    }

    :hover img{
        opacity:0.5
    }

    :hover ${StyledPhotoFrameDeleteButton}{
        display: block;
    }
`;