import { IButtonStyles, ITextFieldStyles, ITheme } from '@fluentui/react';

export const getFilterSelectStyle = (theme: ITheme, readOnly?: boolean, transparent?: boolean): Partial<ITextFieldStyles> => {
  return {
    root: {
      flex: '1 1 auto'
    },
    fieldGroup: {
      backgroundColor: transparent ? 'transparent' : undefined,
      borderColor: transparent || readOnly ? 'transparent' : undefined,
      selectors: readOnly || transparent
        ? {
            '::after': {
              borderColor: 'transparent'
            },
            ':hover': {
              borderColor: 'transparent',
              backgroundColor: transparent ? theme.palette.neutralLighter : undefined
            }
          }
        : undefined
    },
    field: readOnly
      ? {
          selectors: {
            cursor: 'default'
          }
        }
      : undefined,
    suffix: {
      padding: 'none',
      overflow: 'hidden',
      cursor: 'pointer',
      background: 'transparent'
    }
  };
};

export const filterSelectDropDownIconStyle: Partial<IButtonStyles> = {
  root: {
      background: 'transparent'
  },
  rootDisabled: {
      background: 'rgb(243, 242, 241)'
  },
  rootHovered: {
      background: 'transparent',
      color: '#008545'
  },
  rootPressed: {
      background: 'transparent'
  }
};