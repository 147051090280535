import { ISubMenuGroup } from 'sections/configuration/components/ConfigurationPageContainer/components/SubMenu';
import SubMenu from 'sections/configuration/components/ConfigurationPageContainer/components/SubMenu/components/SubMenu';
import { ConfigurationPage, configurationRouteBases } from 'sections/configuration/routes/configuration-page.types';
import {
   StyledConfigurationMenuContainer,
   StyledConfigurationOverviewContainer,
   StyledConfigurationPageContainer
} from '../styled/configuration-page.styled';
import { useTranslation } from 'react-i18next';

const groups: ISubMenuGroup[] = [
   {
      key: 'Observation',
      text: 'ConfigurationMenu.Observation',
      items: [
         {
            text: 'ConfigurationMenu.Appsettings',
            to: configurationRouteBases[ConfigurationPage.AppSettings],
            key: 'AppSetting',
            icon: 'Tag'
         },
         {
            text: 'ConfigurationMenu.Categories',
            to: configurationRouteBases[ConfigurationPage.NearMissCategory],
            key: 'category',
            icon: 'Settings'
         },
         {
            text: 'ConfigurationMenu.SheqManager',
            to: configurationRouteBases[ConfigurationPage.SheqManager],
            key: 'sheqManager',
            icon: 'PartyLeader'
         }
      ]
   },
   {
      key: 'accessControl',
      text: 'ConfigurationMenu.AccessControl',
      items: [
         {
            text: 'ConfigurationMenu.Profiles',
            to: configurationRouteBases[ConfigurationPage.Profiles],
            key: 'profiles',
            icon: 'Permissions'
         },
         {
            text: 'ConfigurationMenu.Users',
            to: configurationRouteBases[ConfigurationPage.Users],
            key: 'users',
            icon: 'Contact'
         },
         {
            text: 'ConfigurationMenu.Depots',
            to: configurationRouteBases[ConfigurationPage.Depots],
            key: 'depots',
            icon: 'MapPin'
         }
      ]
   }
];

interface ConfigurationPageContainerProps {
   children: React.ReactElement;
}

const ConfigurationPageContainer = ({ children }: ConfigurationPageContainerProps) => {
   const { t } = useTranslation();

   const translatedGroups = groups.map(column => ({
      ...column,
      text: t(column.text)
   }));

   return (
      <StyledConfigurationOverviewContainer>
         <StyledConfigurationMenuContainer>
            <SubMenu title={t('ConfigurationMenu.Configuration')} groups={translatedGroups} />
         </StyledConfigurationMenuContainer>
         <StyledConfigurationPageContainer>{children}</StyledConfigurationPageContainer>
      </StyledConfigurationOverviewContainer>
   );
};

export default ConfigurationPageContainer;
