import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ApolloError } from 'apollo-client';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { FileStatus } from 'shared/enums/FileStatus.enum';
import { FormFieldData, FormProvider } from 'modules/forms';
import { ReadOn, useAdminRights, usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import { IAppConfig } from 'modules/config';
import { useRestClient } from 'modules/rest-client';
import { ActionResponse, ErrorListToastMessage } from 'modules/errors';
import { IFormIsValidFunction } from 'modules/forms';
import { ObservationStatusEnum } from 'shared/enums/ObservationStatus.enum';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import Loading from 'components/layout/Loading';
import { tryParseInt } from 'shared/utils/string.utils';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import GoodPracticeHeader from './components/GoodPracticeHeader';
import GoodPracticeSubHeader from './components/GoodPracticeSubHeader';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import GoodPracticeForbidden from './components/GoodPracticeForbidden';
import { GoodPracticeDisplayEnum } from './enums/good-practice-display.enum';
import {
   GetGoodPracticeByIdVariables,
   GoodPractice,
   GoodPracticeByIdResponse
} from './queries/view-good-practice.queries.types';
import { StyledGoodPracticeContainer } from './styled/ViewAmendGoodPractice.styled';
import { GoodPracticeEntity } from 'shared/types/domain/GoodPracticeEntity';
import { amendGoodPracticeOptions } from './options/amend-good-practice.options';
import {
   IDeleteFileVariables,
   IUpdateFileVariables,
   ReopenGoodPracticeResponse,
   ReopenGoodPracticeVariables,
   UpdateGoodPracticeResponse,
   UpdateGoodPracticeVariables
} from './mutations/amend-good-practice.mutations.types';
import { useFiles } from 'shared/utils/files/hooks/useFiles';
import { AmendGoodPracticeLoadingState } from './enums/amend-good-practice-loading-state.enum';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { allowedMimeTypes } from 'shared/options/observation-files.options';
import { useConfig } from 'shared/utils/config/hooks/useConfig';
import { mapFileToFormData } from 'shared/utils/mapping.utils';
import { IFileUploadResponse } from 'shared/types/file/IFileUploadRequest';
import { ObservationFileMapper } from 'shared/mappers/ObservationFileMapper';
import { GetFilesByReferenceResponse } from 'shared/types/file/observation-file.type';
import { GetObservationPhotoFileVariables } from 'components/ObservationPhoto/queries/observation-photos.queries.types';
import { GetObservationPhotoFiles } from 'components/ObservationPhoto/queries/observation-photos.queries';
import {
   DeleteFile,
   ReopenGoodPractice,
   UpdateFile,
   UpdateGoodPractice
} from './mutations/amend-good-practice.mutations';
import { GetGoodPracticeById } from './queries/view-good-practice.queries';
import { GoodPracticeEntityMapper } from './mappers/GoodPracticeEntityMapper';
import { UpdateGoodPracticeRequestMapper } from './mappers/UpdateGoodPracticeMapper';
import AmendGoodPractice from './components/AmendGoodPracticeForm';
import AuditContainer from 'components/AuditContainer';
import { getErrorMessages } from 'shared/utils/errors/errors.utils';
import { ViewGoodPracticeCompletion } from './components/GoodPracticeCompletion/components/ViewGoodPracticeCompletion';
import { useTranslation } from 'react-i18next';

export const ViewAmendGoodPractice = () => {
   const { settings } = useConfig<IAppConfig>();
   const { params } = useRouteMatch<{ id: string }>();

   const restClient = useRestClient<'fileUpload'>();
   const client = useApolloClient();
   const [isLoading, setIsLoading] = useState<boolean>(true);
   const [loadingMessage, setLoadingMessage] = useState<AmendGoodPracticeLoadingState>(
      AmendGoodPracticeLoadingState.loadingGoodPractice
   );
   const [loadingError, setLoadingError] = useState<ApolloError>();

   const { hasPermission } = usePermissions();
   const { hasAdminRights } = useAdminRights();

   const { addToast } = useToasts();

   const [goodPractice, setGoodPractice] = useState<GoodPractice>();
   const [goodPracticeForm, setGoodPracticeForm] = useState<FormFieldData<GoodPracticeEntity> | undefined>();
   const [updateFileMutation] = useMutation<{ updateDocument: boolean }, IUpdateFileVariables>(UpdateFile);
   const [deleteFileMutation] = useMutation<{ deleteDocument: boolean }, IDeleteFileVariables>(DeleteFile);

   const { files, removeFiles, setData } = useFiles<ObservationFileEntity>({
      allowedMimeTypes,
      onNotAllowed: handleNotAllowedFilesAdded,
      addFile: uploadFile,
      updateFile: updateFile,
      removeFile: deleteFile,
      maxFileUploadBytes: tryParseInt('20917520')
   });

   const { t } = useTranslation();

   function handleNotAllowedFilesAdded(messages: string[]): void {
      addToast(
         <ErrorListToastMessage
            errors={messages}
            baseMessage={t('ObservationPage.DisallowedFileTypes')}
         />,
         { appearance: 'warning' }
      );
   }

   useEffect(() => {
      loadImageDetails();
   }, [goodPractice]);

   const loadImageDetails = async () => {
      setIsLoading(true);
      if (!goodPractice) {
         return;
      }

      try {
         const result = await loadGoodPracticeImages(goodPractice.uniqueRecordId);
         if (!isNullOrUndefined(result)) {
            await setData(result);
         }
      } catch (e) {
         setLoadingError(e as ApolloError);
      } finally {
         setIsLoading(false);
      }
   };

   async function loadGoodPracticeImages(uniqueRecordId: UID): Promise<ObservationFileEntity[] | null> {
      try {
         const result = await client.query<GetFilesByReferenceResponse, GetObservationPhotoFileVariables>({
            query: GetObservationPhotoFiles,
            variables: {
               id: uniqueRecordId
            },
            fetchPolicy: 'network-only'
         });
         if (result.errors) return null;

         const mapper = new ObservationFileMapper();
         return mapper.map(result.data);
      } catch (error) {
         setLoadingError(error as ApolloError);
         return null;
      }
   }

   async function uploadFile(
      file: ObservationFileEntity,
      folderName?: string,
      data?: UID
   ): Promise<ObservationFileEntity> {
      const formData = mapFileToFormData<{ referenceId: UID | undefined }>(file, settings, folderName, {
         referenceId: data
      });

      const endpoint = restClient.getEndpoint('fileUpload');
      const result = await endpoint.post<IFileUploadResponse>({
         method: 'UploadFile',
         data: formData,
         onUploadProgress: p => {}
      });

      return { ...file, fileId: result.data.fileId };
   }

   async function updateFile(file: ObservationFileEntity, data: UID): Promise<void> {
      await updateFileMutation({
         variables: {
            request: {
               comment: file.comment ?? '',
               documentTypeId: file.type?.id ?? 6,
               fileId: file.fileId!,
               referenceId: data
            }
         }
      });
   }

   const [displayMode, setDisplayMode] = useState<GoodPracticeDisplayEnum>(GoodPracticeDisplayEnum.Overview);

   const setLoadingState = (loadingMessage: AmendGoodPracticeLoadingState, isLoading: boolean): void => {
      setLoadingMessage(loadingMessage);
      setIsLoading(isLoading);
   };

   async function deleteFile(file: ObservationFileEntity): Promise<void> {
      await deleteFileMutation({
         variables: {
            referenceId: goodPractice?.uniqueRecordId ?? '',
            fileId: file.fileId!
         }
      });
   }

   const loadGoodPracticeEntity = useCallback(async (): Promise<void> => {
      const goodPracticeId = tryParseInt(params.id);

      if (!isNullOrUndefined(goodPracticeId)) {
         setLoadingState(AmendGoodPracticeLoadingState.loadingGoodPractice, true);

         try {
            let result = await client.query<GoodPracticeByIdResponse, GetGoodPracticeByIdVariables>({
               query: GetGoodPracticeById,
               variables: {
                  id: goodPracticeId
               },
               fetchPolicy: 'network-only'
            });

            setGoodPractice(result.data.goodPractice);

            if (result?.data != null) {
               const mapper = new GoodPracticeEntityMapper();

               setGoodPracticeForm(mapper.map(result?.data));
            } else {
               setGoodPracticeForm(undefined);
               setGoodPractice(undefined);
            }
         } catch (e) {
            setLoadingError(e as ApolloError);
         } finally {
            setLoadingState(AmendGoodPracticeLoadingState.loadingGoodPractice, false);
         }
      }
   }, [params.id, client]);

   const handleSaveChanges = async (
      formData: FormFieldData<GoodPracticeEntity> | undefined,
      fileData: ObservationFileEntity[] | undefined,
      isValid: IFormIsValidFunction
   ): Promise<ActionResponse> => {
      setLoadingState(AmendGoodPracticeLoadingState.savingChanges, true);

      const response = await updateGoodPracticeEntity(formData, isValid);

      if (fileData) {
         fileData
            .filter(f => f.fileStatus === FileStatus.Removed)
            .forEach(f => {
               deleteFile(f);
            });
      }

      setLoadingState(AmendGoodPracticeLoadingState.savingChanges, false);

      if (!response.successful) {
         addToast(<ErrorListToastMessage errors={response.getErrorMessages()} />, { appearance: 'error' });
      } else {
         addToast(t('ObservationPage.ChangesSavedSuccessfully'), { appearance: 'success' });

         loadGoodPracticeEntity();
      }

      return response;
   };

   const updateGoodPracticeEntity = async (
      formData: FormFieldData<GoodPracticeEntity> | undefined,
      isValid: IFormIsValidFunction
   ): Promise<ActionResponse> => {
      const actionResponse = new ActionResponse();

      if (isNullOrUndefined(formData) || !isValid(true)) {
         return actionResponse.set({
            successful: false,
            error: {
               message:
                  t('ObservationPage.InformationNotValidFixValidation')
            }
         });
      }

      if (goodPractice?.observationStatus !== ObservationStatusEnum.Open) {
         return actionResponse.set({
            successful: false,
            error: {
               message: t('ObservationPage.AmendClosedGoodPracticeProhibited')
            }
         });
      }

      if (
         !hasPermission(WriteOn(UserPermission.ExistingObservation)) &&
         !hasPermission(WriteOn(UserPermission.IncidentDate)) &&
         !hasPermission(WriteOn(UserPermission.ObservationReporter))
      ) {
         return actionResponse.set({
            successful: false,
            error: {
               message: t('ObservationPage.DoNotHaveRequiredPermissions')
            }
         });
      }

      const mapper = new UpdateGoodPracticeRequestMapper();
      const requestObject = mapper.map(formData);

      try {
         await client.mutate<UpdateGoodPracticeResponse, UpdateGoodPracticeVariables>({
            mutation: UpdateGoodPractice,
            variables: {
               request: requestObject
            }
         });

         actionResponse.set({ successful: true });
      } catch (error) {
         actionResponse.set({
            successful: false,
            error: {
               message: {
                  baseMessage: t('ObservationPage.IssueUpdatingGoodPractice'),
                  messages: getErrorMessages(error as ApolloError)
               }
            }
         });
      }

      return actionResponse;
   };

   const handleCompleteGoodPractice = () => {
      loadGoodPracticeEntity();
   };

   const handleReOpenGoodPractice = async () => {
      setLoadingState(AmendGoodPracticeLoadingState.submittingReOpening, true);
      const actionResponse = new ActionResponse();

      if (goodPractice?.observationStatus == ObservationStatusEnum.Open) {
         setLoadingState(AmendGoodPracticeLoadingState.submittingReOpening, false);
         return actionResponse.set({
            successful: false,
            error: {
               message: 'The good practice is already open'
            }
         });
      }

      if (!hasPermission(WriteOn(UserPermission.ReOpen))) {
         setLoadingState(AmendGoodPracticeLoadingState.submittingReOpening, false);
         return actionResponse.set({
            successful: false,
            error: {
               message: 'You do not have the required permissions to perform this action'
            }
         });
      }

      try {
         await client.mutate<ReopenGoodPracticeResponse, ReopenGoodPracticeVariables>({
            mutation: ReopenGoodPractice,
            variables: {
               nearMissId: goodPractice?.observationId ?? 0
            }
         });

         loadGoodPracticeEntity();

         actionResponse.set({ successful: true });
      } catch (error: any) {
         actionResponse.set({
            successful: false,
            error: {
               message: 'There was an issue re-opening the good practice',
               error: error
            }
         });
      }

      const response = actionResponse;

      setLoadingState(AmendGoodPracticeLoadingState.submittingReOpening, false);

      if (!response.successful) {
         addToast(<ErrorListToastMessage errors={response.getErrorMessages()} />, { appearance: 'error' });
      } else {
         addToast(t('ObservationPage.ChangesSavedSuccessfully'), { appearance: 'success' });
      }

      return response;
   };

   useEffect(() => {
      loadGoodPracticeEntity();
   }, [loadGoodPracticeEntity]);

   const goToOverview = (): void => {
      setDisplayMode(GoodPracticeDisplayEnum.Overview);
   };

   const goToCompletionDetails = (): void => {
      if (goodPractice?.completion) {
         setDisplayMode(GoodPracticeDisplayEnum.Completion);
      }
   };

   const goToAuditLog = (): void => {
      setDisplayMode(GoodPracticeDisplayEnum.Audit);
   };

   return (
      <React.Fragment>
         <Loading isLoading={isLoading} message={t(loadingMessage)} noDelay>
            <GraphQlErrorBoundary error={loadingError}>
               <StyledGoodPracticeContainer>
                  <ConditionalRender condition={hasAdminRights} elseRender={() => <GoodPracticeForbidden />}>
                     <FormProvider<GoodPracticeEntity> options={amendGoodPracticeOptions} formFields={goodPracticeForm}>
                        <GoodPracticeHeader
                           goodPractice={goodPractice}
                           onSave={handleSaveChanges}
                           onComplete={handleCompleteGoodPractice}
                           onReOpen={handleReOpenGoodPractice}
                           setLoadingState={setLoadingState}
                           isLoading={isLoading}
                           files={files}
                        />

                        <GoodPracticeSubHeader
                           goodPractice={goodPractice}
                           displayMode={displayMode}
                           formHasErrors={false}
                           goToOverview={goToOverview}
                           goToCompletionDetails={goToCompletionDetails}
                           goToAuditLog={goToAuditLog}
                        />

                        <ConditionalRender condition={displayMode == GoodPracticeDisplayEnum.Overview}>
                           <AmendGoodPractice goodPractice={goodPractice} removeFiles={removeFiles} files={files} />
                        </ConditionalRender>

                        <ConditionalRender condition={displayMode == GoodPracticeDisplayEnum.Completion}>
                           <ViewGoodPracticeCompletion goodPracticeCompletion={goodPractice?.completion} />
                        </ConditionalRender>

                        <ConditionalRender
                           condition={
                              displayMode == GoodPracticeDisplayEnum.Audit &&
                              hasPermission(ReadOn(UserPermission.Audit))
                           }
                        >
                           {goodPractice && <AuditContainer uniqueRecordId={goodPractice.uniqueRecordId} />}
                        </ConditionalRender>
                     </FormProvider>
                  </ConditionalRender>
               </StyledGoodPracticeContainer>
            </GraphQlErrorBoundary>
         </Loading>
      </React.Fragment>
   );
};
