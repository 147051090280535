import { IFormOptions } from "modules/forms/types/IFormOptions";
import { validationRegexTypes } from "modules/validation/constants/validation-regex.constants";
import { ValidationRule } from "modules/validation/rules/default.validation-rules";
import { ObservationLocationTypeEnum } from "shared/enums/ObservationLocationType.enum";
import { GoodPracticeEntity } from "shared/types/domain/GoodPracticeEntity";
import { keysAreEqual } from "shared/utils/equality.utils";

export const createGoodPracticeOptions: IFormOptions<GoodPracticeEntity> = {

    fields: {
        observationId: {
            rules: []
        },
        companyId: {
            rules: [
                {
                    name: ValidationRule.Required
                }
            ]
        },
        locationType: {
            rules: [
                {
                    name: ValidationRule.Required
                }
            ]
        },
        depotPcId: {
            rules: [
                {
                    condition: (data) => keysAreEqual(data?.locationType, ObservationLocationTypeEnum.SunbeltDepot),
                    name: ValidationRule.Required
                }
            ]
        },
        observationCategoryId: {
            rules: [
                {
                    name: ValidationRule.Required
                }
            ]
        },
        whatHappened: {
            rules: [
                {
                    name: ValidationRule.Required
                },
                {
                    data: {
                        length: 600
                    },
                    name: ValidationRule.MaxLength
                }
            ]
        },
        whoShouldBeCommended: {
            rules: [
                {
                    name: ValidationRule.Required
                },
                {
                    data: {
                        length: 600
                    },
                    name: ValidationRule.MaxLength
                }
            ]
        },
        reporterType: {
            rules: [
                {
                    name: ValidationRule.Required
                }
            ]
        },
        anonymousEntry: {
            rules: []
        },
        locationDescription: {
            rules: [
                {
                    condition: (data) => keysAreEqual(data?.locationType, ObservationLocationTypeEnum.Other),
                    name: ValidationRule.Required
                },
                {
                    data: {
                        length: 100
                    },
                    name: ValidationRule.MaxLength
                }
            ]
        },
        customerSiteName: {
            rules: [
                {
                    condition: data => keysAreEqual(data?.locationType, ObservationLocationTypeEnum.CustomerSite),
                    name: ValidationRule.Required
                },
                {
                    data: {
                        length: 100
                    },
                    name: ValidationRule.MaxLength
                }
            ]
        },
        locationLatitude: {
            rules: [
                {
                    condition: data => keysAreEqual(data?.locationType, ObservationLocationTypeEnum.Other),
                    name: ValidationRule.Required
                }
            ]
        },
        locationLongitude: {
            rules: [
                {
                    condition: data => keysAreEqual(data?.locationType, ObservationLocationTypeEnum.Other),
                    name: ValidationRule.Required
                }
            ]
        },
        reporterContactName: {
            rules: [
                {
                    condition: data => !data?.anonymousEntry,
                    name: ValidationRule.Required
                },
                {
                    data: {
                        length: 30
                    },
                    name: ValidationRule.MaxLength
                }
            ]
        },
        reporterPhoneNumber: {
            rules: [
                {
                    data: { regex: validationRegexTypes.Telephone },
                    name: ValidationRule.Regex
                },
                {
                    data: {
                        length: 50
                    },
                    name: ValidationRule.MaxLength
                }
            ]
        },
        reporterEmail: {
            rules: [
                {
                    condition: data => !data?.anonymousEntry,
                    name: ValidationRule.Required
                },
                {
                    data: { regex: validationRegexTypes.Email },
                    name: ValidationRule.Regex
                },
                {
                    data: {
                        length: 50
                    },
                    name: ValidationRule.MaxLength
                }
            ]
        },
        incidentDate: {
            rules: []
        }
    }

};