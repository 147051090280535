import { Col, Grid, Row } from "react-flexbox-grid";
import styled from "styled-components";

export const StyledObservationHeader = styled.div`
  ${({ theme }) => `
    padding: 10px 25px;

    h1 {
      color: ${theme.palette.gray[900]};
      font-size: 1.9em;
      line-height: 2;
    }
  `};
`;

export const StyledActionsContainer = styled(Grid)`
  padding: 0 !important;
`;

export const StyledActionsRow = styled(Row)``;

export const StyledActionsCol = styled(Col)`
  display: flex;
  align-items: center;
`;