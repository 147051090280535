import { useApolloClient } from '@apollo/react-hooks';
import TextInput from 'components/controls/TextInput';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { StyledSection, StyledSubHeader } from 'sections/configuration/styled/configuration.styles';
import { DepotCollectionModel } from 'shared/types/configuration/DepotModels';
import { ApolloErrorToastMessage } from '../../../../../../../../modules/errors';
import { GetDepotManager } from '../queries/depot-manager.queries';
import { DepotManagerRequest, DepotManagerResponse } from '../queries/depot-manager.queries types';

interface DepotDetailsProps {
    depot: DepotCollectionModel;
}

export const DepotDetails = ({ depot }: DepotDetailsProps) => {
    const client = useApolloClient();
    const { addToast } = useToasts();
    const [depotManager, setDepotManager] = useState<string>('unknown');

    const getDepotManager = async (depotId: number) => {
        client
            .query<DepotManagerResponse, DepotManagerRequest>({
                query: GetDepotManager,
                variables: {
                    pcid: depotId
                },
                fetchPolicy: 'network-only'
            })
            .then(result => {
                if (result.data && result.data.serviceCentreByPCID.pCManager) {
                    setDepotManager(result.data.serviceCentreByPCID.pCManager.toString());
                }
            })
            .catch(err => {
                addToast(<ApolloErrorToastMessage error={err} baseMessage='Issue retrieving Depot Manager details' />, {
                    appearance: 'error'
                });
            });
    };
    useEffect(() => {
        getDepotManager(depot.pCID);
    }, [])

    return (
        <StyledSection>
            <StyledSubHeader>Depot Details</StyledSubHeader>
            <TextInput label={'Depot'} onChange={() => { }} value={depot.pcName} disabled={true} />
            <TextInput label={'Depot Manager'} onChange={() => { }} value={depotManager} disabled={true} />
            <br />
        </StyledSection>
    );
};
