import React, { FC } from 'react';
import { theme } from 'shared/themes/aplant.theme';
import { StyledSubHeaderTab } from '../styled/SubHeaderTab.styles';

interface ICustomerSubHeaderTabProps {
    onClick: () => void;
    isActive: boolean;
    display: string | React.ReactElement;
    title?: string;
    highlightErrors?: boolean;
    highlightChanges?: boolean;
}

const SubHeaderTab: FC<ICustomerSubHeaderTabProps> = ({
    onClick,
    isActive,
    display,
    title,
    highlightErrors,
    highlightChanges
}: ICustomerSubHeaderTabProps) => {
    return (
        <StyledSubHeaderTab onClick={onClick} className={isActive ? 'active' : ''} title={title} style={{ color: highlightErrors ? theme.palette.error.main : undefined }}>
            {display}
            {highlightChanges && ' *'}
        </StyledSubHeaderTab>
    );
};

export default SubHeaderTab;
