/**
 * Validates a user search value
 * @param {string} searchQuery The search value
 * @returns {{isValid: boolean, validationMessage: string}}
 */
import { UserSearchValidationResult } from '../utils/user-search.utils.types';

export const validateUserSearch = (searchQuery: string): UserSearchValidationResult => {
    const regex = /^([!"£$%^&*()+=_}{\][~#@;:/?.>,<\\|`¬])+/;
    const returnValue = {
        isValid: true,
        validationMessage: ''
    };

    if (regex.test(searchQuery ?? '')) {
        returnValue.isValid = false;
        returnValue.validationMessage = 'The search can not contain !"£$%^&*()+=_}{][~#@;:/?.>,<\\|`¬';
    } else if (searchQuery.length < 2) {
        returnValue.isValid = false;
        returnValue.validationMessage = 'Search term must be 2 characters or more';
    }

    return returnValue;
};
