import { gql } from 'graphql.macro';

export const InitialiseAppSettingQuery = gql`
    query initialiseAppSettingQuery {
        NearMissAppSettings {
            active
            appSettingId
            appSettingName
            appSettingValue
            dataTypeId
      }
    }
`;
