import styled from 'styled-components';

const StyledNearMissListContainer = styled.div`
    padding-bottom: 25px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export { StyledNearMissListContainer };