import { useCallback } from 'react';
import { Switch } from 'react-router-dom';
import { Suspense } from 'react';
import { SecureRoute } from '@okta/okta-react';

import { useAdminRights } from 'modules/permissions';

import { getBaseRoute } from '../utils/routing.utils';

import { nearMissManagementRoutes } from 'sections/near-miss-management'
import { nearMissReportNewRoutes } from 'sections/near-miss-report-new';
import { configurationRoutes } from 'sections/configuration';
import { goodPracticeManagementRoutes } from 'sections/good-practice-management';
import { goodPracticeReportNewRoutes } from 'sections/good-practice-report-new';

export const AppRouter = () => {

    const { hasAdminRights } = useAdminRights();

    const baseComponent = useCallback(() => {
        return getBaseRoute(hasAdminRights());
    },
        [hasAdminRights],
    );

    return (
        <Suspense fallback={<div>&nbsp;</div>}>
            <Switch>
                <SecureRoute
                    path='/'
                    component={baseComponent()}
                    exact={true}
                />
                {
                    nearMissReportNewRoutes.map(
                        (route, index) => {
                            return <SecureRoute
                                path={route.path}
                                component={route.component}
                                exact={route.exact}
                                key={index * 100}
                            />
                        })
                }
                {
                    nearMissManagementRoutes.map(
                        (route, index) => {
                            return <SecureRoute
                                path={route.path}
                                component={route.component}
                                exact={route.exact}
                                key={index}
                            />
                        })
                }
                {
                    goodPracticeReportNewRoutes.map(
                        (route, index) => {
                            return <SecureRoute
                                path={route.path}
                                component={route.component}
                                exact={route.exact}
                                key={index * 100}
                            />
                        })
                }
                {
                    goodPracticeManagementRoutes.map(
                        (route, index) => {
                            return <SecureRoute
                                path={route.path}
                                component={route.component}
                                exact={route.exact}
                                key={index}
                            />
                        })
                }
                {
                    configurationRoutes.map(
                        (route, index) => {
                            return <SecureRoute
                                path={route.path}
                                component={route.component}
                                exact={route.exact}
                                key={index * 100}
                            />
                        })
                }
            </Switch>
        </Suspense>
    );
}