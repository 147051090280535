import React, { CSSProperties, memo, useCallback, useEffect } from 'react';
import {
   DirectionalHint,
   Dropdown,
   getTheme,
   ICalloutContentStyles,
   IDropdownOption,
   IDropdownStyles,
   IRenderFunction,
   ISelectableOption,
   ITheme,
   Label
} from '@fluentui/react';
import { ReactComponent as Logo } from 'assets/images/Sunbelt.svg';
import { StyledLoadingMessage, StyledSelectContainer, StyledSelectOverlay } from '../styled/Select.styled';
import { RequiredPermission, usePermissions } from 'modules/permissions';
import { ReadOnlyField } from 'components/controls/ReadOnlyField';
import { useTranslation } from 'react-i18next';

interface SelectProps {
   name?: string;
   disabled?: boolean;
   multiSelect?: boolean;
   options: IDropdownOption[];
   selectedKey?: string | number | null;
   selectedKeys?: string[] | number[];
   label?: string;
   placeholder?: string;
   className?: string;
   transparent?: boolean;
   defaultSelectedKey?: string;
   style?: CSSProperties;
   calloutWidth?: 'auto' | 'fixed';
   onChange?: (
      event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption | undefined,
      name?: string,
      index?: number | undefined
   ) => void;
   onFocus?: () => void;
   required?: boolean;
   errorMessage?: string;
   isLoading?: boolean;
   loadingMessage?: string;
   optionsError?: boolean;
   readPermissions?: RequiredPermission[];
   writePermissions?: RequiredPermission[];
   readonly?: boolean;
   onDismiss?: () => void;
   title?: string;
   onRenderTitle?: (
      props?: IDropdownOption[],
      defaultRender?: (props?: IDropdownOption[]) => JSX.Element | null
   ) => JSX.Element | null;
   directionalHintNotFixed?: boolean;
   onRenderOption?: IRenderFunction<ISelectableOption>;
}

const styles =
   ({ transparent }: SelectProps, theme: ITheme) =>
   (): Partial<IDropdownStyles> => {
      if (!transparent)
         return {
            dropdown: {
               selectors: {
                  ':focus::after': {}
               }
            }
         };

      return {
         callout: {
            width: 'auto !important'
         },
         dropdown: {
            height: '26px',
            maxWidth: '250px',
            selectors: {
               ':focus::after': {
                  border: 'none'
               }
            }
         },
         dropdownOptionText: {
            overflow: 'visible'
         },
         title: {
            background: 'transparent',
            border: 'none',

            selectors: {
               ':hover': {
                  background: 'transparent'
               }
            }
         }
      };
   };

const calloutStyles =
   ({ calloutWidth = 'fixed' }: SelectProps) =>
   (): Partial<ICalloutContentStyles> => {
      if (calloutWidth === 'auto') {
         return {
            root: {
               width: 'auto !important'
            }
         };
      }

      return {};
   };

const Select = memo((props: SelectProps) => {
   const theme = getTheme();
   const { hasPermissions } = usePermissions();
   const { t } = useTranslation();
   const placeholder = props.optionsError ? 'Could not load data...' : t('Select.SelectAnOption');

   const getValue = useCallback((): string => {
      const value = props.options.find(x => x.key === props.selectedKey);

      return hasPermissions(props.readPermissions) ? value?.text ?? '' : '************';
   }, [props.options, props.selectedKey, hasPermissions, props.readPermissions]);

   return (
      <React.Fragment>
         {props.label && <Label required={props.required}>{props.label}</Label>}

         <StyledSelectContainer>
            {props.isLoading && !props.optionsError && (
               <StyledSelectOverlay>
                  <Logo
                     style={{
                        animation: 'spin 3s linear infinite',
                        height: '22px',
                        minHeight: '22px',
                        minWidth: '22px',
                        width: '22px'
                     }}
                  />
                  {props.loadingMessage && <StyledLoadingMessage>{props.loadingMessage}...</StyledLoadingMessage>}
               </StyledSelectOverlay>
            )}

            {props.readonly && <ReadOnlyField text={getValue()} />}

            {!props.readonly && !hasPermissions(props.writePermissions) && <ReadOnlyField text={getValue()} locked />}

            {!props.readonly && hasPermissions(props.writePermissions) && (
               <Dropdown
                  title={props.title}
                  disabled={props.disabled || props.optionsError}
                  style={props.style}
                  errorMessage={props.errorMessage}
                  styles={styles(props, theme)}
                  className={props.className}
                  multiSelect={props.multiSelect}
                  onDismiss={props.onDismiss}
                  selectedKey={props.selectedKey}
                  selectedKeys={props.selectedKeys}
                  options={props.options}
                  placeholder={props.placeholder || placeholder}
                  defaultSelectedKey={props.defaultSelectedKey}
                  onChange={(e, v, i) => props.onChange?.call(undefined, e, v, props.name, i)}
                  onRenderTitle={props.onRenderTitle}
                  calloutProps={{
                     directionalHint: DirectionalHint.bottomLeftEdge,
                     directionalHintFixed: !props.directionalHintNotFixed,
                     styles: calloutStyles(props)
                  }}
                  onFocus={props.onFocus}
                  onRenderOption={props.onRenderOption}
               />
            )}
         </StyledSelectContainer>
      </React.Fragment>
   );
});

Select.displayName = 'memo(Select)';

export default Select;
