import React, { useEffect, useRef, useState } from 'react';
import { StyledLoadingContainer, StyledLoadingLabel } from '../styled/Loading.styled';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import { ReactComponent as Logo } from 'assets/images/Sunbelt.svg';
import StyledLoaderContainerStyled from '../styled/StyledLoaderContainer.styled';
import StyledLoader from '../styled/Loader.styled';
import StyledRelativeDiv from '../styled/RelativeDiv.styled';

interface LoadingProps {
    isLoading?: boolean;
    children: any;
    message?: string;
    noDelay?: boolean;
    overlay?: boolean;
}

const Loading = ({ isLoading, children, message = 'Loading', noDelay, overlay }: LoadingProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const timer = useRef<number | null>(null);

    useEffect(() => {
        if (!isNullOrUndefined(timer.current)) clearTimeout(timer.current);

        if (isLoading === false) {
            setLoading(false);
            return;
        }

        noDelay ? setLoading(!!isLoading) : (timer.current = setTimeout(() => setLoading(!!isLoading), 400));

        return () => {
            if (!isNullOrUndefined(timer.current)) clearTimeout(timer.current);
        };
    }, [isLoading, noDelay]);

    return (
        <React.Fragment>
            {!overlay && (
                <React.Fragment>
                    {loading && (
                        <StyledLoadingContainer>
                            <Logo style={{ height: '40px', width: '40px', animation: 'spin 3s linear infinite' }} />
                            <StyledLoadingLabel>{message}</StyledLoadingLabel>
                        </StyledLoadingContainer>
                    )}
                    {!loading && !isLoading && children}
                </React.Fragment>
            )}
            {overlay && (
                <StyledRelativeDiv>
                    {loading && (
                        <StyledLoaderContainerStyled>
                            <StyledLoader>
                                <Logo style={{ height: '40px', width: '40px', animation: 'spin 3s linear infinite' }} />
                            </StyledLoader>
                        </StyledLoaderContainerStyled>
                    )}
                    {children}
                </StyledRelativeDiv>
            )}
        </React.Fragment>
    );
};

export default Loading;
