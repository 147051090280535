import { gql } from 'graphql.macro';

export const UpdateNearMiss = gql`
    mutation updateNearMiss($request: UpdateNearMissRequestModelInput) {
        updateNearMiss(request: $request)
    }
`;

export const CompleteNearMiss = gql`
    mutation CompleteNearMiss($request: CompleteNearMissRequestModelInput) {
      completeNearMiss(request: $request)
    }
`;

export const ReopenNearMiss = gql`
mutation ReOpenNearMiss($nearMissId: Int!) {
  reopenNearMiss(nearMissId: $nearMissId)
}
`;

export const UpdateFile = gql`
  mutation updateFile($request: FileUpdateRequestInput) {
    updateFile(request: $request)
  }
`;

export const DeleteFile = gql`
  mutation deleteFile($referenceId: Uuid!, $fileId: Int!) {
    deleteFile(referenceId: $referenceId, fileId: $fileId)
  }
`;