import { gql } from 'graphql.macro';

export const CreateNewProfile = gql`
    mutation CreateNewProfile(
        $deletedUsers: [Int!]
        $newUsers: [Int!]
        $profile: ProfileModelInput
        $permissionSets: [Int!]
    ) {
        saveProfile(
            request: {
                deletedUsers: $deletedUsers
                newUsers: $newUsers
                profile: $profile
                permissionSets: $permissionSets
            }
        )
    }
`;