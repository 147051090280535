import { useSecurity } from 'modules/security';
import { useCallback } from 'react';
import { ReadOn } from '../utils/permission.utils';
import { usePermissions } from './usePermissions';
import { AdminRights } from '../types/AdminRights';
import { UserPermission } from '../enums/user-permissions.enum';

export const useAdminRights = (): AdminRights => {

    const { hasPermission } = usePermissions();
    const { user } = useSecurity();

    const hasAdminRights = useCallback((): boolean => {

        return (!!user && hasPermission(ReadOn(UserPermission.ExistingObservation)))
            || (!!user && hasPermission(ReadOn(UserPermission.ExistingObservation_Local)))

    }, [user, hasPermission, usePermissions]);

    return { hasAdminRights }
};