import { useCallback, useState } from 'react';

import { IconButton } from 'components/controls/Button';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { ConfirmationDialog } from 'components/layout/Dialog';

import { theme } from 'shared/themes/aplant.theme';
import { User } from "shared/types/configuration/UserModels";

import { StyledUserCard, StylesUserContainer } from '../styled/linked-user.styled';

interface LinkedUserList {
  userList: User[];
  onRemoveUser: (user: User) => void;
}

export const LinkedUserList = ({ userList, onRemoveUser }: LinkedUserList) => {

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [userToRemove, setUserToRemove] = useState<User>();

  const showRemoveConfirmation = (user: User) => {
    setUserToRemove(user);
    setShowConfirmation(true);
  }

  const cancelRemoveUser = () => {
    setShowConfirmation(false);
    setUserToRemove(undefined);
  }

  const confirmRemoveUser = () => {
    if (userToRemove) onRemoveUser(userToRemove);
    setShowConfirmation(false);
    setUserToRemove(undefined);
  }

  return (
    <>
      <ConditionalRender condition={!!userList}>
        {userList.map(x => (
          <StylesUserContainer key={x.employeeId}>
            <StyledUserCard>
              <div>{x.displayName}</div>
            </StyledUserCard>
            <div>
              <IconButton
                iconName={'Blocked2Solid'}
                title={'Remove'}
                style={{ color: theme.palette.error.main, fontSize: '25px' }}
                onClick={() => showRemoveConfirmation(x)}
              />
            </div>
          </StylesUserContainer>
        ))
        }

        <ConfirmationDialog
          show={showConfirmation}
          title={'Confirm Remove User'}
          subText={'Are you sure you want to remove this User?'}
          onDecline={cancelRemoveUser}
          onAccept={confirmRemoveUser}
        />
      </ConditionalRender>
    </>
  );
};
