import { useSecurity } from 'modules/security';
import { useCallback } from 'react';
import { ReadOn, WriteOn } from '../utils/permission.utils';
import { usePermissions } from './usePermissions';
import { ConfigRights } from '../types/ConfigRights';
import { UserPermission } from '../enums/user-permissions.enum';

export const useConfigRights = (): ConfigRights => {

    const { hasPermission } = usePermissions();
    const { user } = useSecurity();

    const hasConfigRights = useCallback((): boolean => {

        return (!!user && (
            hasPermission(ReadOn(UserPermission.Category))
            || hasPermission(ReadOn(UserPermission.AppSettings))
            || hasPermission(ReadOn(UserPermission.DepotLinks))
            || hasPermission(ReadOn(UserPermission.Profiles))
            || hasPermission(ReadOn(UserPermission.User))
            || hasPermission(ReadOn(UserPermission.ExistingObservation_Local))
            || hasPermission(WriteOn(UserPermission.Archive))
            || hasPermission(WriteOn(UserPermission.Category))
            || hasPermission(WriteOn(UserPermission.ManageDepots))
            || hasPermission(WriteOn(UserPermission.Profiles))
            || hasPermission(WriteOn(UserPermission.User))
        ))

    }, [user, hasPermission, usePermissions]);

    return { hasConfigRights }
};