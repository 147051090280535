import React, { FunctionComponent, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import { useToasts } from 'react-toast-notifications';
import { cloneDeep } from 'lodash';
import { ProfileModel } from 'shared/types/configuration/ProfileModels';
import { GetApplicationProfile } from '../queries/view-profile-query';
import { ApolloErrorToastMessage } from 'modules/errors';
import { UpdateProfile } from '../mutations/view-profile-mutation';
import { ApolloError } from 'apollo-client';
import Loading from 'components/layout/Loading';
import ProfileConfiguration from './ProfileConfiguration';
import ConfigurationPageContainer from 'sections/configuration/components/ConfigurationPageContainer';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';

const ViewProfileConfiguration: FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const client = useApolloClient();
  const { addToast } = useToasts();
  const [data, setData] = useState<ProfileModel | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<ApolloError>();

  useEffect(() => {
    if (!hasLoaded) {
      if (params && isLoading) {
        client.query<{ applicationProfile: ProfileModel }>({
          query: GetApplicationProfile,
          variables: {
            'profileId': parseInt(params.id)
          },
          fetchPolicy: 'network-only'
        }).then(res => {

          if (res.data?.applicationProfile) {
            if (res.data) {
              if (!res.data.applicationProfile.users) {
                res.data.applicationProfile.users = [];
              }
              if (!res.data.applicationProfile.usersToRemove) {
                res.data.applicationProfile.usersToRemove = [];
              }
              if (!res.data.applicationProfile.usersToAdd) {
                res.data.applicationProfile.usersToAdd = [];
              }
              setData(res.data?.applicationProfile)
            }
          }
        }).catch(err => {
          setLoadingError(err as ApolloError);
        }).finally(() => {
          setLoading(false);
          setHasLoaded(true);
        });
      }
    }
  }, [params, addToast, client]);

  const saveProfile = async (profile: ProfileModel) => {
    try {
      setLoading(true);
      const result = await client.mutate<{ updateProfile: boolean }>({
        mutation: UpdateProfile,
        variables: {
          request: {
            deletedUsers: profile.usersToRemove.map(x => x.employeeId),
            newUsers: profile.usersToAdd.map(x => x.employeeId),
            profile: {
              profileId: profile.profile.profileId,
              profileName: profile.profile.profileName,
              description: profile.profile.description
            },
            permissionSets: profile.profilePermissionSets.filter(x => x.hasPermissionSet).map(x => x.permissionSetId)
          }
        }
      });

      if (result?.data && result?.data.updateProfile) {
        setData(cloneDeep<ProfileModel>(profile));
        addToast(`Profile ${data?.profile.profileName} has been updated`, { appearance: 'success' });
      }

    } catch (err) {
      addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Loading isLoading={isLoading} message={"Loading"}>
      <GraphQlErrorBoundary error={loadingError}>
        {data &&
          (<ConfigurationPageContainer>
            <ProfileConfiguration
              profileModel={data}
              onSave={saveProfile}
              isNewProfile={false}
            />
          </ConfigurationPageContainer>)
        }
      </GraphQlErrorBoundary>
    </Loading>
  );
};

export default ViewProfileConfiguration;