import { joinValues, renderDate } from 'shared/utils/format.utils';
import { IconButton } from '@fluentui/react';
import React from 'react';
import { ApplicationUser } from '../queries/user-search.queries.types';
import { StyledTableCell } from '../components/UserListConfiguration.styles';
import { IVirtualTableColumn } from 'components/layout/VirtualTable/Table/components/VirtualTable';
import { LinkButton } from 'components/controls/LinkButton';

export function getColumns(
    onViewRow: (searchResult: ApplicationUser) => void,
    onAddNew: (newUser: ApplicationUser) => void
): IVirtualTableColumn<ApplicationUser | null>[] {
    return [
        {
            //Name
            data: 'string',
            isPadded: false,
            key: 'type',
            minWidth: 100,
            maxWidth: 200,
            name: 'Name',
            onRender: (item: ApplicationUser) => <StyledTableCell>{item.displayName}</StyledTableCell>
        },
        {
            data: 'menu',
            isPadded: false,
            key: 'contextMenu',
            maxWidth: 30,
            minWidth: 30,
            name: '',
            onRender: (item: ApplicationUser) => (
                <IconButton
                    menuProps={{
                        items: [
                            {
                                iconProps: { iconName: 'RedEye' },
                                key: 'viewResult',
                                onClick: () => onViewRow(item),
                                text: 'View'
                            }
                        ]
                    }}
                    menuIconProps={{ style: { display: 'none' } }}
                    iconProps={{ iconName: 'More', style: { fontSize: '16px' } }}
                />
            )
        },
        {
            //Profiles
            data: 'string',
            isPadded: true,
            key: 'profiles',
            minWidth: 100,
            maxWidth: 800,
            name: 'Profiles',
            onRender: (item: ApplicationUser) => <StyledTableCell>{joinValues(item.profiles)}</StyledTableCell>
        },
        {
            //Service Centre
            data: 'string',
            isPadded: true,
            key: 'serviceCentre',
            minWidth: 100,
            maxWidth: 400,
            name: 'Service Centre',
            onRender: (item: ApplicationUser) => <StyledTableCell>{item.serviceCentre}</StyledTableCell>
        },

        {
            //Date Added
            data: 'string',
            isPadded: true,
            key: 'dateAdded',
            minWidth: 100,
            maxWidth: 200,
            name: 'Date Added',
            onRender: (item: ApplicationUser) => item.dateAdded ?
                <StyledTableCell>{renderDate(item.dateAdded)}</StyledTableCell> :
                <LinkButton
                    text='Add Now'
                    onClick={e => {
                        e.preventDefault();
                        onAddNew(item);
                    }}
                />
        }
    ];
}
