import { actionCreatorFactory } from 'shared/utils/redux.utils';
import { UserSearchStateActionEnum } from '../../enums/UserSearchStateAction.enum';
import { UserSearchState } from '../../store/state.types';
import { isNull } from 'shared/utils/validation.utils';
import immutableUpdate from 'immutability-helper';

export const setSearchInputError = actionCreatorFactory<typeof UserSearchStateActionEnum, string | undefined>(
    UserSearchStateActionEnum.SetSearchInputError
);

export const setSearchInputErrorHandler: ActionHandler<UserSearchState, typeof setSearchInputError> = (
    state,
    action
) => {
    if (isNull(action.payload)) return state;

    return immutableUpdate(state, {
        searchInputError: { $set: action.payload }
    });
};
