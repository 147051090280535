import { CSSProperties, useCallback, useRef, useState } from 'react';
import { Callout, DirectionalHint, IDropdownOption, ITextFieldStyles, IconButton, TextField, getTheme } from '@fluentui/react';

import Loading from 'components/layout/Loading';

import { FilterResultsContainer } from '../styled/FilterSelectV2.styled';
import { FilterSelectItemV2 } from './FilterSelectItemV2';
import { filterSelectDropDownIconStyle, getFilterSelectStyle } from '../styled/FilterSelectV2TextInput.styled';

interface FilterSelectV2Props {
  name?: string;
  disabled?: boolean;
  multiSelect?: boolean;
  options: IDropdownOption[];
  selectedKey?: string | number | null;
  selectedKeys?: (string | number)[];
  label?: string;
  placeholder?: string;
  className?: string;
  transparent?: boolean;
  style?: CSSProperties;
  calloutWidth?: number;
  onChange?: (option?: IDropdownOption | undefined) => void;
  onChangeMulti?: (option?: IDropdownOption[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  required?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  loadingMessage?: string;
  optionsError?: boolean;
  readOnly?: boolean;
  title?: string;
  directionalHintNotFixed?: boolean;
  showPersona?: boolean;
}

export const FilterSelectV2 = ({
  name,
  disabled,
  multiSelect,
  options,
  selectedKey,
  selectedKeys,
  label,
  placeholder,
  className,
  transparent,
  style,
  calloutWidth,
  onChange,
  onChangeMulti,
  onFocus,
  onBlur,
  required,
  errorMessage,
  isLoading,
  loadingMessage,
  optionsError,
  readOnly,
  title,
  directionalHintNotFixed,
  showPersona
}: FilterSelectV2Props) => {

  const theme = getTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  const [ displayMode, setDisplayMode ] = useState<boolean>(false);
  const [ searchTerm, setSearchTerm ] = useState<string>();
  const [ showCallout, setShowCallout ] = useState<boolean>(false);
  const [ hasBlurred, setHasBlurred ] = useState<boolean>(false);

  const addedStyles = useCallback((): Partial<ITextFieldStyles> => {
    return getFilterSelectStyle(theme, readOnly, transparent)
  }, [readOnly, transparent])
    
  const toggleCallout = () => {
    
    if (disabled || optionsError) return;

    setShowCallout(!showCallout);
  }

  const getCalloutWidth = useCallback((): number => {
    return calloutWidth ?? containerRef?.current?.clientWidth ?? 200;
  }, [calloutWidth, containerRef])

  const handleSearch = (e: any, value: string | undefined) => {
    setSearchTerm(value);

    if (value && !showCallout) setShowCallout(true);
  }

  const filteredOptions = useCallback((): IDropdownOption[] => {

    if (!searchTerm) return options;

    return options.filter(x => x.text.toUpperCase().includes(searchTerm.toUpperCase()))
  }, [searchTerm, options])

  const isSelected = useCallback((option: IDropdownOption): boolean => {
    if (!multiSelect) {
      return selectedKey === option.key;
    }

    return !!(selectedKeys?.any((x: string | number) => x === option.key));
  }, [selectedKey, multiSelect, selectedKeys]);

  const selectOption = (option: IDropdownOption, value?: boolean) => {

    if (onChange && !multiSelect) {
      onChange(option);
      setShowCallout(false);
    } 
    
    if (multiSelect && onChangeMulti) {
      
      const selectedOptions = getSelectedOptions();

      if (value) {
        selectedOptions.addIfNotExists(option, 'key');
      } else {
        selectedOptions.removeIfExists(option, 'key');
      }

      onChangeMulti(selectedOptions);
    }

    setDisplayMode(true);
  }

  const handleFocus = () => {

    if (onFocus) onFocus();

    if (disabled || optionsError) return;

    if (!multiSelect)  {
      setSearchTerm(getSelectedOption()?.text ?? searchTerm);
    }
      
    setDisplayMode(false);

    if (hasBlurred && !showCallout) setShowCallout(true);
  }

  const handleBlur = () => {

    if (onBlur) onBlur();

    if (disabled || optionsError) return;

    setDisplayMode(true);
    setHasBlurred(true);
  }

  const getDisplayValue = useCallback((): string | undefined => {
    
    if (!displayMode) return searchTerm;

    if (!multiSelect) return getSelectedOption()?.text ?? searchTerm;

    const selectedOptions = getSelectedOptions();

    const numberOfSelectedOptions = selectedOptions.length;

    if (numberOfSelectedOptions === 0) return searchTerm;

    if (numberOfSelectedOptions === 1) return selectedOptions[0].text;

    return `${selectedOptions[0].text} (+${numberOfSelectedOptions - 1})`

  }, [displayMode, multiSelect, searchTerm, selectedKey, selectedKeys, options])

  function getSelectedOption(): IDropdownOption | undefined {
    return options.find(x => x.key == selectedKey);
  }

  function getSelectedOptions(): IDropdownOption[] {
    return options.filter(x => selectedKeys?.any((k: string | number) => k == x.key));
  }

  return (
    <>
      <div ref={containerRef}>
        <TextField 
          value={getDisplayValue()}
          onChange={handleSearch}
          title={title}
          required={required}
          errorMessage={errorMessage}
          disabled={disabled}
          readOnly={readOnly}
          label={label}
          placeholder={placeholder}
          name={name}
          className={className}
          style={style}
          styles={addedStyles()}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onRenderSuffix={() => (
            <IconButton 
              disabled={disabled}
              iconProps={{ iconName: 'ChevronDown' }} 
              onClick={toggleCallout} 
              styles={filterSelectDropDownIconStyle}
            />
          )}
        />
      </div>
      {
        showCallout &&
          <Callout
            isBeakVisible={false}
            calloutWidth={getCalloutWidth()}
            target={containerRef}
            directionalHint={DirectionalHint.bottomLeftEdge}
            directionalHintFixed={!directionalHintNotFixed}
            onDismiss={() => {
              if (!isLoading) setShowCallout(false);
            }}
            hideOverflow={true}
          >
            <Loading isLoading={isLoading} message={loadingMessage}>
              <FilterResultsContainer height={300}>
                {
                  filteredOptions().map((x, i) => (
                    <FilterSelectItemV2 
                      key={i}
                      option={x} 
                      isSelected={isSelected(x)}
                      multiSelect={multiSelect}
                      onClick={selectOption}   
                      showPersona={showPersona}                 
                    />))
                }
              </FilterResultsContainer>
            </Loading>
          </Callout>
      }
    </>
  );
};