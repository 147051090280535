import { useState } from 'react';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { NewGoodPracticeMain } from './components/NewGoodPracticeMain/components/NewGoodPracticeMain';
import { NewGoodPracticeSuccess } from './components/NewGoodPracticeSuccess/NewGoodPracticeSuccess';

export const NewGoodPractice = () => {

    const [createdSuccessfully, setCreatedSuccessfully] = useState<boolean>(false);

    const showSuccess = () => {
        setCreatedSuccessfully(true);
    }

    const hideSuccess = () => {
        setCreatedSuccessfully(false);
    }

    return (
        <>
            <ConditionalRender condition={createdSuccessfully} >
                <NewGoodPracticeSuccess onCreateAnother={hideSuccess} />
            </ConditionalRender>
            <ConditionalRender condition={!createdSuccessfully} >
                <NewGoodPracticeMain onComplete={showSuccess} />
            </ConditionalRender>

        </>
    );
};