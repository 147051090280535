import { useState } from 'react';
import { StyledMenuContainer } from '../styled/SideMenu.styled';
import SideMenuAction from './SideMenuAction';
import { theme } from 'shared/themes/aplant.theme';
import { ChevronsLeft, ChevronsRight } from 'styled-icons/feather';
import { Settings } from 'styled-icons/material';
import { FontIcon } from '@fluentui/react';
import SideMenuLink from './SideMenuLink';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { useSecurity } from 'modules/security';
import { EnvironmentEnum, useConfig, IAppConfig } from 'modules/config';
import { nearMissManagementHome } from 'sections/near-miss-management';
import { configurationHome } from 'sections/configuration';
import { goodPracticeManagementHome } from 'sections/good-practice-management';
import { useAdditionalMenuRights } from 'modules/permissions/hooks/useAdditionalMenuRights';
import { useTranslation } from 'react-i18next';

const SideMenu = () => {
    const { accessToken } = useSecurity();
    const [isExpanded, setIsExpanded] = useState(false);
    const { settings } = useConfig<IAppConfig>();
    const { hasAdditionalMenuRights } = useAdditionalMenuRights();
    const { t } = useTranslation();

    async function copyTokenToClipboard() {
        await navigator.clipboard.writeText(accessToken ?? '');
    }

    return (
        <StyledMenuContainer isExpanded={isExpanded}>

            <SideMenuLink
                icon={<FontIcon iconName='BlockedSite' style={{ color: 'white' }} />}
                label={t('SideMenu.NearMiss')}
                to={nearMissManagementHome}
                title={t('SideMenu.NearMiss')}
            />
            <SideMenuLink
                icon={<FontIcon iconName='Like' style={{ color: 'white' }} />}
                label={t('SideMenu.GoodPractice')}
                to={goodPracticeManagementHome}
                title={t('SideMenu.GoodPractice')}
            />

            <ConditionalRender
                condition={ hasAdditionalMenuRights }
            >
                <SideMenuLink
                    icon={<Settings size='24px' style={{ color: theme.palette.secondary.main }} />}
                    label={t('SideMenu.Configuration')}
                    to={configurationHome}
                    title={t('SideMenu.Configuration')}
                    style={{ marginTop: 'auto', borderTop: '1px solid #484848' }}
                />

                <ConditionalRender
                    condition={
                        settings.Environment === EnvironmentEnum.Development ||
                        settings.Environment === EnvironmentEnum.Test
                    }
                >
                    <SideMenuAction
                        icon={<FontIcon iconName='Ticket' style={{ color: 'white' }} />}
                        label='Access Token'
                        title='Copy the access token to clipboard'
                        onClick={copyTokenToClipboard}
                    />
                </ConditionalRender>

                <SideMenuAction
                    icon={
                        isExpanded ? (
                            <ChevronsLeft strokeWidth='2px' size='24px' style={{ color: 'white' }} />
                        ) : (
                            <ChevronsRight strokeWidth='2px' size='24px' style={{ color: 'white' }} />
                        )
                    }
                    label={isExpanded ? t('SideMenu.Collapse') : t('SideMenu.Open')}
                    onClick={() => setIsExpanded(!isExpanded)}
                />

            </ConditionalRender>

        </StyledMenuContainer>
    );
};

SideMenu.displayName = 'SideMenu';

export default SideMenu;
