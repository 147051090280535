import { gql } from 'graphql.macro';

export const GetNearMissCompletionLookups = gql`

    query GetNearmissenumlookup($request: NearMissEnumLookupRequestInput) {
        closureReasons: nearmissenumlookup(request: $request) {
            id
            name: type
            description
        }
    }
`;
