import { CSSProperties } from "react";

export const mapContainerStyle: CSSProperties = {
    width: '100%',
    height: '400px'
};

export const searchBoxStyle: CSSProperties = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `calc(100% - 40px)`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: "absolute",
    top: "20px",
    left: "0",
    marginLeft: "20px",
    marginRight: "20px"
}