import { useApolloClient } from "@apollo/react-hooks";
import { ApolloError } from "apollo-client";
import Loading from "components/layout/Loading";
import { ApolloErrorToastMessage } from "modules/errors";
import { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ConfigurationPageContainer from "sections/configuration/components/ConfigurationPageContainer";
import { ConfigurationPage, configurationRouteBases } from "sections/configuration/routes/configuration-page.types";
import { NearMissCategoryCollectionModel } from "shared/types/configuration/NearMissCategoryModel";
import NearMissCategoryConfiguration from "../../../components/NearMissCategoryConfiguration";
import CategoryHeader from "../../ViewNearMissCategory/CategoryHeader";
import { CreateCategory } from "../mutations/new-near-miss-category.mutations";

const NewNearMissCategoryConfiguration: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const emptyNearmissCategoryModel: NearMissCategoryCollectionModel = {
    nearMissCategoryName: '',
    isActive: true,
    nearMissCategoryId: 0,
    uniqueRecordId: '',
    users: [],
    usersToAdd: [],
    usersToRemove: []
  };

  const client = useApolloClient();
  const { addToast } = useToasts();
  const history = useHistory();

  const [category, setCategory] = useState<NearMissCategoryCollectionModel>();

  const saveNearMissCategory = async () => {
    try {
      setIsLoading(true);
      const result = await client.mutate<{ createNearMissCategory: number }>({
        mutation: CreateCategory,
        variables: {
          request: {
            nearMissCategoryName: category?.nearMissCategoryName,
            nearMissCategoryNameDE: category?.nearMissCategoryNameDE,
            isActive: category?.isActive,
            newUsers: category?.usersToAdd?.map(x => x.employeeId)
          }
        }
      });

      if (result?.data && result?.data.createNearMissCategory > 0) {
        addToast(`${category?.nearMissCategoryName} has been saved`, { appearance: 'success' });
        history.push(configurationRouteBases[ConfigurationPage.NearMissCategory]);
      }
    } catch (err) {
      addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfigurationPageContainer>
      <Loading isLoading={isLoading} message={"Loading"}>
        <CategoryHeader
          categoryModel={emptyNearmissCategoryModel}
          onSave={saveNearMissCategory}
        />
        <NearMissCategoryConfiguration
          categoryModel={emptyNearmissCategoryModel}
          onChangeCategory={setCategory}
        />
      </Loading>
    </ConfigurationPageContainer>
  );
};

export default NewNearMissCategoryConfiguration;
