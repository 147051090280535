import { useCallback, useState } from 'react';

import { IconButton } from 'components/controls/Button';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { ConfirmationDialog } from 'components/layout/Dialog';

import { theme } from 'shared/themes/aplant.theme';
import { DepotEntity } from 'shared/types/domain/DepotEntity';

import { StyledDepotCard, StylesDepotContainer } from '../styled/linked-depot.styled';

interface LinkedDepotList {
    depotList: DepotEntity[];
    depotManager?: number;
  onRemoveDepot: (depot: DepotEntity) => void;
}

export const LinkedDepotList = ({ depotList, depotManager, onRemoveDepot }: LinkedDepotList) => {

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [depotToRemove, setDepotToRemove] = useState<DepotEntity>();

  const showRemoveConfirmation = (depot: DepotEntity) => {
    setDepotToRemove(depot);
    setShowConfirmation(true);
  }

  const cancelRemoveDepot = () => {
    setShowConfirmation(false);
    setDepotToRemove(undefined);
  }

  const confirmRemoveDepot = () => {
      if (depotToRemove) onRemoveDepot(depotToRemove);
      setShowConfirmation(false);
      setDepotToRemove(undefined);
  }

  const sortByPcNumber = useCallback((): DepotEntity[] => {
    if (!depotList) return [];
    if (depotList.length === 0) return [];

    return depotList.sort((a: DepotEntity, b: DepotEntity) => {
      return a.pc - b.pc;
    });

  }, [depotList]);

  return (
    <>
      <ConditionalRender condition={!!depotList}>
        {sortByPcNumber().map(x => (
            <StylesDepotContainer key={x.pcId}>
                <StyledDepotCard>
                    <div>{x.pc} - {x.pcName}</div>
                </StyledDepotCard>
                <ConditionalRender condition={!(x.profitCentreManagerId === depotManager) || depotManager == null}>
                    <div>
                      <IconButton
                        iconName={'Blocked2Solid'}
                        title={'Remove'}
                        style={{ color: theme.palette.error.main, fontSize: '25px' }}
                        onClick={() => showRemoveConfirmation(x)}
                      />
                    </div>
                </ConditionalRender>
            </StylesDepotContainer>
          ))
        }

        <ConfirmationDialog
          show={showConfirmation}
          title={'Confirm Remove Depot'}
          subText={'Are you sure you want to remove this Depot?'}
          onDecline={cancelRemoveDepot}
          onAccept={confirmRemoveDepot}
        />
      </ConditionalRender>
    </>
  );
};
