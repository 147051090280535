import styled from "styled-components";

interface StyleToggleGroupLabelProps {
    showRequired: boolean;
}

export const StyledToggleGroupContainer = styled.div`
`;

export const StyledToggleGroupLabel = styled.label<StyleToggleGroupLabelProps>`
font-family: Roboto, "Open Sans", sans-serif, Arial;
-webkit-font-smoothing: antialiased;
font-size: 14px;
font-weight: 600;
color: rgb(50, 49, 48);
box-sizing: border-box;
box-shadow: none;
margin: 0px;
display: block;
padding: 5px 0px;
overflow-wrap: break-word;

    ${p => p.showRequired && 
        `&:after {
            content: " *";
            color: rgb(164, 38, 44);
            padding-right: 12px;
        }`
    }
`;

export const StyledToggleGroup = styled.div`
display: flex;
flex-direction: row;
align-items: stretch;
padding: 5px 0px;

    > button {
        padding-top: 18px;
        padding-bottom: 18px;
    }
`;

export const StyledToggleGroupErrorMessage = styled.div`
line-height: 16px;
font-size: 12px;
font-style: normal;
text-align: left;
color: rgb(164, 38, 44);
`;