import { actionCreatorFactory } from 'shared/utils/redux.utils';
import { UserSearchStateActionEnum } from '../../enums/UserSearchStateAction.enum';
import { isUndefined } from 'shared/utils/validation.utils';
import { UserSearchState } from '../../store/state.types';

export const setSearchQuery = actionCreatorFactory<typeof UserSearchStateActionEnum, string>(
    UserSearchStateActionEnum.SetSearchQuery
);

export const setSearchQueryHandler: ActionHandler<UserSearchState, typeof setSearchQuery> = (state, action) => {
    if (isUndefined(action.payload)) return state;

    return {
        ...state,
        searchQuery: action.payload
    };
};
