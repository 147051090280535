import { isNullOrUndefined } from "./null.utils";

export function CopyListAndSort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean,
    valueResolver?: (item: T) => any
): T[] {
    const key = columnKey as keyof T;
    return [...items].sort((a: T, b: T) => {
        const valueA = !isNullOrUndefined(valueResolver) ? valueResolver(a) : a[key];
        const valueB = !isNullOrUndefined(valueResolver) ? valueResolver(b) : b[key];

        let sortValueA = typeof valueA === 'string' ? valueA.toLowerCase() : valueA;
        let sortValueB = typeof valueB === 'string' ? valueB.toLowerCase() : valueB;

        return (isSortedDescending ? sortValueA < sortValueB : sortValueA > sortValueB) ? 1 : -1;
    });
}