import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useApolloClient } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { ApolloErrorToastMessage } from 'modules/errors';
import { ReadOn, usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import Loading from 'components/layout/Loading';
import { ConfigurationPage, configurationRouteBases } from 'sections/configuration/routes/configuration-page.types';
import ConfigurationPageContainer from 'sections/configuration/components/ConfigurationPageContainer';
import { AdUserEntity } from 'shared/types/configuration/AdUserEntity';
import { GetDetailsForDepot } from './GetDetailsForDepot/GetDetailsForDepot';
import { CreateDepot } from '../mutations/new-depot.mutations';
import { CreateDepotRequest } from '../mutations/new-depot.mutations.types';
import { DepotsConfiguration } from '../../../components/DepotsConfiguration/components/DepotsConfiguration';
import DepotHeader from '../../ViewDepot/DepotHeader/DepotHeader';
import { DepotCollectionModel } from 'shared/types/configuration/DepotModels';
import { useCallback } from 'react';
import { DepotEntity } from 'shared/types/configuration/DepotEntity';

export const NewDepot = () => {
  const client = useApolloClient();
  const { hasPermission } = usePermissions();
  const { addToast } = useToasts();
  const history = useHistory();
  
  const [selectedDepot, setSelectedDepot] = useState<DepotCollectionModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const [newManagerLinks, setNewManagerLinks] = useState<AdUserEntity[]>([]);

   const createDepot = async () => {
       try {
         if (!selectedDepot) return;
         setIsLoading(true);
         const result = await client.mutate<{ createObservationDepot: number }, CreateDepotRequest>({
         mutation: CreateDepot,
         variables: {
             request: {
                 pCId: selectedDepot?.pCID ?? 0,
             newEmployeeIds: newManagerLinks.map(x => x.employeeId)
           }
         }
       });
         if (result?.data && result?.data.createObservationDepot) {
             addToast(`The Depot '${selectedDepot?.pcName}' has been added`, { appearance: 'success' });
             history.push(configurationRouteBases[ConfigurationPage.Depots]);
           }
         } catch (err) {
           addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
         } finally {
           setIsLoading(false);
         }
       };
      
   const selectDepot = (depot: DepotEntity | null | undefined): void => {
     if (!depot) return;
     setSelectedDepot({
       pCID: depot.pCID,
       pC: depot.pC,
       pcName: depot.pcName,
       uniqueRecordId: '',
       usersToAdd: [],
       managedManagers: [],
       usersToRemove: []
     })
   }

    const allowSave = useCallback((): boolean => {
        return hasChanges() && hasPermission(WriteOn(UserPermission.ManageDepots))
    }, [newManagerLinks])

    const hasChanges = () => {
        return newManagerLinks?.length > 0 
    }

  return (
    <ConfigurationPageContainer>
          <Loading isLoading={isLoading}>
              <ConditionalRender condition={!selectedDepot && hasPermission(ReadOn(UserPermission.ManageDepots))}>
          <GetDetailsForDepot onSelectedDepot={selectDepot} />
        </ConditionalRender>
              <ConditionalRender condition={!!selectedDepot && hasPermission(ReadOn(UserPermission.ManageDepots))}>
          <DepotHeader
              depotModel={selectedDepot!}
              allowSave={allowSave()}
              onSave={createDepot}
            />          
            <DepotsConfiguration
              selectedDepot={selectedDepot!}
              onChangeNewManagerLinks={setNewManagerLinks}           
            />
        </ConditionalRender>
      </Loading>
    </ConfigurationPageContainer>
  );
};
