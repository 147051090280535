import { VirtualTable } from 'components/layout/VirtualTable/Table';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SheqManagerPage, sheqManagerRouteParams } from 'sections/configuration/routes/configuration-page.types';
import { StyledNoResults } from 'shared/styled/no-results.styled';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import { SheqManager } from '../../../queries/sheq-manager-list.query.types';
import { sheqManagerListColumns } from '../constants/sheq-manager-list-table.constants';
import { useTranslation } from 'react-i18next';

interface SheqManagerListTableProps {
   data?: SheqManager[];
}

export const SheqManagerListTable = ({ data }: SheqManagerListTableProps) => {
   const { t } = useTranslation();

   const tsheqManagerListColumns = sheqManagerListColumns.map(column => ({
      ...column,
      name: t(column.name)
   }));

   const history = useHistory();
   return (
      <React.Fragment>
         {(isNullOrUndefined(data) || data.length === 0) && (
            <StyledNoResults>{t('SheqManager.NoRecords')}</StyledNoResults>
         )}

         {!isNullOrUndefined(data) && data.length > 0 && (
            <VirtualTable<SheqManager>
               columns={tsheqManagerListColumns}
               items={data}
               onRowClick={item => {
                  history.push(sheqManagerRouteParams[SheqManagerPage.ViewSheqManager](item?.employeeId.toString()));
               }}
               keyFieldName='employeeId'
            />
         )}
      </React.Fragment>
   );
};
