import Button from 'components/controls/Button';
import { FormFieldData, useForm } from 'modules/forms';
import { useCallback, useState } from 'react';
import { NearMissEntity } from 'shared/types/domain/NearMissEntity';
import {
   StyledActionsContainer,
   StyledObservationHeader,
   StyledActionsRow,
   StyledActionsCol
} from 'shared/styled/ObservationHeader.styled';
import { IFormIsValidFunction } from 'modules/forms/types/IFormIsValidFunction';
import { Prompt } from 'react-router-dom';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { onLeaveNearMissMessage } from 'shared/constants/onLeaveInProgressMessages';
import { useTranslation } from 'react-i18next';
import { ActionResponse } from 'modules/errors';

interface NearMissHeaderProps {
   onSave: (
      formData: FormFieldData<NearMissEntity> | undefined,
      fileData: ObservationFileEntity[] | undefined,
      isValid: IFormIsValidFunction
   ) => Promise<ActionResponse>;
   isLoading: boolean;
   files: ObservationFileEntity[];
}

const NearMissHeader = ({ onSave, isLoading, files }: NearMissHeaderProps) => {
   const getTitle = (): string => {
      return t('ObservationPage.NewNearMiss');
   };

   const { t } = useTranslation();
   const { formData, isValid } = useForm<NearMissEntity>();
   const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

   const hasChanges = useCallback((): boolean => {
      var dataChanged =
         !!formData?.concernLevel ||
         !!formData?.locationType ||
         !!formData?.locationDescription ||
         !!formData?.locationLatitude ||
         !!formData?.locationLongitude ||
         !!formData?.depotPcId ||
         !!formData?.observationCategoryId ||
         !!formData?.whatHappened ||
         !!formData?.howToStop ||
         !!formData?.reporterType ||
         (!formData?.anonymousEntry && !!formData?.reporterContactName) ||
         (!formData?.anonymousEntry && !!formData?.reporterPhoneNumber) ||
         (!formData?.anonymousEntry && !!formData?.reporterEmail) ||
         files?.length > 0;

      if (dataChanged) {
         window.onbeforeunload = () => true;
      } else {
         window.onbeforeunload = null;
      }
      return dataChanged;
   }, [formData, files]);

   const saveNearMiss = async () => {
      if (!formSubmitted) {
         setFormSubmitted(true);
         await onSave(formData, files, isValid).then(() => {
            setFormSubmitted(false);
         });
      }
   };

   return (
      <StyledObservationHeader>
         <Prompt when={!formSubmitted && hasChanges()} message={t(onLeaveNearMissMessage)} />
         <h1>{getTitle()}</h1>
         <StyledActionsContainer fluid>
            <StyledActionsRow>
               <StyledActionsCol xs={12} style={{ justifyContent: 'flex-end' }}>
                  <Button
                     isLoading={isLoading}
                     text={t('ObservationPage.Submit')}
                     onClick={saveNearMiss}
                     iconName={t('InputDialog.Save')}
                     disabled={!isValid(false)}
                  />
               </StyledActionsCol>
            </StyledActionsRow>
         </StyledActionsContainer>
      </StyledObservationHeader>
   );
};

export default NearMissHeader;
