import { Col, Grid, Row } from 'react-flexbox-grid';

import { StyledHeaderText, StyledSection, StyledSubHeader } from 'sections/configuration/styled/configuration.styles';

import { AdUserEntity } from 'shared/types/configuration/AdUserEntity';
import UserSearchInput from '../../../../../Profile/components/UserSearchInput/components/UserSearchInput';
import { useTranslation } from 'react-i18next';

interface GetAdUserForSheqManagerProps {
  onSelectADUser: (user: AdUserEntity | null | undefined) => void;
}
export const GetAdUserForSheqManager = ({ onSelectADUser: onSelectUser }: GetAdUserForSheqManagerProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div>
                  <StyledHeaderText>{t('NewSheqManager.NewSheqManager')}</StyledHeaderText>
        </div>
      </div>
      <Grid fluid>
        <Row>
          <Col lg={6} xl={4}>
            <StyledSection>
              <StyledSubHeader>{t('NewSheqManager.SelectNewSheqManager')}</StyledSubHeader>
              <UserSearchInput onUserSelect={onSelectUser} label={t('NewSheqManager.FindAUser')}/>
            </StyledSection>
          </Col>
        </Row>
      </Grid>
    </>
  );
};
