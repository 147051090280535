import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { ConfigProvider } from 'modules/config';
import { SecurityProvider } from 'modules/security';
import { SecurityRouter } from 'modules/routing';

import { theme } from 'shared/themes/aplant.theme';
import { GlobalStyle } from 'shared/themes/GlobalStyle.styled';

import { PostLogout } from './PostLogout';
import AppSecureContainer from './AppSecureContainer';

import 'shared/utils/localisation/components/i18n';

function App() {
   return (
      <ConfigProvider path={process.env.REACT_APP_PATH_TO_CONFIG}>
         <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Router>
               <SecurityProvider>
                  <SecurityRouter loggedOut={PostLogout} loggedIn={AppSecureContainer} />
               </SecurityProvider>
            </Router>
         </ThemeProvider>
      </ConfigProvider>
   );
}

export default App;
