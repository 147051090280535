import { gql } from 'graphql.macro';

export const GetAzureADUsers = gql`
    query getAzureADUsers($displayName: String, $nextLinkOptions: [KeyValuePairOfStringAndStringInput!], $resultsPerPage: Int ){
        allAzureADUsers(displayName: $displayName, nextLinkOptions :$nextLinkOptions, resultsPerPage: $resultsPerPage){
            nextLinkOptions{
                key
                value
            }
            users{
                employeeId
                displayName
                givenName
                department
                surname
                jobTitle
                email
                businessPhones
            }
        }
    }
`;