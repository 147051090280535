import React, { FC } from 'react';
import { FontIcon } from '@fluentui/react';
import { ISubMenuItem } from '..';
import { StyledConfigurationMenuItem } from '../styled/SubMenuItem.styled';
import { useTranslation } from 'react-i18next';

/**
 * Props interface for {@link SubMenuItem}
 */
interface ISubMenuItemProps {
    item: ISubMenuItem;
}

/**
 * ConfigurationMenuItem component
 * @param props {@link ISubMenuItemProps}
 */
const SubMenuItem: FC<ISubMenuItemProps> = ({ item }: ISubMenuItemProps) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        item.onClick?.call(event.target, event);

        if (item.disabled) {
            event.preventDefault();
            event.stopPropagation();
        }
    };
    const { t } = useTranslation();
    return (
        <StyledConfigurationMenuItem
            to={item.to}
            onClick={handleClick}
            exact={item.exact}
            disabled={item.disabled}
            title={item.title}
        >
            {item.icon && <FontIcon iconName={item.icon} style={{ marginRight: '15px' }} />}
            {t(item.text)}
        </StyledConfigurationMenuItem>
    );
};

export default SubMenuItem;
