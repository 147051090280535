import React from 'react';
import { Error } from 'styled-icons/boxicons-regular';
import { StyledErrorBoundaryContainer } from '../styled/GraphQlErrorBoundary.styled';
import { isNullOrEmpty, isNullOrUndefined } from 'shared/utils/null.utils';
import { getErrorMessages, isApolloError, ActionError } from 'modules/errors';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

interface IActionResponseErrorBoundaryProps {
    error?: Nullable<ActionError<unknown>>;
    children: React.ReactNode;
}

const ActionResponseErrorBoundary = ({ error, children }: IActionResponseErrorBoundaryProps) => {
    const getMessages = (): string[] => {
        const errors: string[] = [];

        if (!isNullOrEmpty(error?.message))
            typeof error!.message === 'string' ? errors.push(error!.message) : errors.push(...error!.message.messages);

        if (!isNullOrUndefined(error?.error) && isApolloError(error?.error))
            errors.push(...getErrorMessages(error!.error));

        if (errors.length === 0) errors.push('An unknown error occurred');

        return errors;
    };
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {!isNullOrUndefined(error) ? (
                <StyledErrorBoundaryContainer>
                    <Error style={{ color: '#FED203' }} size='100px' />
                    <h1>{t('Error.Oops')}</h1>
                    <ul>
                        {getMessages().map(x => (
                            <li key={v4()}>{t('Error.NotAuthorised')}</li>
                        ))}
                    </ul>
                </StyledErrorBoundaryContainer>
            ) : (
                children
            )}
        </React.Fragment>
    );
};

export default ActionResponseErrorBoundary;
