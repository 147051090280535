import { IMapper } from 'shared/types/IMapper';
import { ISelectableOption } from '@fluentui/react';
import { IListBoxItem } from 'azure-devops-ui/ListBox';
import { FilterSelectItemData } from '../types/FilterSelectItemData';

export class FilterMapper implements IMapper<ISelectableOption, IListBoxItem<FilterSelectItemData>, IListBoxItem> {
    map(entity: ISelectableOption): IListBoxItem<FilterSelectItemData> {
        return {
            text: entity.text,
            id: entity.key.toString(),
            data: entity.data
        };
    }

    mapArr(entity: ISelectableOption[]): IListBoxItem<FilterSelectItemData>[] {
        return entity.map<IListBoxItem<FilterSelectItemData>>(x => this.map(x));
    }
}
