import TextInput from "components/controls/TextInput";
import { useForm } from "modules/forms";
import { StyledControlContainer } from "shared/styled/control.styled";
import { StyledPanelTitle } from "shared/styled/panel.stayled";
import { NearMissEntity } from "shared/types/domain/NearMissEntity";
import { useTranslation } from 'react-i18next';

interface NearMissDetailsProps {
    readOnly: boolean;
};

const NearMissDetails = ({
    readOnly
}: NearMissDetailsProps) => {

    const { formData, updateFields, formErrors } = useForm<NearMissEntity>();
    const { t } = useTranslation();

    return (
        <>
            <StyledPanelTitle>{t('ObservationPage.ExplanationOfNearMiss')}</StyledPanelTitle>

            <StyledControlContainer>
                <TextInput
                    label={t('ObservationPage.WhatHappened')}
                    multiline
                    value={formData?.whatHappened}
                    errorMessage={formErrors?.whatHappened}
                    onChange={(e, v) => updateFields({
                        whatHappened: { runValidation: true, value: v }
                    })}
                    disabled={readOnly}
                    required
                />
            </StyledControlContainer>

            <StyledControlContainer>
                <TextInput
                    label= {t('ObservationPage.HowYouStopHappeningAgain')}
                    multiline
                    value={formData?.howToStop}
                    errorMessage={formErrors?.howToStop}
                    onChange={(e, v) => updateFields({
                        howToStop: { runValidation: true, value: v }
                    })}
                    disabled={readOnly}
                    required
                />
            </StyledControlContainer> 
        </>
    )
};

export default NearMissDetails;