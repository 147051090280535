import { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ApolloError } from 'apollo-client';
import { useApolloClient } from '@apollo/react-hooks';
import { DeleteOn, ReadOn, usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import { ApolloErrorToastMessage } from 'modules/errors';
import { ButtonProps } from 'components/controls/Button';
import Loading from 'components/layout/Loading';
import { SheqManagerEntity } from 'shared/types/configuration/SheqManagerEntity';
import { DepotEntity } from 'shared/types/domain/DepotEntity';
import ConfigurationPageContainer from 'sections/configuration/components/ConfigurationPageContainer';
import { SheqManagerConfiguration } from '../../../components/SheqManagerConfiguration/components/SheqManagerConfiguration';
import { SheqManagerEntityMapper } from '../mappers/SheqManagerEntityMapper';
import { GetSheqManagers } from '../queries/view-sheq-manager.queries';
import { ViewSheqManagerRequest, ViewSheqManagerResponse } from '../queries/view-sheq-manager.queries.types';
import { UpdateSheqManager } from '../mutations/update-sheq-manager.mutations';
import { UpdateSheqManagerRequest } from '../mutations/update-sheq-manager.mutations.types';
import ReassignAllDepotsDialog from './ReassignAllDepotsDialog';
import RemoveSheqManagerDialog from './RemoveSheqManagerDialog';
import { AmmendSheqManagerLoadingState } from '../enums/AmmendSheqManagerLoadingState';
import SheqManagerHeader from '../SheqManagerHeader';
import SheqManagerSubHeader from '../SheqManagerSubHeader';
import { SheqManagerDisplayEnum } from '../enums/sheq-manager-display.enum';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import SheqManagerAudit from '../SheqManagerAudit';

export const ViewSheqManager = () => {

  const { params } = useRouteMatch<{ id: string }>();
  const client = useApolloClient();
  const { addToast } = useToasts();
  const { hasPermission } = usePermissions();

  const [sheqManager, setSheqManager] = useState<SheqManagerEntity>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<AmmendSheqManagerLoadingState>();

  const [showReassignDialog, setShowReassignDialog] = useState<boolean>(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);

  const [displayMode, setDisplayMode] = useState<SheqManagerDisplayEnum>(SheqManagerDisplayEnum.Overview);

  const [newDepots, setNewDepots] = useState<DepotEntity[]>([]);
  const [surplusDepots, setSurplusDepots] = useState<DepotEntity[]>([]);

  useEffect(() => {
    if (params && !initialLoad) {
      getSheqManager(parseInt(params.id));
    }
  }, [params, initialLoad]);

  useEffect(() => {

    if (sheqManager) {
      setLoadingState(AmmendSheqManagerLoadingState.LoadingSheqManager, false);
    }

  }, [sheqManager])

  const getSheqManager = (employeeId: number) => {

    setLoadingState(AmmendSheqManagerLoadingState.LoadingSheqManager, true);

    client
      .query<ViewSheqManagerResponse, ViewSheqManagerRequest>({
        query: GetSheqManagers,
        variables: {
          employeeId: employeeId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        if (res?.data) {
          const mapper = new SheqManagerEntityMapper();

          const manager = mapper.map(res.data.viewSheqManager) ?? undefined;

          setSheqManager(manager);
        }
      })
      .catch(err => {
        addToast(<ApolloErrorToastMessage error={err} baseMessage='Issue retrieving SHEQ Manager details' />, {
          appearance: 'error'
        });
      })
      .finally(() => {
        setInitialLoad(true);
      });
  };

  const updateSheqManager = async () => {
    try {
      if (!sheqManager) return;

      if (newDepots.length === 0 && surplusDepots.length === 0) return;

      setLoadingState(AmmendSheqManagerLoadingState.UpdatingSheqManager, true);
      const result = await client.mutate<{ updateSHEQManager: boolean }, UpdateSheqManagerRequest>({
        mutation: UpdateSheqManager,
        variables: {
          request: {
            managerEmployeeId: sheqManager?.employeeId ?? 0,
            newDepotPcIds: newDepots.map(x => x.pcId),
            deletedDepotPcIds: surplusDepots.map(x => x.pcId)
          }
        }
      });

      if (result?.data && result?.data.updateSHEQManager) {
        refreshSheqManager();
        addToast(`${sheqManager?.givenName}'s SHEQ Manager profile has been updated`, { appearance: 'success' });
      }
    } catch (err) {
      addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
      setLoadingState(AmmendSheqManagerLoadingState.UpdatingSheqManager, false);
    }
  };

  const refreshSheqManager = () => {
    if (sheqManager) {
      getSheqManager(sheqManager?.employeeId);
    }
  }

  const allowSave = useCallback((): boolean => {
    return hasChanges() && hasPermission(WriteOn(UserPermission.DepotLinks))
  },[newDepots, surplusDepots])

  const additionalButtons = useCallback((): ButtonProps[] => {

    const hasDepotsAssigned = sheqManager && sheqManager?.managedDepots.length > 0;

    return [
      {
        text: 'Reassign All Depots',
        onClick: () => setShowReassignDialog(true),
        disabled: hasChanges() || !hasDepotsAssigned || !hasPermission(WriteOn(UserPermission.DepotLinks)),
        iconName: 'Switch'
      },
      {
        text: 'Remove SHEQ Manager',
        onClick: () => setShowRemoveDialog(true),
        disabled: hasChanges() || !hasPermission(DeleteOn(UserPermission.DepotLinks)),
        iconName: 'UserRemove'
      }
    ]
  },[newDepots, surplusDepots, sheqManager])
  
  const hasChanges = () => {
    return newDepots?.length > 0 || surplusDepots?.length > 0
  }
  
  const goToOverview = (): void => {
    setDisplayMode(SheqManagerDisplayEnum.Overview);
  } 

  const goToAuditLog = (): void => {
    setDisplayMode(SheqManagerDisplayEnum.Audit);
  }

  const setLoadingState = (loadingMessage: AmmendSheqManagerLoadingState, isLoading: boolean): void => {
    setLoadingMessage(loadingMessage);
    setIsLoading(isLoading);
  }

  return (
    <ConfigurationPageContainer>
      <Loading isLoading={isLoading} message={loadingMessage?.toString()}>
        {sheqManager && (
          <SheqManagerHeader          
              sheqManagerModel={sheqManager}
              allowSave={allowSave()}
              onSave={updateSheqManager}
              additionalButtons={additionalButtons()}
          />)}
          <SheqManagerSubHeader
              displayMode={displayMode}
              formHasErrors={false}
              goToOverview={goToOverview}
              goToAuditLog={goToAuditLog}
          />
          <ConditionalRender condition={displayMode == SheqManagerDisplayEnum.Audit && hasPermission(ReadOn(UserPermission.Audit))}>
            <SheqManagerAudit
              uniqueRecordId={sheqManager?.uniqueRecordId ?? ''}
            />
          </ConditionalRender>
          <ConditionalRender condition={displayMode == SheqManagerDisplayEnum.Overview}>
            {sheqManager && (
              <>
                <SheqManagerConfiguration
                  sheqManager={sheqManager}
                  onChangeNewDepotLinks={setNewDepots}
                  onChangeSurplusDepotLinks={setSurplusDepots}
                />

                <ReassignAllDepotsDialog
                  sheqManager={sheqManager}
                  showDialog={showReassignDialog}
                  setLoadingState={setLoadingState}
                  refreshSheqManager={refreshSheqManager}
                  onHideDialog={() => setShowReassignDialog(false)}
                />

                <RemoveSheqManagerDialog
                  sheqManager={sheqManager}
                  showDialog={showRemoveDialog}
                  setLoadingState={setLoadingState}
                  onHideDialog={() => setShowRemoveDialog(false)}
                />
              </>
            )}
          </ConditionalRender>
      </Loading>
    </ConfigurationPageContainer>
  );
};
