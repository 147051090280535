import { IMapper } from "shared/types/IMapper";
import { isNullOrUndefined } from "shared/utils/null.utils";
import { ViewDepotResponse } from "../queries/view-depot.queries.types";
import { DepotCollectionModel } from "shared/types/configuration/DepotModels";
import { User } from "shared/types/configuration/UserModels";

export class DepotEntityMapper implements IMapper<ViewDepotResponse, DepotCollectionModel> {
  
    public map(entity: ViewDepotResponse): DepotCollectionModel | null {
    const depotData = entity?.observationsDepotManagers.depot;

    if (isNullOrUndefined(depotData)) {
      return null;
      }
      return {
          pCID: depotData.pCID,
          pC: depotData.pC,
          pcName: depotData.pcName,
          usersToAdd: [],
          usersToRemove: [],
          uniqueRecordId: entity.observationsDepotManagers.uniqueRecordId,
          managedManagers: entity.observationsDepotManagers.managers.map<User>(x => {
              return {
                  employeeId: x.employeeId,
                  department: x.department,
                  userId: x.userId,
                  displayName: x.displayName,
                  businessPhones: x.businessPhones,
                  givenName: x.givenName,
                  email: x.email,
                  jobTitle: x.jobTitle,
                  surname: x.givenName                  
        };
      })
    };
  }
}