import React from 'react';
import { Dialog, DialogType, IDialogProps, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react';

interface IInputDialogProps extends IDialogProps {
   show: boolean;
   title: string;
   subText: string;
   isBlocking?: boolean;
   onCancel: () => void;
   onDone: () => void;
   cancelButtonText?: string;
   doneButtonText?: string;
   width?: string;
   children: React.ReactNode;
}

const InputDialog = ({
   show,
   title,
   children,
   subText,
   isBlocking,
   onCancel,
   onDone,
   cancelButtonText = 'Cancel',
   doneButtonText = 'Done',
   width = '288'
}: IInputDialogProps) => {
   return (
      <Dialog
         minWidth={width}
         hidden={!show}
         onDismiss={onCancel}
         dialogContentProps={{
            type: DialogType.normal,
            title: title,
            closeButtonAriaLabel: cancelButtonText,
            subText: subText
         }}
         modalProps={{
            isBlocking: isBlocking,
            styles: { main: { maxWidth: 450 } }
         }}
      >
         {children}
         <DialogFooter>
            <PrimaryButton onClick={onDone} text={doneButtonText} />
            <DefaultButton onClick={onCancel} text={cancelButtonText} />
         </DialogFooter>
      </Dialog>
   );
};

export default InputDialog;
