import { IAppConfig } from "modules/config/types/IAppConfig";
import { ValidationRule } from "modules/validation/rules/default.validation-rules";
import { GeneralAppSettingEnum } from "../enums/AppSetting.enum";
import { GeneralAppSettingEntity, GeneralAppSettingState } from "./appsettings.state.types";

const defaultAppSetting = (settings: IAppConfig): GeneralAppSettingEntity => ({
  [GeneralAppSettingEnum.ArchivePeriod]: {
    id: GeneralAppSettingEnum.ArchivePeriod,
    description: 'AppSettings.ClosedRecords',
    name: 'AppSettings.ArchivePeriod',
    validationRules: [
      {
        name: ValidationRule.Required
      },
      {
        name: ValidationRule.Min,
        data: {
          min: 1
        }
      }
    ],
    value: 0
  }
});

export const defaultGeneralAppSettingState = (settings: IAppConfig): GeneralAppSettingState => ({
  ui: {
    hasChanged: false,
    isLoading: true,
    isSaving: false
  },
  errors: {
    initialisationError: undefined
  },
  appSetting: defaultAppSetting(settings),
  persistedAppSetting: defaultAppSetting(settings),
  appSettingValidation: {
    [GeneralAppSettingEnum.ArchivePeriod]: undefined
  }
});
