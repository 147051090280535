import { VirtualTable } from 'components/layout/VirtualTable/Table';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
   NearMissCategoryPage,
   nearMissCategoryRouteParams
} from 'sections/configuration/routes/configuration-page.types';
import { NearMissCategoryModel } from 'shared/types/configuration/NearMissCategoryModel';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import { nearMissCategoryColumns } from '../constants/near-miss-category-list-table.constants';
import { StyledNoResults } from '../styled/near-miss-category-list-table.styled';
import { NearMissCategoryNumbered } from '../types/NearMissCategoryNumbered';
import { useTranslation } from 'react-i18next';

interface NearMissCategoryListTableProps {
   data?: NearMissCategoryModel[];
}

export const NearMissCategoryListTable = ({ data }: NearMissCategoryListTableProps) => {
   const history = useHistory();
   const { t } = useTranslation();

   const sortedNumberedCategories = useCallback((): NearMissCategoryNumbered[] => {
      if (!data) return [];

      return mapToNumbered(
         data.sort((a: NearMissCategoryModel, b: NearMissCategoryModel) => {
            if (a.isActive == b.isActive) {
               return a.nearMissCategoryId < b.nearMissCategoryId ? -1 : 1;
            }

            return a.isActive ? -1 : 1;
         })
      );
   }, [data]);

   const mapToNumbered = (unnumbered: NearMissCategoryModel[]): NearMissCategoryNumbered[] => {
      return unnumbered.map((category: NearMissCategoryModel, index: number) => {
         return {
            key: category.nearMissCategoryId,
            category: category,
            sequence: category.isActive ? index + 1 : 0
         };
      });
   };

   const translatedCategoryColumns = nearMissCategoryColumns.map(column => ({
      ...column,
      name: t(column.name)
   }));

   return (
      <React.Fragment>
         {(isNullOrUndefined(data) || data.length === 0) && (
            <StyledNoResults>{t('Category.NoRecords')}</StyledNoResults>
         )}

         {!isNullOrUndefined(data) && data.length > 0 && (
            <VirtualTable<NearMissCategoryNumbered>
               columns={translatedCategoryColumns}
               items={sortedNumberedCategories()}
               onRowClick={item => {
                  history.push(nearMissCategoryRouteParams[NearMissCategoryPage.ViewCategory](item?.key.toString()));
               }}
               keyFieldName='key'
            />
         )}
      </React.Fragment>
   );
};
