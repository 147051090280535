import React, { forwardRef, useEffect, useRef } from 'react';
import { DropdownMultiSelection, DropdownSelection } from 'azure-devops-ui/Utilities/DropdownSelection';
import { Dropdown } from 'azure-devops-ui/Dropdown';
import { IDropdownProps } from '../types/IDropdownProps';
import { IListBoxItem } from 'azure-devops-ui/ListBox';
import { IDropdownOption } from '@fluentui/react';

const MultiDropdown = forwardRef<Dropdown, IDropdownProps>((props: IDropdownProps, ref) => {

    const selection = useRef<DropdownSelection>(new DropdownMultiSelection());

    selection.current.subscribe(v => {

        const selected = props.options[v[0]?.beginIndex];

        const previouslySelected = props.selectedIndexes;

        if (props.onSelect && selected) {
            const event = new CustomEvent('Multi dropdown Change', {
                detail: selected
            });

            const option = selected as IDropdownOption;

            option.selected = previouslySelected ? !!previouslySelected.indexOf(option.key as never) : true;

            props.onSelect(event, option);
        }
    });

    useEffect(() => {

        if (props.selectedIndexes && props.selectedIndexes.length == 0) {
            selection.current.clear();
        } 

    }, [props.selectedIndexes]);

    return (
        <div className={props.errorMsg ? 'errorClass' : ''}>
            <Dropdown
                className='searchableDropDownComponent'
                items={props.options as never}
                selection={selection.current}
                placeholder={props.placeholder}
                showFilterBox={true}
                disabled={props.disabled}
                noItemsText='No items to show'
                calloutContentClassName='calloutContentDropdown'
                ref={ref}
            />
        </div>
    );
});

export { MultiDropdown };
