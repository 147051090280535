import { ErrorMessageGroup } from '../types/ErrorMessageGroup';
import { isNullOrUndefined } from 'shared/utils/null.utils';

export enum ActionType {
    Unknown = 0,
    GraphQl = 1,
    Rest = 2
}

export type ActionError<TError> = {
    message?: string | ErrorMessageGroup;
    error?: TError;
};

export type CreateAsyncActionResponseRequest<TError, TData = null> = {
    successful: boolean;
    error?: ActionError<TError>;
    children?: ActionResponse<unknown>[] | null;
    data?: TData;
};

export class ActionResponse<TError = null, TData = null> {
    private type: ActionType;
    public successful: boolean;
    public error: ActionError<TError> | null;
    public children: ActionResponse<unknown>[] | null;
    public data: TData | null;

    constructor(type?: ActionType) {
        this.type = type ?? ActionType.Unknown;
        this.successful = false;
        this.error = null;
        this.children = null;
        this.data = null;
    }

    public set(response: CreateAsyncActionResponseRequest<TError, TData>): this {
        this.successful = response.successful;
        this.error = response.error ?? null;
        this.children = response.children ?? null;
        this.data = response.data ?? null;

        return this;
    }

    public getErrorMessages(): (string | ErrorMessageGroup)[] {
        const errorMessages: (string | ErrorMessageGroup)[] = !isNullOrUndefined(this.error?.message)
            ? [this.error!.message]
            : [];

        errorMessages.push(
            ...(this.children?.reduce<(string | ErrorMessageGroup)[]>((messageCollection, childResponse) => {
                if (isNullOrUndefined(childResponse?.error?.message)) return messageCollection;

                return [...messageCollection, childResponse!.error!.message];
            }, []) ?? [])
        );

        return errorMessages;
    }
}
