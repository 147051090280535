import { gql } from 'graphql.macro';

export const GetNearMissList = gql`
    query GetNearMissRecords($request: NearMissFilterRequestInput) {
        nearMissRecords(request: $request) {
            observationId
            concernLevel
            locationType
            locationTypeDescription
            offSiteLocation
            {
                customerSiteName
                locationDescription
            }
            depotPcId
            depotPC
            depotPcName
            divisionId
            divisionName
            regionId
            regionName
            sHEQManagerId
            sHEQManager
            observationCategoryId
            observationCategoryName
            observationCategoryNameDE
            whatHappened
            observationStatus
            incidentDate
        }
    }
`;
