import React, { FC, useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { GetLoggedInUserPermissionsResponse } from '../queries/permissions.queries.types';
import { GetLoggedInUserPermissions } from '../queries/permissions.queries';
import { ApplicationPermission } from '../types/ApplicationPermission';
import { PermissionContext } from '../contexts/permissions.context';
import { ApolloErrorToastMessage } from 'modules/errors';
import { useToasts } from 'react-toast-notifications';
import { isNullOrEmpty, isNullOrUndefined } from 'shared/utils/null.utils';
import { RequiredPermission } from '../types/RequiredPermission';
import { User } from 'modules/security';
import { useSecurity } from '../../security/hooks/useSecurity';

/**
 * Props interface for {@link PermissionProvider}
 */
interface IPermissionProviderProps {
    user: Nullable<User>;
    children: React.ReactNode;
}

/**
 * PermissionProvider component
 * @param props {@link IPermissionProviderProps}
 */
export const PermissionProvider: FC<IPermissionProviderProps> = ({ user, children }: IPermissionProviderProps) => {
    const [permissions, setPermissions] = useState<ApplicationPermission[]>([]);
    const [hasInitialised, setHasInitialised] = useState<boolean>(false);
    const { addToast } = useToasts();
    const apolloClient = useApolloClient();
    const employeeId = user?.employeeId;

    useEffect(() => {
        if (!isNullOrEmpty(employeeId)) {
            apolloClient
                .query<GetLoggedInUserPermissionsResponse>({
                    query: GetLoggedInUserPermissions
                })
                .then(response => {
                    setPermissions(response.data.clientPermissions ?? []);
                    setHasInitialised(true);
                })
                .catch(error => {
                    addToast(<ApolloErrorToastMessage error={error} baseMessage='Issue loading user permissions' />, {
                        appearance: 'warning'
                    });
                });
        }
    }, [employeeId, addToast, apolloClient]);

    const hasPermission = useCallback(
        (requiredPermission?: RequiredPermission): boolean => {
            if (isNullOrUndefined(requiredPermission)) return true;

            const permission = permissions.find(y => y.permissionKey === requiredPermission.permissionKey);

            if (isNullOrUndefined(permission)) return false;

            if (isNullOrUndefined(requiredPermission.permissionTypes)) return true;

            var z = requiredPermission.permissionTypes.all(x => permission[x]);

            return z;

        },
        [permissions]
    );

    const hasPermissions = useCallback(
        (requiredPermissions?: RequiredPermission[]): boolean => {
            if (isNullOrUndefined(requiredPermissions)) return true;

            return requiredPermissions.any(hasPermission);
        },
        [hasPermission]
    );

    return (
        <PermissionContext.Provider
            value={{
                permissions,
                hasPermission,
                hasPermissions,
                initialised: hasInitialised
            }}
        >
            {children}
        </PermissionContext.Provider>
    );
};