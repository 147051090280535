import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import Loading from 'components/layout/Loading';

import {
  StyledAuditLogContainer,
  StyledHistoryContainer
} from '../styled/near-miss-audit.styled';

import { AuditLogRequest, AuditLogResponse } from 'shared/queries/near-miss-audit.queries.types';
import { AuditRecord } from 'shared/types/audit/AuditRecord';
import { GetPaginatedAuditLogs } from 'shared/queries/near-miss-audit.queries';
import AuditHistory from 'components/AuditContainer/components/AuditHistory';

interface AuditContainerProps {
  uniqueRecordId: UID;
}

const pageSize: number = 10;

export const AuditContainer = ({ uniqueRecordId }: AuditContainerProps) => {

  const graphClient = useApolloClient();
  const dateFields = ["NearMissCreatedDate", "NearMissIncidentDate", "LastModified", "CompletionDetails.CompletionDate"]

  const [auditRecords, setAuditRecords] = useState<AuditRecord[]>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<ApolloError>();

  useEffect(() => {
    loadAuditRecords();
  }, [uniqueRecordId, pageNumber]);

  useEffect(() => {
    setIsLoading(false);
    if (!initialLoad) setInitialLoad(true);
  }, [auditRecords, initialLoad])

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  }

  const loadAuditRecords = async () => {
    setIsLoading(true);

    await graphClient
      .query<AuditLogResponse, AuditLogRequest>({
        query: GetPaginatedAuditLogs,
        variables: {
          referenceId: uniqueRecordId,
          page: pageNumber,
          offSet: pageSize
        },
        fetchPolicy: 'network-only'
      })
      .then(result => {
        if (result.data && result.data.auditRecords) {
          setAuditRecords([...auditRecords ?? [], ...result.data.auditRecords]);
        }
      })
      .catch(e => setLoadingError(e as ApolloError));
  }

  return (
    <Loading isLoading={isLoading && !initialLoad} message={'Loading Audit...'}>
      <StyledAuditLogContainer>
        <GraphQlErrorBoundary error={loadingError}>
          <StyledHistoryContainer>
            {
              auditRecords &&
              <AuditHistory auditRecords={auditRecords!} dateFields={dateFields} onNextPage={handleNextPage} />
            }
          </StyledHistoryContainer>
        </GraphQlErrorBoundary>
      </StyledAuditLogContainer>
    </Loading>
  );
};