import { FC, CSSProperties } from "react";

export const CellContainer: FC<{ style?: CSSProperties }> = ({ children, style }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                height: '100%',
                ...style
            }}
        >
            {children}
        </div>
    );
};