import { Col, Grid, Row } from 'react-flexbox-grid';
import { StyledHeaderText, StyledSection, StyledSubHeader } from 'sections/configuration/styled/configuration.styles';
import DepotSearchInput from 'sections/configuration/components/DepotSearchInput/components/DepotSearchInput';
import { DepotEntity } from 'shared/types/configuration/DepotEntity';
import { useTranslation } from 'react-i18next';

interface GetDetailsForDepotProps {
    onSelectedDepot: (depot: DepotEntity | null | undefined) => void;
}

export const GetDetailsForDepot = ({ onSelectedDepot: onSelectDepot }: GetDetailsForDepotProps) => {

    const { t } = useTranslation();
    
    return (
        <>
            <div style={{ display: 'flex' }}>
                <div>
                    <StyledHeaderText>{t('Depot.NewDepot')}</StyledHeaderText>
                </div>
            </div>
            <Grid fluid>
                <Row>
                    <Col lg={6} xl={4}>
                        <StyledSection>
                            <StyledSubHeader>{t('Depot.SelectNewDepot')}</StyledSubHeader>
                            <DepotSearchInput onDepotSelect={onSelectDepot} />
                        </StyledSection>
                    </Col>
                </Row>
            </Grid>
        </>
    );
};
