import { FormFieldData } from "modules/forms";
import { ObservationLocationTypeEnum } from "shared/enums/ObservationLocationType.enum";
import { NearMissEntity } from "shared/types/domain/NearMissEntity";
import { IMapper } from "shared/types/IMapper";
import { ConvertToUTC } from "shared/utils/date.utils";
import { keysAreEqual } from "shared/utils/equality.utils";
import { UpdateNearMissRequest } from "../mutations/ammend-near-miss.mutations.types";

export class UpdateNearMissRequestMapper implements IMapper<FormFieldData<NearMissEntity>, UpdateNearMissRequest> {
    map(entity: FormFieldData<NearMissEntity>): UpdateNearMissRequest {
        return {
            nearMissId: entity.nearMissId,
            companyId: entity.companyId,
            concernLevel: entity.concernLevel,
            locationType: entity.locationType.toUpperCase(),
            depotPcId: entity.depotPcId,
            customerSiteName: keysAreEqual(entity.locationType, ObservationLocationTypeEnum.CustomerSite) ? entity.customerSiteName : null,
            locationDescription: keysAreEqual(entity.locationType, ObservationLocationTypeEnum.SunbeltDepot) ? null : entity.locationDescription,
            locationLatitude: keysAreEqual(entity.locationType, ObservationLocationTypeEnum.Other) ? entity.locationLatitude : null,
            locationLongitude: keysAreEqual(entity.locationType, ObservationLocationTypeEnum.Other) ? entity.locationLongitude : null,
            observationCategoryId: entity.observationCategoryId,
            whatHappened: entity.whatHappened,
            howToStop: entity.howToStop,
            reporterType: entity.reporterType,
            anonymousEntry: entity.anonymousEntry,
            contactName: entity.reporterContactName,
            contactPhoneNumber: entity.reporterPhoneNumber,
            contactEmail: entity.reporterEmail,
            incidentDate: ConvertToUTC(entity.incidentDate)
        }
    };
}