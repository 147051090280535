import { IDocumentType } from 'shared/types/file/IDocumentType';
import { DocumentType } from 'shared/enums/DocumentType.enum';

export const documentTypes: { [key in DocumentType]: IDocumentType } = {
    [DocumentType.PersonalIdentification]: {
        id: 1,
        name: 'Personal Identification'
    },
    [DocumentType.CompanyLetterhead]: {
        id: 2,
        name: 'Company Letterhead'
    },
    [DocumentType.CreditReport]: {
        id: 3,
        name: 'Credit Report'
    },
    [DocumentType.InsurancePolicyDocument]: {
        id: 4,
        name: 'Insurance Policy Document'
    },
    [DocumentType.DeedofGuarantee]: {
        id: 5,
        name: 'Deed of Guarantee'
    },
    [DocumentType.ApplicationforRentalInsurance]: {
        id: 6,
        name: 'Application for Rental Insurance'
    },
    [DocumentType.ConsolidatedApplicationPack]: {
        id: 7,
        name: 'Consolidated Application Pack'
    }, 
    [DocumentType.Other]: {
        id: 8,
        name: 'Other'
    },
    [DocumentType.IDVerificationDocument]: {
        id: 9,
        name: 'ID Verification Document'
    },
    [DocumentType.OriginalApplication]: {
        id: 10,
        name: 'Original Application'
    },
    [DocumentType.Evidence]: {
        id: 11,
        name: 'Evidence'
    },
};
