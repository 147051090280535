import { AppRoute } from "modules/routing/types/AppRoute";
import AppSettings from "../pages/AppSettings/components/AppSettings";
import NearMissCategoryList from "../pages/NearMissCategory/pages/NearMissCategoryList";
import NewNearMissCategoryConfiguration from "../pages/NearMissCategory/pages/NewNearMissCategory/components/NewNearMissCategoryConfiguration";
import ViewNearMissCategoryConfiguration from "../pages/NearMissCategory/pages/ViewNearMissCategory/components/ViewNearMissCategoryConfiguration";
import NewProfile from "../pages/Profile/pages/NewProfile/components/NewProfile";
import ProfileList from "../pages/Profile/pages/ProfileList/components/ProfileList";
import ViewProfile from "../pages/Profile/pages/ViewProfile/components/ViewProfile";
import { UserList } from "../pages/User/pages/UserList/components/UserList";
import ViewUser from "../pages/User/pages/ViewUser/components/ViewUserConfiguration";
import NewSheqManager from "../pages/SheqManager/pages/NewSheqManager";
import SheqManagerList from "../pages/SheqManager/pages/SheqManagerList";
import ViewSheqManager from "../pages/SheqManager/pages/ViewSheqManager";
import { baseRoute, ConfigurationPage, configurationRouteBases, NearMissCategoryPage, nearMissCategoryRouteParams, ProfilePage, profileRouteParams, SheqManagerPage, UserPage, userRouteParams, sheqManagerRouteParams, DepotPage, depotRouteParams } from "./configuration-page.types";
import NewDepot from "../pages/Depots/pages/NewDepot";
import ViewDepot from "../pages/Depots/pages/ViewDepot";
import DepotList from "../pages/Depots/pages/DepotList";

export const configurationHome = baseRoute;

// Change this to change the landing page for configuration
const defaultComponent = NearMissCategoryList;

export const configurationRoutes: AppRoute[] = [
  {
    name: 'App Settings',
    path: configurationRouteBases[ConfigurationPage.AppSettings],
    component: AppSettings,
    exact: true
  },
  {
    name: 'Near Miss Category List',
    path: configurationRouteBases[ConfigurationPage.NearMissCategory],
    component: NearMissCategoryList,
    exact: true
  },
  {
    name: 'New Near Miss Category',
    path: nearMissCategoryRouteParams[NearMissCategoryPage.NewCategory](),
    component: NewNearMissCategoryConfiguration,
    exact: true
  },
  {
    name: 'View/Ammend Near Miss Category',
    path: nearMissCategoryRouteParams[NearMissCategoryPage.ViewCategory](),
    component: ViewNearMissCategoryConfiguration,
    exact: true
  },
  {
    name: 'Sheq Manager List',
    path: configurationRouteBases[ConfigurationPage.SheqManager],
    component: SheqManagerList,
    exact: true
  },
  {
    name: 'New Sheq Manager',
    path: sheqManagerRouteParams[SheqManagerPage.NewSheqManager](),
    component: NewSheqManager,
    exact: true
  },
  {
    name: 'View Sheq Manager',
    path: sheqManagerRouteParams[SheqManagerPage.ViewSheqManager](),
    component: ViewSheqManager,
    exact: true
  },
  {
    name: 'Profile List',
    path: configurationRouteBases[ConfigurationPage.Profiles],
    component: ProfileList,
    exact: true
  },
  {
    name: 'New Profile',
    path: profileRouteParams[ProfilePage.NewProfile](),
    component: NewProfile,
    exact: true
  },
  {
    name: 'View Profile',
    path: profileRouteParams[ProfilePage.ViewProfile](),
    component: ViewProfile,
    exact: true
  },
  {
    name: 'User List',
    path: configurationRouteBases[ConfigurationPage.Users],
    component: UserList,
    exact: true
  },
  {
    name: 'View User',
    path: userRouteParams[UserPage.ViewUser](),
    component: ViewUser,
    exact: true
  },
  {
    name: 'Depot List',
    path: configurationRouteBases[ConfigurationPage.Depots],
    component: DepotList,
    exact: true
  },
  {
    name: 'New Depot',
    path: depotRouteParams[DepotPage.NewDepot](),
    component: NewDepot,
    exact: true
  },
  {
    name: 'View Depot',
    path: depotRouteParams[DepotPage.ViewDepot](),
    component: ViewDepot,
    exact: true
  },
  {
    name: 'Default Route',
    path: configurationHome,
    component: defaultComponent
  }
];