import { actionCreatorFactory } from 'shared/utils/redux.utils';
import { UserSearchStateActionEnum } from '../../enums/UserSearchStateAction.enum';
import { UserSearchState } from '../../store/state.types';
import { isNullOrUndefined } from 'shared/utils/validation.utils';
import immutableUpdate from 'immutability-helper';

export const setSearchInputValue = actionCreatorFactory<typeof UserSearchStateActionEnum, string>(
    UserSearchStateActionEnum.SetSearchInputValue
);

export const setSearchInputValueHandler: ActionHandler<UserSearchState, typeof setSearchInputValue> = (
    state,
    action
) => {
    if (isNullOrUndefined(action.payload)) return state;

    return immutableUpdate(state, {
        searchInputValue: { $set: action.payload }
    });
};
