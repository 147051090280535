import { actionCreatorFactory } from 'shared/utils/redux.utils';
import { ApolloError } from 'apollo-client';
import { UserSearchStateActionEnum } from '../../enums/UserSearchStateAction.enum';
import { UserSearchState } from '../state.types';

export const setSearchError = actionCreatorFactory<typeof UserSearchStateActionEnum, ApolloError | undefined>(
    UserSearchStateActionEnum.SetSearchError
);

export const setSearchErrorHandler: ActionHandler<UserSearchState, typeof setSearchError> = (state, action) => {
    return {
        ...state,
        searchError: action.payload
    };
};
