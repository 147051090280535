import Button from "components/controls/Button";
import { CSSProperties } from "react";
import { ObservationConcernLevelEnum } from "shared/enums/ObservationConcernLevel.enum";
import { getConcernLevelColor } from "shared/utils/concern-level.utils";

export interface ConcernLevelButtonProps {
    forConcernLevel: ObservationConcernLevelEnum;
    text: string;
    value: ObservationConcernLevelEnum;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled: boolean;
    tooltip?: string;
}

const ConcernLevelButton = ({
    forConcernLevel,
    text,
    value,
    onClick,
    disabled,
    tooltip
}: ConcernLevelButtonProps) => {

    const mainColor = (): string => {
        return getConcernLevelColor(forConcernLevel);
    }

    const defaultColor = 'white';

    const backGroundColor = (): string => {
        return value == forConcernLevel ? mainColor() : defaultColor;
    }

    const textColor = (): string => {
        return value == forConcernLevel ? defaultColor : mainColor();
    }

    const buttonStyle: CSSProperties = {
        backgroundColor: backGroundColor(),
        color: textColor(),
        borderColor: mainColor(),
        opacity: disabled ? 0.6 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer'
    };

    return (
        <Button text={text} style={buttonStyle} onClick={onClick} disabled={disabled} title={tooltip} />
    );
}

export default ConcernLevelButton;