import { IDropdownOption, Persona, Checkbox } from '@fluentui/react'
import { ConditionalRender } from 'components/layout/ConditionalRender';

import { 
  FilterSelectResult
} from '../styled/FilterSelectV2.styled'

interface FilterSelectItemV2Props {
  option: IDropdownOption;
  isSelected?: boolean;
  multiSelect?: boolean;
  onClick?: (option: IDropdownOption, value?: boolean) => void;
  showPersona?: boolean;
}

export const FilterSelectItemV2 = ({
  option,
  isSelected,
  multiSelect,
  onClick,
  showPersona
}: FilterSelectItemV2Props) => {

  const selectOption = (): void => {
    if (onClick) onClick(option, !isSelected);
  }

  // Note: This component does not yet cater for Persona AND multi-select

  return (
    <>
      <ConditionalRender condition={!!showPersona}>
        <FilterSelectResult onClick={selectOption}>
          <Persona text={option.text} />
        </FilterSelectResult>
      </ConditionalRender>
      <ConditionalRender condition={!!multiSelect && !showPersona}>
        <FilterSelectResult>
          <Checkbox label={option.text} checked={isSelected} onChange={selectOption} />
        </FilterSelectResult>
      </ConditionalRender>
      <ConditionalRender condition={!multiSelect && !showPersona}>
        <FilterSelectResult onClick={selectOption}>
          {option.text}
        </FilterSelectResult>
      </ConditionalRender>
    </>
  )
}