export enum UserPermission {
    NewObservation = 'nearmissreporting:NewNearMissRecord',
    ExistingObservation = 'nearmissreporting:NearMissOverview',
    ExistingObservation_Local = 'nearmissreporting:NearMissOverviewLocal',
    ObservationReporter = 'nearmissreporting:ReporterContact',
    Completion = 'nearmissreporting:Completion',
    Archive = 'nearmissreporting:Archive',
    ReOpen = 'nearmissreporting:Reopen',
    Category = 'nearmissreporting:NearMissCategory',
    DepotLinks = 'nearmissreporting:DepotLinks',
    EnumLookup = 'nearmissreporting:Lookup',
    ImageFile = 'applications:documents',
    IncidentDate = 'nearmissreporting:IncidentDate',
    ManageDepots = 'nearmissreporting:ManageDepots',
    Audit = 'applications:AuditLog',
    Profiles = 'UserProfile',
    User = 'User',
    AppSettings = 'nearmissreporting:AppSettings'
}