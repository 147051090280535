import { gql } from 'graphql.macro';

export const GetGoodPracticeById = gql`
    query GetGoodPractice($id: Int!) {
        goodPractice: goodPracticeById(id: $id) {
            observationId
            uniqueRecordId
            companyId
            locationType
            offSiteLocation {
                customerSiteName
                locationDescription
                locationLatitude
                locationLongitude
            }
            depotPcId
            observationCategoryId
            observationCategoryName
            whatHappened
            whoShouldBeCommended
            anonymousEntry
            reporterType
            reportedBy {
                reporterContactName
                reporterPhoneNumber
                reporterEmail
            }
            observationStatus
            lastModified
            incidentDate
            completion {                
                closureReason
                closeDate
                employeeId
            }
        }
    }
`;
