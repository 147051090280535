import styled from 'styled-components';

export const StyledConfigurationContainer = styled.div`
    flex: 1;
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
`;

export const StyledHeaderText = styled.h1`
    margin-bottom: 25px;
    font-size: 1.9em;
    word-break: break-all;
`;

export const StyledSubHeader = styled.h2`
    display: block;
    font-size: 1.5em;
    margin-bottom: 15px;
    color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledFlexRow = styled.div`
    display: flex;

    > div {
        padding-left: 25px;
    }

    > div:first-of-type {
        padding-left: 0;
        padding-right: 25px;
        border-right: 1px solid ${({ theme }) => theme.palette.gray[300]};
    }
`;

export const StyledSection = styled.div``;

export const StyledOptionDescription = styled.span`
    display: block;
    padding: 5px 0;
`;

export const StyledOptionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledOptionTextContainer = styled.div`
    margin: 15px 0 15px 0;
    width: 100%;
`;

export const StyledOptionToggleContainer = styled.div`
    flex: end;
`;

export const StyledOptionLabelControlContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledOptionLabelControl = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
`;

export const StyledOptionLabel = styled.label`
    display: block;
    padding: 5px 0;

    > span {
        color: ${({ theme }) => theme.palette.error.dark};
    }
`;

export const StyledButtonContainer = styled.div`
    margin: 15px 0;

    > button:not(:first-of-type) {
        margin-left: 15px;
    }
`;

export const StyledHorizontalRule = styled.hr`
    margin: 2em 0;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.gray[300]};
`;

export const StyledBoldText = styled.label`
    font-size: 14px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
`;

export const StyledGrayInfoText = styled.div`
    font-size: 12px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
    color: ${({ theme }) => theme.palette.gray[500]};
    font-style: italic;
`;

export const StyledCommandBar = styled.div`
    display: flex;
`;