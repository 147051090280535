import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useValidation } from 'modules/validation';
import { usePermissions } from 'modules/permissions';
import { isNullOrUndefined, isUndefined } from 'shared/utils/null.utils';
import { FormPermissions } from '../types/FormPermissions';
import { IFormFieldConfigValue } from '../types/FormFieldConfig';
import { IFormOptions } from '../types/IFormOptions';
import { FormFieldData } from '../types/FormFieldData';
import { FormErrors } from '../types/FormErrors';
import { FormContext } from '../contexts/form.context';
import { IFormFieldUpdateFunction } from '../types/IFormFieldUpdateFunction';
import { FormFieldUpdateRequest } from '../types/FormFieldUpdateRequest';

/**
 * Props interface for {@link FormProvider}
 */
interface IFormProviderProps<TEntity = unknown> {
    /**
     * The form fields for TEntity
     */
    formFields?: FormFieldData<TEntity> | undefined;

    /**
     * The components children
     */
    children: React.ReactNode;

    /**
     * The form options
     */
    options: IFormOptions<TEntity>;
}

/**
 * FormProvider component
 * @param props {@link IFormProviderProps}
 */
export const FormProvider = <TEntity extends object>({ formFields, children, options }: IFormProviderProps<TEntity>) => {
    const validationTimers = useRef<{ [key in keyof TEntity]?: number }>({});
    const { hasPermissions } = usePermissions();
    const [formData, setFormData] = useState<FormFieldData<TEntity> | undefined>(formFields);
    const [formErrors, setFormErrors] = useState<FormErrors<TEntity>>({});
    const [formPermissions, setFormPermissions] = useState<FormPermissions<TEntity> | undefined>();

    const { validate } = useValidation();

    useEffect(() => {
        const formPermissions = Object.entries<IFormFieldConfigValue<TEntity>>(options.fields).reduce<
            FormPermissions<TEntity>
        >((currentPermissions, [key, value]) => {
            return { ...currentPermissions, [key]: value.permissions };
        }, {} as FormPermissions<TEntity>);

        setFormPermissions(formPermissions);
    }, [options]);

    useEffect(() => {
        setFormData(formFields);
    }, [formFields]);

    const isFieldValid = useCallback(
        (
            fieldName: keyof TEntity,
            newValue: unknown,
            currentState: FormFieldData<TEntity> | undefined,
            setErrors = false
        ): boolean => {
            let currentError: string | undefined = undefined;
            let isValid = true;

            const requiredFieldPermissions = options.fields[fieldName].permissions;

            if (isNullOrUndefined(requiredFieldPermissions) || hasPermissions(requiredFieldPermissions)) {
                for (const rule of options.fields[fieldName].rules) {
                    if (isUndefined(rule.condition) || rule.condition(currentState)) {
                        const result = validate<TEntity>(newValue, rule.name, rule.data);

                        if (result.hasError) {
                            isValid = false;
                            currentError = result.error?.message;
                            break;
                        }
                    }
                }
            }

            if (setErrors) {
                setFormErrors(prevErrors => {
                    return prevErrors[fieldName] === currentError
                        ? prevErrors
                        : { ...prevErrors, [fieldName]: currentError };
                });
            }

            return isValid;
        },
        [validate, options, hasPermissions]
    );

    function isValid(setErrors = false): boolean {
        if (isUndefined(formData)) return false;

        let isValid = true;

        const fieldsToValidate = Object.entries<IFormFieldConfigValue<TEntity>>(options.fields).filter(
            ([, validationConfig]) => validationConfig.rules.length > 0
        );

        for (const field of fieldsToValidate) {
            const [fieldName] = field;

            const valid = isFieldValid(
                fieldName as keyof TEntity,
                formData[fieldName as keyof TEntity],
                formData,
                setErrors
            );

            if (!valid) isValid = false;
        }

        return isValid;
    }

    const setFieldErrorsDebounce = useCallback(
        (
            fieldName: keyof TEntity,
            newValue: unknown,
            currentState: FormFieldData<TEntity> | undefined,
            delay = 400
        ): void => {
            if (!isUndefined(validationTimers.current[fieldName])) {
                clearTimeout(validationTimers.current[fieldName]);
                validationTimers.current[fieldName] = undefined;
            }

            validationTimers.current[fieldName] = setTimeout(() => {
                validationTimers.current[fieldName] = undefined;

                isFieldValid(fieldName, newValue, currentState, true);
            }, delay);
        },
        [isFieldValid]
    );

    const updateFields = useCallback<IFormFieldUpdateFunction<TEntity>>(
        fields => {
            setFormData(state => {
                const newState = { ...state } as FormFieldData<TEntity>;

                Object.entries<FormFieldUpdateRequest | undefined>(fields).forEach(([fieldName, request]) => {
                    if (!isUndefined(request)) {
                        if (!isUndefined(request.value)) newState[fieldName as keyof TEntity] = request.value;

                        if (request.runValidation)
                            setFieldErrorsDebounce(
                                fieldName as keyof TEntity,
                                newState[fieldName as keyof TEntity],
                                newState
                            );
                    }
                });

                return newState;
            });
        },
        [setFieldErrorsDebounce]
    );

    return (
        <FormContext.Provider value={{ formData, formErrors, isValid, updateFields, formPermissions }}>
            {children}
        </FormContext.Provider>
    );
};