import React, { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Notifications } from '../types/Notifications';
import { ApolloErrorToastMessage, ActionError, isApolloError, ErrorListToastMessage } from 'modules/errors';
import { isNullOrUndefined } from 'shared/utils/null.utils';

/**
 * Used to notify users of an event or action
 * @dependency {@link useToasts} The hook requires the consuming component to be wrapped in a ToastProvider
 * @returns {Notifications}
 */
export const useNotifications = (): Notifications => {
    const { addToast } = useToasts();

    /**
     * Notifies an error using a red toast notification, with an optional {@link ActionError}, which if supplied will
     * display any internal error messages, if the {@link ActionError.error} is an ApolloError, the apollo error
     * message will be displayed
     * @param {string} message The base message to display
     * @param {ActionError<unknown> | null} error If supplied, this error will be used to show internal error
     * messages from the {@link ActionError}
     */
    const notifyError = useCallback(
        (message: string, error?: ActionError<unknown> | null): void => {
            const internalError = error?.error;

            if (!isNullOrUndefined(error)) {
                if (!isNullOrUndefined(internalError) && isApolloError(internalError)) {
                    addToast(<ApolloErrorToastMessage error={internalError} baseMessage={message} />, {
                        appearance: 'error'
                    });

                    return;
                }

                addToast(
                    <ErrorListToastMessage
                        errors={[error.message ?? 'An unknown error occurred']}
                        baseMessage={message}
                    />,
                    {
                        appearance: 'error'
                    }
                );

                return;
            }

            addToast(<ErrorListToastMessage errors={[]} baseMessage={message} />, {
                appearance: 'error'
            });
        },
        [addToast]
    );

    /**
     * Notifies success by displaying a green toast notification
     * @param {string} message The success message to display
     */
    const notifySuccess = useCallback(
        (message: string): void => {
            addToast(message, { appearance: 'success' });
        },
        [addToast]
    );

    /**
     * Notifies of a warning by displaying a yellow toast notification
     * @param {string} message The warning message to display
     */
    const notifyWarning = useCallback(
        (message: string): void => {
            addToast(message, { appearance: 'warning' });
        },
        [addToast]
    );

    return { notifyError, notifySuccess, notifyWarning };
};
