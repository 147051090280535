import { AppRoute } from "modules/routing/types/AppRoute";
import { GoodPracticeList } from "../pages/GoodPracticeList/GoodPracticeList";
import { ViewAmendGoodPractice } from "../pages/ViewAmendGoodPractice/ViewAmendGoodPractice";

export const goodPracticeManagementHome = '/goodpractice';

export const viewAmendGoodPracticeRoute = (id?: string): string => {
    return `/goodpractice/${id ?? ':id'}`
}

export const goodPracticeManagementRoutes: AppRoute[] = [
    {
        name: 'Good Practice List Home',
        path: goodPracticeManagementHome,
        exact: true,
        component: GoodPracticeList
    },
    {
        name: 'View/Amend Good Practice',
        path: viewAmendGoodPracticeRoute(),
        exact: true,
        component: ViewAmendGoodPractice
    }
];