import styled from 'styled-components';

export const StylesUserContainer = styled.div`
  display: flex;
  align-items: center;
  
  &:hover{
   background-color: ${({ theme }) => theme.palette.gray['200']};
  }
`;

export const StyledUserCard = styled.div`
  padding: 10px 15px; 
  flex: 1;
  cursor:pointer;
`;