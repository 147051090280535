import { useRef, useEffect, useCallback } from 'react';
import { IValidationRule } from '../types/IValidationRule';
import { rules as defaultRules } from '../rules/default.validation-rules';
import { IValidationOptions } from '../types/IValidationOptions';
import { IUseValidation } from '../types/IUseValidation';
import { IValidationResult } from '../types/IValidationResult';
import { ValidationResultCreator } from '../classes/ValidationResultCreator';
import { isNullOrUndefined } from 'shared/utils/null.utils';

export function useValidation(options?: IValidationOptions): IUseValidation {
    const validationRules = useRef<IValidationRule[]>(defaultRules);

    useEffect(() => {
        if (!isNullOrUndefined(options) && !isNullOrUndefined(options.rules))
            validationRules.current = defaultRules.concat(options.rules);
    }, [options]);

    const validate = useCallback(<TData>(value: any, ruleName: string, data?: TData): IValidationResult => {
        const validator = validationRules.current.find(x => x.name === ruleName);

        if (!isNullOrUndefined(validator)) {
            return validator.resolver(value, data);
        }

        return ValidationResultCreator.pass();
    }, []);

    return { validate };
}
