import React, { useState } from 'react';
import { NewNearMissLoadingState } from 'sections/near-miss-report-new/pages/NewNearMiss/components/NewNearMissMain/enums/new-near-miss-loading-state.enum';
import { NearMissEntity } from 'shared/types/domain/NearMissEntity';
import { ApolloError } from 'apollo-client';
import { FormFieldData, FormProvider } from 'modules/forms';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { useFiles } from 'shared/utils/files/hooks/useFiles';
import { allowedMimeTypes } from 'shared/options/observation-files.options';
import { mapFileToFormData } from 'shared/utils/mapping.utils';
import { useRestClient } from 'modules/rest-client/hooks/useRestClient';
import { IFileUploadResponse } from 'shared/types/file/IFileUploadRequest';
import { ErrorListToastMessage } from 'modules/errors';
import { useToasts } from 'react-toast-notifications';
import { useApolloClient } from '@apollo/react-hooks';
import { IAppConfig, useConfig } from 'modules/config';
import { IFormIsValidFunction } from 'modules/forms/types/IFormIsValidFunction';
import { ActionResponse } from 'modules/errors/classes/ActionResponse';
import Loading from 'components/layout/Loading';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import { StyledNearMissContainer } from 'sections/near-miss-management/pages/ViewAmmendNearMiss/styled/ViewAmmendNearMiss.styled';
import { isNullOrUndefined } from 'shared/utils/validation.utils';
import { createNearMissOptions } from '../options/create-near-miss.options';
import { CreateNearMissResponse, CreateNearMissVariables } from '../mutations/create-near-miss.mutations.types';
import { CreateNearMiss } from '../mutations/create-near-miss.mutation';
import { CreateNearMissRequestMapper } from '../mappers/CreateNearMissMapper';
import NewNearMissForm from './NewNearMissForm';
import NewNearMissSubHeader from './NearMissSubHeader/components/NewNearMissSubHeader';
import NewNearMissHeader from './NewNearMissHeader';
import { useTranslation } from 'react-i18next';

interface NewNearMissMainProps {
   onComplete: () => void;
}

export const NewNearMissMain = ({ onComplete }: NewNearMissMainProps) => {
   const { addToast } = useToasts();
   const [nearMissForm, setNearMissForm] = useState<FormFieldData<NearMissEntity> | undefined>();
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [loadingMessage, setLoadingMessage] = useState<string>(
      NewNearMissLoadingState.loadingNearMiss
   );
   const [loadingError, setLoadingError] = useState<ApolloError>();

   const { settings } = useConfig<IAppConfig>();
   const restClient = useRestClient<'fileUpload'>();
   const setLoadingState = (loadingMessage: NewNearMissLoadingState, isLoading: boolean): void => {
      setLoadingMessage(t(loadingMessage));
      setIsLoading(isLoading);
   };

    const client = useApolloClient();
    const { t } = useTranslation(); 

   const { files, addFiles, syncFiles, removeFilesByIds } = useFiles<ObservationFileEntity>({
      allowedMimeTypes,
      onNotAllowed: handleNotAllowedFilesAdded,
      addFile: uploadFile,
      updateFile: () => {},
      removeFile: () => {},
      maxFileUploadBytes: settings.MaxFileUploadBytes
   });

    function handleNotAllowedFilesAdded(messages: string[]): void {
        addToast(
            <ErrorListToastMessage
                errors={messages}
                baseMessage={t('ObservationPage.DisallowedFileTypes')}
            />,
            { appearance: 'warning' }
        );
    }

   async function uploadFile(
      file: ObservationFileEntity,
      folderName?: string,
      data?: UID
   ): Promise<ObservationFileEntity> {
      const formData = mapFileToFormData<{ referenceId: UID | undefined }>(file, settings, folderName, {
         referenceId: data
      });

      const endpoint = restClient.getEndpoint('fileUpload');
      const result = await endpoint.post<IFileUploadResponse>({
         method: 'UploadFile',
         data: formData,
         onUploadProgress: p => {}
      });

      return { ...file, fileId: result.data.fileId };
   }

   const handleSaveChanges = async (
      formData: FormFieldData<NearMissEntity> | undefined,
      fileData: ObservationFileEntity[] | undefined,
      isValid: IFormIsValidFunction
   ): Promise<ActionResponse> => {
      setLoadingState(NewNearMissLoadingState.submitting, true);

      const response = await createNearMissEntity(formData, isValid);

      setLoadingState(NewNearMissLoadingState.submitting, false);

      if (!response.successful) {
         addToast(<ErrorListToastMessage errors={[response.error.message]} />, { appearance: 'error' });

         return response;
      }

      if (files) {
         setLoadingState(NewNearMissLoadingState.uploadingFiles, true);

         const filesSynced = await syncFiles(response.data.nearMissId.toString(), response.data.uniqueRecordId);

         setLoadingState(NewNearMissLoadingState.uploadingFiles, false);

            if (!filesSynced) {
                addToast(<ErrorListToastMessage errors={[t('ObservationPage.IssueUploadingFiles')]} />, { appearance: 'error' });

            return response;
         }
      }

        addToast(t('ObservationPage.ChangesSavedSuccessfully'), { appearance: 'success' });

      onComplete();

      return response;
   };

   const createNearMissEntity = async (
      formData: FormFieldData<NearMissEntity> | undefined,
      isValid: IFormIsValidFunction
   ): Promise<any> => {
      let actionResponse = {};

        if (isNullOrUndefined(formData) || !isValid(true)) {
            return {
                successful: false,
                error: {
                    message: t('ObservationPage.InformationNotValidFixValidation')
                }
            };
        }

      const mapper = new CreateNearMissRequestMapper();
      const requestObject = mapper.map(formData);

      try {
         const response = await client.mutate<{ createNearMiss: CreateNearMissResponse }, CreateNearMissVariables>({
            mutation: CreateNearMiss,
            variables: {
               request: requestObject
            }
         });

            actionResponse = { successful: true, data: response.data?.createNearMiss };

        } catch (error) {
            actionResponse = {
                successful: false,
                error: {
                    message: t('ObservationPage.IssueCreatingNearMiss'),
                    error: error
                }
            };
        }
        return actionResponse;
    }

   return (
      <React.Fragment>
         <Loading isLoading={isLoading} message={loadingMessage} noDelay>
            <GraphQlErrorBoundary error={loadingError}>
               <StyledNearMissContainer>
                  <FormProvider<NearMissEntity> options={createNearMissOptions} formFields={nearMissForm}>
                     <NewNearMissHeader onSave={handleSaveChanges} isLoading={isLoading} files={files} />
                     <NewNearMissSubHeader formHasErrors={false} goToOverview={() => {}} />
                     <NewNearMissForm handleAddFile={addFiles} handleDeleteFile={removeFilesByIds} />
                  </FormProvider>
               </StyledNearMissContainer>
            </GraphQlErrorBoundary>
         </Loading>
      </React.Fragment>
   );
};
