import { useCallback, useEffect, useRef, useState } from 'react';

import Loading from 'components/layout/Loading';

import { AuditRecord } from 'shared/types/audit/AuditRecord';
import {
   StyledAuditHistoryContainer,
   StyledAuditHeader,
   StyledAuditHistoryRow,
   StyledAuditHistorySection
} from '../styled/audit-history.styled';
import AuditEmployeeCard from '../../AuditEmployeeCard';
import FieldChangesDisplay from '../../FieldChangesDisplay';
import { useTranslation } from 'react-i18next';

interface AuditHistoryProps {
   auditRecords: AuditRecord[];
   dateFields: string[];
   onNextPage: () => void;
}

export const AuditHistory = ({ auditRecords, dateFields, onNextPage }: AuditHistoryProps) => {
   const employeesScrollerRef = useRef<HTMLDivElement>(null);
   const { t } = useTranslation();
   const [auditToDisplay, setAuditToDisplay] = useState<AuditRecord>();
   const [isLoading, setIsLoading] = useState<boolean>(false);

   useEffect(() => {
      setIsLoading(false);

      if (auditRecords.length > 0 && !auditToDisplay) {
         setAuditToDisplay(auditRecords[0]);
      }
   }, [auditRecords, auditToDisplay]);

   const handleScroll = () => {
      if (employeesScrollerRef.current) {
         if (
            employeesScrollerRef.current.scrollHeight - employeesScrollerRef.current.scrollTop ===
            employeesScrollerRef.current.clientHeight
         ) {
            setIsLoading(true);
            onNextPage();
         }
      }
   };

   const distinctAuditRecords = useCallback((): AuditRecord[] => {
      return auditRecords.distinct(x => x);
   }, [auditRecords]);

   return (
      <StyledAuditHistoryContainer flex={1}>
         <StyledAuditHeader>{t('AuditLog.History')}</StyledAuditHeader>

         {auditRecords.length > 0 && (
            <StyledAuditHistoryRow style={{ marginTop: '25px' }}>
               <StyledAuditHistorySection flex={1}>
                  <Loading isLoading={isLoading} overlay={true}>
                     <div
                        ref={employeesScrollerRef}
                        onScroll={handleScroll}
                        style={{
                           minHeight: '400px',
                           maxHeight: '400px',
                           overflowY: 'scroll',
                           position: 'relative'
                        }}
                     >
                        {distinctAuditRecords().map((record, index, array) => {
                           return (
                              <AuditEmployeeCard
                                 key={`${record.referenceId}${record.auditLogId}`}
                                 employee={record.employee}
                                 auditId={record.auditLogId}
                                 date={record.date.toString()}
                                 employeeId={record.employeeId}
                                 selected={
                                    record.employeeId === auditToDisplay?.employeeId &&
                                    record.auditLogId === auditToDisplay?.auditLogId
                                 }
                                 clicked={() => setAuditToDisplay(record)}
                              />
                           );
                        })}
                     </div>
                  </Loading>
               </StyledAuditHistorySection>
               <StyledAuditHistorySection flex={5}>
                  <FieldChangesDisplay auditRecord={auditToDisplay} dateFields={dateFields} />
               </StyledAuditHistorySection>
            </StyledAuditHistoryRow>
         )}

         {auditRecords.length <= 0 && <div>{t('AuditLog.NoChangeHistoryDisplayed')}</div>}
      </StyledAuditHistoryContainer>
   );
};
