import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { IDropdownOption, TextField } from '@fluentui/react';
import ToggleGroup from 'components/controls/ToggleGroup';
import { InputDialog } from 'components/layout/Dialog';
import { useEffect, useState } from 'react';
import { NearMiss } from 'sections/near-miss-management/pages/ViewAmmendNearMiss/queries/view-near-miss.queries.types';
import { ObservationClosureReasonEnum } from 'shared/enums/ObservationClosureReason.enum';
import { StyledControlContainer } from 'shared/styled/control.styled';
import { mapFileToFormData, mapToDropdownOptions } from 'shared/utils/mapping.utils';
import {
   actionTakenNotProvided,
   actionTakenTooLong,
   closureReasonNotSelected,
   closureDateNotProvided,
   closureDateInFuture,
   closureDateBeforeIncident
} from '../constants/completion-validation-messages.cosntants';
import { IClosureReason, IObservationCompletionLookupData } from 'shared/queries/observation-completion.queries.types';
import { AmmendNearMissLoadingState } from 'sections/near-miss-management/pages/ViewAmmendNearMiss/enums/ammend-near-miss-loading-state.enum';
import { ErrorListToastMessage } from 'modules/errors';
import { ActionResponse } from 'modules/errors/classes/ActionResponse';
import { ObservationStatusEnum } from 'shared/enums/ObservationStatus.enum';
import { useToasts } from 'react-toast-notifications';
import { usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import {
   CompleteNearMissResponse,
   CompleteNearMissVariables,
   IDeleteFileVariables
} from 'sections/near-miss-management/pages/ViewAmmendNearMiss/mutations/ammend-near-miss.mutations.types';
import {
   CompleteNearMiss,
   DeleteFile
} from 'sections/near-miss-management/pages/ViewAmmendNearMiss/mutations/ammend-near-miss.mutations';
import DateTimeInput from 'components/controls/DateTimeInput';
import { ConvertToUTC } from 'shared/utils/date.utils';
import { GetNearMissCompletionLookups } from 'sections/near-miss-management/pages/ViewAmmendNearMiss/queries/near-miss-completion.queries';
import { IAddFiles } from 'shared/utils/files/types/IAddFiles';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { IAppConfig, useConfig } from 'modules/config';
import { useRestClient } from 'modules/rest-client';
import { NewEvidenceDocuments } from 'components/EvidenceDocuments/components/NewEvidenceDocuments/NewEvidenceDocuments';
import { IFileUploadResponse } from 'shared/types/file/IFileUploadRequest';
import { IUpdateFiles } from 'shared/utils/files/types/IUpdateFiles';
import { IRemoveFiles } from 'shared/utils/files/types/IRemoveFiles';
import { FileStatus } from 'shared/enums/FileStatus.enum';
import { isNullOrUndefined } from 'shared/utils/validation.utils';
import { useTranslation } from 'react-i18next';

interface CompleteDialogProps {
   nearMiss: NearMiss | undefined;
   isOpen: boolean;
   setLoadingState: (loadingMessage: AmmendNearMissLoadingState, isLoading: boolean) => void;
   onDismiss: () => void;
   onComplete: () => void;
   files: ObservationFileEntity[];
   handleAddEvidence: IAddFiles<ObservationFileEntity>;
   handleUpdateFiles: IUpdateFiles<ObservationFileEntity>;
   handleDeleteEvidence: IRemoveFiles<ObservationFileEntity>;
}

export const CompleteDialog = ({
   nearMiss,
   isOpen,
   setLoadingState,
   onDismiss,
   onComplete,
   files,
   handleAddEvidence,
   handleUpdateFiles,
   handleDeleteEvidence
}: CompleteDialogProps) => {
   const { settings } = useConfig<IAppConfig>();
   const client = useApolloClient();
   const restClient = useRestClient<'fileUpload'>();
   const { addToast } = useToasts();
   const { hasPermission } = usePermissions();

   const [selectedClosureReason, setSelectedClosureReason] = useState<ObservationClosureReasonEnum>();
   const [closureReasonValidationMessage, setClosureReasonValidationMessage] = useState<string>();
   const [actionTaken, setActionTaken] = useState<string>();
   const [actionTakenValidationMessage, setActionTakenValidationMessage] = useState<string>();
   const [selectedClosureDate, setSelectedClosureDate] = useState<string | null>();
   const [closureDateValidationMessage, setClosureDateValidationMessage] = useState<string>();
   const [selectedAttachmentOption, setSelectedAttachmentOption] = useState<string | number>(1);

   const [loadingClosureReasons, setLoadingClosureReasons] = useState<boolean>(true);
   const [closureReasons, setClosureReasons] = useState<IClosureReason[]>();

   const [deleteFileMutation] = useMutation<{ deleteDocument: boolean }, IDeleteFileVariables>(DeleteFile);
   const { t, i18n } = useTranslation();

   async function uploadEvidence(
      file: ObservationFileEntity,
      folderName?: string,
      data?: UID
   ): Promise<ObservationFileEntity> {
      const evidenceData = mapFileToFormData<{ referenceId: UID | undefined }>(file, settings, folderName, {
         referenceId: data
      });

      const endpoint = restClient.getEndpoint('fileUpload');
      const result = await endpoint.post<IFileUploadResponse>({
         method: 'UploadFile',
         data: evidenceData,
         onUploadProgress: p => {}
      });

      return { ...file, fileId: result.data.fileId };
   }

   useEffect(() => {
      loadReporterTypes();
      setSelectedClosureDate(new Date().toISOString());
   }, []);

   const loadReporterTypes = async () => {
      setLoadingClosureReasons(true);

      try {
         let result = await client.query<IObservationCompletionLookupData>({
            query: GetNearMissCompletionLookups,
            variables: { request: { type: 'NEARMISSCLOSUREREASON', selectedLanguage: i18n.language.toUpperCase() } },
            fetchPolicy: 'network-only'
         });

         if (result?.data?.closureReasons != null) {
            setClosureReasons(result.data.closureReasons);
         }
      } catch (e: any) {
      } finally {
         setLoadingClosureReasons(false);
      }
   };

   const resetFormAndClose = () => {
      setSelectedClosureReason(undefined);
      setActionTaken(undefined);
      setClosureReasonValidationMessage(undefined);
      setActionTakenValidationMessage(undefined);

      onDismiss();
   };

   const updateClosureReason = (selection: string | number) => {
      setClosureReasonValidationMessage(undefined);
      setSelectedClosureReason(selection as ObservationClosureReasonEnum);
   };

   const updateClosureDate = (date: string | null | undefined) => {
      setClosureDateValidationMessage(undefined);
      setSelectedClosureDate(date);
   };

   const updateActionTaken = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      validateActionTaken(newValue);
      setActionTaken(newValue);
   };

   const validateClosureReason = (): boolean => {
      if (!selectedClosureReason) {
         setClosureReasonValidationMessage(t(closureReasonNotSelected));
         return false;
      }

      setClosureReasonValidationMessage(undefined);
      return true;
   };

   const validateClosureDate = (value: string | undefined): boolean => {
      if (!value) {
         setClosureDateValidationMessage(t(closureDateNotProvided));
         return false;
      } else if (value && new Date(value).getTime() > Date.now()) {
         setClosureDateValidationMessage(t(closureDateInFuture));
         return false;
      } else if (
         nearMiss?.incidentDate! &&
         value &&
         new Date(value).getTime() < Date.parse(nearMiss?.incidentDate.toString())
      ) {
         setClosureDateValidationMessage(t(closureDateBeforeIncident));
         return false;
      }

      setClosureDateValidationMessage(undefined);
      return true;
   };

   const validateActionTaken = (value: string | undefined): boolean => {
      if (!value || value?.length < 1) {
         setActionTakenValidationMessage(t(actionTakenNotProvided));
         return false;
      } else if (value && value.length > 600) {
         setActionTakenValidationMessage(t(actionTakenTooLong));
         return false;
      }

      setActionTakenValidationMessage(undefined);
      return true;
   };

   const submitCompletion = async () => {
      var closureReasonValid = validateClosureReason();
      var actionTakenValid = validateActionTaken(actionTaken);
      var closeDateValid = validateClosureDate(selectedClosureDate ?? undefined);

      if (!closureReasonValid || !actionTakenValid || !closeDateValid) {
         return;
      }

      const response = await handleCompleteNearMiss();
      if (!response.successful) {
         addToast(<ErrorListToastMessage errors={response.getErrorMessages()} />, { appearance: 'error' });
      } else {
         addToast(t('ObservationPage.ChangesSavedSuccessfully'), { appearance: 'success' });
         onComplete();
      }
   };

   const handleCompleteNearMiss = async (): Promise<ActionResponse> => {
      const actionResponse = new ActionResponse();

      if (nearMiss?.observationStatus !== ObservationStatusEnum.Open) {
         return actionResponse.set({
            successful: false,
            error: {
               message: t('ObservationPage.AmendClosedNearMissProhibited')
            }
         });
      }

      if (!hasPermission(WriteOn(UserPermission.Completion))) {
         return actionResponse.set({
            successful: false,
            error: {
               message: t('ObservationPage.DoNotHaveRequiredPermissions')
            }
         });
      }

      if (files.filter(f => f.fileStatus !== FileStatus.Removed && f.type?.id === 11).sum(f => f.size) > 20971520) {
         return actionResponse.set({
            successful: false,
            error: {
               message: 'Total size of all attachments are not to exceed 20mb'
            }
         });
      }

      setLoadingState(AmmendNearMissLoadingState.savingChanges, true);

      let filesToEmail = files
         .filter(f => f.fileStatus !== FileStatus.New && f.type?.id === 11 && f.emailEvidence)
         .map(f => f.fileId);

      try {
         const newFiles = files?.filter(f => f.fileStatus === FileStatus.New && f.type?.id === 11);

         if (newFiles) {
            for (let i = 0; i < newFiles.length; i++) {
               const file = newFiles[i];

               try {
                  let newFile = await uploadEvidence(file, nearMiss.observationId.toString(), nearMiss.uniqueRecordId);
                  if (file.emailEvidence) {
                     filesToEmail.push(newFile.fileId);
                  }
               } catch (error) {
                  return actionResponse.set({
                     successful: false,
                     error: {
                        message: t('ObservationPage.FailedToUploadEvidence')
                     }
                  });
               }
            }
         }

         await client.mutate<CompleteNearMissResponse, CompleteNearMissVariables>({
            mutation: CompleteNearMiss,
            variables: {
               request: {
                  nearMissId: nearMiss?.observationId ?? 0,
                  actionTaken: actionTaken!,
                  closeDate: ConvertToUTC(selectedClosureDate!),
                  closureReason: selectedClosureReason!.toUpperCase(),
                  hasAttachments: selectedAttachmentOption === 1,
                  emailFileIds: filesToEmail
               }
            }
         });

         var filesToDelete = files.filter(
            f => f.fileStatus === FileStatus.Removed && f.type?.id === 11 && !isNullOrUndefined(f.fileId)
         );
         if (filesToDelete) {
            for (let i = 0; i < filesToDelete.length; i++) {
               const file = filesToDelete[i];
               await deleteFile(file);
            }
         }

         actionResponse.set({ successful: true });
      } catch (error: any) {
         actionResponse.set({
            successful: false,
            error: {
               message: t('ObservationPage.IssueClosingNearMiss'),
               error: error
            }
         });
      }

      setLoadingState(AmmendNearMissLoadingState.savingChanges, false);

      return actionResponse;
   };

   async function deleteFile(file: ObservationFileEntity): Promise<void> {
      await deleteFileMutation({
         variables: {
            referenceId: nearMiss!.uniqueRecordId,
            fileId: file.fileId!
         }
      });
   }

   return (
      <InputDialog
         title={t('CompleteObservation.CompleteNearMiss')}
         subText={t('CompleteObservation.ProvideDetails')}
         show={isOpen}
         onCancel={resetFormAndClose}
         onDone={submitCompletion}
         width={'500px'}
         cancelButtonText={t('InputDialog.Cancel')}
         doneButtonText={t('InputDialog.Done')}
      >
         <StyledControlContainer>
            <DateTimeInput
               label={t('CompleteObservation.DateClosed')}
               value={selectedClosureDate}
               onDateTimeSelect={updateClosureDate}
               errorMessage={closureDateValidationMessage}
            />
         </StyledControlContainer>
         <StyledControlContainer>
            <ToggleGroup
               label={t('CompleteObservation.ReasonForClosure')}
               options={mapToDropdownOptions<IClosureReason, IDropdownOption>(
                  closureReasons,
                  type => type.name,
                  type => t(`CompleteObservation.${type.name}`)
               )}
               selectedKey={selectedClosureReason}
               onChange={updateClosureReason}
               errorMessage={closureReasonValidationMessage}
               isLoading={loadingClosureReasons}
            />
         </StyledControlContainer>
         <StyledControlContainer>
            <TextField
               label={t('CompleteObservation.ActionsTaken')}
               multiline
               value={actionTaken}
               errorMessage={actionTakenValidationMessage}
               onChange={updateActionTaken}
            />
         </StyledControlContainer>
         <StyledControlContainer>
            <NewEvidenceDocuments
               handleAddEvidence={handleAddEvidence}
               handleUpdateFiles={handleUpdateFiles}
               handleDeleteEvidence={handleDeleteEvidence}
               files={files.filter(x => x.fileStatus !== FileStatus.Removed)}
               isReadonly={false}
               selectedAttachmentOption={selectedAttachmentOption}
               setSelectedAttachmentOption={setSelectedAttachmentOption}
               observationUniqueId={nearMiss?.uniqueRecordId ?? ''}
            />
         </StyledControlContainer>
      </InputDialog>
   );
};
