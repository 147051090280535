import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import translationGB from '../locales/gb/translation.json';
import translationDE from '../locales/de/translation.json';
import { DateTime } from 'luxon';

const resources = {
   gb: {
     translation: translationGB
   },
   de: {
     translation: translationDE
   }
 };

 const storedLanguage = localStorage.getItem("i18nextLng");

i18n
   .use(Backend)
   .use(LanguageDetector)
   .use(initReactI18next)
   .init({
      lng: storedLanguage || "gb",
      resources,
      debug: false,
      fallbackLng: 'gb',
   });

   i18n.services.formatter.add('DATETIME_MED_WITH_WEEKDAY', (value, lng, options) => {
    return DateTime.fromISO(value).setLocale(lng).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
  });
export default i18n;
