

export const onLeaveNearMissMessage: string
  = 'ObservationPage.UnsavedChangesNearMiss'

export const onLeaveSheqManagerMessage: string
  = 'You have unsaved changes to the current SHEQ Manager, are you sure you wish to navigate away?'

export const onLeaveGoodPracticeMessage: string
    = 'ObservationPage.UnsavedChangesGoodPractice'

export const onLeaveDepotManagerMessage: string
    = 'You have unsaved changes to the current depot, are you sure you wish to navigate away?'