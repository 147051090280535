import React from 'react';
import { ApolloError } from 'apollo-client';
import { getErrorMessages } from '../errors.utils';
import { isNullOrEmpty } from 'shared/utils/validation.utils';

interface IApolloErrorToastMessageProps {
  baseMessage?: string;
  error: ApolloError;
}

const ApolloErrorToastMessage = ({
  baseMessage = 'Oops!',
  error
}: IApolloErrorToastMessageProps) => {
  
  return (
    <div>
      {!isNullOrEmpty(baseMessage) && <div style={{paddingBottom: '10px'}}>{baseMessage}</div>}
      {getErrorMessages(error).map((message, i) => (<div key={i}>{message}</div>))}
    </div>
  )
}

export default ApolloErrorToastMessage;