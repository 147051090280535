import NearMissCategorisation from 'components/NearMissCategorisation';
import NearMissDetails from 'components/NearMissDetails';
import { usePermissions, UserPermission, WriteOn, ReadOn, DeleteOn } from 'modules/permissions';
import React, { useCallback } from 'react';
import { ObservationStatusEnum } from 'shared/enums/ObservationStatus.enum';
import { StyledPanelGrid, StyledPanelRow, StyledPanelColumn, StyledContainer } from 'shared/styled/panel.stayled';
import { NearMiss } from '../../../queries/view-near-miss.queries.types';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { IRemoveFiles } from 'shared/utils/files/types/IRemoveFiles';
import { ObservationCategoryEntity } from 'shared/types/domain/ObservationCategoryEntity';
import { GoogleMapsApiKeyProvider } from 'modules/mapping';
import NearMissReporter from '../../NearMissReporter';
import AmendObservationPhotos from 'components/ObservationPhoto/components/AmendObservationPhotos';

interface AmmendNearMissForm {
   nearMiss: NearMiss | undefined;
   removeFiles: IRemoveFiles<ObservationFileEntity>;
   files: ObservationFileEntity[];
}

const AmmendNearMissForm = ({ nearMiss, removeFiles, files }: AmmendNearMissForm) => {
   const { hasPermission } = usePermissions();

   const canEditNearMiss = useCallback((): boolean => {
      return (
         nearMiss?.observationStatus === ObservationStatusEnum.Open &&
         hasPermission(WriteOn(UserPermission.ExistingObservation))
      );
   }, [nearMiss, hasPermission]);

   const canDeletePhoto = useCallback((): boolean => {
      return (
         nearMiss?.observationStatus === ObservationStatusEnum.Open && hasPermission(DeleteOn(UserPermission.ImageFile))
      );
   }, [nearMiss, hasPermission]);

   const canEditReporter = useCallback((): boolean => {
      return (
         nearMiss?.observationStatus === ObservationStatusEnum.Open &&
         hasPermission(WriteOn(UserPermission.ObservationReporter))
      );
   }, [nearMiss, hasPermission]);

   const selectedCategory = useCallback((): ObservationCategoryEntity | undefined => {
      if (nearMiss) {
         return {
            observationCategoryId: nearMiss.observationCategoryId,
            observationCategoryName: nearMiss.observationCategoryName,
            observationCategoryNameDe: nearMiss.observationCategoryName
         };
      }

      return undefined;
   }, [nearMiss]);

   return (
      <React.Fragment>
         <StyledContainer>
            <StyledPanelGrid fluid>
               <StyledPanelRow>
                  <StyledPanelColumn md={12} lg={4} xl={4}>
                     <GoogleMapsApiKeyProvider>
                        <NearMissCategorisation readOnly={!canEditNearMiss()} selectedCategory={selectedCategory()} />
                     </GoogleMapsApiKeyProvider>
                  </StyledPanelColumn>
                  <StyledPanelColumn md={12} lg={4} xl={4}>
                     <NearMissDetails readOnly={!canEditNearMiss()} />
                     <AmendObservationPhotos
                        uniqueRecordId={nearMiss?.uniqueRecordId}
                        observationType={'Near Miss'}
                        removeFiles={removeFiles}
                        files={files}
                        canDelete={canDeletePhoto()}
                     />
                  </StyledPanelColumn>
                  <StyledPanelColumn md={12} lg={4} xl={4}>
                     <NearMissReporter nearMissReadOnly={true} reporterReadOnly={!canEditReporter()} />
                  </StyledPanelColumn>
               </StyledPanelRow>
            </StyledPanelGrid>
         </StyledContainer>
      </React.Fragment>
   );
};

export default AmmendNearMissForm;
