import { useMemo } from "react";
import { ApolloProvider } from "@apollo/react-hooks"
import { ToastProvider } from "react-toast-notifications"

import { createGraphqlClientWithCache } from "modules/cache";
import { IAppConfig, useConfig } from "modules/config";
import { ToastContainer } from 'modules/notifications';
import { EndPoint, IEndPoints, RestClient, RestProvider } from "modules/rest-client"
import { useSecurity } from "modules/security";

interface AppServicesContainerProps {
    children: React.ReactElement
}

export const AppServicesContainer = ({
    children
}: AppServicesContainerProps) => {

    const { settings } = useConfig<IAppConfig>();
    const { accessToken } = useSecurity();

    const graphQlClient = useMemo(
        () =>
            createGraphqlClientWithCache(
                settings.GraphQlApiUrl,
                accessToken
            ),
        [settings, accessToken]
    );
    
    const restClient = useMemo(
        () =>
            new RestClient<IEndPoints>({
                endpoints: {
                    fileUpload: new EndPoint({
                        url: settings.FileUrl,
                        token: accessToken
                    }),
                    emptyUrl: new EndPoint({
                        url: '',
                        token: accessToken
                    })
                }
            }),
        [settings, accessToken]
    );

    return (
        <ApolloProvider client={graphQlClient}>
            <RestProvider<IEndPoints> client={restClient}>
                <ToastProvider
                    autoDismiss={true}
                    autoDismissTimeout={10000}
                    placement='top-right'
                    components={{ ToastContainer }}
                >
                    {children}
                </ToastProvider>
            </RestProvider>
        </ApolloProvider>
    );
}