import React from 'react';
import { StyledPageHeader } from '../styled/PageHeader.styled';

interface PageHeaderProps {
   children: string | any;
}

const PageHeader = (props: PageHeaderProps) => {
   return <StyledPageHeader>{props.children}</StyledPageHeader>;
};

export default PageHeader;
