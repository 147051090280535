import styled from 'styled-components';

export const StyledConfigurationOverviewContainer = styled.div`
    flex: 1;
    display: flex;
    overflow: hidden;
`;

export const StyledConfigurationMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-width: min-content;
`;

export const StyledConfigurationPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 15px 25px;
    width: 100%;
`;
