import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { InputDialog } from 'components/layout/Dialog';
import { FilterSelectV2 } from 'components/controls/Select';
import { StyledControlContainer } from 'shared/styled/control.styled';
import { DepotEntity } from 'shared/types/domain/DepotEntity';
import { mapToDropdownOptions } from 'shared/utils/mapping.utils';
import { IDropdownOption } from '@fluentui/react';
import { DepotListQuery } from '../types/user-configuration-depot-types';
import { DepotsNotLinkedToUserResponse } from 'shared/types/configuration/depot-search.queries.types';
import { GetDepotsNotLinkedToUser } from 'shared/queries/depot-search.queries';

interface MultiDepotSearchDialogProps {
  showDialog: boolean;
  onAddDepot: (depot: DepotEntity[]) => void;
  onHideDialog: () => void;
  includeDepots?: DepotEntity[];
  excludeDepots?: DepotEntity[];
    selectedDepots: DepotListQuery;
    setSelectedDepots: (newRequest: DepotListQuery) => void;
    employeeId: number;
}

export const MultiDepotSearchDialog = ({
  showDialog,
  onAddDepot,
  onHideDialog,
  includeDepots,
    excludeDepots,
    selectedDepots,
    setSelectedDepots,
    employeeId
}: MultiDepotSearchDialogProps) => {

  const { data: depotData, loading } = useQuery<DepotsNotLinkedToUserResponse>(GetDepotsNotLinkedToUser,     
  {
    variables: {empId: employeeId},
    fetchPolicy: 'network-only'
  });

  const [ depotSelectionErrorMessage, setDepotSelectionErrorMessage ] = useState<string>();

    const hideAndResetDialog = () => {

        var depots = selectedDepots;
        depots.depotPcId = [];

        setSelectedDepots(depots);
        setDepotSelectionErrorMessage(undefined);
        onHideDialog();
    }

  const onAddDepotConfirmed = () => {

      var depotFilter = selectedDepots.depotPcId;

      if (depotFilter?.length === 0) {
      setDepotSelectionErrorMessage('Select a depot from the list to continue');
      return;
      }

      var depotsList = getSortedCleansedDepotList();

      var depotFilteredEntities = depotsList.filter((depot) => depotFilter?.includes(depot.pcId));

      onAddDepot(depotFilteredEntities);
    hideAndResetDialog();
    }

    const getSortedCleansedDepotList = useCallback((): DepotEntity[] => {
        if (!depotData) return [];

        var dataWithoutExclusions = depotData.depotsNotLinkedToUser.filter(x => !excludeDepots?.any(d => d.pcId === x.pcId));
        dataWithoutExclusions = dataWithoutExclusions.filter(x => !includeDepots?.any(d => d.pcId === x.pcId));

        const newDepotData = dataWithoutExclusions.concat(includeDepots ?? []);

        return newDepotData.sort((a: DepotEntity, b: DepotEntity) => {
            return a.pc - b.pc;
        });

    }, [depotData, includeDepots, excludeDepots])

    const onDepotSelect = (options?: IDropdownOption[]) => {

        setSelectedDepots({
            ...selectedDepots,
            depotPcId: options?.map(x => Number(x.key))
        });
    }

  return (
    <InputDialog
      title='New Managed Depot'
      subText='Select from the list of currently un-linked depots to link to this user'
      show={showDialog}
      onDone={onAddDepotConfirmed}
      onCancel={hideAndResetDialog}
    >
      <StyledControlContainer>
              <FilterSelectV2
                  placeholder='Search Depot Name or Number'
                  errorMessage={depotSelectionErrorMessage}
                  options={mapToDropdownOptions<DepotEntity, IDropdownOption>(
                      getSortedCleansedDepotList(),
                      depot => depot.pcId,
                      depot => `${depot.pc} - ${depot.pcName}`
                  )}
                  isLoading={loading}
                  multiSelect
                  selectedKeys={selectedDepots.depotPcId}
                  onChangeMulti={onDepotSelect}
               /> 
      </StyledControlContainer>
    </InputDialog>
  );
};