import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { LoginCallback, SecureRoute } from '@okta/okta-react';

import { SecurityRoute } from '../enums/SecurityRoute.enum';
import { securityRoutes } from '../constants/security.routes';

import { useConfig, IAppConfig } from 'modules/config';

interface SecurityRouterProps {
    loggedOut: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    loggedIn: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const SecurityRouter = ({
    loggedOut,
    loggedIn
}: SecurityRouterProps) => {
    
    const { settings } = useConfig<IAppConfig>();

    return (
        <Switch>
            <Route path={securityRoutes[SecurityRoute.PostLogout]()} component={loggedOut} />
            <Route path={securityRoutes[SecurityRoute.ImplicitCallback](settings)} component={LoginCallback} />
            <SecureRoute path={'/'} component={loggedIn} />
        </Switch>
    );
};