import { IDropdownOption } from "@fluentui/react"
import Button from "components/controls/Button";
import { CSSProperties } from "react";
import { keysAreEqual } from "shared/utils/equality.utils";
import { StyledToggleGroup, StyledToggleGroupContainer, StyledToggleGroupErrorMessage, StyledToggleGroupLabel } from "../styled/toggle-group.styled";
import { useTranslation } from "react-i18next";

interface ToggleGroupProps {
    options: IDropdownOption[];
    label?: string;
    selectedKey?: string | number | null;
    onChange?: (selection: string | number) => void;
    errorMessage?: string;
    required?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
}

export const ToggleGroup = ({
    label,
    options,
    selectedKey,
    onChange,
    errorMessage,
    required,
    disabled,
    isLoading
}: ToggleGroupProps) => {

    const { t } = useTranslation();

    const onClickOption = (selection: string | number) => {
        if (onChange) {
            onChange(selection);
        }
    }

    const getStyle = (key: number | string | undefined): CSSProperties => {
        const isSelected = keysAreEqual(selectedKey, key)

        return {
            backgroundColor: isSelected ? 'green' : 'white',
            color:  isSelected ? 'white' : 'green',
            borderColor: 'green',
            opacity: disabled ? 0.6 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer'
        }
    }

    return (
        <StyledToggleGroupContainer>
            {label &&
                <StyledToggleGroupLabel showRequired={!!required}>
                    {label}
                </StyledToggleGroupLabel>
            }
            <StyledToggleGroup>
                {
                    options?.map((opt) => <Button 
                                            key={opt.key} 
                                            text={t(opt.text)} 
                                            title={t(opt.data) ?? ''}
                                            style={getStyle(opt.key)} 
                                            onClick={() => onClickOption(opt.key ?? '')} 
                                            disabled={disabled}
                                            isLoading={isLoading}
                                        />)
                }
            </StyledToggleGroup>
            {errorMessage && 
                <StyledToggleGroupErrorMessage>
                    {errorMessage}
                </StyledToggleGroupErrorMessage>
            }
        </StyledToggleGroupContainer>
    )
};