import { Dialog, DialogType, IDialogProps, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

interface IConfirmationDialogProps extends IDialogProps {
   show: boolean;
   title: string;
   subText: string;
   isBlocking?: boolean;
   onDecline: () => void;
   onAccept: () => void;
   declineButtonText?: string;
   acceptButtonText?: string;
}

const ConfirmationDialog = ({
   show,
   title,
   subText,
   isBlocking,
   onDecline,
   onAccept,
   declineButtonText = 'ConfirmationDialog.Close',
   acceptButtonText = 'ConfirmationDialog.Proceed'
}: IConfirmationDialogProps) => {

   
const { t } = useTranslation();

   return (
      <Dialog
         hidden={!show}
         onDismiss={onDecline}
         dialogContentProps={{
            type: DialogType.normal,
            title: title,
            closeButtonAriaLabel: declineButtonText,
            subText: subText
         }}
         modalProps={{
            isBlocking: isBlocking,
            styles: { main: { maxWidth: 450 } }
         }}
      >
         <DialogFooter>
            <PrimaryButton onClick={onAccept} text={t(acceptButtonText)} />
            <DefaultButton onClick={onDecline} text={t(declineButtonText)} />
         </DialogFooter>
      </Dialog>
   );
};

export default ConfirmationDialog;
