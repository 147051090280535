import Button from 'components/controls/Button';
import {
   StyledMessagedButton,
   StyledMessagePage,
   StyledMessagePageContainer,
   StyledMessagePageLogo,
   StyledMessagePageLogoContainer,
   StyledMessagePageText,
   StyledMessagePageTextContainer
} from 'shared/styled/message-page.styled';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface NewNearMissSuccessProps {
   onCreateAnother: () => void;
}

export const NewNearMissSuccess = ({ onCreateAnother }: NewNearMissSuccessProps) => {
   const history = useHistory();
   const { t } = useTranslation();

   function handleGoodPracticeClick() {
      history.push('/goodpractice/new');
   }

   return (
      <StyledMessagePageContainer>
         <StyledMessagePage>
            <StyledMessagePageLogoContainer>
               <StyledMessagePageLogo />
            </StyledMessagePageLogoContainer>
            <StyledMessagePageTextContainer>
               <StyledMessagePageText>{t('ObservationSucess.NMLine1')}</StyledMessagePageText>
               <StyledMessagePageText>{t('ObservationSucess.NMLine2')}</StyledMessagePageText>
               <StyledMessagePageText>{t('ObservationSucess.NMLine3')}</StyledMessagePageText>
               <StyledMessagePageText>
                  <StyledMessagedButton>
                     <Button text={t('ObservationPage.NewNearMiss')} onClick={onCreateAnother} />
                  </StyledMessagedButton>
                  <StyledMessagedButton>
                     <Button text={t('ObservationPage.NewGoodPractice')} onClick={handleGoodPracticeClick} />
                  </StyledMessagedButton>
               </StyledMessagePageText>
            </StyledMessagePageTextContainer>
         </StyledMessagePage>
      </StyledMessagePageContainer>
   );
};
