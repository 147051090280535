import { useState } from 'react';

type ArrayStateOptions<T> = {
  key: keyof T;
};

export function useArrayState<TData = any>({
  key
}: ArrayStateOptions<TData>): [
  TData[], 
  (setArray: TData[]) => void,
  (itemToAdd: TData) => void, 
  (itemToRemove: TData) => void
] {
  const [arrayData, setArrayData] = useState<TData[]>([]);

  const existsInData = (newData: TData): TData | undefined => {
    return arrayData.find(x => x[key] === newData[key]);
  };

  const addIfNotExists = (itemToAdd: TData) => {

    if (existsInData(itemToAdd)) return;

    const newArrayData = arrayData;
    newArrayData.push(itemToAdd);

    setArrayData(newArrayData);
  };

  const removeIfExists = (itemToRemove: TData) => {

    const newArrayData = arrayData.filter(x => x[key] !== itemToRemove[key]);

    setArrayData(newArrayData);
  };

  return [arrayData, setArrayData, addIfNotExists, removeIfExists];
}
