import { LinkButton } from "components/controls/LinkButton";
import ActionResponseErrorBoundary from "components/layout/ErrorBoundary/components/ActionResponseErrorBoundary";
import { ActionError } from "modules/errors"
import { useHistory } from "react-router-dom";
import { goodPracticeManagementHome } from "sections/good-practice-management";


export const GoodPracticeForbidden = () => {

    const history = useHistory();

    const error: ActionError<string> = {
        message: 'You do not have permission to view this Good Practice Record'
    }

    const returnToGoodPracticeList = (): void => {
        history.push(goodPracticeManagementHome)
    }

    return (
        <ActionResponseErrorBoundary error={error}>
            Click <LinkButton text="Here" onClick={returnToGoodPracticeList} /> to return to the Good Practice management page
        </ActionResponseErrorBoundary>
    )
};