import { gql } from "graphql.macro";

export const UpdateProfile = gql`
    mutation UpdateProfile($request: ProfileCollectionRequestModelInput){
        updateProfile(request: $request)
    }
`;

export const DeleteProfile = gql`
    mutation DeleteProfile($profileId: Short!){
        removeProfile(profileId: $profileId)
    }
`;