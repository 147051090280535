import { useQuery } from '@apollo/react-hooks';
import { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import ConfigurationPageContainer from 'sections/configuration/components/ConfigurationPageContainer';
import {
   StyledCommandBar,
   StyledConfigurationContainer,
   StyledHeaderText,
   StyledSection
} from 'sections/configuration/styled/configuration.styles';
import { isNullOrUndefined } from 'shared/utils/validation.utils';
import VirtualTable, { IVirtualTableColumn } from 'components/layout/VirtualTable/Table/components/VirtualTable';
import { DepotPage, depotRouteParams } from 'sections/configuration/routes/configuration-page.types';
import Button from 'components/controls/Button';
import { ReadOn, usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import { GetDepots } from '../queries/depot-list.query';
import { DepotsListResponse, DepotViewModel } from 'shared/types/configuration/DepotModels';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import Loading from 'components/layout/Loading';
import { useTranslation } from 'react-i18next';

const columns: IVirtualTableColumn<DepotViewModel>[] = [
   {
      key: 'column1',
      name: 'Depot.PC',
      fieldName: 'PC',
      minWidth: 100,
      maxWidth: 400,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item: DepotViewModel) => item.pC
   },
   {
      key: 'column2',
      name: 'Depot.Depot',
      fieldName: 'pcName',
      minWidth: 100,
      maxWidth: 400,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item: DepotViewModel) => item.pcName
   }
];

const DepotList: FunctionComponent = () => {
   const history = useHistory();

   const { addToast } = useToasts();

   const { data, loading, error } = useQuery<DepotsListResponse>(GetDepots, {
      fetchPolicy: 'network-only'
   });

   const { hasPermission } = usePermissions();

   useEffect(() => {
      if (!isNullOrUndefined(error)) addToast(t('Depot.CouldNotLoad'), { appearance: 'warning' });
   }, [data, addToast]);

   const goToNewDepot = () => {
      history.push(depotRouteParams[DepotPage.NewDepot]());
   };
   const { t } = useTranslation();

   const tColumns = columns.map(column => ({
      ...column,
      name: t(column.name)
   }));

   return (
      <ConfigurationPageContainer>
         <>
            <StyledConfigurationContainer>
               <StyledHeaderText>{t('Depot.Depots')}</StyledHeaderText>
               <StyledCommandBar>
                  <Button
                     style={{ marginLeft: 'auto' }}
                     onClick={goToNewDepot}
                     iconName='Add'
                     text={t('Depot.AddDepot')}
                     disabled={!hasPermission(WriteOn(UserPermission.ManageDepots))}
                  />
               </StyledCommandBar>
               <StyledSection>
                  <ConditionalRender
                     condition={
                        !!data?.observationsDepotsManagers.length &&
                        !!hasPermission(ReadOn(UserPermission.ManageDepots))
                     }
                  >
                     <GraphQlErrorBoundary error={error}>
                        <Loading isLoading={loading} message={'Loading'}>
                           {data && (
                              <VirtualTable<DepotViewModel>
                                 columns={tColumns}
                                 items={data.observationsDepotsManagers}
                                 onRowClick={item => {
                                    history.push(depotRouteParams[DepotPage.ViewDepot](item?.pCID.toString()));
                                 }}
                                 keyFieldName='pC'
                              />
                           )}
                        </Loading>
                     </GraphQlErrorBoundary>
                  </ConditionalRender>
                  <ConditionalRender condition={!data?.observationsDepotsManagers.length}></ConditionalRender>
               </StyledSection>
            </StyledConfigurationContainer>
         </>
      </ConfigurationPageContainer>
   );
};
export default DepotList;
