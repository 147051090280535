import React, { memo, RefObject, useCallback, useEffect, useState } from 'react';
import { ITextField, ITextFieldProps, TextField } from '@fluentui/react';
import { ReadOnlyField } from '../../ReadOnlyField';
import { style } from '../styled/TextInput.styled';
import { usePermissions, RequiredPermission} from 'modules/permissions';

export interface ITextInputProps extends ITextFieldProps {
    /**
     * If supplied, this will be returned back in the {@link onChange} callback
     */
    name?: string;

    /**
     * Callback fired when the text input value is changed
     * @param {React.FormEvent<HTMLInputElement | HTMLTextAreaElement>} event The triggered event
     * @param {string} [newValue] The new value
     * @param {string} [name] The field name
     */
    onChange: (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
        name?: string
    ) => void;

    /**
     * Fired when the text input is focused
     */
    onFocus?: () => void;

    /**
     * Fired when the text input is exited
     */
    onBlur?: () => void;

    /**
     * If the text field is used in the context of a {@link Security}, the users permissions will be compared
     * against the required permissions supplied
     * the permissionTypes here will be ignored
     */
    readPermissions?: RequiredPermission[];
    writePermissions?: RequiredPermission[];
    /**
     * A static identifier used to uniquely identify the component for automation testing
     */
    testIdentifier?: string;

    /**
     * Whether the input should be readonly
     */
    readonly?: boolean;

    /**
     *  If true, the field will display a pencil with click listener;
     *  Only available if the field is readonly
     */
    clickEditable?: boolean;

    /**
     *  Locked edit click listener
     */
    onClickEditable?: () => void;

    autoFocus?: boolean;
}

const TextInput = React.forwardRef<ITextField, ITextInputProps>((props: ITextInputProps, ref) => {
    const [styles, setStyles] = useState(style(props.readOnly ?? false));
    const { hasPermissions } = usePermissions();

    useEffect(() => {
        setStyles(style(props.readOnly ?? false));
    }, [props.readOnly]);

    function onChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        props.onChange(event, newValue, props.name);
    }

    const getValue = useCallback((): string => {
        return hasPermissions(props.readPermissions) ? props.value ?? '' : '************';
    }, [props.value, hasPermissions, props.readPermissions]);

    return (
        <React.Fragment>
            {hasPermissions(props.writePermissions) && !props.readonly ? (
                <TextField
                    {...props}
                    title={props.title ?? props.value}
                    list='autocompleteOff'
                    componentRef={ref as RefObject<ITextField>}
                    autoComplete='off'
                    styles={styles}
                    onChange={onChange}
                    value={getValue()}
                    data-testing-id={`${props.testIdentifier}-ti`}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    autoFocus={props.autoFocus}
                />
            ) : (
                <ReadOnlyField
                    required={props.required}
                    label={props.label}
                    text={getValue()}
                    locked={!props.readonly}
                    clickEditable={props.clickEditable}
                    onClickLockedEditable={props.onClickEditable}
                />
            )}
        </React.Fragment>
    );
});

TextInput.displayName = 'TextInput';

export default memo(TextInput);
