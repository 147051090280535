import React, { FunctionComponent, useRef, useState } from 'react';
import {
    StyledAssignUserBody,
    StyledAssignUserContainer,
    StyledAssignUserFlexColumn,
    StyledAssignUserFlexRow,
    StyledAssignUserFooter
} from './AssignUser.styles';
import { Persona } from 'office-ui-fabric-react/lib/Persona';
import { User } from 'shared/types/configuration/UserModels';
import Button, { IconButton } from 'components/controls/Button';
import { isNullOrEmpty } from 'shared/utils/null.utils';
import { Modal } from 'components/controls/Modal';
import UserSearchInput from '../../UserSearchInput/components/UserSearchInput';
import { useTranslation } from 'react-i18next';

interface AssignUserProps {
    isOpen: boolean;
    onDismiss: () => void;
    onAddUsers: (users: User[]) => void;
}

const AssignUser: FunctionComponent<AssignUserProps> = props => {
    const modelRef = useRef<HTMLDivElement>(null);
    const [userToAdd, setUserToAdd] = useState<User[]>([]);
    const { t } = useTranslation();

    const removeFromAddList = (user: User) => {
        setUserToAdd(userToAdd.filter(x => x.employeeId !== user.employeeId));
    };

    const addUsersToParent = () => {
        props.onAddUsers(userToAdd);
        setUserToAdd([]);
        props.onDismiss();
    };

    const handleUserSelect = (user: User) => {
        setUserToAdd([...userToAdd, user]);
    };

    return (
        <>
            <Modal
                title={t('Category.AssignUsers')}
                isOpen={props.isOpen}
                onDismiss={() => {
                    setUserToAdd([]);
                    props.onDismiss();
                }}
                isBlocking={true}
            >
                <StyledAssignUserContainer ref={modelRef}>
                    <StyledAssignUserFlexColumn>
                        <StyledAssignUserBody>
                            <UserSearchInput
                                label={t('Category.FindAUser')}
                                modelContentRef={modelRef}
                                onUserSelect={handleUserSelect}
                            />
                            <div style={{ marginTop: '30px' }}>
                                {userToAdd.map(x => (
                                    <div key={x.employeeId} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ padding: '10px 15px', flex: 1 }}>
                                            <Persona
                                                text={x.displayName}
                                                onRenderSecondaryText={props => (
                                                    <div>
                                                        <div>{`${x.jobTitle ?? ''} ${isNullOrEmpty(x.department) ? '' : '@ ' + x.department
                                                            }`}</div>
                                                        <div>{x.email}</div>
                                                        <div>{x.businessPhones.join()}</div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <IconButton
                                                iconName={'Blocked2Solid'}
                                                title={'Remove'}
                                                style={{ color: 'red', fontSize: '25px' }}
                                                onClick={() => removeFromAddList(x)}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </StyledAssignUserBody>
                        <StyledAssignUserFlexRow>
                            <StyledAssignUserFooter>
                                <Button text={'Add'} onClick={addUsersToParent} />
                            </StyledAssignUserFooter>
                        </StyledAssignUserFlexRow>
                    </StyledAssignUserFlexColumn>
                </StyledAssignUserContainer>
            </Modal>
        </>
    );
};

export default AssignUser;
