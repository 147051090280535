import { actionCreatorFactory } from 'shared/utils/redux.utils';
import { UserSearchStateActionEnum } from '../../enums/UserSearchStateAction.enum';
import { isUndefined } from 'shared/utils/validation.utils';
import { UserSearchState } from '../state.types';

export const setFetchingResults = actionCreatorFactory<typeof UserSearchStateActionEnum, boolean>(
    UserSearchStateActionEnum.SetFetchingResults
);

export const setFetchingResultsHandler: ActionHandler<UserSearchState, typeof setFetchingResults> = (state, action) => {
    if (isUndefined(action.payload)) return state;

    return {
        ...state,
        fetchingResults: action.payload
    };
};
