import styled from "styled-components";

export const StyledAuditLogContainer = styled.div`
    display: flex;
    width: 98%;
    margin: 15px 1%;
    flex-wrap: wrap;
`;

export const StyledHistoryContainer = styled.div`
    flex-basis: 100%;
    display: flex;
    margin: 15px 0;
`;