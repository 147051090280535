import React from 'react';

import { IDropdownOption, IRenderFunction, ISelectableOption, Persona, PersonaSize } from '@fluentui/react';
import { Link } from 'react-router-dom';

import { useSecurity } from 'modules/security';

import { isInternetExplorer } from 'shared/utils/browser.utils';
import { theme } from 'shared/themes/aplant.theme';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

import {
   StyledAccountContainer,
   StyledBrowserWarning,
   StyledHeaderContainer,
   StyledTitleContainer,
   StyledImageWrapper
} from '../styled/Header.styled';

import { MenuBar, MenuBarSelect } from './MenuBar';
import { setPostLogout } from '../utils/post-logout.utils';
import Select from 'components/controls/Select';
import deFlag from 'country-flag-icons/3x2/DE.svg';
import ukFlag from 'country-flag-icons/3x2/GB.svg';
import { useTranslation } from 'react-i18next';

const Header = () => {
   const { user, signOut } = useSecurity();
   const { i18n } = useTranslation();

   const getLanguage = () => {
      let lang = i18n.language;
      if (lang === 'de') return lang;

      return 'gb';
   };

   const { t } = useTranslation();

   const handleSignOut = async () => {
      setPostLogout();
      await signOut();
   };

   const options: IDropdownOption[] = [
      { key: 'gb', text: 'English (UK)', data: { flagURL: ukFlag } },
      { key: 'de', text: 'German', data: { flagURL: deFlag } }
   ];

   const onRenderOption = (option: IDropdownOption): JSX.Element => {
      return <div>{option.data && option.data.flagURL && <StyledImageWrapper src={option.data.flagURL} />}</div>;
   };

   const switchLanguage = (lng: string) => {
      i18n.changeLanguage(lng);
   };

   return (
      <React.Fragment>
         {isInternetExplorer() && <StyledBrowserWarning>{t('Header.BetterExperience')}</StyledBrowserWarning>}
         <StyledHeaderContainer>
            <StyledTitleContainer>
               <Link to='/'>
                  <Logo style={{ height: '40px', width: '132px' }} title='Sunbelt Rentals UK - Near Miss' />
               </Link>
            </StyledTitleContainer>
            <StyledAccountContainer>
               <MenuBar>
                  <Select
                     transparent={true}
                     name={'LanguageSelect'}
                     selectedKey={getLanguage()}
                     options={options}
                     onRenderOption={onRenderOption as IRenderFunction<ISelectableOption>}
                     onRenderTitle={props => {
                        return <StyledImageWrapper alt={props[0].text} src={props[0].data.flagURL} />;
                     }}
                     onChange={(ev, opt) => {
                        switchLanguage(opt?.key?.toString() ?? 'gb');
                     }}
                  />
                  <Persona
                     text={user?.name}
                     size={PersonaSize.size32}
                     hidePersonaDetails
                     initialsColor={theme.palette.secondary.main}
                     title={user?.name}
                  />
                  <MenuBarSelect invert>
                     <span onClick={handleSignOut}>{t('Header.Logout')}</span>
                  </MenuBarSelect>
               </MenuBar>
            </StyledAccountContainer>
         </StyledHeaderContainer>
      </React.Fragment>
   );
};

export default Header;
