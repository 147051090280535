import { gql } from 'graphql.macro';

export const GetObservationDepotsNotLinkedToDepotManagers = gql`
  query {
    observationDepotsNotLinkedToDepotManagers {
      pCID
      pC
      pcName
    }
  }
`;