import { FilterSelectItemProps } from '../types/FilterSelectItemProps';
import React, { useState } from 'react';
import { FilterSelectDetailCardPositionProps } from '../types/FilterSelectDetailCardPositionProps';
import { FontIcon, Persona, PersonaSize, Stack } from '@fluentui/react';
import { FilterSelectDetailCard } from './FilterSelectDetailCard';

const FilterSelectItem = (props: FilterSelectItemProps) => {
    const [position, setPosition] = useState<FilterSelectDetailCardPositionProps>({ x: 0, y: 0 });

    function handleDisplayClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        e.stopPropagation();
        setPosition({ x: e.clientX + 15, y: e.clientY });
        if (props.showIndex === props.item.id) {
            props.setShowIndex('');
        } else {
            props.setShowIndex(props.item.id);
        }
    }

    return (
        <td style={{ display: 'block', margin: '10px 5px', position: 'relative' }}>
            <Stack horizontal verticalAlign='center'>
                <Stack.Item grow={2}>
                    <Persona
                        text={props.item.text}
                        secondaryText={props.item.data?.jobTitle}
                        size={PersonaSize.size32}
                        hidePersonaDetails={false}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <FontIcon
                        onClickCapture={handleDisplayClick}
                        style={{ float: 'right', fontSize: '20px', zIndex: 2000 }}
                        iconName='ContactCard'
                    />
                </Stack.Item>
            </Stack>
            <FilterSelectDetailCard item={props.item} show={props.showIndex === props.item.id} position={position} />
        </td>
    );
};

export { FilterSelectItem };
