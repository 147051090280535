import styled from 'styled-components';

export const FilterResultsContainer = styled.div<{ height: number }>`
  max-height: ${props => props.height}px;
  overflow: auto;
`;

export const FilterSelectResult = styled.div`
  padding: 10px 15px;
  cursor: pointer;
   
  &:hover{
    background-color: ${({ theme }) => theme.palette.gray['200']};
  }
`;