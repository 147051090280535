import React, { FC } from 'react';
import { StyledMenuGroupHeader } from '../styled/SubMenuGroup.styled';
import { ISubMenuGroup } from '../types/ISubMenuGroup';
import SubMenuItem from './SubMenuItem';

/**
 * Props interface for {@link SubMenuGroup}
 */
interface ISubMenuGroupProps {
   group: ISubMenuGroup;
}

/**
 * ConfigurationMenuItemContainer component
 * @param props {@link ISubMenuGroupProps}
 */
const SubMenuGroup: FC<ISubMenuGroupProps> = ({ group }: ISubMenuGroupProps) => {
   return (
      <React.Fragment>
         <StyledMenuGroupHeader>{group.text}</StyledMenuGroupHeader>
         {group.items.map(x => (
            <SubMenuItem item={x} key={x.key} />
         ))}
      </React.Fragment>
   );
};

export default SubMenuGroup;
