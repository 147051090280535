import React, { FC, useMemo } from 'react';
import { SecurityRoute, securityRoutes } from 'modules/routing';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { IAppConfig, useConfig } from 'modules/config';

export const withOkta = <TProps extends object>(WrappedComponent: FC<TProps>) => {
    const OktaSecurityWrapper: FC<TProps> = (props: TProps): React.ReactElement => {
        const { settings } = useConfig<IAppConfig>();

        const oktaAuth = useMemo(
            () =>
                new OktaAuth({
                    issuer: securityRoutes[SecurityRoute.Issuer](settings),
                    clientId: settings.OktaClientId,
                    redirectUri: securityRoutes[SecurityRoute.RedirectUrl](settings),
                    pkce: false,
                    scopes: ['openid', 'email', 'profile']
                }),
            [settings]
        );

        return (
            <Security oktaAuth={oktaAuth} className='securityContainer'>
                <WrappedComponent {...props} />
            </Security>
        );
    };

    OktaSecurityWrapper.displayName = `withOkta(${WrappedComponent.displayName})`;

    return OktaSecurityWrapper;
};
