import { gql } from 'graphql.macro';

export const GetNearMissCategories = gql`
    query{
      nearMissCategories{
            nearMissCategoryId
            nearMissCategoryName
            nearMissCategoryNameDE
            isActive
        }
    }
`;