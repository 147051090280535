import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ApolloError } from 'apollo-client';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { FormFieldData, FormProvider } from 'modules/forms';
import { ReadOn, useAdminRights, usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import { IAppConfig } from 'modules/config';
import { useRestClient } from 'modules/rest-client';
import { ActionResponse, ErrorListToastMessage } from 'modules/errors';
import { IFormIsValidFunction } from 'modules/forms';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import Loading from 'components/layout/Loading';
import { tryParseInt } from 'shared/utils/string.utils';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import AmmendNearMiss from './components/AmmendNearMissForm';
import NearMissHeader from './components/NearMissHeader';
import NearMissSubHeader from './components/NearMissSubHeader';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import ViewNearMissCompletion from './components/NearMissCompletion';
import NearMissForbidden from './components/NearMissForbidden';
import { NearMissDisplayEnum } from './enums/near-miss-display.enum';
import { GetNearMissById } from './queries/view-near-miss.queries';
import { GetNearMissByIdVariables, NearMiss, NearMissByIdResponse } from './queries/view-near-miss.queries.types';
import { StyledNearMissContainer } from './styled/ViewAmmendNearMiss.styled';
import { NearMissEntity } from 'shared/types/domain/NearMissEntity';
import { ammendNearMissOptions } from './options/ammend-near-miss.options';
import { NearMissEntityMapper } from './mappers/NearMissEntityMapper';
import {
   UpdateNearMissResponse,
   UpdateNearMissVariables,
   IDeleteFileVariables,
   IUpdateFileVariables,
   ReopenNearMissResponse,
   ReopenNearMissVariables
} from './mutations/ammend-near-miss.mutations.types';
import { UpdateNearMiss, DeleteFile, UpdateFile, ReopenNearMiss } from './mutations/ammend-near-miss.mutations';
import { UpdateNearMissRequestMapper } from './mappers/UpdateNearMissMapper';
import { ObservationStatusEnum } from 'shared/enums/ObservationStatus.enum';
import { useFiles } from 'shared/utils/files/hooks/useFiles';
import { AmmendNearMissLoadingState } from './enums/ammend-near-miss-loading-state.enum';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { allowedMimeTypes } from 'shared/options/observation-files.options';
import { useConfig } from 'shared/utils/config/hooks/useConfig';
import { mapFileToFormData } from 'shared/utils/mapping.utils';
import { IFileUploadResponse } from 'shared/types/file/IFileUploadRequest';
import { GetObservationPhotoFiles } from 'components/ObservationPhoto/queries/observation-photos.queries';
import { GetObservationPhotoFileVariables } from 'components/ObservationPhoto/queries/observation-photos.queries.types';
import { ObservationFileMapper } from 'shared/mappers/ObservationFileMapper';
import { GetFilesByReferenceResponse } from 'shared/types/file/observation-file.type';
import { FileStatus } from 'shared/enums/FileStatus.enum';
import { getErrorMessages } from 'shared/utils/errors/errors.utils';
import AuditContainer from 'components/AuditContainer';
import { useTranslation } from 'react-i18next';

export const ViewAmmendNearMiss = () => {
   const { settings } = useConfig<IAppConfig>();
   const { params } = useRouteMatch<{ id: string }>();

   const restClient = useRestClient<'fileUpload'>();
   const client = useApolloClient();
   const [isLoading, setIsLoading] = useState<boolean>(true);
   const [loadingMessage, setLoadingMessage] = useState<AmmendNearMissLoadingState>(
      AmmendNearMissLoadingState.loadingNearMiss
   );
   const [loadingError, setLoadingError] = useState<ApolloError>();

   const { hasPermission } = usePermissions();
   const { hasAdminRights } = useAdminRights();

   const { t } = useTranslation();
   const { addToast } = useToasts();

   const [nearMiss, setNearMiss] = useState<NearMiss>();
   const [nearMissForm, setNearMissForm] = useState<FormFieldData<NearMissEntity> | undefined>();
   const [updateFileMutation] = useMutation<{ updateDocument: boolean }, IUpdateFileVariables>(UpdateFile);
   const [deleteFileMutation] = useMutation<{ deleteDocument: boolean }, IDeleteFileVariables>(DeleteFile);

   const { files, addFiles, updateFiles, removeFiles, setData } = useFiles<ObservationFileEntity>({
      allowedMimeTypes,
      onNotAllowed: handleNotAllowedFilesAdded,
      addFile: uploadFile,
      updateFile: updateFile,
      removeFile: deleteFile,
      maxFileUploadBytes: tryParseInt('20917520')
   });

   function handleNotAllowedFilesAdded(messages: string[]): void {
      addToast(
         <ErrorListToastMessage
            errors={messages}
            baseMessage={t('ObservationPage.DisallowedFileTypes')}
         />,
         { appearance: 'warning' }
      );
   }

   useEffect(() => {
      loadFiles();
   }, [nearMiss]);

   const loadFiles = async () => {
      setIsLoading(true);
      if (!nearMiss) {
         return;
      }
      try {
         const result = await loadNearMissImages(nearMiss.uniqueRecordId);

         if (!isNullOrUndefined(result)) {
            const emailedFiles = nearMiss.emailedFileIds;
            if (!isNullOrUndefined(emailedFiles)) {
               result.forEach(f => {
                  if (emailedFiles.indexOf(f.fileId as number) !== -1) {
                     f.emailEvidence = true;
                  }
               });
            }

            await setData(result);
         }
      } catch (e) {
         setLoadingError(e as ApolloError);
      } finally {
         setIsLoading(false);
      }
   };

   async function loadNearMissImages(uniqueRecordId: UID): Promise<ObservationFileEntity[] | null> {
      try {
         const result = await client.query<GetFilesByReferenceResponse, GetObservationPhotoFileVariables>({
            query: GetObservationPhotoFiles,
            variables: {
               id: uniqueRecordId
            },
            fetchPolicy: 'network-only'
         });
         if (result.errors) return null;

         const mapper = new ObservationFileMapper();
         return mapper.map(result.data);
      } catch (error) {
         setLoadingError(error as ApolloError);
         return null;
      }
   }

   async function uploadFile(
      file: ObservationFileEntity,
      folderName?: string,
      data?: UID
   ): Promise<ObservationFileEntity> {
      const formData = mapFileToFormData<{ referenceId: UID | undefined }>(file, settings, folderName, {
         referenceId: data
      });

      const endpoint = restClient.getEndpoint('fileUpload');
      const result = await endpoint.post<IFileUploadResponse>({
         method: 'UploadFile',
         data: formData,
         onUploadProgress: p => {}
      });

      return { ...file, fileId: result.data.fileId };
   }

   async function updateFile(file: ObservationFileEntity, data: UID): Promise<void> {
      await updateFileMutation({
         variables: {
            request: {
               comment: file.comment ?? '',
               documentTypeId: file.type?.id ?? 6,
               fileId: file.fileId!,
               referenceId: data
            }
         }
      });
   }

   const [displayMode, setDisplayMode] = useState<NearMissDisplayEnum>(NearMissDisplayEnum.Overview);

   const setLoadingState = (loadingMessage: AmmendNearMissLoadingState, isLoading: boolean): void => {
      setLoadingMessage(loadingMessage);
      setIsLoading(isLoading);
   };

   async function deleteFile(file: ObservationFileEntity): Promise<void> {
      await deleteFileMutation({
         variables: {
            referenceId: nearMiss?.uniqueRecordId ?? '',
            fileId: file.fileId!
         }
      });
   }

   const loadNearMissEntity = useCallback(async (): Promise<void> => {
      const nearMissId = tryParseInt(params.id);

      if (!isNullOrUndefined(nearMissId)) {
         setLoadingState(AmmendNearMissLoadingState.loadingNearMiss, true);

         try {
            let result = await client.query<NearMissByIdResponse, GetNearMissByIdVariables>({
               query: GetNearMissById,
               variables: {
                  id: nearMissId
               },
               fetchPolicy: 'network-only'
            });

            setNearMiss(result.data.nearMiss);

            if (result?.data != null) {
               const mapper = new NearMissEntityMapper();

               setNearMissForm(mapper.map(result?.data));
            } else {
               setNearMissForm(undefined);
               setNearMiss(undefined);
            }
         } catch (e) {
            setLoadingError(e as ApolloError);
         } finally {
            setLoadingState(AmmendNearMissLoadingState.loadingNearMiss, false);
         }
      }
   }, [params.id, client]);

   const handleSaveChanges = async (
      formData: FormFieldData<NearMissEntity> | undefined,
      fileData: ObservationFileEntity[] | undefined,
      isValid: IFormIsValidFunction
   ): Promise<ActionResponse> => {
      setLoadingState(AmmendNearMissLoadingState.savingChanges, true);

      const response = await updateNearMissEntity(formData, isValid);

      if (fileData) {
         fileData
            .filter(f => f.fileStatus === FileStatus.Removed)
            .forEach(f => {
               deleteFile(f);
            });
      }

      setLoadingState(AmmendNearMissLoadingState.savingChanges, false);

      if (!response.successful) {
         addToast(<ErrorListToastMessage errors={response.getErrorMessages()} />, { appearance: 'error' });
      } else {
         addToast(t('ObservationPage.ChangesSavedSuccessfully'), { appearance: 'success' });

         loadNearMissEntity();
      }

      return response;
   };

   const updateNearMissEntity = async (
      formData: FormFieldData<NearMissEntity> | undefined,
      isValid: IFormIsValidFunction
   ): Promise<ActionResponse> => {
      const actionResponse = new ActionResponse();

      if (isNullOrUndefined(formData) || !isValid(true)) {
         return actionResponse.set({
            successful: false,
            error: {
               message:
                  t('ObservationPage.InformationNotValidFixValidation')
            }
         });
      }

      if (nearMiss?.observationStatus !== ObservationStatusEnum.Open) {
         return actionResponse.set({
            successful: false,
            error: {
               message: t('ObservationPage.AmendClosedNearMissProhibited')
            }
         });
      }

      if (
         !hasPermission(WriteOn(UserPermission.ExistingObservation)) &&
         !hasPermission(WriteOn(UserPermission.IncidentDate)) &&
         !hasPermission(WriteOn(UserPermission.ObservationReporter))
      ) {
         return actionResponse.set({
            successful: false,
            error: {
               message: t('ObservationPage.DoNotHaveRequiredPermissions')
            }
         });
      }

      const mapper = new UpdateNearMissRequestMapper();
      const requestObject = mapper.map(formData);

      try {
         await client.mutate<UpdateNearMissResponse, UpdateNearMissVariables>({
            mutation: UpdateNearMiss,
            variables: {
               request: requestObject
            }
         });

         actionResponse.set({ successful: true });
      } catch (error) {
         actionResponse.set({
            successful: false,
            error: {
               message: {
                  baseMessage: t('ObservationPage.IssueUpdatingNearMiss'),
                  messages: getErrorMessages(error as ApolloError)
               }
            }
         });
      }

      return actionResponse;
   };

   const handleCompleteNearMiss = () => {
      loadNearMissEntity();
   };

   const handleReOpenNearMiss = async () => {
      setLoadingState(AmmendNearMissLoadingState.submittingReOpening, true);
      const actionResponse = new ActionResponse();

      if (nearMiss?.observationStatus == ObservationStatusEnum.Open) {
         setLoadingState(AmmendNearMissLoadingState.submittingReOpening, false);
         return actionResponse.set({
            successful: false,
            error: {
               message: 'The near miss is already open'
            }
         });
      }

      if (!hasPermission(WriteOn(UserPermission.ReOpen))) {
         setLoadingState(AmmendNearMissLoadingState.submittingReOpening, false);
         return actionResponse.set({
            successful: false,
            error: {
               message: t('ObservationPage.DoNotHaveRequiredPermissions')
            }
         });
      }

      try {
         await client.mutate<ReopenNearMissResponse, ReopenNearMissVariables>({
            mutation: ReopenNearMiss,
            variables: {
               nearMissId: nearMiss?.observationId ?? 0
            }
         });

         loadNearMissEntity();

         actionResponse.set({ successful: true });
      } catch (error: any) {
         actionResponse.set({
            successful: false,
            error: {
               message: 'There was an issue re-opening the near miss',
               error: error
            }
         });
      }

      const response = actionResponse;

      setLoadingState(AmmendNearMissLoadingState.submittingReOpening, false);

      if (!response.successful) {
         addToast(<ErrorListToastMessage errors={response.getErrorMessages()} />, { appearance: 'error' });
      } else {
         addToast(t('ObservationPage.ChangesSavedSuccessfully'), { appearance: 'success' });
      }

      return response;
   };

   useEffect(() => {
      loadNearMissEntity();
   }, [loadNearMissEntity]);

   const goToOverview = (): void => {
      setDisplayMode(NearMissDisplayEnum.Overview);
   };

   const goToCompletionDetails = (): void => {
      if (nearMiss?.completion) {
         setDisplayMode(NearMissDisplayEnum.Completion);
      }
   };

   const goToAuditLog = (): void => {
      setDisplayMode(NearMissDisplayEnum.Audit);
   };

   return (
      <React.Fragment>
         <Loading isLoading={isLoading} message={t(loadingMessage)} noDelay>
            <GraphQlErrorBoundary error={loadingError}>
               <StyledNearMissContainer>
                  <ConditionalRender condition={hasAdminRights} elseRender={() => <NearMissForbidden />}>
                     <FormProvider<NearMissEntity> options={ammendNearMissOptions} formFields={nearMissForm}>
                        <NearMissHeader
                           nearMiss={nearMiss}
                           onSave={handleSaveChanges}
                           onComplete={handleCompleteNearMiss}
                           onReOpen={handleReOpenNearMiss}
                           setLoadingState={setLoadingState}
                           isLoading={isLoading}
                           files={files}
                           handleAddFile={addFiles}
                           handleUpdateFiles={updateFiles}
                           handleDeleteEvidence={removeFiles}
                        />

                        <NearMissSubHeader
                           nearMiss={nearMiss}
                           displayMode={displayMode}
                           formHasErrors={false}
                           goToOverview={goToOverview}
                           goToCompletionDetails={goToCompletionDetails}
                           goToAuditLog={goToAuditLog}
                        />

                        <ConditionalRender condition={displayMode == NearMissDisplayEnum.Overview}>
                           <AmmendNearMiss nearMiss={nearMiss} removeFiles={removeFiles} files={files} />
                        </ConditionalRender>

                        <ConditionalRender condition={displayMode === NearMissDisplayEnum.Completion}>
                           <ViewNearMissCompletion
                              observationUniqueId={nearMiss?.uniqueRecordId ?? ''}
                              nearMissCompletion={nearMiss?.completion}
                              files={files.filter(x => x.type?.id === 11)}
                           />
                        </ConditionalRender>

                        <ConditionalRender
                           condition={
                              displayMode == NearMissDisplayEnum.Audit && hasPermission(ReadOn(UserPermission.Audit))
                           }
                        >
                           {nearMiss && <AuditContainer uniqueRecordId={nearMiss.uniqueRecordId} />}
                        </ConditionalRender>
                     </FormProvider>
                  </ConditionalRender>
               </StyledNearMissContainer>
            </GraphQlErrorBoundary>
         </Loading>
      </React.Fragment>
   );
};
