import styled from 'styled-components';

export const StyledSubHeaderTab = styled.div`
    ${({ theme }) => `
        display: flex;
        max-height: 42px;
        align-items: center;
        padding: 6px 15px;
        border: 1px solid ${theme.palette.gray[400]};
        margin-bottom: -1px;
        text-decoration: none;
        font-size: 12px;
        color: ${theme.palette.gray[600]};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 16px;
        cursor: pointer;
    
        i {
          font-size: 16px;
        }
        
        &.active {
          background: ${theme.palette.common.white};
          border-bottom: 1px solid ${theme.palette.common.white};
          color: ${theme.palette.primary.main};
    
          :hover {
            background-color: ${theme.palette.common.white};
          }
        }
    
        :hover {
          background-color: ${theme.palette.gray[300]};
        }
    
        &:not(:first-child) {
          border-left: none;
        }
    `}
`;
