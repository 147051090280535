import { gql } from 'graphql.macro';

export const GetDepotsNotLinkedToSheqManager = gql`
  query {
    depotsNotLinkedToSHEQManager {
      pcId: pCID
      pc: pC
      pcName
    }
  }
`;

export const GetDepotsNotLinkedToUser = gql`
  query GetDepotsNotLinkedToUser($empId: Int!){
    depotsNotLinkedToUser(employeeId: $empId){
      pcId: pCID
      pc: pC
      pcName
    }
  }
`;