import { gql } from 'graphql.macro';

export const GetApplicationProfiles = gql`
    query{
        applicationProfiles{
            profileId
            profileName
            description
        }
    }
`;