import { gql } from 'graphql.macro';

export const GetApplicationProfile = gql`
    query GetApplicationProfile($profileId: Short!){
        applicationProfile(profileId: $profileId){
            profile{
                profileId
                profileName
                description
            }
            profilePermissionSets{
                permissionSetId
                permissionSetName
                permissionSetDescription
                hasPermissionSet
            }
            users{
                displayName
                email
                employeeId
                userId
                givenName
                surname
                jobTitle
                businessPhones
                department
            }
        }
    }
`;

export const GetAllPermissions = gql`    
    query GetAllPermissions{
        applicationPermissions{
            permissionId
            permissionKey
            permissionName
        }
    }
`;

export const GetAllPermissionSets = gql`    
    query GetAllPermissionSets{
        applicationPermissionSets{
            permissionSetId
            permissionSetName
            permissionSetDescription
        }
    }
`;

export const GetNextProfileUsers = gql`
    query GetNextProfileUsers($profileId:Short!, $currentPage: Int!){
        profileUsers(profileId:$profileId, currentPage: $currentPage, resultsPerPage: 10){
            givenName
            surname
            displayName
            email
            employeeId
            businessPhones
            department
        }
    }
`;