import React, { forwardRef, useRef, useState, useImperativeHandle, useEffect, CSSProperties } from 'react';
import { isNullOrUndefined } from '../../validation.utils';
import { FontIcon } from '@fluentui/react';
import { StyledDragContainer, StyledDragOverlay, StyledDragOverlayInner } from '../styled/FileUploadZone.styled';

interface IFileUploadZoneProps {
    children: any;
    dragAndDrop?: boolean;
    onFilesAdded: (files: File[]) => void;
    style?: CSSProperties;
    className?: string;
}

export interface IFileUploadZone {
    showDialog: () => void;
}

function preventDragOverEvent(e: DragEvent) {
    e.preventDefault();
}

function preventDropEvent(e: DragEvent) {
    e.preventDefault();
}

const FileUploadZone: React.RefForwardingComponent<IFileUploadZone, IFileUploadZoneProps> = (
    { children, dragAndDrop, style, className, onFilesAdded }: IFileUploadZoneProps,
    ref
) => {
    const count = useRef(0);
    const [isDragged, setIsDragged] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        window.addEventListener('dragover', preventDragOverEvent);
        window.addEventListener('drop', preventDropEvent);

        return () => {
            window.removeEventListener('dragover', preventDragOverEvent);
            window.removeEventListener('drop', preventDropEvent);
        };
    }, []);

    useImperativeHandle(ref, () => ({
        showDialog: () => inputRef.current?.click()
    }));

    /**
     * Event handler for dragging files over the drag enabled container
     * @param e The drag event.
     */
    function handleDragEnter(e: React.DragEvent<HTMLDivElement>): void {
        if (e.dataTransfer.types[0] !== 'Files') return;

        if (count.current === 0) {
            setIsDragged(true);
        }

        count.current++;
    }

    /**
     * Event handler for dragging files away the drag enabled container
     * @param e The drag event.
     */
    function handleDragLeave(e: React.DragEvent<HTMLDivElement>): void {
        if (count.current > 0) {
            count.current--;
        }

        if (count.current === 0) {
            setIsDragged(false);
        }
    }

    /**
     * Handles extracting a list of files from the selected/dropped files and passes them to the onFilesAdded prop
     * @param fileList The file list.
     */
    function addFiles(fileList: FileList | null): void {
        if (isNullOrUndefined(fileList)) return;

        let newFileList: File[] = [];

        for (var i = 0; i < fileList.length; i++) {
            let file = fileList[i];
            newFileList.push(file);
        }

        inputRef.current!.value = '';

        onFilesAdded(newFileList);
    }

    function handleDrop(e: React.DragEvent<HTMLDivElement>) {
        if (count.current <= 0) return;

        count.current = 0;
        setIsDragged(false);

        addFiles(e.dataTransfer.files);
    }

    return (
        <StyledDragContainer
            style={style}
            className={className}
            ref={containerRef}
            onDragEnter={dragAndDrop ? handleDragEnter : undefined}
            onDragLeave={dragAndDrop ? handleDragLeave : undefined}
            onDrop={dragAndDrop ? handleDrop : undefined}
        >
            <StyledDragOverlay isDragged={isDragged}>
                <StyledDragOverlayInner>
                    <FontIcon iconName='Upload' style={{ fontSize: '48px' }} />
                    <h1>Upload Files</h1>
                    <h2>Drag and drop your files here</h2>
                </StyledDragOverlayInner>
            </StyledDragOverlay>

            <div style={{ display: 'none' }}>
                <input type='file' multiple ref={inputRef} onChange={e => addFiles(e.target.files)} />
            </div>
            {children}
        </StyledDragContainer>
    );
};

export default forwardRef(FileUploadZone);
