import { actionCreatorFactory } from 'shared/utils/redux.utils';
import { UserSearchStateActionEnum } from '../../enums/UserSearchStateAction.enum';
import { UserSearchState } from '../..//store/state.types';
import { isNullOrUndefined } from 'shared/utils/validation.utils';
import immutableUpdate from 'immutability-helper';

export const setIsAddUserModalOpen = actionCreatorFactory<typeof UserSearchStateActionEnum, boolean>(
    UserSearchStateActionEnum.SetIsAddUserModalOpen
);

export const setIsAddUserModalOpenHandler: ActionHandler<UserSearchState, typeof setIsAddUserModalOpen> = (
    state,
    action
) => {
    if (isNullOrUndefined(action.payload)) return state;

    return immutableUpdate(state, {
        isAddUserModalOpen: { $set: action.payload }
    });
};
