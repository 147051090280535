import { theme } from "shared/themes/aplant.theme";
import styled from "styled-components";

export const StyledAuditHistoryContainer = styled.div<{flex: number}>`    
    flex: ${props => props.flex}
`;

export const StyledAuditHeader = styled.h2`
  color: ${theme.palette.primary.main};
`;

export const StyledAuditHistoryRow = styled.div`
    display: flex; 
    flex-direction: row;
`;

export const StyledAuditHistorySection = styled.div<{flex: number}>`    
    flex: ${props => props.flex}
`;