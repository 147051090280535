import { useApolloClient } from '@apollo/react-hooks';
import { IDropdownOption } from '@fluentui/react';
import { ApolloError } from 'apollo-client';
import ToggleGroup from 'components/controls/ToggleGroup';
import { InputDialog } from 'components/layout/Dialog';
import { useEffect, useState } from 'react';
import { GoodPractice } from 'sections/good-practice-management/pages/ViewAmendGoodPractice/queries/view-good-practice.queries.types';
import { ObservationClosureReasonEnum } from 'shared/enums/ObservationClosureReason.enum';
import { StyledControlContainer } from 'shared/styled/control.styled';
import { mapToDropdownOptions } from 'shared/utils/mapping.utils';
import {
   closureReasonNotSelected,
   closureDateNotProvided,
   closureDateInFuture,
   closureDateBeforeIncident
} from '../constants/completion-validation-messages.cosntants';
import { GetGoodPracticeCompletionLookups } from 'sections/good-practice-management/pages/ViewAmendGoodPractice/queries/good-practice-completion.queries';
import { IClosureReason, IObservationCompletionLookupData } from 'shared/queries/observation-completion.queries.types';
import { AmendGoodPracticeLoadingState } from 'sections/good-practice-management/pages/ViewAmendGoodPractice/enums/amend-good-practice-loading-state.enum';
import { ErrorListToastMessage } from 'modules/errors';
import { ActionResponse } from 'modules/errors/classes/ActionResponse';
import { ObservationStatusEnum } from 'shared/enums/ObservationStatus.enum';
import { useToasts } from 'react-toast-notifications';
import { usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import {
   CompleteGoodPracticeResponse,
   CompleteGoodPracticeVariables
} from 'sections/good-practice-management/pages/ViewAmendGoodPractice/mutations/amend-good-practice.mutations.types';
import { CompleteGoodPractice } from 'sections/good-practice-management/pages/ViewAmendGoodPractice/mutations/amend-good-practice.mutations';
import DateTimeInput from 'components/controls/DateTimeInput';
import { ConvertToUTC } from 'shared/utils/date.utils';
import { useTranslation } from 'react-i18next';

interface CompleteDialogProps {
   goodPractice: GoodPractice | undefined;
   isOpen: boolean;
   setLoadingState: (loadingMessage: AmendGoodPracticeLoadingState, isLoading: boolean) => void;
   onDismiss: () => void;
   onComplete: () => void;
}

export const CompleteDialog = ({
   goodPractice,
   isOpen,
   setLoadingState,
   onDismiss,
   onComplete
}: CompleteDialogProps) => {
   const client = useApolloClient();
   const { t, i18n } = useTranslation();
   const { addToast } = useToasts();
   const { hasPermission } = usePermissions();

   const [selectedClosureReason, setSelectedClosureReason] = useState<ObservationClosureReasonEnum>();
   const [closureReasonValidationMessage, setClosureReasonValidationMessage] = useState<string>();
   const [actionTaken, setActionTaken] = useState<string>();
   const [actionTakenValidationMessage, setActionTakenValidationMessage] = useState<string>();
   const [selectedClosureDate, setSelectedClosureDate] = useState<string | null>();
   const [closureDateValidationMessage, setClosureDateValidationMessage] = useState<string>();

   const [loadingClosureReasons, setLoadingClosureReasons] = useState<boolean>(true);
   const [loadingClosureReasonsError, setLoadingClosureReasonsError] = useState<ApolloError>();
   const [closureReasons, setClosureReasons] = useState<IClosureReason[]>();

   useEffect(() => {
      loadReporterTypes();
      setSelectedClosureDate(new Date().toISOString());
   }, []);

   const loadReporterTypes = async () => {
      setLoadingClosureReasons(true);

      try {
         let result = await client.query<IObservationCompletionLookupData>({
            query: GetGoodPracticeCompletionLookups,
            variables: {
               request: { type: 'GOODPRACTICECLOSUREREASON', selectedLanguage: i18n.language.toUpperCase() }
            },
            fetchPolicy: 'network-only'
         });

         if (result?.data?.closureReasons != null) {
            setClosureReasons(result.data.closureReasons);
         }
      } catch (e: any) {
         setLoadingClosureReasonsError(e);
      } finally {
         setLoadingClosureReasons(false);
      }
   };

   const resetFormAndClose = () => {
      setSelectedClosureReason(undefined);
      setActionTaken(undefined);
      setClosureReasonValidationMessage(undefined);
      setActionTakenValidationMessage(undefined);

      onDismiss();
   };

   const updateClosureReason = (selection: string | number) => {
      setClosureReasonValidationMessage(undefined);
      setSelectedClosureReason(selection as ObservationClosureReasonEnum);
   };

   const updateClosureDate = (date: string | null | undefined) => {
      setClosureDateValidationMessage(undefined);
      setSelectedClosureDate(date);
   };

   const validateClosureReason = (): boolean => {
      if (!selectedClosureReason) {
         setClosureReasonValidationMessage(t(closureReasonNotSelected));
         return false;
      }

      setClosureReasonValidationMessage(undefined);
      return true;
   };

   const validateClosureDate = (value: string | undefined): boolean => {
      if (!value) {
         setClosureDateValidationMessage(t(closureDateNotProvided));
         return false;
      } else if (value && new Date(value).getTime() > Date.now()) {
         setClosureDateValidationMessage(t(closureDateInFuture));
         return false;
      } else if (
         goodPractice?.incidentDate! &&
         value &&
         new Date(value).getTime() < Date.parse(goodPractice?.incidentDate.toString())
      ) {
         setClosureDateValidationMessage(t(closureDateBeforeIncident));
         return false;
      }

      setClosureDateValidationMessage(undefined);
      return true;
   };

   const submitCompletion = () => {
      var closureReasonValid = validateClosureReason();
      var closeDateValid = validateClosureDate(selectedClosureDate ?? undefined);

      if (!closureReasonValid || !closeDateValid) {
         return;
      }

      handleCompleteGoodPractice();
   };

   const handleCompleteGoodPractice = async (): Promise<ActionResponse> => {
      setLoadingState(AmendGoodPracticeLoadingState.savingChanges, true);
      const actionResponse = new ActionResponse();

      if (goodPractice?.observationStatus !== ObservationStatusEnum.Open) {
         return actionResponse.set({
            successful: false,
            error: {
               message: 'You are attempting to amend a closed Good Practice which is prohibited'
            }
         });
      }

      if (!hasPermission(WriteOn(UserPermission.Completion))) {
         return actionResponse.set({
            successful: false,
            error: {
               message: 'You do not have the required permissions to perform this action'
            }
         });
      }

      try {
         await client.mutate<CompleteGoodPracticeResponse, CompleteGoodPracticeVariables>({
            mutation: CompleteGoodPractice,
            variables: {
               request: {
                  goodPracticeId: goodPractice?.observationId ?? 0,
                  closeDate: ConvertToUTC(selectedClosureDate!),
                  closureReason: selectedClosureReason!.toUpperCase()
               }
            }
         });

         actionResponse.set({ successful: true });
      } catch (error: any) {
         actionResponse.set({
            successful: false,
            error: {
               message: 'There was an issue closing the Good Practice',
               error: error
            }
         });
      }

      const response = actionResponse;

      setLoadingState(AmendGoodPracticeLoadingState.savingChanges, false);

      if (!response.successful) {
         addToast(<ErrorListToastMessage errors={response.getErrorMessages()} />, { appearance: 'error' });
      } else {
         addToast(t('ObservationPage.ChangesSavedSuccessfully'), { appearance: 'success' });

         onComplete();
      }

      return response;
   };

   return (
      <InputDialog
         title={t('CompleteObservation.CompleteGoodPractice')}
         subText={t('CompleteObservation.CompleteDetails')}
         show={isOpen}
         onCancel={resetFormAndClose}
         onDone={submitCompletion}
         width={'500px'}
         cancelButtonText={t('InputDialog.Cancel')}
         doneButtonText={t('InputDialog.Done')}
      >
         <StyledControlContainer>
            <DateTimeInput
               label={t('CompleteObservation.DateClosed')}
               value={selectedClosureDate}
               onDateTimeSelect={updateClosureDate}
               errorMessage={closureDateValidationMessage}
            />
         </StyledControlContainer>
         <StyledControlContainer>
            <ToggleGroup
               label={t('CompleteObservation.ReasonForClosure')}
               options={mapToDropdownOptions<IClosureReason, IDropdownOption>(
                  closureReasons,
                  type => type.name,
                  type => t(`CompleteObservation.${type.name}`)
               )}
               selectedKey={selectedClosureReason}
               onChange={updateClosureReason}
               errorMessage={closureReasonValidationMessage}
               isLoading={loadingClosureReasons}
            />
         </StyledControlContainer>
      </InputDialog>
   );
};
