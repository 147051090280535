import { DayFormat, MonthFormat, renderDate, renderTime, WeekdayFormat, YearFormat } from 'shared/utils/format.utils';
import {
   StyledSubHeader,
   StyledSubHeaderControl,
   StyledSubHeaderControlContainer,
   StyledSubHeaderGroup,
   StyledSubHeaderLabel,
   StyledSubHeaderSection,
   StyledSubHeaderTabContainer
} from 'shared/styled/SubHeader.styled';
import SubHeaderTab from 'components/SubHeaderTab';
import { useTranslation } from 'react-i18next';

interface NewGoodPracticeSubHeaderProps {
   formHasErrors: boolean;
   goToOverview: () => void;
}

const NewGoodPracticeSubHeader = ({ formHasErrors, goToOverview }: NewGoodPracticeSubHeaderProps) => {
   const { t } = useTranslation();

   const displayDateTime = (): string => {
      let dateString = new Date().toISOString();

      const datePart = renderDate(dateString, {
         day: DayFormat.Numeric,
         month: MonthFormat.Short,
         weekday: WeekdayFormat.Short,
         year: YearFormat.Numeric
      });
      const timePart = renderTime(dateString ?? '00:00');

      return `${datePart} ${timePart}`;
   };

   return (
      <StyledSubHeader>
         <StyledSubHeaderSection style={{ maxWidth: '25%' }}>
            <StyledSubHeaderGroup>
               <StyledSubHeaderControlContainer>
                  <StyledSubHeaderLabel>{t('ObservationPage.IncidentDate')}</StyledSubHeaderLabel>
                  <StyledSubHeaderControl>{displayDateTime()}</StyledSubHeaderControl>
               </StyledSubHeaderControlContainer>
            </StyledSubHeaderGroup>
            <StyledSubHeaderGroup>
               <StyledSubHeaderControlContainer>
                  <StyledSubHeaderLabel>{t('ObservationList.Status')}</StyledSubHeaderLabel>
                  <StyledSubHeaderControl>{t('ObservationPage.New')}</StyledSubHeaderControl>
               </StyledSubHeaderControlContainer>
            </StyledSubHeaderGroup>
         </StyledSubHeaderSection>
         <StyledSubHeaderSection style={{ flex: '2 1 auto' }}>
            <StyledSubHeaderGroup style={{ justifyContent: 'flex-end' }}>
               <StyledSubHeaderTabContainer>
                  <SubHeaderTab
                     onClick={goToOverview}
                     isActive={true}
                     display={t('ObservationPage.Overview')}
                     highlightErrors={formHasErrors}
                  />
               </StyledSubHeaderTabContainer>
            </StyledSubHeaderGroup>
         </StyledSubHeaderSection>
      </StyledSubHeader>
   );
};

export default NewGoodPracticeSubHeader;
