import { ConditionalRender } from 'components/layout/ConditionalRender';
import IconButton from 'components/controls/Button/components/IconButton';
import { theme } from 'shared/themes/aplant.theme';
import { StyledPhotoFrame } from '../styled/near-miss-photo-display.styled';
import { StyledPhotoFrameDeleteButton } from 'components/ObservationPhoto/components/ObservationPhotoDisplay/styled/near-miss-photo-display.styled';
import placeholder from 'assets/images/photo-placeholder.png';
import { ConfirmationDialog } from 'components/layout/Dialog';
import React, { useState } from 'react';
import { ObservationAddPhoto, ObservationPhoto } from 'components/ObservationPhoto/types/observation-photo.type';
import { useTranslation } from 'react-i18next';

interface ObservationPhotoProps {
   photo: ObservationPhoto | ObservationAddPhoto;
   canDelete: boolean;
   onClick: () => void;
   onDeleteClick: () => void;
}

export const ObservationPhotoDisplay = ({ photo, canDelete, onClick, onDeleteClick }: ObservationPhotoProps) => {
   const [showDiscard, setShowDiscard] = useState<boolean>(false);

   const handleDiscard = () => setShowDiscard(true);

   const handleDiscardConfirm = () => {
      onDeleteClick();
      handleDiscardCancel();
   };

   const handleDiscardCancel = () => setShowDiscard(false);
   const { t } = useTranslation();

   return (
      <React.Fragment>
         <StyledPhotoFrame>
            <ConditionalRender condition={!photo.url}>
               <img src={placeholder} alt='ImageLoading' />
            </ConditionalRender>
            <ConditionalRender condition={!!photo.url}>
               <img src={photo.url} onClick={onClick} />
               {canDelete && (
                  <StyledPhotoFrameDeleteButton onClick={handleDiscard}>
                     {' '}
                     <IconButton
                        iconName={'Blocked2Solid'}
                        title={t('ObservationPage.Delete')}
                        style={{
                           color: theme.palette.error.main,
                           fontSize: '25px',
                           backgroundColor: 'transparent',
                           cursor: 'pointer'
                        }}
                     />
                  </StyledPhotoFrameDeleteButton>
               )}
            </ConditionalRender>
         </StyledPhotoFrame>
         <ConfirmationDialog
            title={t('ObservationPage.DeletePhoto')}
            subText={t('ObservationPage.PhotoDeletedSaveChanges')}
            hidden={true}
            show={showDiscard}
            onAccept={handleDiscardConfirm}
            onDecline={handleDiscardCancel}
            acceptButtonText='OK'
            declineButtonText={t('Cancel')}
         />
      </React.Fragment>
   );
};
