import { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ApolloError } from 'apollo-client';
import { useApolloClient } from '@apollo/react-hooks';
import { ReadOn, usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import { ApolloErrorToastMessage } from 'modules/errors';
import Loading from 'components/layout/Loading';
import ConfigurationPageContainer from 'sections/configuration/components/ConfigurationPageContainer';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { GetDepot } from '../queries/view-depot.queries';
import { ViewDepotRequest, ViewDepotResponse } from '../queries/view-depot.queries.types';
import { DepotEntityMapper } from 'sections/configuration/pages/Depots/pages/ViewDepot/mappers/DepotEntityMapper';
import { DepotLoadingStates } from '../enums/DepotLoadingStates';
import { UpdateDepotRequest } from '../mutations/update-depot.mutations.types';
import { UpdateDepot } from '../mutations/update-depot.mutations';
import DepotHeader from '../DepotHeader/DepotHeader';
import DepotSubHeader from '../DepotSubHeader';
import DepotsConfiguration from '../../../components/DepotsConfiguration/Index';
import { DepotDisplayEnum } from '../enums/depot-display.enum';
import { DepotCollectionModel } from 'shared/types/configuration/DepotModels';
import DepotAudit from '../DepotAudit';
import { AdUserEntity } from 'shared/types/configuration/AdUserEntity';
import { ConfigurationPage, configurationRouteBases } from '../../../../../routes/configuration-page.types';

export const ViewDepot = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const client = useApolloClient();
  const { addToast } = useToasts();
  const { hasPermission } = usePermissions();
  const history = useHistory();

  const [manageDepot, setManageDepot] = useState<DepotCollectionModel>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<DepotLoadingStates>();

  const [displayMode, setDisplayMode] = useState<DepotDisplayEnum>(DepotDisplayEnum.Overview);

    const [newManagers, setNewManagers] = useState<AdUserEntity[]>([]);
    const [surplusManagers, setSurplusManagers] = useState<AdUserEntity[]>([]);

  useEffect(() => {
      if (params && !initialLoad) { 
              getDepot(parseInt(params.id));
    }
  }, [params, initialLoad]);

    useEffect(() => {

    if (manageDepot) {
      setLoadingState(DepotLoadingStates.LoadingDepot, false);
    }
    
  }, [manageDepot])
  
    const getDepot = (pcId: number) => {
    setLoadingState(DepotLoadingStates.LoadingDepot, true);
    client
      .query<ViewDepotResponse, ViewDepotRequest>({
        query: GetDepot,
        variables: {
          pcId: pcId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
          if (res?.data) {

            const mapper = new DepotEntityMapper();
            const depot = mapper.map(res.data) ?? undefined;
            setManageDepot(depot);
          }
      })
      .catch(err => {
        addToast(<ApolloErrorToastMessage error={err} baseMessage='Issue retrieving Depot details' />, {
          appearance: 'error'
        });
      })
      .finally(() => {
        setInitialLoad(true);
      });
  };

  const updateDepot = async () => {
      try {
        if (!manageDepot) return;

        if (newManagers.length === 0 && surplusManagers.length === 0) return;
        setLoadingState(DepotLoadingStates.UpdatingDepot, true);
        const result = await client.mutate<{ updateDepot: boolean }, UpdateDepotRequest>({
        mutation: UpdateDepot,
        variables: {
            request: {
                pCId: manageDepot?.pCID ?? 0,
                newEmployeeIds: newManagers.map(x => x.employeeId),
                deletedEmployeeIds: surplusManagers.map(x => x.employeeId)
          }
        }
      });

          if (result?.data) {
              refreshDepot();
              addToast(`${manageDepot?.pcName} Depot has been updated`, { appearance: 'success' });
              history.push(configurationRouteBases[ConfigurationPage.Depots]);
          }
      } catch (err) {
         addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
         setLoadingState(DepotLoadingStates.UpdatingDepot, false);
    }
  };

    const refreshDepot = () => {
        if (manageDepot && manageDepot.usersToAdd.length > 0 && manageDepot.usersToRemove.length > 0) {
          getDepot(manageDepot?.pCID);
        }
    }

    const allowSave = useCallback((): boolean => {
        return hasChanges() && hasPermission(WriteOn(UserPermission.ManageDepots))
  }, [newManagers, surplusManagers])
  
  const hasChanges = () => {
    return newManagers?.length > 0 || surplusManagers?.length > 0
  }
  
  const goToOverview = (): void => {
    setDisplayMode(DepotDisplayEnum.Overview);
  } 

  const goToAuditLog = (): void => {
    setDisplayMode(DepotDisplayEnum.Audit);
  }

  const setLoadingState = (loadingMessage: DepotLoadingStates, isLoading: boolean): void => {
    setLoadingMessage(loadingMessage);
    setIsLoading(isLoading);
  }

  return (
      <ConfigurationPageContainer>
        <Loading isLoading={isLoading} message={loadingMessage?.toString()}>
        {manageDepot && (
          <DepotHeader          
              depotModel={manageDepot}
              allowSave={allowSave()}
              onSave={updateDepot}
          />)}
          <DepotSubHeader
              displayMode={displayMode}
              formHasErrors={false}
              goToOverview={goToOverview}
              goToAuditLog={goToAuditLog}
          />
          <ConditionalRender condition={displayMode == DepotDisplayEnum.Audit && hasPermission(ReadOn(UserPermission.Audit))}>
            <DepotAudit
              uniqueRecordId={manageDepot?.uniqueRecordId ?? ''}
            />
          </ConditionalRender>
              <ConditionalRender condition={displayMode == DepotDisplayEnum.Overview && hasPermission(ReadOn(UserPermission.ManageDepots))}>
            {manageDepot && (
              <>
                <DepotsConfiguration
                  selectedDepot={manageDepot}
                  onChangeNewManagerLinks={setNewManagers}
                  onChangeSurplusManagerLinks={setSurplusManagers}
                />

              </>
            )}
              </ConditionalRender>
          </Loading>
    </ConfigurationPageContainer>
  );
};