import { ApolloError } from 'apollo-client';
import { getErrorMessages } from '../utils/errors.utils';
import { isNullOrEmpty } from 'shared/utils/null.utils';

interface IApolloErrorToastMessageProps {
  baseMessage?: string;
  error: ApolloError;
}

export const ApolloErrorToastMessage = ({
  baseMessage = 'Oops!',
  error
}: IApolloErrorToastMessageProps) => {
  
  return (
    <div>
      {!isNullOrEmpty(baseMessage) && <div style={{paddingBottom: '10px'}}>{baseMessage}</div>}
      {getErrorMessages(error).map((message, i) => (<div key={i}>{message}</div>))}
    </div>
  )
}