import GoodPracticeCategorisation from 'components/GoodPracticeCategorisation/components/GoodPracticeCategorisation';
import GoodPracticeDetails from 'components/GoodPracticeDetails';
import { usePermissions, UserPermission, WriteOn, DeleteOn } from 'modules/permissions';
import React, { useCallback } from 'react';
import { ObservationStatusEnum } from 'shared/enums/ObservationStatus.enum';
import { StyledPanelGrid, StyledPanelRow, StyledPanelColumn, StyledContainer } from 'shared/styled/panel.stayled';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { IRemoveFiles } from 'shared/utils/files/types/IRemoveFiles';
import { ObservationCategoryEntity } from 'shared/types/domain/ObservationCategoryEntity';
import { GoogleMapsApiKeyProvider } from 'modules/mapping';
import GoodPracticeReporter from '../../GoodPracticeReporter';
import { GoodPractice } from '../../../queries/view-good-practice.queries.types';
import AmendObservationPhotos from 'components/ObservationPhoto/components/AmendObservationPhotos';

interface AmendGoodPracticeForm {
   goodPractice: GoodPractice | undefined;
   removeFiles: IRemoveFiles<ObservationFileEntity>;
   files: ObservationFileEntity[];
}

const AmendGoodPracticeForm = ({ goodPractice, removeFiles, files }: AmendGoodPracticeForm) => {
   const { hasPermission } = usePermissions();

   const canEditGoodPractice = useCallback((): boolean => {
      return (
         goodPractice?.observationStatus === ObservationStatusEnum.Open &&
         hasPermission(WriteOn(UserPermission.ExistingObservation))
      );
   }, [goodPractice, hasPermission]);

   const canDeletePhoto = useCallback((): boolean => {
      return (
         goodPractice?.observationStatus === ObservationStatusEnum.Open &&
         hasPermission(DeleteOn(UserPermission.ImageFile))
      );
   }, [goodPractice, hasPermission]);

   const canEditReporter = useCallback((): boolean => {
      return (
         goodPractice?.observationStatus === ObservationStatusEnum.Open &&
         hasPermission(WriteOn(UserPermission.ObservationReporter))
      );
   }, [goodPractice, hasPermission]);

   const selectedCategory = useCallback((): ObservationCategoryEntity | undefined => {
      if (goodPractice) {
         return {
            observationCategoryId: goodPractice.observationCategoryId,
            observationCategoryName: goodPractice.observationCategoryName,
            observationCategoryNameDe: goodPractice.observationCategoryName
         };
      }

      return undefined;
   }, [goodPractice]);

   return (
      <React.Fragment>
         <StyledContainer>
            <StyledPanelGrid fluid>
               <StyledPanelRow>
                  <StyledPanelColumn md={12} lg={4} xl={4}>
                     <GoogleMapsApiKeyProvider>
                        <GoodPracticeCategorisation
                           readOnly={!canEditGoodPractice()}
                           selectedCategory={selectedCategory()}
                        />
                     </GoogleMapsApiKeyProvider>
                  </StyledPanelColumn>
                  <StyledPanelColumn md={12} lg={4} xl={4}>
                     <GoodPracticeDetails readOnly={!canEditGoodPractice()} />
                     <AmendObservationPhotos
                        uniqueRecordId={goodPractice?.uniqueRecordId}
                        observationType={'Good Practice'}
                        removeFiles={removeFiles}
                        files={files}
                        canDelete={canDeletePhoto()}
                     />
                  </StyledPanelColumn>
                  <StyledPanelColumn md={12} lg={4} xl={4}>
                     <GoodPracticeReporter goodPracticeReadOnly={true} reporterReadOnly={!canEditReporter()} />
                  </StyledPanelColumn>
               </StyledPanelRow>
            </StyledPanelGrid>
         </StyledContainer>
      </React.Fragment>
   );
};

export default AmendGoodPracticeForm;
