import { AppRoute } from "modules/routing/types/AppRoute";
import { NewNearMiss } from "../pages/NewNearMiss/NewNearMiss";

export const nearMissReportNew = '/nearmiss/new';

export const nearMissReportNewRoutes: AppRoute[] = [
    {
        name: 'New-Near-Miss',
        path: nearMissReportNew,
        exact: true,
        component: NewNearMiss
    }
];