import Button from 'components/controls/Button';
import { FormFieldData, useForm } from 'modules/forms';
import { useCallback, useState } from 'react';
import { GoodPracticeEntity } from 'shared/types/domain/GoodPracticeEntity';
import {
   StyledActionsContainer,
   StyledObservationHeader,
   StyledActionsRow,
   StyledActionsCol
} from 'shared/styled/ObservationHeader.styled';
import { IFormIsValidFunction } from 'modules/forms/types/IFormIsValidFunction';
import { Prompt } from 'react-router-dom';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { onLeaveGoodPracticeMessage } from 'shared/constants/onLeaveInProgressMessages';
import { ActionResponse } from 'modules/errors';
import { useTranslation } from 'react-i18next';

interface GoodPracticeHeaderProps {
   onSave: (
      formData: FormFieldData<GoodPracticeEntity> | undefined,
      fileData: ObservationFileEntity[] | undefined,
      isValid: IFormIsValidFunction
   ) => Promise<ActionResponse>;
   isLoading: boolean;
   files: ObservationFileEntity[];
}

const GoodPracticeHeader = ({ onSave, isLoading, files }: GoodPracticeHeaderProps) => {
   const { t } = useTranslation();

   const getTitle = (): string => {
      return t('ObservationPage.NewGoodPractice');
   };

   const { formData, isValid } = useForm<GoodPracticeEntity>();
   const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

   const hasChanges = useCallback((): boolean => {
      var dataChanged =
         !!formData?.locationType ||
         !!formData?.locationDescription ||
         !!formData?.locationLatitude ||
         !!formData?.locationLongitude ||
         !!formData?.depotPcId ||
         !!formData?.observationCategoryId ||
         !!formData?.whatHappened ||
         !!formData?.whoShouldBeCommended ||
         !!formData?.reporterType ||
         (!formData?.anonymousEntry && !!formData?.reporterContactName) ||
         (!formData?.anonymousEntry && !!formData?.reporterPhoneNumber) ||
         (!formData?.anonymousEntry && !!formData?.reporterEmail) ||
         files?.length > 0;

      if (dataChanged) {
         window.onbeforeunload = () => true;
      } else {
         window.onbeforeunload = null;
      }
      return dataChanged;
   }, [formData, files]);

   const saveGoodPractice = async () => {
      if (!formSubmitted) {
         setFormSubmitted(true);
         await onSave(formData, files, isValid).then(() => {
            setFormSubmitted(false);
         });
      }
   };

   return (
      <StyledObservationHeader>
         <Prompt when={!formSubmitted && hasChanges()} message={t(onLeaveGoodPracticeMessage)} />
         <h1>{getTitle()}</h1>
         <StyledActionsContainer fluid>
            <StyledActionsRow>
               <StyledActionsCol xs={12} style={{ justifyContent: 'flex-end' }}>
                  <Button
                     isLoading={isLoading}
                     text={t('ObservationPage.Submit')}
                     onClick={saveGoodPractice}
                     iconName='Save'
                     disabled={!isValid(false)}
                  />
               </StyledActionsCol>
            </StyledActionsRow>
         </StyledActionsContainer>
      </StyledObservationHeader>
   );
};

export default GoodPracticeHeader;
