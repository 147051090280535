import Button from 'components/controls/Button';
import { FormFieldData } from 'modules/forms';
import { usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import { useEffect, useState } from 'react';
import { StyledObservationHeader } from 'shared/styled/ObservationHeader.styled';
import { NearMissCategoryCollectionModel } from 'shared/types/configuration/NearMissCategoryModel';
import { useUnload } from 'shared/hooks/useUnload';
import { cloneDeep } from 'lodash';
import { StyledHeaderText } from 'sections/configuration/styled/configuration.styles';
import { useTranslation } from 'react-i18next';

interface CategoryHeaderProps {
   categoryModel: NearMissCategoryCollectionModel;
   onSave: (category: FormFieldData<NearMissCategoryCollectionModel>) => void;
}

const CategoryHeader = ({ categoryModel, onSave }: CategoryHeaderProps) => {
   const { setBlockUnload } = useUnload();
   const { t, i18n } = useTranslation();
   const [stateCategory, setStateCategory] = useState<NearMissCategoryCollectionModel>(
      cloneDeep<NearMissCategoryCollectionModel>(categoryModel)
   );
   const { hasPermission } = usePermissions();

   useEffect(() => {
      setStateCategory(categoryModel);
   }, []);

   const saveNewCategory = () => {
      setBlockUnload({ shouldBlock: false });
      onSave(stateCategory);
   };

   return (
      <StyledObservationHeader>
         <div style={{ display: 'flex' }}>
            <div>
               <StyledHeaderText>
                  {stateCategory.nearMissCategoryName.length > 0
                     ? i18n.language === 'de'
                        ? stateCategory.nearMissCategoryNameDE
                        : stateCategory.nearMissCategoryName
                     : t('Category.NewCategory')}
               </StyledHeaderText>
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
               <Button
                  text={t('InputDialog.Save')}
                  onClick={saveNewCategory}
                  disabled={!hasPermission(WriteOn(UserPermission.Category))}
               />
            </div>
         </div>
      </StyledObservationHeader>
   );
};

export default CategoryHeader;
