import React, { FC } from 'react';
import { FontIcon, Label } from '@fluentui/react';
import {
    StyledField,
    StyledFieldIcon,
    StyledFieldText
} from './ReadOnlyField.styles';

/**
 * Props interface for {@link ReadOnlyField}
 */
interface IReadOnlyFieldProps {
    /**
     * The field label
     */
    label?: string;

    /**
     * The text to display in the field
     */
    text?: string;

    /**
     * If true, the field with display a locked icon instead of an eye
     */
    locked?: boolean;

    /**
     *  If true, the field will display a pencil with click listener;
     */
    clickEditable?: boolean;

    /**
     *  Locked edit click listener
     */
    onClickLockedEditable?: () => void;

    /**
     * Whether the field is required
     */
    required?: boolean;
}

/**
 * ReadOnlyField component
 * @param props {@link IReadOnlyFieldProps}
 */
const ReadOnlyField: FC<IReadOnlyFieldProps> = ({
    label,
    text,
    locked,
    required,
    clickEditable,
    onClickLockedEditable
}: IReadOnlyFieldProps) => {
    /**
     * Gets the tooltip title based on the type of read only field
     */
    const getTitle = (): string => {
        const subTitle = locked ? 'insufficient permissions' : 'readonly';
        return `'${text ?? 'Empty'}' (${subTitle})`;
    };

    return (
        <React.Fragment>
            {label && <Label required={required}>{label}</Label>}
            <StyledField title={getTitle()}>
                <StyledFieldText>{text}</StyledFieldText>
                <StyledFieldIcon>
                    {!clickEditable && <FontIcon iconName={locked ? 'Lock' : 'RedEye'} />}
                    {clickEditable && (
                        <span onClick={onClickLockedEditable} style={{ cursor: 'pointer' }} title='Click to edit'>
                            <FontIcon iconName='Edit' />
                        </span>
                    )}
                </StyledFieldIcon>
            </StyledField>
        </React.Fragment>
    );
};

export default ReadOnlyField;
