import { ConditionalRender } from 'components/layout/ConditionalRender';
import { memo } from 'react';
import { StyledFilterBar, StyledFilterClearIcon } from '../styled/FilterBar.styled';

interface IFilterBarProps {
  children: any;
  showReset?: boolean;
  onReset?: () => void;
}

const FilterBar = memo(({ 
  children,
  showReset,
  onReset
}: IFilterBarProps) => {
  return (
    <StyledFilterBar>
        {children}
        <ConditionalRender condition={!!showReset}>
          <StyledFilterClearIcon iconName='ChromeClose' onClick={onReset} />
        </ConditionalRender>
    </StyledFilterBar>
  );
});

FilterBar.displayName = 'memo(FilterBar)';

export default FilterBar;

