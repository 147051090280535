import { actionCreatorFactory } from 'shared/utils/redux.utils';
import { UserSearchStateActionEnum } from '../../enums/UserSearchStateAction.enum';
import { UserSearchState } from '../../store/state.types';

export const resetSearch = actionCreatorFactory<typeof UserSearchStateActionEnum, undefined>(
    UserSearchStateActionEnum.ResetSearch
);

export const resetSearchHandler: ActionHandler<UserSearchState, typeof resetSearch> = state => {
    return {
        ...state,
        currentPage: 1,
        searchResults: [],
        searchError: undefined
    };
};
