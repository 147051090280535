import { ApolloError, ApolloQueryResult } from 'apollo-client';
import InputError from 'components/controls/InputError';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import Loading from 'components/layout/Loading';
import { useEffect, useState } from 'react';
import { StyledControlContainer } from 'shared/styled/control.styled';
import { StyledPanelTitle } from 'shared/styled/panel.stayled';
import { StyledImageContainer } from 'components/ObservationPhoto/styled/observation-photos.styled';
import ObservationPhotoDialog from 'components/ObservationPhoto/components/ObservationPhotoDialog';
import ObservationPhotoDisplay from 'components/ObservationPhoto/components/ObservationPhotoDisplay';
import { IRemoveFiles } from 'shared/utils/files/types/IRemoveFiles';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { useApolloClient } from '@apollo/react-hooks';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import {
   GetObservationPhotoUrlVariables,
   ObservationPhotoUrlResponse
} from 'components/ObservationPhoto/queries/observation-photos.queries.types';
import { FileStatus } from 'shared/enums/FileStatus.enum';
import { ObservationPhoto } from 'components/ObservationPhoto/types/observation-photo.type';
import { GetObservationFileUrl } from 'shared/queries/observation-file.queries';
import { useTranslation } from 'react-i18next';

interface AmendObservationPhotosProps {
   uniqueRecordId: UID | undefined;
   observationType: string;
   removeFiles: IRemoveFiles<ObservationFileEntity>;
   files: ObservationFileEntity[];
   canDelete: boolean;
}

export const AmendObservationPhotos = ({
   uniqueRecordId,
   observationType,
   canDelete,
   removeFiles,
   files
}: AmendObservationPhotosProps) => {
   const [isLoading] = useState<boolean>(false);
   const client = useApolloClient();
   const [loadingError, setLoadingError] = useState<ApolloError>();
   const [images, setImages] = useState<ObservationPhoto[] | undefined>();
   const [hasUrls, setHasUrls] = useState<boolean>(false);
   const [showPhotoDialog, setShowPhotoDialog] = useState<boolean>(false);
   const [photoDialogUrl, setPhotoDialogUrl] = useState<string>();
   const { t } = useTranslation();

   useEffect(() => {
      if (files && !hasUrls) {
         loadImageUrls();
      }
   }, [files, hasUrls]);

   /**
    * Event handler for removing a file.
    * @param fileId The id of the file to remove.
    */
   function handleRemoveFile(fileId: number): void {
      const currentFile = files!.find(x => x.fileId === fileId);
      if (isNullOrUndefined(currentFile)) return;

      removeFiles([currentFile]);

      if (!images) {
         return;
      }

      var index = images!.findIndex(x => x.fileId === fileId);
      if (isNullOrUndefined(index)) return;

      var newImages = [...images];
      newImages[index].delete = true;
      setImages(newImages);
   }

   const loadImageUrls = () => {
      if (files.length < 1) {
         return;
      }

      setHasUrls(true);

      let photo = files
         .filter(x => x.fileStatus !== FileStatus.Removed && x.type?.id === 8)
         ?.map(x => {
            return {
               fileId: x.fileId,
               referenceId: uniqueRecordId
            } as ObservationPhoto;
         });

      Promise.all(photo?.map(files => getImageUrlPromise(files)))
         .then((res: ObservationPhoto[]) => {
            setImages(res);
         })
         .catch(e => {
            setLoadingError(e);
         });
   };

   const getImageUrlPromise = (image: ObservationPhoto): Promise<ObservationPhoto> => {
      return new Promise((resolve: (newImage: ObservationPhoto) => void, reject) => {
         client
            .query<ObservationPhotoUrlResponse, GetObservationPhotoUrlVariables>({
               query: GetObservationFileUrl,
               variables: {
                  fileId: image.fileId,
                  referenceId: uniqueRecordId ?? ''
               },
               fetchPolicy: 'network-only'
            })
            .then((response: ApolloQueryResult<ObservationPhotoUrlResponse>) => {
               image.url = response.data.retrieveSASForFile;

               resolve(image);
            })
            .catch((err: any) => reject(err));
      });
   };

   const onShowPhotoInDialog = (image: ObservationPhoto): void => {
      getImageUrlPromise(image).then(image => {
         setPhotoDialogUrl(image.url);
         setShowPhotoDialog(true);
      });
   };

   const onHidePhotoDialog = (): void => {
      setShowPhotoDialog(false);
      setPhotoDialogUrl(undefined);
   };

   const isLoadingImagesMessage = (observationType: string): string=>
   {
      if(observationType == 'Near Miss'){
         return t('ObservationPage.LoadingImagesNearMiss')
      }
      else{
         return t('ObservationPage.LoadingImagesGoodPractice')
      }
   }

   const noImagesMessage = (observationType: string): string=>
   {
      if(observationType == 'Near Miss'){
         return t('ObservationPage.NoImagesNearMiss')
      }
      else{
         return t('ObservationPage.NoImagesGoodPractice')
      }
   }

   return (
      <>
         <ObservationPhotoDialog showPhoto={showPhotoDialog} onDismiss={onHidePhotoDialog} imageUrl={photoDialogUrl} />

         <StyledControlContainer>
            <StyledPanelTitle>{t('ObservationPage.Photos')}</StyledPanelTitle>
            <Loading isLoading={isLoading} message={isLoadingImagesMessage(observationType)} noDelay>
               <StyledImageContainer>
                  {images &&
                     images
                        .filter(x => !x.delete)
                        .map((i, key) => (
                           <ObservationPhotoDisplay
                              key={key}
                              photo={i}
                              canDelete={canDelete}
                              onClick={() => onShowPhotoInDialog(i)}
                              onDeleteClick={() => handleRemoveFile(i.fileId)}
                           />
                        ))}
                  <ConditionalRender condition={!images && !loadingError}>
                     {noImagesMessage(observationType)}
                  </ConditionalRender>
                  <ConditionalRender condition={!images && !!loadingError}>
                     <InputError error={loadingError?.message} />
                  </ConditionalRender>
               </StyledImageContainer>
            </Loading>
         </StyledControlContainer>
      </>
   );
};
