import { useEffect, useState } from 'react';
import { Persona } from 'office-ui-fabric-react/lib/Persona';
import { useApolloClient } from '@apollo/react-hooks';
import { useToasts } from 'react-toast-notifications';
import { GetUserByEmployeeId } from 'shared/queries/user-details.queries';
import Loading from 'components/layout/Loading';
import { ApolloErrorToastMessage } from 'modules/errors';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { isNullOrEmpty } from 'shared/utils/null.utils';
import { IADUser, IADUserResponse } from 'shared/queries/user-details.queries.types';

interface CompletedByDisplayProps {
    completedByEmployeeId?: number;
}

const CompletedByDiplay = ({ completedByEmployeeId }: CompletedByDisplayProps) => {

    const [completedByUser, setCompletedByUser] = useState<IADUser | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const client = useApolloClient();
    const { addToast } = useToasts();

    useEffect(() => {
        if (completedByEmployeeId && completedByEmployeeId > 0) {
            setIsLoading(true);
            client.query<IADUserResponse>({
                query: GetUserByEmployeeId,
                variables: {
                    empId: completedByEmployeeId
                },
                fetchPolicy: 'network-only'
            }).then((response: any) => {

                if (response.data.azureADUser) {
                    setCompletedByUser(response.data.azureADUser);
                }
            }).catch((error: any) => {
                addToast(<ApolloErrorToastMessage error={error} />, { appearance: 'error' });
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [completedByEmployeeId, client, addToast]);

    return (
        <>
            <ConditionalRender condition={!!completedByEmployeeId}>
                <Loading isLoading={isLoading} message="" overlay={true}>
                    <div style={{ border: '1px solid #a19f9d', borderRadius: '2px', padding: '10px 5px' }}>
                        <Persona
                            text={completedByUser?.displayName}
                            onRenderSecondaryText={p => <div
                                style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>
                                    <div>{`${completedByUser?.jobTitle ?? ''} ${isNullOrEmpty(completedByUser?.department) ? '' : '@ ' + completedByUser?.department}`}</div>
                                    <div>{completedByUser?.email}</div>
                                    <div>{completedByUser?.businessPhones.join()}</div>
                                </div>
                            </div>}
                        />
                    </div>
                </Loading>
            </ConditionalRender>
        </>
    );
};

export default CompletedByDiplay;