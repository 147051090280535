import { FormFieldData } from "modules/forms";
import { GoodPracticeEntity } from "shared/types/domain/GoodPracticeEntity";
import { IMapper } from "shared/types/IMapper";
import { GoodPracticeByIdResponse } from "../queries/view-good-practice.queries.types";

export class GoodPracticeEntityMapper implements IMapper<GoodPracticeByIdResponse, FormFieldData<GoodPracticeEntity>> {
    map(entity: GoodPracticeByIdResponse): FormFieldData<GoodPracticeEntity> {
        return {
            observationId: entity.goodPractice.observationId,
            companyId: entity.goodPractice.companyId,
            locationType: entity.goodPractice.locationType,
            depotPcId: entity.goodPractice.depotPcId,
            customerSiteName: entity.goodPractice.offSiteLocation?.customerSiteName,
            locationDescription: entity.goodPractice.offSiteLocation?.locationDescription,
            locationLatitude: entity.goodPractice.offSiteLocation?.locationLatitude,
            locationLongitude: entity.goodPractice.offSiteLocation?.locationLongitude,
            observationCategoryId: entity.goodPractice.observationCategoryId,
            whatHappened: entity.goodPractice.whatHappened,
            whoShouldBeCommended: entity.goodPractice.whoShouldBeCommended,
            reporterType: entity.goodPractice.reporterType,
            anonymousEntry: entity.goodPractice.anonymousEntry,
            reporterContactName: entity.goodPractice.reportedBy?.reporterContactName,
            reporterPhoneNumber: entity.goodPractice.reportedBy?.reporterPhoneNumber,
            reporterEmail: entity.goodPractice.reportedBy?.reporterEmail,
            incidentDate: entity.goodPractice.incidentDate
        }
    }
}