export enum UserSearchStateActionEnum {
    SetPage = 'SET_PAGE',
    SetSearchQuery = 'SET_SEARCH_QUERY',
    SetSearchError = 'SET_SEARCH_ERROR',
    AddSearchResults = 'ADD_TO_SEARCH_RESULTS',
    SetFetchingResults = 'SET_FETCHING_RESULTS',
    ResetSearch = 'RESET_SEARCH',
    SetIsSaving = 'SET_IS_SAVING',
    SetSearchInputValue = 'SET_SEARCH_INPUT_VALUE',
    SetSearchInputError = 'SET_SEARCH_INPUT_ERROR',
    SetIsAddUserModalOpen = 'SET_IS_ADD_USER_MODAL_OPEN'
}
