import { IDropdownOption } from '@fluentui/react';
import { DateInput } from 'components/controls/DateInput';
import { cloneDeep } from 'lodash';
import Select from 'components/controls/Select';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import FilterBar from 'components/layout/FilterBar';
import { ReadOn, usePermissions, UserPermission } from 'modules/permissions';
import { useCallback, useEffect, useState } from 'react';
import { ObservationStatusEnum } from 'shared/enums/ObservationStatus.enum';
import { mapToDropdownOptions } from 'shared/utils/mapping.utils';
import { GoodPractice, GoodPracticeListQuery } from '../../../queries/good-practice-list.queries.types';
import {
   GoodPracticeCategoryLookup,
   GoodPracticeDepotLookup,
   GoodPracticeDivisionLookup,
   GoodPracticeRegionLookup,
   SHEQManagerLookup,
   GoodPracticeEnumLookup,
   GoodPracticeFilterLookup
} from '../types/good-practice-filter.types';
import { isEmpty, isNullOrEmpty, isNullOrUndefined } from 'shared/utils/null.utils';
import { FilterSelectV2 } from 'components/controls/Select';
import { getCookie, setCookie, setDateFromCookie } from 'shared/utils/cookies.utils';
import { ObservationListFilterEnum } from 'shared/enums/ObservationListFilter.enum';
import { useTranslation } from 'react-i18next';

interface GoodPracticeFilterBarProps {
   filterDataLoaded: boolean;
   showFilterBar: boolean;
   toggleFilterBar: () => void;
   filterRequest: GoodPracticeListQuery;
   goodPracticeData: GoodPractice[] | undefined;
   setFilterRequest: (newFilter: GoodPracticeListQuery) => void;
   resetFilter: () => void;
}

const defaultLookupValues: GoodPracticeFilterLookup = {
   categories: [],
   statuses: [],
   depots: [],
   divisions: [],
   regions: [],
   sHEQManagers: []
};

export const GoodPracticeFilterBar = ({
   filterDataLoaded,
   showFilterBar,
   toggleFilterBar,
   filterRequest,
   goodPracticeData,
   setFilterRequest,
   resetFilter
}: GoodPracticeFilterBarProps) => {
   const { t, i18n } = useTranslation();
   const [lookupValues, setLookUpValues] = useState<GoodPracticeFilterLookup | undefined>();
   const [originalLookupValues, setOriginalLookUpValues] = useState<GoodPracticeFilterLookup | undefined>();

   const { hasPermission } = usePermissions();

   useEffect(() => {
      if (!goodPracticeData) {
         return;
      }

      let dateFrom = setDateFromCookie(getCookie(ObservationListFilterEnum.GoodPracticeDateFrom));
      let dateTo = setDateFromCookie(getCookie(ObservationListFilterEnum.GoodPracticeDateTo));
      let observationCategoryId =
         getCookie(ObservationListFilterEnum.GoodPracticeCategoryId)?.split(',').map(Number) ?? [];
      let observationStatus = (getCookie(ObservationListFilterEnum.GoodPracticeStatus)?.split(
         ','
      ) as ObservationStatusEnum[]) ?? [ObservationStatusEnum.Open, ObservationStatusEnum.Closed];
      let regionId = getCookie(ObservationListFilterEnum.GoodPracticeRegionIds)?.split(',').map(Number) ?? [];
      let divisionId = getCookie(ObservationListFilterEnum.GoodPracticeDivisionIds)?.split(',').map(Number) ?? [];
      let depotPcId = getCookie(ObservationListFilterEnum.GoodPracticeDepotPcId)?.split(',').map(Number) ?? [];
      let sHEQManagerId = getCookie(ObservationListFilterEnum.GoodPracticeManagerIds)?.split(',').map(Number) ?? [];

      setFilterRequest({
         ...filterRequest,
         dateFrom: dateFrom,
         dateTo: dateTo,
         observationCategoryId: observationCategoryId,
         observationStatus: observationStatus,
         regionId: regionId,
         divisionId: divisionId,
         depotPcId: depotPcId,
         sHEQManagerId: sHEQManagerId
      });

      if (
         !isNullOrEmpty(dateFrom) ||
         !isNullOrEmpty(dateTo) ||
         (observationStatus.toString() != 'OPEN,CLOSED' && observationStatus.toString() != 'CLOSED,OPEN') ||
         observationCategoryId.length > 0 ||
         regionId.length > 0 ||
         divisionId.length > 0 ||
         depotPcId.length > 0 ||
         sHEQManagerId.length > 0
      ) {
         toggleFilterBar();
      }

      const newLookupValues = cloneDeep<GoodPracticeFilterLookup>(lookupValues ?? defaultLookupValues);

      newLookupValues.categories = goodPracticeData
         .map(x => {
            return {
               id: x.observationCategoryId,
               name: i18n.language === 'de' ? x.observationCategoryNameDE : x.observationCategoryName
            };
         })
         .distinct(x => x.id)
         .sort((a: GoodPracticeCategoryLookup, b: GoodPracticeCategoryLookup) => {
            const sortValueA = a.name;
            const sortValueB = b.name;

            return (false ? sortValueA < sortValueB : sortValueA > sortValueB) ? 1 : -1;
         });

      newLookupValues.statuses = goodPracticeData
         .map(x => {
            return {
               id: x.observationStatus as ObservationStatusEnum,
               name: x.observationStatus.toProperCase()
            };
         })
         .distinct(x => x.id);

      if (
         !newLookupValues?.statuses.any(s => s.id === ObservationStatusEnum.Archived) &&
         hasPermission(ReadOn(UserPermission.Archive))
      ) {
         newLookupValues.statuses.push({
            id: ObservationStatusEnum.Archived,
            name: ObservationStatusEnum.Archived.toProperCase()
         });
      }

      newLookupValues.depots = goodPracticeData
         .filter(x => x.depotPcName != null)
         .map(x => {
            return {
               regionId: x.regionId,
               divisionId: x.divisionId,
               pcId: x.depotPcId,
               pc: x.depotPC,
               pcName: x.depotPcName
            };
         })
         .distinct(x => x.pcName)
         .sort((a: GoodPracticeDepotLookup, b: GoodPracticeDepotLookup) => {
            const sortValueA = a.pc;
            const sortValueB = b.pc;

            return (false ? sortValueA < sortValueB : sortValueA > sortValueB) ? 1 : -1;
         });

      newLookupValues.divisions = goodPracticeData
         .filter(x => x.divisionName != null)
         .map(x => {
            return {
               regionId: x.regionId,
               divisionId: x.divisionId,
               divisionName: x.divisionName
            };
         })
         .distinct(x => x.divisionId)
         .sort((a: GoodPracticeDivisionLookup, b: GoodPracticeDivisionLookup) => {
            const sortValueA = a.divisionName;
            const sortValueB = b.divisionName;

            return (false ? sortValueA < sortValueB : sortValueA > sortValueB) ? 1 : -1;
         });

      newLookupValues.regions = goodPracticeData
         .filter(x => x.regionName != null)
         .map(x => {
            return {
               regionId: x.regionId,
               regionName: x.regionName
            };
         })
         .distinct(x => x.regionId)
         .sort((a: GoodPracticeRegionLookup, b: GoodPracticeRegionLookup) => {
            const sortValueA = a.regionName;
            const sortValueB = b.regionName;

            return (false ? sortValueA < sortValueB : sortValueA > sortValueB) ? 1 : -1;
         });

      newLookupValues.sHEQManagers = goodPracticeData
         .filter(x => x.sHEQManager != null)
         .map(x => {
            return {
               sHEQManagerId: x.sHEQManagerId,
               sHEQManager: x.sHEQManager
            };
         })
         .distinct(x => x.sHEQManagerId)
         .sort((a: SHEQManagerLookup, b: SHEQManagerLookup) => {
            const sortValueA = a.sHEQManager;
            const sortValueB = b.sHEQManager;

            return (false ? sortValueA < sortValueB : sortValueA > sortValueB) ? 1 : -1;
         });

      setLookUpValues(newLookupValues);
      setOriginalLookUpValues(newLookupValues);
   }, [filterDataLoaded, i18n.language]);

   const onResetFilter = () => {
      const newLookupValues = cloneDeep<GoodPracticeFilterLookup>(lookupValues ?? defaultLookupValues);
      newLookupValues.divisions = originalLookupValues?.divisions;
      newLookupValues.depots = originalLookupValues?.depots;
      setLookUpValues(newLookupValues);

      resetCookies();
      resetFilter();
   };

   const onDateFromSelect = (date: Date | null | undefined) => {
      setCookie(ObservationListFilterEnum.GoodPracticeDateFrom, date);

      setFilterRequest({
         ...filterRequest,
         dateFrom: date ?? undefined
      });
   };

   const onDateToSelect = (date: Date | null | undefined) => {
      if (!isNullOrUndefined(date)) {
         let toDate = new Date(date.setHours(23, 59, 59, 999));

         setCookie(ObservationListFilterEnum.GoodPracticeDateTo, toDate);

         setFilterRequest({
            ...filterRequest,
            dateTo: toDate ?? undefined
         });
      }
   };

   const onCategorySelect = (event: React.FormEvent<HTMLDivElement> | undefined, option?: IDropdownOption) => {
      if (option) {
         let categoryIds = filterRequest.observationCategoryId;

         const alreadySelected = categoryIds?.any(x => x === option!.key);

         if (option.selected && !alreadySelected) {
            categoryIds!.push(Number(option!.key));
         }

         if (!option.selected && alreadySelected) {
            categoryIds = categoryIds?.filter(x => x !== option!.key);
         }

         setCookie(ObservationListFilterEnum.GoodPracticeCategoryId, categoryIds);

         setFilterRequest({
            ...filterRequest,
            observationCategoryId: categoryIds
         });
      }
   };

   const onStatusSelect = (event: React.FormEvent<HTMLDivElement> | undefined, option?: IDropdownOption) => {
      if (option) {
         let selectedStatuses = filterRequest.observationStatus;

         const alreadySelected = selectedStatuses?.any(x => x === (option!.key as ObservationStatusEnum));

         if (option.selected && !alreadySelected) {
            selectedStatuses!.push(option!.key as ObservationStatusEnum);
         }

         if (!option.selected && alreadySelected) {
            selectedStatuses = selectedStatuses?.filter(x => x !== (option!.key as ObservationStatusEnum));
         }

         setCookie(ObservationListFilterEnum.GoodPracticeStatus, selectedStatuses);

         setFilterRequest({
            ...filterRequest,
            observationStatus: selectedStatuses
         });
      }
   };

   const onDepotSelect = (options?: IDropdownOption[]) => {
      let depotPcId = options?.map(x => Number(x.key));

      setCookie(ObservationListFilterEnum.GoodPracticeDepotPcId, depotPcId);

      setFilterRequest({
         ...filterRequest,
         depotPcId: depotPcId
      });
   };

   const onDivisionSelect = (options?: IDropdownOption[]) => {
      let divisionIds = options?.map(x => Number(x.key));

      setCookie(ObservationListFilterEnum.GoodPracticeDivisionIds, divisionIds);
      setCookie(ObservationListFilterEnum.GoodPracticeDepotPcId, '');

      setFilterRequest({
         ...filterRequest,
         divisionId: divisionIds,
         depotPcId: []
      });

      let depots =
         originalLookupValues?.depots?.filter(x => divisionIds?.any(r => r === x.divisionId))?.distinct(x => x.pcId) ??
         [];

      const newLookupValues = cloneDeep<GoodPracticeFilterLookup>(lookupValues ?? defaultLookupValues);
      newLookupValues.depots = depots;
      setLookUpValues(newLookupValues);
   };

   const onRegionSelect = (options?: IDropdownOption[]) => {
      let regionIds = options?.map(x => Number(x.key)) ?? [];

      setCookie(ObservationListFilterEnum.GoodPracticeRegionIds, regionIds);
      setCookie(ObservationListFilterEnum.GoodPracticeDivisionIds, '');
      setCookie(ObservationListFilterEnum.GoodPracticeDepotPcId, '');

      setFilterRequest({
         ...filterRequest,
         regionId: regionIds,
         divisionId: [],
         depotPcId: []
      });

      let divisions: GoodPracticeDivisionLookup[] | undefined = [];
      let depots: GoodPracticeDepotLookup[] | undefined = [];
      if (isEmpty(regionIds)) {
         divisions = originalLookupValues?.divisions ?? [];
         depots = originalLookupValues?.depots ?? [];
      } else {
         divisions =
            originalLookupValues?.divisions
               ?.filter(x => regionIds?.any(r => r === x.regionId))
               ?.distinct(x => x.divisionId) ?? [];

         depots =
            originalLookupValues?.depots?.filter(x => regionIds?.any(r => r === x.regionId))?.distinct(x => x.pcId) ??
            [];
      }

      const newLookupValues = cloneDeep<GoodPracticeFilterLookup>(lookupValues ?? defaultLookupValues);
      newLookupValues.divisions = divisions;
      newLookupValues.depots = depots;
      setLookUpValues(newLookupValues);
   };

   const onSHEQManagerSelect = (options?: IDropdownOption[]) => {
      let managerIds = options?.map(x => Number(x.key));

      setCookie(ObservationListFilterEnum.GoodPracticeManagerIds, managerIds);

      setFilterRequest({
         ...filterRequest,
         sHEQManagerId: managerIds
      });
   };

   const resetCookies = () => {
      setCookie(ObservationListFilterEnum.GoodPracticeDateFrom, '');
      setCookie(ObservationListFilterEnum.GoodPracticeDateTo, '');
      setCookie(ObservationListFilterEnum.GoodPracticeCategoryId, '');
      setCookie(ObservationListFilterEnum.GoodPracticeStatus, 'OPEN,CLOSED');
      setCookie(ObservationListFilterEnum.GoodPracticeRegionIds, '');
      setCookie(ObservationListFilterEnum.GoodPracticeDivisionIds, '');
      setCookie(ObservationListFilterEnum.GoodPracticeDepotPcId, '');
      setCookie(ObservationListFilterEnum.GoodPracticeManagerIds, '');
   };

   const showSHEQTeamFilter = useCallback(() => {
      return hasPermission(ReadOn(UserPermission.DepotLinks));
   }, [hasPermission]);

   return (
      <ConditionalRender condition={showFilterBar}>
         <FilterBar showReset onReset={onResetFilter}>
            <DateInput
               placeholder={t('ObservationFilter.DateFrom')}
               borderless
               restrictWidth
               onDateSelect={onDateFromSelect}
               value={filterRequest.dateFrom?.toDateString()}
               transparent
            />

            <DateInput
               placeholder={t('ObservationFilter.DateTo')}
               borderless
               restrictWidth
               onDateSelect={onDateToSelect}
               value={filterRequest.dateTo?.toDateString()}
               transparent
            />

            <Select
               style={{ minWidth: '150px', marginTop: '-8px', marginLeft: '70px' }}
               options={mapToDropdownOptions<GoodPracticeCategoryLookup, IDropdownOption>(
                  lookupValues?.categories,
                  status => status.id,
                  status => status.name
               )}
               transparent
               multiSelect
               placeholder={t('ObservationFilter.Category')}
               selectedKeys={filterRequest.observationCategoryId}
               onChange={onCategorySelect}
               calloutWidth='auto'
            />

            <Select
               style={{ minWidth: '140px', marginTop: '-8px', marginLeft: '0px' }}
               options={mapToDropdownOptions<GoodPracticeEnumLookup<ObservationStatusEnum>, IDropdownOption>(
                  lookupValues?.statuses,
                  status => status.id.toUpperCase(),
                  status => t(`ObservationFilter.${status.name.toProperCase()}`)
               )}
               transparent
               multiSelect
               placeholder={t('ObservationList.Status')}
               selectedKeys={filterRequest.observationStatus ?? []}
               onChange={onStatusSelect}
               calloutWidth='auto'
            />
         </FilterBar>

         {
            <ConditionalRender condition={showSHEQTeamFilter}>
               <FilterBar>
                  <FilterSelectV2
                     style={{ minWidth: '120px', marginTop: '-2px', marginLeft: '10px' }}
                     options={mapToDropdownOptions<GoodPracticeRegionLookup, IDropdownOption>(
                        lookupValues?.regions,
                        region => region.regionId,
                        region => `${region.regionName}`
                     )}
                     transparent
                     multiSelect
                     placeholder={t('ObservationFilter.Region')}
                     selectedKeys={filterRequest.regionId}
                     onChangeMulti={onRegionSelect}
                  />

                  <FilterSelectV2
                     style={{ minWidth: '160px', marginTop: '-2px' }}
                     options={mapToDropdownOptions<GoodPracticeDivisionLookup, IDropdownOption>(
                        lookupValues?.divisions,
                        division => division.divisionId,
                        division => `${division.divisionName}`
                     )}
                     transparent
                     multiSelect
                     placeholder={t('ObservationFilter.Division')}
                     selectedKeys={filterRequest.divisionId}
                     onChangeMulti={onDivisionSelect}
                  />

                  <FilterSelectV2
                     style={{ minWidth: '300px', marginTop: '-2px' }}
                     options={mapToDropdownOptions<GoodPracticeDepotLookup, IDropdownOption>(
                        lookupValues?.depots,
                        depot => depot.pcId,
                        depot => `${depot.pcName}`
                     )}
                     transparent
                     multiSelect
                     placeholder={t('ObservationFilter.Depot')}
                     selectedKeys={filterRequest.depotPcId}
                     onChangeMulti={onDepotSelect}
                  />

                  <FilterSelectV2
                     style={{ minWidth: '100px', marginTop: '-2px' }}
                     options={mapToDropdownOptions<SHEQManagerLookup, IDropdownOption>(
                        lookupValues?.sHEQManagers,
                        sheqManager => sheqManager.sHEQManagerId,
                        sheqManager => `${sheqManager.sHEQManager}`
                     )}
                     transparent
                     multiSelect
                     placeholder={t('ObservationFilter.SheqManager')}
                     selectedKeys={filterRequest.sHEQManagerId}
                     onChangeMulti={onSHEQManagerSelect}
                  />
               </FilterBar>
            </ConditionalRender>
         }
      </ConditionalRender>
   );
};
