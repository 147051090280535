import { useSecurity } from "modules/security";
import { useCallback } from "react";
import { UserPermission } from "../enums/user-permissions.enum";
import { AdditionalMenuRights } from "../types/AdditionalMenuRights";
import { ReadOn, WriteOn } from "../utils/permission.utils";
import { usePermissions } from "./usePermissions";

export const useAdditionalMenuRights = (): AdditionalMenuRights => {

    const { hasPermission } = usePermissions();
    const { user } = useSecurity();

    const hasAdditionalMenuRights = useCallback((): boolean => {

        return (!!user && (
            hasPermission(ReadOn(UserPermission.Category))
            || hasPermission(ReadOn(UserPermission.AppSettings))
            || hasPermission(ReadOn(UserPermission.DepotLinks))
            || hasPermission(ReadOn(UserPermission.Profiles))
            || hasPermission(ReadOn(UserPermission.User))
            || hasPermission(WriteOn(UserPermission.Archive))
            || hasPermission(WriteOn(UserPermission.Category))
            || hasPermission(WriteOn(UserPermission.ManageDepots))
            || hasPermission(WriteOn(UserPermission.Profiles))
            || hasPermission(WriteOn(UserPermission.User))
        ))
    }, [user, hasPermission, usePermissions]);
    return { hasAdditionalMenuRights }
};