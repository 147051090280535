import { LinkButton } from 'components/controls/LinkButton';
import { IVirtualTableColumn } from 'components/layout/VirtualTable/Table/components/VirtualTable';
import { GoodPractice } from '../../../queries/good-practice-list.queries.types';
import { CellContainer } from '../components/CellContainer';
import i18next from 'i18next';
import { DateTime } from 'luxon';

export const getGoodPracticeListTableColumns = (
   handleColumnClick: (ev: React.MouseEvent<HTMLElement>, column: IVirtualTableColumn<GoodPractice>) => void,
   handleRowClick: (item: GoodPractice) => void,
): IVirtualTableColumn<GoodPractice>[] => {

   const formatDate = (date?: Date): string => {
      if (!date) return '';

      const dt = DateTime.fromJSDate(date).setLocale(i18next.language);
      return dt.toLocaleString({...DateTime.DATE_SHORT, weekday: 'short', month: 'short'});
   };

   return [
      {
         key: 'column1',
         name: 'ObservationList.CaseId',
         fieldName: 'observationId',
         minWidth: 50,
         maxWidth: 60,
         isResizable: false,
         isSorted: false,
         isSortedDescending: false,
         sortAscendingAriaLabel: 'Sorted A to Z',
         sortDescendingAriaLabel: 'Sorted Z to A',
         onColumnClick: handleColumnClick,
         data: 'string',
         isPadded: true,
         onRender: (item: GoodPractice) => <CellContainer>{item.observationId}</CellContainer>
      },
      {
         key: 'column2',
         name: 'ObservationList.IncidentDate',
         fieldName: 'incidentDate',
         minWidth: 100,
         maxWidth: 120,
         isResizable: false,
         isSorted: true,
         isSortedDescending: true,
         sortAscendingAriaLabel: 'Sorted A to Z',
         sortDescendingAriaLabel: 'Sorted Z to A',
         onColumnClick: handleColumnClick,
         data: 'string',
         isPadded: true,
         onRender: (item: GoodPractice) => (
            <CellContainer>
               {formatDate(new Date(item.incidentDate))}
               
            </CellContainer>
         )
      },
      {
         key: 'column3',
         name: 'ObservationList.Details',
         fieldName: 'whatHappened',
         minWidth: 150,
         maxWidth: 400,
         isResizable: true,
         isSorted: false,
         isSortedDescending: false,
         sortAscendingAriaLabel: 'Sorted A to Z',
         sortDescendingAriaLabel: 'Sorted Z to A',
         onColumnClick: handleColumnClick,
         data: 'string',
         isPadded: true,
         onRender: (item: GoodPractice) => <CellContainer>{item.whatHappened}</CellContainer>,
         sortValueResolver: (item: GoodPractice) => item.whatHappened
      },
      {
         key: 'column4',
         name: 'ObservationList.Location',
         fieldName: 'locationType',
         minWidth: 100,
         maxWidth: 120,
         isResizable: false,
         isSorted: false,
         isSortedDescending: false,
         sortAscendingAriaLabel: 'Sorted A to Z',
         sortDescendingAriaLabel: 'Sorted Z to A',
         onColumnClick: handleColumnClick,
         data: 'string',
         isPadded: true,
         onRender: (item: GoodPractice) => <CellContainer>{i18next.t(`ObservationList.${item.locationTypeDescription}`)}</CellContainer>
      },
      {
         key: 'column5',
         name: 'ObservationList.LocationDescription',
         fieldName: 'offSiteLocationDescription',
         minWidth: 150,
         maxWidth: 170,
         isResizable: false,
         isSorted: false,
         isSortedDescending: false,
         sortAscendingAriaLabel: 'Sorted A to Z',
         sortDescendingAriaLabel: 'Sorted Z to A',
         onColumnClick: handleColumnClick,
         data: 'string',
         isPadded: true,
         onRender: (item: GoodPractice) => <CellContainer>{item.offSiteLocation?.locationDescription}</CellContainer>
      },
      {
         key: 'column6',
         name: 'ObservationList.CustomerSite',
         fieldName: 'offSiteLocationCustomerSite',
         minWidth: 100,
         maxWidth: 120,
         isResizable: false,
         isSorted: false,
         isSortedDescending: false,
         sortAscendingAriaLabel: 'Sorted A to Z',
         sortDescendingAriaLabel: 'Sorted Z to A',
         onColumnClick: handleColumnClick,
         data: 'string',
         isPadded: true,
         onRender: (item: GoodPractice) => <CellContainer>{item.offSiteLocation?.customerSiteName}</CellContainer>
      },
      {
         key: 'column7',
         name: 'ObservationList.Depot',
         fieldName: 'depotPcName',
         minWidth: 120,
         maxWidth: 150,
         isResizable: false,
         isSorted: false,
         isSortedDescending: false,
         sortAscendingAriaLabel: 'Sorted A to Z',
         sortDescendingAriaLabel: 'Sorted Z to A',
         onColumnClick: handleColumnClick,
         data: 'string',
         isPadded: true,
         onRender: (item: GoodPractice) => <CellContainer>{item.depotPcName}</CellContainer>
      },
      {
         key: 'column8',
         name: 'ObservationList.Category',
         fieldName: 'observationCategoryName',
         minWidth: 100,
         maxWidth: 120,
         isResizable: false,
         isSorted: false,
         isSortedDescending: false,
         sortAscendingAriaLabel: 'Sorted A to Z',
         sortDescendingAriaLabel: 'Sorted Z to A',
         onColumnClick: handleColumnClick,
         data: 'string',
         isPadded: true,
         onRender: (item: GoodPractice) => (
            <CellContainer>
               {i18next.language === 'de' ? item.observationCategoryNameDE : item.observationCategoryName}
            </CellContainer>
         )
      },
      {
         key: 'column9',
         name: 'ObservationList.Status',
         fieldName: 'observationStatus',
         minWidth: 70,
         maxWidth: 80,
         isResizable: false,
         isSorted: false,
         isSortedDescending: false,
         sortAscendingAriaLabel: 'Sorted Open first',
         sortDescendingAriaLabel: 'Sorted Open last',
         onColumnClick: handleColumnClick,
         data: 'date',
         isPadded: true,
         onRender: (item: GoodPractice) => (
            <CellContainer>{i18next.t(`ObservationFilter.${item.observationStatus.toProperCase()}`)}</CellContainer>
         ),
         sortValueResolver: (item: GoodPractice) => {
            i18next.t(`ObservationFilter.${item.observationStatus.toProperCase()}`);
         }
      },
      {
         key: 'column10',
         name: '',
         minWidth: 60,
         maxWidth: 60,
         isResizable: false,
         data: 'date',
         isPadded: true,
         onRender: (item: GoodPractice) => (
            <LinkButton
               text={i18next.t('ObservationList.View')}
               onClick={e => {
                  e.preventDefault();
                  handleRowClick(item);
               }}
            />
         )
      }
   ];
};
