import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IVirtualTableColumn } from 'components/layout/VirtualTable/Table/components/VirtualTable';
import { VirtualTable } from 'components/layout/VirtualTable/Table';
import { StyledNoResults } from '../styled/GoodPracticeListTable.styled';
import { getGoodPracticeListTableColumns } from '../utils/getGoodPracticeListTableColumns';
import { CopyListAndSort } from 'shared/utils/sorting.utils';
import { viewAmendGoodPracticeRoute } from 'sections/good-practice-management/routes/good-practice-management.routes';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import { GoodPractice } from '../../../queries/good-practice-list.queries.types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

interface GoodPracticeTableProps {
   data?: GoodPractice[];
}

const GoodPracticeListTable = ({ data = [] }: GoodPracticeTableProps) => {
   const history = useHistory();
   const { t } = useTranslation();

   const displayDataRef = useRef<GoodPractice[]>([]);
   const [displayData, setDisplayData] = useState<GoodPractice[]>([]);
   const [displayColumns, setDisplayColumns] = useState<IVirtualTableColumn<GoodPractice>[]>(prepairTableColumns());

   function prepairTableColumns(): IVirtualTableColumn<GoodPractice>[] {
      let columns = getGoodPracticeListTableColumns(handleColumnClick, handleRowClick);
      columns.forEach((column: IVirtualTableColumn<GoodPractice>) => {
         column.name = t(column.name);
      });
      return columns;
   }

   useEffect(() => {
      setDisplayData(
         data.sort((a: GoodPractice, b: GoodPractice) => {
            const sortValueA = a.incidentDate;
            const sortValueB = b.incidentDate;

            return (true ? sortValueA < sortValueB : sortValueA > sortValueB) ? 1 : -1;
         })
      );
      displayDataRef.current = data;
   }, [data]);

   useEffect(() => {
      let newColumns = prepairTableColumns();
      setDisplayColumns(newColumns);
   }, [i18next.language]);

   function handleColumnClick(ev: React.MouseEvent<HTMLElement>, column: IVirtualTableColumn<GoodPractice>): void {
      const newColumns: IVirtualTableColumn<GoodPractice>[] = [...displayColumns];

      const selectedColumn: IVirtualTableColumn<GoodPractice> = newColumns.filter(
         currCol => column.key === currCol.key
      )[0];

      newColumns.forEach((column: IVirtualTableColumn<GoodPractice>) => {
         if (column === selectedColumn) {
            selectedColumn.isSortedDescending = !selectedColumn.isSortedDescending;
            selectedColumn.isSorted = true;
         } else {
            column.isSorted = false;
         }
      });

      let newList = CopyListAndSort(
         displayDataRef.current,
         selectedColumn.fieldName!,
         selectedColumn.isSortedDescending,
         selectedColumn.sortValueResolver
      );
      setDisplayColumns(newColumns);
      setDisplayData(newList);
   }

   function handleRowClick(item: GoodPractice) {      
       history.push(viewAmendGoodPracticeRoute(item.observationId.toString()));
   }

   return (
      <React.Fragment>
         {(isNullOrUndefined(data) || data.length === 0) && (
            <StyledNoResults>No records found for the currently selected filters</StyledNoResults>
         )}

         {!isNullOrUndefined(data) && data.length > 0 && (
            <VirtualTable<GoodPractice>
               columns={displayColumns}
               items={displayData}
               onRowClick={item => history.push(viewAmendGoodPracticeRoute(item?.observationId.toString()))}
               keyFieldName='observationId'
            />
         )}
      </React.Fragment>
   );
};

export default GoodPracticeListTable;
