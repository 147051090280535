import { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { StyledGoodPracticeListContainer } from './styled/GoodPracticeList.styled';
import {
   GoodPractice,
   GoodPracticeListQuery,
   GoodPracticeListResponse
} from './queries/good-practice-list.queries.types';

import { GetGoodPracticeList } from './queries/good-practice-list.queries';
import GoodPracticeListHeader from './components/GoodPracticeListHeader';
import GoodPracticeFilterBar from './components/GoodPracticeListFilterBar';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import Loading from 'components/layout/Loading';
import { ApolloError } from 'apollo-client';
import { ObservationStatusEnum } from 'shared/enums/ObservationStatus.enum';
import GoodPracticeListTable from './components/GoodPracticeListTable';
import { useTranslation } from 'react-i18next';

export const GoodPracticeList = () => {
   const client = useApolloClient();
   const [loading, setLoading] = useState<boolean>(true);
   const [loadingError, setLoadingError] = useState<ApolloError>();
   const [filterDataLoaded, setFilterDataLoaded] = useState<boolean>(false);
   const { t } = useTranslation();

   const [showFilterBar, setShowFilterBar] = useState<boolean>(false);

   const defaultFilter: GoodPracticeListQuery = {
      dateFrom: undefined,
      dateTo: undefined,
      observationCategoryId: [],
      observationStatus: [ObservationStatusEnum.Open, ObservationStatusEnum.Closed],
      depotPcId: [],
      divisionId: [],
      regionId: [],
      sHEQManagerId: []
   };

   const [goodPracticeRequest, setGoodPracticeRequest] = useState<GoodPracticeListQuery>(defaultFilter);

   const [goodPracticeData, setGoodPracticeData] = useState<GoodPractice[]>();

   const toggleFilterBar = () => {
      setShowFilterBar(!showFilterBar);
   };

   useEffect(() => {
      loadGoodPracticeData();
   }, [goodPracticeRequest]);

   const resetGoodPracticeRequest = () => {
      setGoodPracticeRequest(defaultFilter);
   };

   const loadGoodPracticeData = async () => {
      setLoading(true);

      try {
         let result = await client.query<GoodPracticeListResponse>({
            query: GetGoodPracticeList,
            variables: {
               request: goodPracticeRequest
            },
            fetchPolicy: 'network-only'
         });

         if (result?.data?.goodPracticeRecords != null) {
            setGoodPracticeData(result.data.goodPracticeRecords);
         } else {
            setGoodPracticeData(undefined);
         }

         result?.data?.goodPracticeRecords.forEach(data => {
            if (data.offSiteLocation != null) {
               data.offSiteLocationDescription = data.offSiteLocation.locationDescription;
               data.offSiteLocationCustomerSite = data.offSiteLocation.customerSiteName;
            } else {
               data.offSiteLocationDescription = ' ';
               data.offSiteLocationCustomerSite = ' ';
            }

            if (data.depotPcName == null) {
               data.depotPcName = ' ';
            }
         });

         if (filterDataLoaded === false) {
            setFilterDataLoaded(true);
         }
      } catch (e: any) {
         setLoadingError(e);
      } finally {
         setLoading(false);
      }
   };

   return (
      <StyledGoodPracticeListContainer>
         <GoodPracticeListHeader toggleFilterBar={toggleFilterBar} />
         <GoodPracticeFilterBar
            showFilterBar={showFilterBar}
            toggleFilterBar={toggleFilterBar}
            goodPracticeData={goodPracticeData}
            filterDataLoaded={filterDataLoaded}
            filterRequest={goodPracticeRequest}
            setFilterRequest={setGoodPracticeRequest}
            resetFilter={resetGoodPracticeRequest}
         />

         <GraphQlErrorBoundary error={loadingError}>
            <Loading isLoading={loading} message={t('ObservationList.LoadingGoodPracticeReports')}>
               <GoodPracticeListTable data={goodPracticeData} />
            </Loading>
         </GraphQlErrorBoundary>
      </StyledGoodPracticeListContainer>
   );
};
