export enum ObservationListFilterEnum {
    NearMissDateFrom = 'NearMissDateFrom',
    NearMissDateTo = 'NearMissDateTo',
    NearMissConcernLevel = 'NearMissConcernLevel',
    NearMissCategoryId = 'NearMissCategoryId',
    NearMissStatus = 'NearMissStatus',
    NearMissRegionIds = 'NearMissRegionIds',
    NearMissDivisionIds = 'NearMissDivisionIds',
    NearMissDepotPcId = 'NearMissDepotPcId',
    NearMissManagerIds = 'NearMissManagerIds',
    GoodPracticeDateFrom = 'GoodPracticeDateFrom',
    GoodPracticeDateTo = 'GoodPracticeDateTo',
    GoodPracticeCategoryId = 'GoodPracticeCategoryId',
    GoodPracticeStatus = 'GoodPracticeStatus',
    GoodPracticeRegionIds = 'GoodPracticeRegionIds',
    GoodPracticeDivisionIds = 'GoodPracticeDivisionIds',
    GoodPracticeDepotPcId = 'GoodPracticeDepotPcId',
    GoodPracticeManagerIds = 'GoodPracticeManagerIds'
}