import Button from 'components/controls/Button';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { FormFieldData, useForm } from 'modules/forms';
import { usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import { useCallback, useState } from 'react';
import { ObservationStatusEnum } from 'shared/enums/ObservationStatus.enum';
import { GoodPracticeEntity } from 'shared/types/domain/GoodPracticeEntity';
import { GoodPractice } from '../../../queries/view-good-practice.queries.types';
import {
   StyledActionsContainer,
   StyledObservationHeader,
   StyledActionsRow,
   StyledActionsCol
} from 'shared/styled/ObservationHeader.styled';
import CompleteDialog from './CompleteDialog';
import { IFormIsValidFunction } from 'modules/forms/types/IFormIsValidFunction';
import { Prompt } from 'react-router-dom';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { FileStatus } from 'shared/enums/FileStatus.enum';
import { AmendGoodPracticeLoadingState } from '../../../enums/amend-good-practice-loading-state.enum';
import { onLeaveGoodPracticeMessage } from 'shared/constants/onLeaveInProgressMessages';
import { ConfirmationDialog } from 'components/layout/Dialog';
import { useTranslation } from 'react-i18next';

interface GoodPracticeHeaderProps {
   goodPractice: GoodPractice | undefined;
   onSave: (
      formData: FormFieldData<GoodPracticeEntity> | undefined,
      fileData: ObservationFileEntity[] | undefined,
      isValid: IFormIsValidFunction
   ) => void;
   onComplete: () => void;
   onReOpen: () => void;
   setLoadingState: (loadingMessage: AmendGoodPracticeLoadingState, isLoading: boolean) => void;
   isLoading: boolean;
   files: ObservationFileEntity[];
}

const GoodPracticeHeader = ({
   goodPractice,
   onSave,
   onComplete,
   onReOpen,
   setLoadingState,
   isLoading,
   files
}: GoodPracticeHeaderProps) => {
   const getTitle = (): string => {
      if (goodPractice?.whatHappened) {
         if (goodPractice.whatHappened.length > 100) {
            return `${goodPractice.whatHappened.substring(0, 100)}...`;
         }

         return goodPractice.whatHappened;
      }

      return 'Good Practice Loading...';
   };

   const { t } = useTranslation();
   const [completeFormOpen, setCompleteFormOpen] = useState<boolean>(false);
   const [showReOpenForm, setShowReOpenForm] = useState<boolean>(false);

   const { formData, isValid } = useForm<GoodPracticeEntity>();
   const { hasPermission } = usePermissions();

   const hasChanges = useCallback((): boolean => {
      var dataChanged =
         goodPractice?.observationCategoryId !== formData?.observationCategoryId ||
         goodPractice?.locationType != formData?.locationType ||
         goodPractice?.depotPcId !== formData?.depotPcId ||
         goodPractice?.offSiteLocation?.customerSiteName != formData?.customerSiteName ||
         goodPractice?.offSiteLocation?.locationDescription != formData?.locationDescription ||
         goodPractice?.offSiteLocation?.locationLatitude != formData?.locationLatitude ||
         goodPractice?.offSiteLocation?.locationLongitude != formData?.locationLongitude ||
         goodPractice?.whatHappened !== formData?.whatHappened ||
         goodPractice?.whoShouldBeCommended !== formData?.whoShouldBeCommended ||
         goodPractice?.reporterType !== formData?.reporterType ||
         goodPractice?.anonymousEntry !== formData?.anonymousEntry ||
         goodPractice?.reportedBy?.reporterContactName !== formData?.reporterContactName ||
         goodPractice?.reportedBy?.reporterPhoneNumber !== formData?.reporterPhoneNumber ||
         goodPractice?.reportedBy?.reporterEmail !== formData?.reporterEmail ||
         files?.filter(f => f.fileStatus === FileStatus.Removed).length > 0 ||
         goodPractice?.incidentDate !== formData?.incidentDate;

      if (dataChanged) {
         window.onbeforeunload = () => true;
      } else {
         window.onbeforeunload = null;
      }
      return dataChanged;
   }, [goodPractice, formData, files]);

   const canSave = useCallback((): boolean => {
      return (
         isStillOpen(goodPractice) &&
         (hasPermission(WriteOn(UserPermission.ExistingObservation)) ||
            hasPermission(WriteOn(UserPermission.IncidentDate)) ||
            hasPermission(WriteOn(UserPermission.ObservationReporter)))
      );
   }, [formData, goodPractice, hasPermission]);

   const canComplete = useCallback((): boolean => {
      return isStillOpen(goodPractice) && hasPermission(WriteOn(UserPermission.Completion));
   }, [formData, goodPractice, hasPermission]);

   const canReOpen = useCallback((): boolean => {
      return !isStillOpen(goodPractice) && hasPermission(WriteOn(UserPermission.ReOpen));
   }, [formData, goodPractice, hasPermission]);

   const saveGoodPractice = (): void => {
      onSave(formData, files, isValid);
   };

   const completeGoodPractice = (): void => {
      setCompleteFormOpen(false);
      onComplete();
   };

   const reOpenGoodPractice = (): void => {
      setShowReOpenForm(false);
      onReOpen();
   };

   const closeAndResetCompletionForm = (): void => {
      setCompleteFormOpen(false);
   };

   const reOpenText = useCallback((): string => {
      if (goodPractice?.observationStatus == ObservationStatusEnum.Archived) return t('ObservationPage.Unarchive');
      if (goodPractice?.observationStatus == ObservationStatusEnum.Closed) return t('ObservationPage.Reopen');

      return '';
   }, [goodPractice]);

   const reOpenConfirmationText = useCallback((): string => {
      if (goodPractice?.observationStatus == ObservationStatusEnum.Archived)
         return t('ObservationPage.RetrievingArchiveReopened');
      if (goodPractice?.observationStatus == ObservationStatusEnum.Closed)
         return t('ObservationPage.SureReopenGoodPractice');
      return '';
   }, [goodPractice]);

   return (
      <StyledObservationHeader>
         <Prompt when={hasChanges()} message={t(onLeaveGoodPracticeMessage)} />
         <h1>{getTitle()}</h1>
         <StyledActionsContainer fluid>
            <StyledActionsRow>
               <StyledActionsCol xs={12} style={{ justifyContent: 'flex-end' }}>
                  <ConditionalRender condition={canSave}>
                     <Button
                        isLoading={isLoading}
                        text={t('ObservationPage.SaveChanges')}
                        onClick={saveGoodPractice}
                        iconName='Save'
                        disabled={!(isValid(false) && hasChanges())}
                     />
                  </ConditionalRender>

                  <ConditionalRender condition={canComplete}>
                     <CompleteDialog
                        goodPractice={goodPractice}
                        isOpen={completeFormOpen}
                        setLoadingState={setLoadingState}
                        onDismiss={closeAndResetCompletionForm}
                        onComplete={completeGoodPractice}
                     />

                     <Button
                        style={{ marginLeft: '15px' }}
                        isLoading={isLoading}
                        text={t('CompleteObservation.CompleteAndAction')}
                        onClick={() => setCompleteFormOpen(true)}
                        iconName='CheckMark'
                        disabled={hasChanges()}
                     />
                  </ConditionalRender>

                  <ConditionalRender condition={canReOpen}>
                     <ConfirmationDialog
                        show={showReOpenForm}
                        hidden={true}
                        title={`${reOpenText()} ${t('OverviewPage.GoodPractice')}`}
                        subText={reOpenConfirmationText()}
                        onDecline={() => setShowReOpenForm(false)}
                        onAccept={reOpenGoodPractice}
                     />

                     <Button
                        style={{ marginLeft: '15px' }}
                        isLoading={isLoading}
                        text={reOpenText()}
                        onClick={() => setShowReOpenForm(true)}
                        iconName='Undo'
                        disabled={hasChanges()}
                     />
                  </ConditionalRender>
               </StyledActionsCol>
            </StyledActionsRow>
         </StyledActionsContainer>
      </StyledObservationHeader>
   );
};

export default GoodPracticeHeader;

function isStillOpen(goodPractice: GoodPractice | undefined): boolean {
   return goodPractice?.observationStatus == ObservationStatusEnum.Open;
}
