import { useApolloClient } from '@apollo/react-hooks';
import { IDropdownOption } from '@fluentui/react';
import { ApolloError } from 'apollo-client';
import TextInput from 'components/controls/TextInput';
import ToggleGroup from 'components/controls/ToggleGroup';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { anonimityOptions } from 'components/ObservationReporter/constants/observation-reporter.constants';
import { GetObservationReporterTypeLookups } from 'components/ObservationReporter/queries/observation-reporter.queries';
import {
   IObservationReporterDetails,
   IObservationReporterLookupData,
   IObservationReporterType
} from 'components/ObservationReporter/queries/observation-reporter.queries.types';
import { useForm } from 'modules/forms';
import { useEffect, useState } from 'react';
import { ObservationReporterTypeEnum } from 'shared/enums/ObservationReporterType.enum';
import { StyledControlContainer } from 'shared/styled/control.styled';
import { StyledPanelTitle } from 'shared/styled/panel.stayled';
import { NearMissEntity } from 'shared/types/domain/NearMissEntity';
import { mapToDropdownOptions } from 'shared/utils/mapping.utils';
import { useSecurity } from 'modules/security';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import { useTranslation } from 'react-i18next';

interface NearMissReporterProps {
   nearMissReadOnly: boolean;
   reporterReadOnly: boolean;
}

const NearMissReporter = ({ reporterReadOnly, nearMissReadOnly }: NearMissReporterProps) => {
   const { formData, updateFields, formErrors } = useForm<NearMissEntity>();
   const [loadingReporterTypes, setLoadingReporterTypes] = useState<boolean>(true);
   const [reporterTypes, setReporterTypes] = useState<IObservationReporterType[]>();

   const client = useApolloClient();
   const { t } = useTranslation();

   const [loadingReporterTypesError, setLoadingReporterTypesError] = useState<ApolloError>();
   const { user } = useSecurity();
   const [observationReporterDetails, setObservationReporterDetails] = useState<IObservationReporterDetails>();

   useEffect(() => {
      loadReporterTypes();
   }, []);

   const loadReporterTypes = async () => {
      setLoadingReporterTypes(true);

      try {
         let result = await client.query<IObservationReporterLookupData>({
            query: GetObservationReporterTypeLookups,
            variables: {
               empId: parseInt(user?.employeeId!)
            },
            fetchPolicy: 'network-only'
         });

         if (result?.data?.nearMissReporters != null) {
            setReporterTypes(result.data.nearMissReporters);
            setObservationReporterDetails(result.data.observationReporterDetails);
            if (isNullOrUndefined(formData?.nearMissId)) {
               updateFields({
                  reporterContactName: {
                     runValidation: true,
                     value: result.data.observationReporterDetails?.displayName!
                  }
               });
               updateFields({
                  reporterPhoneNumber: {
                     runValidation: true,
                     value:
                        result.data.observationReporterDetails?.businessPhones!.length > 0
                           ? result.data.observationReporterDetails.businessPhones[0]
                           : null
                  }
               });
               updateFields({
                  reporterEmail: { runValidation: true, value: result.data.observationReporterDetails?.email! }
               });
            }
         }
      } catch (e: any) {
         setLoadingReporterTypesError(e);
      } finally {
         setLoadingReporterTypes(false);
      }
   };

   const getAnonymousType = (selectedOption: boolean | null) => {
      if (selectedOption === true) {
         return 1;
      } else if (selectedOption === false) {
         return 0;
      }
      return 2;
   };

   return (
      <>
         <StyledPanelTitle>{t('ObservationPage.WhoIsReportingNearMiss')}</StyledPanelTitle>
         <StyledControlContainer>
            <ToggleGroup
               label={t('ObservationPage.AreYouA')}
               options={mapToDropdownOptions<IObservationReporterType, IDropdownOption>(
                  reporterTypes,
                  type => type.name,
                  type => t(`ObservationPage.${type.description}`)
               )}
               selectedKey={formData?.reporterType}
               onChange={v =>
                  updateFields({
                     reporterType: { runValidation: false, value: v as ObservationReporterTypeEnum }
                  })
               }
               isLoading={loadingReporterTypes}
               required
               disabled={nearMissReadOnly}
            />
         </StyledControlContainer>

         <StyledControlContainer>
            <ToggleGroup
               label={t('ObservationPage.ReportAnonymously')}
               options={anonimityOptions}
               selectedKey={getAnonymousType(formData?.anonymousEntry)}
               onChange={v =>
                  updateFields({
                     anonymousEntry: { runValidation: false, value: !!v }
                  })
               }
               required
               disabled={nearMissReadOnly}
            />
         </StyledControlContainer>

         <ConditionalRender condition={!getAnonymousType(formData?.anonymousEntry)}>
            <StyledControlContainer>
               <StyledPanelTitle>{t('ObservationPage.ContactDetails')}</StyledPanelTitle>
               <TextInput
                  label={t('ObservationPage.YourName')}
                  value={formData?.reporterContactName}
                  errorMessage={formErrors?.reporterContactName}
                  onChange={(e, v) =>
                     updateFields({
                        reporterContactName: { runValidation: true, value: v }
                     })
                  }
                  required
                  disabled={reporterReadOnly}
               />
               <TextInput
                  label={t('ObservationPage.ContactPhone')}
                  value={formData?.reporterPhoneNumber}
                  errorMessage={formErrors?.reporterPhoneNumber}
                  onChange={(e, v) =>
                     updateFields({
                        reporterPhoneNumber: { runValidation: true, value: v }
                     })
                  }
                  required
                  disabled={reporterReadOnly}
               />
               <TextInput
                  label={t('ObservationPage.ContactEmail')}
                  value={formData?.reporterEmail}
                  errorMessage={formErrors?.reporterEmail}
                  onChange={(e, v) =>
                     updateFields({
                        reporterEmail: { runValidation: true, value: v }
                     })
                  }
                  required
                  disabled={reporterReadOnly}
               />
            </StyledControlContainer>
         </ConditionalRender>
      </>
   );
};

export default NearMissReporter;
