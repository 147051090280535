import React, { CSSProperties } from 'react';
import { PrimaryButton, IContextualMenuItem } from '@fluentui/react';
import { isNullOrUndefined } from 'shared/utils/null.utils';

export interface ButtonProps {
    text: string;
    title?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    iconName?: string;
    subMenuItems?: IContextualMenuItem[];
    disabled?: boolean;
    style?: CSSProperties;
    split?: boolean;
    isLoading?: boolean;
}

export const Button = (props: ButtonProps) => {
    return (
        <PrimaryButton
            title={props.title}
            style={props.style}
            disabled={props.disabled || props.isLoading}
            split={props.split}
            menuProps={
                !isNullOrUndefined(props.subMenuItems)
                    ? {
                          items: props.subMenuItems ?? []
                      }
                    : undefined
            }
            onClick={props.onClick}
            text={props.isLoading ? 'Processing...' : props.text}
            iconProps={{
                iconName: props.iconName
            }}
        />
    );
};
