import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import { IVirtualTableColumn } from 'components/layout/VirtualTable/Table/components/VirtualTable';
import { VirtualTable } from 'components/layout/VirtualTable/Table';
import { NearMiss } from '../../../queries/near-miss-list.queries.types';
import { StyledNoResults } from '../styled/NearMissListTable.styled';
import { viewAmmendNearMissRoute } from '../../../../../routes/near-miss-mangement.routes';
import { getNearMissListTableColumns } from '../utils/getNearMissListTableColumns';
import { CopyListAndSort } from 'shared/utils/sorting.utils';
import { getConcernLevelNumericValue } from 'shared/utils/concern-level.utils';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

interface NearMissTableProps {
   data?: NearMiss[];
}

const NearMissListTable = ({ data = [] }: NearMissTableProps) => {
   const history = useHistory();
   const { t } = useTranslation();

   const displayDataRef = useRef<NearMiss[]>([]);
   const [displayData, setDisplayData] = useState<NearMiss[]>([]);
   const [displayColumns, setDisplayColumns] = useState<IVirtualTableColumn<NearMiss>[]>(prepairTableColumns());

   function prepairTableColumns(): IVirtualTableColumn<NearMiss>[] {
      let columns = getNearMissListTableColumns(handleColumnClick, handleRowClick);
      columns.forEach((column: IVirtualTableColumn<NearMiss>) => {
         column.name = t(column.name);
      });
      return columns;
   }

   useEffect(() => {
      setDisplayData(
         data.sort((a: NearMiss, b: NearMiss) => {
            const sortValueA = a.incidentDate;
            const sortValueB = b.incidentDate;

            return (true ? sortValueA < sortValueB : sortValueA > sortValueB) ? 1 : -1;
         })
      );
      displayDataRef.current = data;
   }, [data]);

   useEffect(() => {
      let newColumns = prepairTableColumns();
      setDisplayColumns(newColumns);
   }, [i18next.language]);

   function handleColumnClick(ev: React.MouseEvent<HTMLElement>, column: IVirtualTableColumn<NearMiss>): void {
      const newColumns: IVirtualTableColumn<NearMiss>[] = [...displayColumns];

      const selectedColumn: IVirtualTableColumn<NearMiss> = newColumns.filter(currCol => column.key === currCol.key)[0];

      newColumns.forEach((column: IVirtualTableColumn<NearMiss>) => {
         if (column === selectedColumn) {
            selectedColumn.isSortedDescending = !selectedColumn.isSortedDescending;
            selectedColumn.isSorted = true;
         } else {
            column.isSorted = false;
         }
      });

      let newList: NearMiss[] = [];

      if (selectedColumn.fieldName == 'concernLevel') {
         newList = SortByConcernlevel(displayDataRef.current, selectedColumn.isSortedDescending);
      } else {
         newList = CopyListAndSort(
            displayDataRef.current,
            selectedColumn.fieldName!,
            selectedColumn.isSortedDescending,
            selectedColumn.sortValueResolver
         );
      }

      setDisplayColumns(newColumns);
      setDisplayData(newList);
   }

   const SortByConcernlevel = (list: NearMiss[], isSortedDescending: boolean | undefined): NearMiss[] => {
      return list.sort((a: NearMiss, b: NearMiss) => {
         const sortValueA = getConcernLevelNumericValue(a.concernLevel);
         const sortValueB = getConcernLevelNumericValue(b.concernLevel);

         return (isSortedDescending ? sortValueA < sortValueB : sortValueA > sortValueB) ? 1 : -1;
      });
   };

   function handleRowClick(item: NearMiss) {
      history.push(viewAmmendNearMissRoute(item.observationId.toString()));
   }

   return (
      <React.Fragment>
         {(isNullOrUndefined(data) || data.length === 0) && (
            <StyledNoResults>No records found for the currently selected filters</StyledNoResults>
         )}

         {!isNullOrUndefined(data) && data.length > 0 && (
            <VirtualTable<NearMiss>
               columns={displayColumns}
               items={displayData}
               onRowClick={item => history.push(viewAmmendNearMissRoute(item?.observationId.toString()))}
               keyFieldName='observationId'
            />
         )}
      </React.Fragment>
   );
};

export default NearMissListTable;
