import { gql } from 'graphql.macro';

export const GetUserProfile = gql`
    query GetUserProfile($userId: Int!){
        user(userId: $userId)
        {
            user{
                employeeId
                displayName
                officeLocation
                email
                jobTitle
                businessPhones
            }
            profileList: profiles{
                description
                profileId
                profileName
            }
            permissionSets: userPermissionSets{
                permissionSetId
                permissionSetName
                permissionSetDescription
                permissionData{
                    displayText
                    value
                    inheritedDisplayText
                  }
            }
        }
    }
`;