import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import { theme } from 'shared/themes/aplant.theme';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import i18next from 'i18next';
import { DateTime } from 'luxon';

interface DateTimeInputProps {
   transparent?: boolean;
   borderless?: boolean;
   placeholder?: string;
   onDateTimeSelect: (date: string | null | undefined) => void;
   value?: string | null;
   restrictWidth?: boolean;
   minDate?: Date | string;
   maxDate?: Date | string;
   label?: string;
   isRequired?: boolean;
   disabled?: boolean;
   nullable?: boolean;
   errorMessage?: string;
   disableFuture?: boolean;
}

export const DateTimeInput = ({
   onDateTimeSelect,
   value,
   label,
   isRequired,
   disabled,
   errorMessage,
   disableFuture
}: DateTimeInputProps) => {
   const handleChange = (event: any) => {
      onDateTimeSelect(event.toISO());
   };

   const StyledDay = styled(PickersDay)(({ theme }) => ({}));

   const getAdapterLocale = (): string => {
      return i18next.language === 'gb' ? 'en-gb' : i18next.language;
   };

   return (
      <>
         <ConditionalRender condition={!isNullOrUndefined(label)}>
            <label className={isRequired ? 'required' : 'notRequired'} style={{ display: 'block', padding: '5px 0px' }}>
               {label}
            </label>
         </ConditionalRender>
         <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={getAdapterLocale()}>
            <Stack spacing={2} sx={{ minWidth: 305 }}>
               <DateTimePicker
                  slots={{
                     day: StyledDay
                  }}
                  onChange={handleChange}
                  defaultValue={DateTime.fromISO(value)}
                  disableFuture={disableFuture}
                  disabled={disabled}
                  slotProps={{
                     textField: { size: 'small' },
                     popper: {
                        sx: {
                           zIndex: 1000000
                        }
                     }
                  }}
               />
            </Stack>
         </LocalizationProvider>

         <ConditionalRender condition={!isNullOrUndefined(errorMessage)}>
            <div style={{ color: theme.palette.errorMessage.main, paddingTop: '5px' }}>
               <span style={{ fontSize: '12px', fontWeight: 400 }}>{errorMessage}</span>
            </div>
         </ConditionalRender>
      </>
   );
};
