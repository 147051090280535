import { IMapper } from "shared/types/IMapper";
import { isNullOrUndefined } from "shared/utils/null.utils";
import { SheqManagerEntity } from "shared/types/configuration/SheqManagerEntity";
import { ViewSheqManager } from "../queries/view-sheq-manager.queries.types";
import { DepotEntity } from "shared/types/domain/DepotEntity";

export class SheqManagerEntityMapper implements IMapper<ViewSheqManager, SheqManagerEntity> {

  public map(entity: ViewSheqManager): SheqManagerEntity | null {

    const sheqManagerData = entity?.sheqManagers[0];

    if (isNullOrUndefined(sheqManagerData)) {
      return null;
    }

    return {
      employeeId: sheqManagerData.employeeId,
      displayName: sheqManagerData.displayName,
      givenName: sheqManagerData.givenName,
      department: sheqManagerData.department,
      jobTitle: sheqManagerData.jobTitle,
      uniqueRecordId: entity.uniqueRecordId,
      managedDepots: entity.depots.map<DepotEntity>(x => {
        return {
          pcId: x.pcId,
          pc: x.pc,
          pcName: x.pcName,
          profitCentreManagerId: x.profitCentreManagerId
        };
      })
    };
  }
}