import { UserPermission } from "../enums/user-permissions.enum"
import { PermissionType } from "../enums/permission-type.enum"
import { RequiredPermission } from "../types/RequiredPermission"

export const ReadOn = (permission: UserPermission): RequiredPermission => {
    return {
        permissionKey: permission,
        permissionTypes: [PermissionType.Read]
    }
}

export const WriteOn = (permission: UserPermission): RequiredPermission => {
    return {
        permissionKey: permission,
        permissionTypes: [PermissionType.Write]
    }
}

export const DeleteOn = (permission: UserPermission): RequiredPermission => {
    return {
        permissionKey: permission,
        permissionTypes: [PermissionType.Delete]
    }
}

export const ReadAndWriteOn = (permission: UserPermission): RequiredPermission => {
    return {
        permissionKey: permission,
        permissionTypes: [PermissionType.Read, PermissionType.Write]
    }
}