import { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { ApolloError } from 'apollo-client';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { IDropdownOption } from '@fluentui/react';

import { ApolloErrorToastMessage } from 'modules/errors';

import Select from 'components/controls/Select';
import { ConfirmationDialog, InputDialog } from 'components/layout/Dialog';

import { StyledControlContainer } from 'shared/styled/control.styled';
import { mapToDropdownOptions } from 'shared/utils/mapping.utils';
import { GetSheqManagerLookup } from '../queries/reassign-all-depots.query';
import { SheqManagerLookupEntity, SheqManagerLookupResponse } from '../queries/reassign-all-depots.query.types';
import { SheqManagerEntity } from 'shared/types/configuration/SheqManagerEntity';
import { AmmendSheqManagerLoadingState } from '../../../enums/AmmendSheqManagerLoadingState';
import { ReassignAllDepots } from '../mutations/reassign-all-depots.mutations';
import { ReassignAllDepotsRequest } from '../mutations/reassign-all-depots.mutations.types';

interface ReassignAllDepotsDialogProps {
  sheqManager: SheqManagerEntity;
  showDialog: boolean;
  setLoadingState: (loadingMessage: AmmendSheqManagerLoadingState, isLoading: boolean) => void;
  refreshSheqManager: () => void;
  onHideDialog: () => void;
}

export const ReassignAllDepotsDialog = ({
  sheqManager,
  showDialog,
  setLoadingState,
  refreshSheqManager,
  onHideDialog
}: ReassignAllDepotsDialogProps) => {

  const client = useApolloClient();
  const { addToast } = useToasts();

  const { data, loading, error } = useQuery<SheqManagerLookupResponse>(GetSheqManagerLookup, {
    fetchPolicy: 'network-only'
  });

  const [selectedManager, setSelectedSheqManager] = useState<SheqManagerLookupEntity>();
  const [selectionError, setSelectionError] = useState<string>();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const clearAndCloseDialog = () => {
    setSelectedSheqManager(undefined);
    setSelectionError(undefined);
    onHideDialog();
  }

  const setNewSheqManagerToAssign = (employeeId: number | string | undefined) => {

    if (!data?.sheqManagers) return

    const manager = data.sheqManagers.find(x => x.employeeId == employeeId);

    if (manager) setSelectedSheqManager(manager);
  }

  const checkSelectionAndShowConfirmDialog = () => {

    if (!selectedManager) {
      setSelectionError('A new SHEQ manager must be selected to continue');
      return;
    }

    setShowConfirmDialog(true);
  };

  const handleReassignAllDepots = async () => {

    if (!selectedManager) {
      setSelectionError('A new SHEQ manager must be selected to continue');
      return;
    }

    if (sheqManager.employeeId === selectedManager.employeeId) {
      setSelectionError('Moving and receiving SHEQ managers must be different from each other');
      return;
    }

    setLoadingState(AmmendSheqManagerLoadingState.ReassigningDepots, true);

    try {
      const result = await client.mutate<{ moveSHEQManagerDepots: boolean }, ReassignAllDepotsRequest>({
        mutation: ReassignAllDepots,
        variables: {
          movingManagerEmployeeId: sheqManager.employeeId,
          receivingManagerEmployeeId: selectedManager.employeeId,
        }
      });

      if (result?.data && result?.data.moveSHEQManagerDepots) {
        onHideDialog();
        refreshSheqManager();
        addToast(`${sheqManager?.givenName}'s depots have been assigned to ${selectedManager.displayName}`, { appearance: 'success' });
      }
    } catch (err) {
      addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
    }
  }

  const allSheqManagerExceptCurrent = useCallback((): SheqManagerLookupEntity[] => {

    if (!data?.sheqManagers) return [];

    return data?.sheqManagers.filter(x => x.employeeId !== sheqManager.employeeId);

  }, [data])

  return (
    <>
      <InputDialog
        title='Re-Assign All Depots'
        subText='Select the SHEQ Manager to assign all depots to'
        show={showDialog && !showConfirmDialog}
        onCancel={clearAndCloseDialog}
        onDone={checkSelectionAndShowConfirmDialog}
        hidden
      >
        <StyledControlContainer>
          <Select
            selectedKey={selectedManager?.employeeId}
            errorMessage={error?.message || selectionError}
            onChange={(e, d) => setNewSheqManagerToAssign(d?.key)}
            options={mapToDropdownOptions<SheqManagerLookupEntity, IDropdownOption>(
              allSheqManagerExceptCurrent(),
              manager => manager.employeeId,
              manager => manager.displayName
            )}
            isLoading={loading}
          />
        </StyledControlContainer>
      </InputDialog>

      <ConfirmationDialog
        title='Confirm Re-Assign All Depots'
        subText={`Are you sure want to change the depot owner? If you make this change all depots will be moved to ${selectedManager?.displayName ?? 'the new SHEQ Owner'}`}
        show={showConfirmDialog}
        hidden
        onAccept={handleReassignAllDepots}
        onDecline={() => setShowConfirmDialog(false)}
      />
    </>
  );
};