import { CommandBarLink } from "components/layout/CommandBar";
import { usePermissions, UserPermission, WriteOn } from "modules/permissions";
import { StyledMessagedButton, StyledMessagePage, StyledMessagePageContainer, StyledMessagePageLogo, StyledMessagePageLogoContainer, StyledMessagePageText, StyledMessagePageTextContainer } from "shared/styled/message-page.styled";
import Button from "components/controls/Button";
import { useHistory } from "react-router-dom";
import { ConditionalRender } from "components/layout/ConditionalRender";
import { useTranslation } from 'react-i18next';

export const ObservationSafetyCheckHome = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { hasPermission } = usePermissions();

    const hasAccess = () => {
        return hasPermission(WriteOn(UserPermission.NewObservation));
    }

    function handleNearMissClick() {
        history.push('/nearmiss/new');
    }


    function handleGoodPracticeClick() {
        history.push('/goodpractice/new');
    }

    return (
        <StyledMessagePageContainer>
            <StyledMessagePage>
                <StyledMessagePageLogoContainer>
                    <StyledMessagePageLogo />
                </StyledMessagePageLogoContainer>
                <StyledMessagePageTextContainer>
                    <StyledMessagePageText>
                    {t('OverviewPage.Observations')}
                    </StyledMessagePageText>
                    <StyledMessagePageText>
                        {t('OverviewPage.EnsureSafeBeforeProceeding')}
                    </StyledMessagePageText>
                    <StyledMessagePageText>
                        <ConditionalRender condition={hasAccess}>
                            <StyledMessagedButton>
                                <Button text={t('OverviewPage.NearMiss')} onClick={handleNearMissClick} />
                            </StyledMessagedButton>
                            <StyledMessagedButton>
                                <Button text={t('OverviewPage.GoodPractice')} onClick={handleGoodPracticeClick} />
                            </StyledMessagedButton>
                        </ConditionalRender>
                    </StyledMessagePageText>
                </StyledMessagePageTextContainer>
            </StyledMessagePage>
        </StyledMessagePageContainer>
    );
}