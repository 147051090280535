import { useHistory } from "react-router-dom";

import { useQuery } from "@apollo/react-hooks";

import { usePermissions, UserPermission, WriteOn } from "modules/permissions";

import Button from "components/controls/Button";
import GraphQlErrorBoundary from "components/layout/ErrorBoundary";
import Loading from "components/layout/Loading";

import ConfigurationPageContainer from "sections/configuration/components/ConfigurationPageContainer";
import { StyledCommandBar, StyledHeaderText, StyledSection } from "sections/configuration/styled/configuration.styles";
import { SheqManagerListResponse } from "../queries/sheq-manager-list.query.types";
import { GetSheqManagers } from "../queries/sheq-manager-list.query";
import { SheqManagerPage, sheqManagerRouteParams } from "sections/configuration/routes/configuration-page.types";
import SheqManagerListTable from "./SheqManagerListTable";
import { useTranslation } from "react-i18next";

export const SheqManagerList = () => {

  const { hasPermission } = usePermissions();

  const { data, loading, error } = useQuery<SheqManagerListResponse>(GetSheqManagers, {
    fetchPolicy: 'network-only'
  });

  const history = useHistory();

  const goToNewSheqManager = () => {
    history.push(sheqManagerRouteParams[SheqManagerPage.NewSheqManager]());
  };

  const { t } = useTranslation();
  return (
    <ConfigurationPageContainer>
      <>
              <StyledHeaderText>{t('SheqManager.SheqManager')}</StyledHeaderText>
        <StyledCommandBar>
          <Button
            style={{ marginLeft: 'auto' }}
            onClick={goToNewSheqManager}
            iconName='AddFriend'
            text={t('SheqManager.AddSheqManager')}
            disabled={!hasPermission(WriteOn(UserPermission.DepotLinks))}
          />
        </StyledCommandBar>
        <StyledSection>
          <GraphQlErrorBoundary error={error}>
                      <Loading isLoading={loading} message={t('SheqManager.Loading')}>
              <SheqManagerListTable data={data?.sheqManagers} />
            </Loading>
          </GraphQlErrorBoundary>
        </StyledSection>
      </>
    </ConfigurationPageContainer>
  );
}