import { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { IDropdownOption } from '@fluentui/react';
import { FilterSelectV2 } from 'components/controls/Select';
import { ObservationDepotsNotLinkedToDepotManagerResponse } from 'sections/configuration/pages/Depots/pages/NewDepot/components/DepotSearchDialog/queries/depot-search.queries.types';
import { mapToDropdownOptions } from 'shared/utils/mapping.utils';
import { GetObservationDepotsNotLinkedToDepotManagers } from 'sections/configuration/pages/Depots/pages/NewDepot/components/DepotSearchDialog/queries/depot-search.queries';
import { DepotEntity } from 'shared/types/configuration/DepotEntity';
import { useTranslation } from 'react-i18next';

interface DepotSearchInputProps {
    onDepotSelect: (depot: DepotEntity) => void;
}

export const DepotSearchInput = ({
    onDepotSelect,
}: DepotSearchInputProps) => {
    const [ selectedDepot, setSelectedDepot ] = useState<DepotEntity>();
    const { data: depotData, loading } = useQuery<ObservationDepotsNotLinkedToDepotManagerResponse>(GetObservationDepotsNotLinkedToDepotManagers, {
        fetchPolicy: 'network-only'
    });
    const selectDepot = (pCID: string | number | undefined): void => {
        const depot = depotData?.observationDepotsNotLinkedToDepotManagers.find(x => x.pCID == pCID);
        if (depot) {
            setSelectedDepot(depot);
            onDepotSelect(depot)
        }
    }
    const { t } = useTranslation();
    
     return (
        <>
            <FilterSelectV2
              placeholder={t('Depot.SearchDepotNameOrNumber')}
              selectedKey={selectedDepot?.pCID}
              onChange={d => selectDepot(d?.key)}
                 options={mapToDropdownOptions<DepotEntity, IDropdownOption>(
                     depotData?.observationDepotsNotLinkedToDepotManagers.sort((a: DepotEntity, b: DepotEntity) => { return a.pC - b.pC }),
                depot => depot.pCID,
                depot => `${depot.pC} - ${depot.pcName}`
              )}
              isLoading={loading}
            />
        </>
    );
};

export default DepotSearchInput;
