import styled from 'styled-components';
import { Persona } from 'office-ui-fabric-react/lib/Persona';

export const StyledAddUserPersona = styled(Persona)`
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.palette.gray['200']};
    }
`;
