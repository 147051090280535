import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { IListBoxItem } from 'azure-devops-ui/ListBox';
import { SingleDropdown } from './SingleDropdown';
import { MultiDropdown } from './MultiDropdown';
import { IComboBoxOption } from '@fluentui/react';
import '../styled/FilterSelect.styles.css';
import { theme } from 'shared/themes/aplant.theme';
import Select from './Select';
import { isInternetExplorer } from 'shared/utils/browser.utils';
import { FilterMapper } from '../mappers/filter.mapper';
import { FilterSelectItemData } from '../types/FilterSelectItemData';
import { FilterSelectItem } from './FilterSelectItem';
import { isNullOrUndefined } from 'shared/utils/null.utils';

import { ReadOnlyField } from 'components/controls/ReadOnlyField';
import { ConditionalRender } from 'components/layout/ConditionalRender';

import { StyledLoadingMessage, StyledSelectContainer, StyledSelectOverlay } from '../styled/Select.styled';

import { ReactComponent as Logo } from 'assets/images/Sunbelt.svg';
import { usePermissions, RequiredPermission } from 'modules/permissions';
import { useTranslation } from 'react-i18next';

interface IFilterSelectProps {
   disabled?: boolean;
   label?: string;
   required?: boolean;
   options: IComboBoxOption[];
   placeholder?: string;
   errorMessage?: string;
   onChange?: (
      event: CustomEvent,
      option?: IComboBoxOption | undefined,
      index?: number | undefined,
      value?: string | undefined
   ) => void;
   selectedKey?: string | number | null;
   multiSelect?: boolean;
   transparent?: boolean;
   selectedKeys?: string[] | number[];
   hidePersona?: boolean;
   style?: CSSProperties;
   isLoading?: boolean;
   loadingMessage?: string;
   optionsError?: boolean;
   readPermissions?: RequiredPermission[];
   writePermissions?: RequiredPermission[];
   readonly?: boolean;
}

const FilterSelect: React.FC<IFilterSelectProps> = (props: IFilterSelectProps) => {
   const [opt, setOpt] = useState<IListBoxItem<FilterSelectItemData>[]>([] as IListBoxItem<FilterSelectItemData>[]);
   const [selectedIndex, setSelectedIndex] = useState<number>(-1);
   const required = 'label requiredLabel';
   const notRequired = 'label';
   const [showIndex, setShowIndex] = useState<string>('');
   const [parseTo, setParseTo] = useState<string | null>(null);
   const { hasPermissions } = usePermissions();

   const { t } = useTranslation();
   const placeholder = props.optionsError ? 'Could not load data...' : t('Select.SelectAnOption');

   function buildOptions(): void {
      if (props.hidePersona) {
         setOpt(props.options as IListBoxItem<FilterSelectItemData>[]);
         return;
      }

      setParseTo(typeof props.options[0]?.key);
      const mapper = new FilterMapper();
      const tmpOps = mapper.mapArr(props.options);

      for (const u of tmpOps) {
         u.render = () => {
            return <FilterSelectItem key={u.id} item={u} showIndex={showIndex} setShowIndex={id => setShowIndex(id)} />;
         };
      }
      setOpt(tmpOps);
   }

   useEffect(() => {
      let index = -1;
      if (!props.multiSelect) {
         buildOptions();
      }

      if (props.selectedKey) {
         index = props.options.findIndex(x => x.key === props.selectedKey);
      }

      setSelectedIndex(index);

      // TODO: Fix dependencies
      // eslint-disable-next-line
   }, [props.selectedKey, props.options, showIndex]);

   function parseIntString(item: string | number): string | number {
      let key: string | number = '';

      if (parseTo !== null) {
         switch (parseTo) {
            case 'number':
               key = parseInt(item.toString());
               break;
            case 'string':
               key = item.toString();
               break;
            default:
               key = item;
               break;
         }
      }

      return key;
   }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   function handleSelectedItem(event: any, item: any) {
      if (props.onChange) {
         if (!isNullOrUndefined(item)) {
            if (parseTo !== null) {
               item.id = parseIntString(item.id);
            }
            let returnObj = item;
            if (!item.key) {
               returnObj = { key: item.id, text: item.text ?? '' };
            }
            props.onChange(event, returnObj);
         }
      }
   }

   const getValue = useCallback((): string => {
      const value = props.options.find(x => x.key === props.selectedKey);

      return hasPermissions(props.readPermissions) ? value?.text ?? '' : '************';
   }, [props.options, props.selectedKey, hasPermissions, props.readPermissions]);

   return (
      <div style={props.style}>
         <ConditionalRender condition={!isNullOrUndefined(props.label)}>
            <label className={props.required ? required : notRequired}>{props.label}</label>
         </ConditionalRender>
         <StyledSelectContainer>
            {props.isLoading && !props.optionsError && (
               <StyledSelectOverlay>
                  <Logo
                     style={{
                        animation: 'spin 3s linear infinite',
                        height: '22px',
                        minHeight: '22px',
                        minWidth: '22px',
                        width: '22px'
                     }}
                  />
                  {props.loadingMessage && <StyledLoadingMessage>{props.loadingMessage}...</StyledLoadingMessage>}
               </StyledSelectOverlay>
            )}

            {props.readonly && <ReadOnlyField text={getValue()} />}

            {!props.readonly && !hasPermissions(props.writePermissions) && <ReadOnlyField text={getValue()} locked />}

            {!props.readonly && hasPermissions(props.writePermissions) && (
               <div style={{ flex: 1 }} className={props.transparent ? 'transparent' : ''}>
                  {props.multiSelect ? (
                     isInternetExplorer() ? (
                        <Select
                           style={{ maxWidth: '100%' }}
                           options={props.options}
                           transparent={props.transparent}
                           multiSelect
                           placeholder={(!props.optionsError && props.placeholder) || placeholder}
                           selectedKeys={props.selectedKeys}
                           onChange={handleSelectedItem}
                           disabled={props.disabled || props.optionsError}
                        />
                     ) : (
                        <MultiDropdown
                           placeholder={(!props.optionsError && props.placeholder) || placeholder}
                           options={props.options as IListBoxItem<FilterSelectItemData>[]}
                           onSelect={handleSelectedItem}
                           disabled={props.disabled || props.optionsError}
                           selectedIndexes={props.selectedKeys}
                           errorMsg={props.errorMessage}
                        />
                     )
                  ) : (
                     <SingleDropdown
                        placeholder={(!props.optionsError && props.placeholder) || placeholder}
                        options={opt}
                        onSelect={handleSelectedItem}
                        disabled={props.disabled || props.optionsError}
                        selectedIndex={selectedIndex}
                        errorMsg={props.errorMessage}
                        onCollapse={() => setShowIndex('')}
                     />
                  )}
               </div>
            )}
         </StyledSelectContainer>
         <ConditionalRender condition={!isNullOrUndefined(props.errorMessage)}>
            <div style={{ color: theme.palette.errorMessage.main, paddingTop: '5px' }}>
               <span style={{ fontSize: '12px', fontWeight: 400 }}>{props.errorMessage}</span>
            </div>
         </ConditionalRender>
      </div>
   );
};

export { FilterSelect };
