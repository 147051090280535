import { isNullOrEmpty, isNullOrUndefined } from 'shared/utils/null.utils';
import { ValidationResultCreator } from '../classes/ValidationResultCreator';
import { validationRegexTypes } from '../constants/validation-regex.constants';
import { IValidationRule } from '../types/IValidationRule';
import i18next from 'i18next';

export enum ValidationRule {
    Required = 'required',
    NotEmpty = 'notEmpty',
    Numeric = 'numeric',
    WholeNumber = 'wholeNumber',
    Email = 'email',
    Telephone = 'telephone',
    Postcode = 'postcode',
    True = 'true',
    False = 'false',
    CompanyRegistration = 'companyRegistration',
    MaxLength = 'maxLength',
    ValueBetween = 'valueBetween',
    Min = 'min',
    Regex = 'regex',
    FutureDate = 'futureDate'
}

export const rules: IValidationRule[] = [
    {
        name: ValidationRule.Required,
        resolver: value => {
            return !isNullOrUndefined(value) && value !== ''
                ? ValidationResultCreator.pass()
                : ValidationResultCreator.fail(ValidationRule.Required, i18next.t('ObservationPage.MustSupplyValue'));
        }
    },
    {
        name: ValidationRule.NotEmpty,
        resolver: value => {
            return !isNullOrUndefined(value) && value.length > 0
                ? ValidationResultCreator.pass()
                : ValidationResultCreator.fail(ValidationRule.NotEmpty, 'You must supply at least one value');
        }
    },
    {
        name: ValidationRule.Numeric,
        resolver: value => {
            return !isNaN(value)
                ? ValidationResultCreator.pass()
                : ValidationResultCreator.fail(ValidationRule.Numeric, 'You must enter a numeric value');
        }
    },
    {
        name: ValidationRule.WholeNumber,
        resolver: value => {
            const regex = validationRegexTypes.WholeNumber;

            return isNullOrUndefined(value) || value === '' || regex.test(value)
                ? ValidationResultCreator.pass()
                : ValidationResultCreator.fail(ValidationRule.WholeNumber, 'You must enter a numeric value');
        }
    },
    {
        name: ValidationRule.Email,
        resolver: value => {
            const regex = validationRegexTypes.Email;

            return isNullOrUndefined(value) || value === '' || regex.test(value)
                ? ValidationResultCreator.pass()
                : ValidationResultCreator.fail(
                    ValidationRule.Email,
                    'You must enter an email address in a valid format'
                );
        }
    },
    {
        name: ValidationRule.Postcode,
        resolver: value => {
            const regex = validationRegexTypes.Postcode;

            return isNullOrUndefined(value) || value === '' || regex.test(value)
                ? ValidationResultCreator.pass()
                : ValidationResultCreator.fail(
                    ValidationRule.Postcode,
                    'You must enter a postcode in a valid UK format'
                );
        }
    },
    {
        name: ValidationRule.Telephone,
        resolver: value => {
            const regex = validationRegexTypes.Telephone;

            return isNullOrUndefined(value) || value === '' || regex.test(value)
                ? ValidationResultCreator.pass()
                : ValidationResultCreator.fail(
                    ValidationRule.Telephone,
                    'You must enter a telephone number in a valid format'
                );
        }
    },
    {
        name: ValidationRule.True,
        resolver: value => {
            return value === true
                ? ValidationResultCreator.pass()
                : ValidationResultCreator.fail(ValidationRule.True, 'You must select this value');
        }
    },
    {
        name: ValidationRule.False,
        resolver: value => {
            return value === false
                ? ValidationResultCreator.pass()
                : ValidationResultCreator.fail(ValidationRule.False, 'You should not select this value');
        }
    },
    {
        name: ValidationRule.CompanyRegistration,
        resolver: value => {
            const regex = validationRegexTypes.CompanyRegistration;

            return isNullOrUndefined(value) || value === '' || regex.test(value)
                ? ValidationResultCreator.pass()
                : ValidationResultCreator.fail(
                    ValidationRule.CompanyRegistration,
                    'You must enter a valid company registration number'
                );
        }
    },
    {
        name: ValidationRule.MaxLength,
        resolver: (value, data: { length: number }) => {
            return isNullOrUndefined(value) || value === '' || value.length <= data.length
                ? ValidationResultCreator.pass()
                : ValidationResultCreator.fail(
                    ValidationRule.MaxLength,
                    `You cannot enter more than ${data.length} characters`
                );
        }
    },
    {
        name: ValidationRule.ValueBetween,
        resolver: (value: number, data: { max: number; min: number }) => {
            if (isNullOrUndefined(value) || isNaN(value)) {
                return ValidationResultCreator.fail(
                    ValidationRule.ValueBetween,
                    'You must enter a valid numeric value'
                );
            }

            if (value > data.max) {
                return ValidationResultCreator.fail(
                    ValidationRule.ValueBetween,
                    `You cannot enter a value greater than ${data.max}`
                );
            }

            if (value < data.min) {
                return ValidationResultCreator.fail(
                    ValidationRule.ValueBetween,
                    `You cannot enter a value less than ${data.min}`
                );
            }

            return ValidationResultCreator.pass();
        }
    },
    {
        name: ValidationRule.Min,
        resolver: (value: number, data: { min: number }) => {
            if (isNullOrUndefined(value) || isNaN(value)) {
                return ValidationResultCreator.fail(
                    ValidationRule.ValueBetween,
                    'You must enter a valid numeric value'
                );
            }

            if (value < data.min) {
                return ValidationResultCreator.fail(
                    ValidationRule.ValueBetween,
                    `You cannot enter a value less than ${data.min}`
                );
            }

            return ValidationResultCreator.pass();
        }
    },
    {
        name: ValidationRule.Regex,
        resolver: (value: string, data: { regex: RegExp }) => {
            if (isNullOrEmpty(value) || data.regex.test(value)) return ValidationResultCreator.pass();

            return ValidationResultCreator.fail(ValidationRule.Regex, i18next.t('ObservationPage.SuppliedValueNotValid'));
        }
    },
    {
        name: ValidationRule.FutureDate,
        resolver: (value) => {
            return new Date(value).getTime() < new Date().getTime()
                ? ValidationResultCreator.pass()
                : ValidationResultCreator.fail(
                    ValidationRule.FutureDate,
                    `You cannot enter a date in the future`
                );
        }
    },
];
