import { StyledToggleGroup, StyledToggleGroupContainer, StyledToggleGroupLabel } from "components/controls/ToggleGroup/styled/toggle-group.styled";
import { useForm } from "modules/forms";
import { ObservationConcernLevelEnum } from "shared/enums/ObservationConcernLevel.enum";
import { NearMissEntity } from "shared/types/domain/NearMissEntity";
import { highConcernLevelTooltip, lowConcernLevelTooltip, mediumConcernLevelTooltip } from "../constants/concern-level-toggle.constants";
import ConcernLevelButton from "./ConcernLevelButton/ConcernLevelButton";
import { useTranslation } from 'react-i18next';

interface ConcernLevelToggleProps {
    readOnly: boolean;
}

export const ConcernLevelToggle = ({
    readOnly
}: ConcernLevelToggleProps) => {

    const { formData, updateFields } = useForm<NearMissEntity>();
    const { t } = useTranslation();

    return (
        <StyledToggleGroupContainer>
            <StyledToggleGroupLabel showRequired>
                {t('ObservationPage.Levelofconcern')}
            </StyledToggleGroupLabel>
            <StyledToggleGroup>
                <ConcernLevelButton
                    forConcernLevel={ObservationConcernLevelEnum.Low}
                    text={t('ObservationPage.Low')}
                    tooltip={t(lowConcernLevelTooltip)}
                    value={formData?.concernLevel}
                    disabled={readOnly}
                    onClick={e => updateFields({
                        concernLevel: { runValidation: false, value: ObservationConcernLevelEnum.Low }
                    })}
                />
                <ConcernLevelButton
                    forConcernLevel={ObservationConcernLevelEnum.Medium}
                    text={t('ObservationPage.Medium')}
                    tooltip={t(mediumConcernLevelTooltip)}
                    value={formData?.concernLevel}
                    disabled={readOnly}
                    onClick={e => updateFields({
                        concernLevel: { runValidation: false, value: ObservationConcernLevelEnum.Medium }
                    })}
                />
                <ConcernLevelButton
                    forConcernLevel={ObservationConcernLevelEnum.High}
                    text={t('ObservationPage.High')}
                    tooltip={t(highConcernLevelTooltip)}
                    value={formData?.concernLevel}
                    disabled={readOnly}
                    onClick={e => updateFields({
                        concernLevel: { runValidation: false, value: ObservationConcernLevelEnum.High }
                    })}
                />
            </StyledToggleGroup>
        </StyledToggleGroupContainer>
    );
}