import Button from 'components/controls/Button';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { FormFieldData, useForm } from 'modules/forms';
import { usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import { useCallback, useState } from 'react';
import { ObservationStatusEnum } from 'shared/enums/ObservationStatus.enum';
import { NearMissEntity } from 'shared/types/domain/NearMissEntity';
import { NearMiss } from '../../../queries/view-near-miss.queries.types';
import {
   StyledActionsContainer,
   StyledObservationHeader,
   StyledActionsRow,
   StyledActionsCol
} from 'shared/styled/ObservationHeader.styled';
import CompleteDialog from './CompleteDialog';
import { IFormIsValidFunction } from 'modules/forms/types/IFormIsValidFunction';
import { Prompt } from 'react-router-dom';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { FileStatus } from 'shared/enums/FileStatus.enum';
import { AmmendNearMissLoadingState } from '../../../enums/ammend-near-miss-loading-state.enum';
import { onLeaveNearMissMessage } from 'shared/constants/onLeaveInProgressMessages';
import { ConfirmationDialog } from 'components/layout/Dialog';
import { IAddFiles } from 'shared/utils/files/types/IAddFiles';
import { IUpdateFiles } from 'shared/utils/files/types/IUpdateFiles';
import { IRemoveFiles } from 'shared/utils/files/types/IRemoveFiles';
import { useTranslation } from 'react-i18next';

interface NearMissHeaderProps {
   nearMiss: NearMiss | undefined;
   onSave: (
      formData: FormFieldData<NearMissEntity> | undefined,
      fileData: ObservationFileEntity[] | undefined,
      isValid: IFormIsValidFunction
   ) => void;
   onComplete: () => void;
   onReOpen: () => void;
   setLoadingState: (loadingMessage: AmmendNearMissLoadingState, isLoading: boolean) => void;
   isLoading: boolean;
   files: ObservationFileEntity[];
   handleAddFile: IAddFiles<ObservationFileEntity>;
   handleUpdateFiles: IUpdateFiles<ObservationFileEntity>;
   handleDeleteEvidence: IRemoveFiles<ObservationFileEntity>;
}

const NearMissHeader = ({
   nearMiss,
   onSave,
   onComplete,
   onReOpen,
   setLoadingState,
   isLoading,
   files,
   handleAddFile,
   handleUpdateFiles,
   handleDeleteEvidence
}: NearMissHeaderProps) => {
   const getTitle = (): string => {
      if (nearMiss?.whatHappened) {
         if (nearMiss.whatHappened.length > 100) {
            return `${nearMiss.whatHappened.substring(0, 100)}...`;
         }

         return nearMiss.whatHappened;
      }

      return 'Near Miss Loading...';
   };

   const { t } = useTranslation();
   const [completeFormOpen, setCompleteFormOpen] = useState<boolean>(false);
   const [showReOpenForm, setShowReOpenForm] = useState<boolean>(false);

   const { formData, isValid } = useForm<NearMissEntity>();
   const { hasPermission } = usePermissions();

   const hasChanges = useCallback((): boolean => {
      var dataChanged =
         nearMiss?.concernLevel !== formData?.concernLevel ||
         nearMiss?.observationCategoryId !== formData?.observationCategoryId ||
         nearMiss?.locationType != formData?.locationType ||
         nearMiss?.companyId != formData?.companyId ||
         nearMiss?.depotPcId !== formData?.depotPcId ||
         nearMiss?.offSiteLocation?.customerSiteName != formData?.customerSiteName ||
         nearMiss?.offSiteLocation?.locationDescription != formData?.locationDescription ||
         nearMiss?.offSiteLocation?.locationLatitude != formData?.locationLatitude ||
         nearMiss?.offSiteLocation?.locationLongitude != formData?.locationLongitude ||
         nearMiss?.whatHappened !== formData?.whatHappened ||
         nearMiss?.howToStop !== formData?.howToStop ||
         nearMiss?.reporterType !== formData?.reporterType ||
         nearMiss?.anonymousEntry !== formData?.anonymousEntry ||
         nearMiss?.reportedBy?.reporterContactName !== formData?.reporterContactName ||
         nearMiss?.reportedBy?.reporterPhoneNumber !== formData?.reporterPhoneNumber ||
         nearMiss?.reportedBy?.reporterEmail !== formData?.reporterEmail ||
         files?.filter(f => f.fileStatus === FileStatus.Removed && f.type?.id !== 11).length > 0 ||
         nearMiss?.incidentDate !== formData?.incidentDate;
      if (dataChanged) {
         window.onbeforeunload = () => true;
      } else {
         window.onbeforeunload = null;
      }
      return dataChanged;
   }, [nearMiss, formData, files]);

   const canSave = useCallback((): boolean => {
      return (
         isStillOpen(nearMiss) &&
         (hasPermission(WriteOn(UserPermission.ExistingObservation)) ||
            hasPermission(WriteOn(UserPermission.IncidentDate)) ||
            hasPermission(WriteOn(UserPermission.ObservationReporter)))
      );
   }, [formData, nearMiss, hasPermission]);

   const canComplete = useCallback((): boolean => {
      return isStillOpen(nearMiss) && hasPermission(WriteOn(UserPermission.Completion));
   }, [formData, nearMiss, hasPermission]);

   const canReOpen = useCallback((): boolean => {
      return !isStillOpen(nearMiss) && hasPermission(WriteOn(UserPermission.ReOpen));
   }, [formData, nearMiss, hasPermission]);

   const saveNearMiss = (): void => {
      onSave(
         formData,
         files.filter(x => x.type?.id !== 11),
         isValid
      );
   };

   const completeNearMiss = (): void => {
      setCompleteFormOpen(false);
      onComplete();
   };

   const reOpenNearMiss = (): void => {
      setShowReOpenForm(false);
      onReOpen();
   };

   const closeAndResetCompletionForm = (): void => {
      setCompleteFormOpen(false);
   };

   const reOpenText = useCallback((): string => {
      if (nearMiss?.observationStatus == ObservationStatusEnum.Archived) return t('ObservationPage.Unarchive');
      if (nearMiss?.observationStatus == ObservationStatusEnum.Closed) return t('ObservationPage.Reopen');

      return '';
   }, [nearMiss]);

   const reOpenConfirmationText = useCallback((): string => {
      if (nearMiss?.observationStatus == ObservationStatusEnum.Archived)
         return t('ObservationPage.RetrievingArchiveReopened');
      if (nearMiss?.observationStatus == ObservationStatusEnum.Closed) return t('ObservationPage.SureReopenNearMiss');
      return '';
   }, [nearMiss]);

   return (
      <StyledObservationHeader>
         <Prompt when={hasChanges()} message={t(onLeaveNearMissMessage)} />
         <h1>{getTitle()}</h1>
         <StyledActionsContainer fluid>
            <StyledActionsRow>
               <StyledActionsCol xs={12} style={{ justifyContent: 'flex-end' }}>
                  <ConditionalRender condition={canSave}>
                     <Button
                        isLoading={isLoading}
                        text={t('ObservationPage.SaveChanges')}
                        onClick={saveNearMiss}
                        iconName='Save'
                        disabled={!(isValid(false) && hasChanges())}
                     />
                  </ConditionalRender>

                  <ConditionalRender condition={canComplete}>
                     <CompleteDialog
                        nearMiss={nearMiss}
                        isOpen={completeFormOpen}
                        setLoadingState={setLoadingState}
                        onDismiss={closeAndResetCompletionForm}
                        onComplete={completeNearMiss}
                        files={files.filter(x => x.type?.id === 11)}
                        handleAddEvidence={handleAddFile}
                        handleUpdateFiles={handleUpdateFiles}
                        handleDeleteEvidence={handleDeleteEvidence}
                     />

                     <Button
                        style={{ marginLeft: '15px' }}
                        isLoading={isLoading}
                        text={t('CompleteObservation.CompleteAndAction')}
                        onClick={() => setCompleteFormOpen(true)}
                        iconName='CheckMark'
                        disabled={hasChanges()}
                     />
                  </ConditionalRender>

                  <ConditionalRender condition={canReOpen}>
                     <ConfirmationDialog
                        show={showReOpenForm}
                        hidden={true}
                        title={`${reOpenText()} ${t('OverviewPage.NearMiss')}`}
                        subText={reOpenConfirmationText()}
                        onDecline={() => setShowReOpenForm(false)}
                        onAccept={reOpenNearMiss}
                     />

                     <Button
                        style={{ marginLeft: '15px' }}
                        isLoading={isLoading}
                        text={reOpenText()}
                        onClick={() => setShowReOpenForm(true)}
                        iconName='Undo'
                        disabled={hasChanges()}
                     />
                  </ConditionalRender>
               </StyledActionsCol>
            </StyledActionsRow>
         </StyledActionsContainer>
      </StyledObservationHeader>
   );
};

export default NearMissHeader;

function isStillOpen(nearMiss: NearMiss | undefined): boolean {
   return nearMiss?.observationStatus == ObservationStatusEnum.Open;
}
