import { CommandBar, CommandBarLink, CommandBarContainer } from 'components/layout/CommandBar';
import { PageHeader } from 'components/layout/PageHeader';
import i18next from 'i18next';
import { usePermissions, UserPermission, WriteOn } from 'modules/permissions';
import { useTranslation } from 'react-i18next';

interface NearMissListHeaderProps {
   toggleFilterBar: () => void;
}

export const NearMissListHeader = ({ toggleFilterBar }: NearMissListHeaderProps) => {
   const { hasPermission } = usePermissions();
   const { t } = useTranslation();

   const items = () => {
      return hasPermission(WriteOn(UserPermission.NewObservation))
         ? [
              {
                 key: 'newObservation',
                 text: i18next.t('ObservationList.ReportNewButton'),
                 data: {
                    link: '/observation/new'
                 },
                 iconProps: { iconName: 'Add' },
                 commandBarButtonAs: CommandBarLink
              }
           ]
         : [];
   };

   return (
      <>
         <PageHeader>{t('ObservationList.NearMissTitle')}</PageHeader>

         <CommandBarContainer>
            <CommandBar
               items={items()}
               farItems={[
                  {
                     key: 'filter',
                     text: t('ObservationList.Filter'),
                     cacheKey: 'filter',
                     iconProps: { iconName: 'Filter' },
                     iconOnly: true,
                     onClick: toggleFilterBar
                  }
               ]}
            />
         </CommandBarContainer>
      </>
   );
};
