import styled from 'styled-components';

export const StyledSubHeader = styled.div`
    ${({ theme }) => `
    background: ${theme.palette.gray[200]};
    border-top: 1px solid ${theme.palette.gray[400]};
    border-bottom: 1px solid ${theme.palette.gray[400]};
    display: flex;
    padding: 0 25px;
  `};
`;

export const StyledSubHeaderSection = styled.div`
    display: flex;
    flex: 0 1 30%;
    flex-direction: column;
`;

export const StyledSubHeaderGroup = styled.div`
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    min-height: 34px;
`;

export const StyledSubHeaderControlContainer = styled.div`
    padding: 5px 25px 5px 0;
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    align-items: center;
`;

export const StyledSubHeaderLabel = styled.div`
    min-width: 70px;
    max-width: 200px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    padding-right: 10px;
`;

export const StyledSubHeaderControl = styled.div`
    flex: 1 1 auto;
`;

export const StyledSubHeaderContent = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
`;

export const StyledStatusBarUpdatedBy = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
`;

export const StyledSubHeaderTabContainer = styled.div`
    display: flex;
    align-items: flex-end;
`;