import { gql } from 'graphql.macro';

export const GetDepot = gql`
query GetObservationsDepotManagers($pcId: Int!) {
  observationsDepotManagers(pcId: $pcId) {
    uniqueRecordId
    depot {
      pC
      pCID
      pcName
      profitCentreManager
    }
    managers {
      employeeId
      givenName
      displayName
      department
      jobTitle
    }
  }
}
`;