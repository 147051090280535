import TextInput from 'components/controls/TextInput';

import { StyledSection, StyledSubHeader } from 'sections/configuration/styled/configuration.styles';
import { SheqManagerEntity } from 'shared/types/configuration/SheqManagerEntity';

interface SheqManagerDetailsProps {
    sheqManager: SheqManagerEntity;
}

export const SheqManagerDetails = ({ sheqManager }: SheqManagerDetailsProps) => {
    return (
        <StyledSection>
            <StyledSubHeader>SHEQ Manager Details</StyledSubHeader>
            <TextInput label={'Name'} onChange={() => { }} value={sheqManager.displayName} disabled={true} />

            <br />

            <TextInput label={'Department'} onChange={() => { }} value={sheqManager.department} disabled={true} />

            <br />

            <TextInput label={'Job Title'} onChange={() => { }} value={sheqManager.jobTitle} disabled={true} />
        </StyledSection>
    );
};
