import React, { useState } from 'react';
import { GoodPracticeEntity } from 'shared/types/domain/GoodPracticeEntity';
import { ApolloError } from 'apollo-client';
import { FormFieldData, FormProvider } from 'modules/forms';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { useFiles } from 'shared/utils/files/hooks/useFiles';
import { allowedMimeTypes } from 'shared/options/observation-files.options';
import { mapFileToFormData } from 'shared/utils/mapping.utils';
import { useRestClient } from 'modules/rest-client/hooks/useRestClient';
import { IFileUploadResponse } from 'shared/types/file/IFileUploadRequest';
import { ErrorListToastMessage } from 'modules/errors';
import { useToasts } from 'react-toast-notifications';
import { useApolloClient } from '@apollo/react-hooks';
import { IAppConfig, useConfig } from 'modules/config';
import { IFormIsValidFunction } from 'modules/forms/types/IFormIsValidFunction';
import { ActionResponse } from 'modules/errors/classes/ActionResponse';
import Loading from 'components/layout/Loading';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import { StyledGoodPracticeContainer } from 'sections/good-practice-management/pages/ViewAmendGoodPractice/styled/ViewAmendGoodPractice.styled';
import { isNullOrUndefined } from 'shared/utils/validation.utils';
import { createGoodPracticeOptions } from '../options/create-good-practice.options';
import {
   CreateGoodPracticeResponse,
   CreateGoodPracticeVariables
} from '../mutations/create-good-practice.mutations.types';
import { CreateGoodPractice } from '../mutations/create-good-practice.mutation';
import { CreateGoodPracticeRequestMapper } from '../mappers/CreateGoodPracticeMapper';
import NewGoodPracticeForm from './NewGoodPracticeForm';
import NewGoodPracticeSubHeader from './GoodPracticeSubHeader/components/NewGoodPracticeSubHeader';
import NewGoodPracticeHeader from './NewGoodPracticeHeader';
import { NewGoodPracticeLoadingState } from '../enums/good-practice-miss-loading-state.enum';
import { useTranslation } from 'react-i18next';

interface NewGoodPracticeMainProps {
   onComplete: () => void;
}

export const NewGoodPracticeMain = ({ onComplete }: NewGoodPracticeMainProps) => {
   const client = useApolloClient();
   const { t } = useTranslation();
   const { addToast } = useToasts();
   const [goodPracticeForm, setGoodPracticeForm] = useState<FormFieldData<GoodPracticeEntity> | undefined>();
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [loadingMessage, setLoadingMessage] = useState<string>(
      NewGoodPracticeLoadingState.loadingGoodPractice
   );
   const [loadingError, setLoadingError] = useState<ApolloError>();

   const { settings } = useConfig<IAppConfig>();
   const restClient = useRestClient<'fileUpload'>();
   const setLoadingState = (loadingMessage: NewGoodPracticeLoadingState, isLoading: boolean): void => {
      setLoadingMessage(t(loadingMessage));
      setIsLoading(isLoading);
   };

   const { files, addFiles, syncFiles, removeFilesByIds } = useFiles<ObservationFileEntity>({
      allowedMimeTypes,
      onNotAllowed: handleNotAllowedFilesAdded,
      addFile: uploadFile,
      updateFile: () => {},
      removeFile: () => {},
      maxFileUploadBytes: settings.MaxFileUploadBytes
   });

   function handleNotAllowedFilesAdded(messages: string[]): void {
      addToast(<ErrorListToastMessage errors={messages} baseMessage={t('ObservationPage.DisallowedFileTypes')} />, {
         appearance: 'warning'
      });
   }

   async function uploadFile(
      file: ObservationFileEntity,
      folderName?: string,
      data?: UID
   ): Promise<ObservationFileEntity> {
      const formData = mapFileToFormData<{ referenceId: UID | undefined }>(file, settings, folderName, {
         referenceId: data
      });

      const endpoint = restClient.getEndpoint('fileUpload');
      const result = await endpoint.post<IFileUploadResponse>({
         method: 'UploadFile',
         data: formData,
         onUploadProgress: p => {}
      });

      return { ...file, fileId: result.data.fileId };
   }

   const handleSaveChanges = async (
      formData: FormFieldData<GoodPracticeEntity> | undefined,
      fileData: ObservationFileEntity[] | undefined,
      isValid: IFormIsValidFunction
   ): Promise<ActionResponse> => {
      setLoadingState(NewGoodPracticeLoadingState.submitting, true);

      const response = await createGoodPracticeEntity(formData, isValid);

      setLoadingState(NewGoodPracticeLoadingState.submitting, false);

      if (!response.successful) {
         addToast(<ErrorListToastMessage errors={[response.error.message]} />, { appearance: 'error' });

         return response;
      }

      if (files) {
         setLoadingState(NewGoodPracticeLoadingState.uploadingFiles, true);

         const filesSynced = await syncFiles(response.data.observationId.toString(), response.data.uniqueRecordId);

         setLoadingState(NewGoodPracticeLoadingState.uploadingFiles, false);

         if (!filesSynced) {
            addToast(<ErrorListToastMessage errors={[t('ObservationPage.IssueUploadingFiles')]} />, {
               appearance: 'error'
            });

            return response;
         }
      }

      addToast(t('ObservationPage.ChangesSavedSuccessfully'), { appearance: 'success' });

      onComplete();

      return response;
   };

   const createGoodPracticeEntity = async (
      formData: FormFieldData<GoodPracticeEntity> | undefined,
      isValid: IFormIsValidFunction
   ): Promise<any> => {
      let actionResponse = {};

      if (isNullOrUndefined(formData) || !isValid(true)) {
         return {
            successful: false,
            error: {
               message: t('ObservationPage.InformationNotValidFixValidation')
            }
         };
      }

      const mapper = new CreateGoodPracticeRequestMapper();
      const requestObject = mapper.map(formData);

      try {
         const response = await client.mutate<
            { createGoodPractice: CreateGoodPracticeResponse },
            CreateGoodPracticeVariables
         >({
            mutation: CreateGoodPractice,
            variables: {
               request: requestObject
            }
         });
         actionResponse = { successful: true, data: response.data?.createGoodPractice };
      } catch (error) {
         actionResponse = {
            successful: false,
            error: {
               message: t('ObservationPage.IssueCreatingGoodPractice'),
               error: error
            }
         };
      }
      return actionResponse;
   };

   return (
      <React.Fragment>
         <Loading isLoading={isLoading} message={loadingMessage} noDelay>
            <GraphQlErrorBoundary error={loadingError}>
               <StyledGoodPracticeContainer>
                  <FormProvider<GoodPracticeEntity> options={createGoodPracticeOptions} formFields={goodPracticeForm}>
                     <NewGoodPracticeHeader onSave={handleSaveChanges} isLoading={isLoading} files={files} />
                     <NewGoodPracticeSubHeader formHasErrors={false} goToOverview={() => {}} />
                     <NewGoodPracticeForm handleAddFile={addFiles} handleDeleteFile={removeFilesByIds} />
                  </FormProvider>
               </StyledGoodPracticeContainer>
            </GraphQlErrorBoundary>
         </Loading>
      </React.Fragment>
   );
};
