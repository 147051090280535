import { useConfigRights, usePermissions } from "modules/permissions";

import { StyledAppContainer } from '../styled/AppLayout.styled'

import { ConditionalRender } from "components/layout/ConditionalRender";

import Header from './Header';
import Main from './Main';
import SideMenu from './SideMenu';
import Body from './Body'
import Loading from "components/layout/Loading";
import { LoadingUserPermissions } from "../constants/app-layout.constants";

interface AppLayoutProps {
    children: React.ReactElement
}

const AppLayout = ({
    children,
}: AppLayoutProps) => {

    const { initialised } = usePermissions();
    const { hasConfigRights } = useConfigRights();

    return (
        <Loading isLoading={!initialised} message={LoadingUserPermissions}>
            <StyledAppContainer>
                <Header />
                <Main>
                    <ConditionalRender condition={hasConfigRights}>
                        <SideMenu />
                    </ConditionalRender>
                    <Body>
                        {children}
                    </Body>
                </Main>
            </StyledAppContainer>
        </Loading>
    );
}

export default AppLayout;