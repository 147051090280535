import { useMemo } from 'react';
import {
   DatePicker,
   ITheme,
   ITextFieldProps,
   ITextFieldStyles,
   getTheme,
   IconButton,
   IDatePickerStrings
} from '@fluentui/react';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import { StyledDateInputErrorMessage } from '../styled/DateInput.styled';
import i18next from 'i18next';
import { DateTime } from 'luxon';

interface IDateInputProps {
   transparent?: boolean;
   borderless?: boolean;
   placeholder?: string;
   onDateSelect: (date: Date | null | undefined) => void;
   onFocus?: () => void;
   value?: string;
   restrictWidth?: boolean;
   minDate?: Date | string;
   maxDate?: Date | string;
   label?: string;
   isRequired?: boolean;
   disabled?: boolean;
   nullable?: boolean;
   errorMessage?: string;
}

const styles =
   (props: IDateInputProps, theme: ITheme) =>
   (datePickerProps: ITextFieldProps): Partial<ITextFieldStyles> => {
      return {
         fieldGroup: {
            maxWidth: props.restrictWidth ? '160px' : undefined,
            minWidth: '160px',
            background: props.transparent ? 'transparent' : undefined,
            selectors: {
               ':hover': {
                  background: props.transparent ? theme.palette.neutralLighter : undefined
               }
            }
         },
         suffix: {
            paddingLeft: '4px',
            paddingRight: '0',
            background: 'transparent'
         },
         field: {
            paddingLeft: '38px',
            paddingRight: '8px'
         }
      };
   };

const parseDate = (date: Date | string | undefined): Date | undefined => {
   if (isNullOrUndefined(date)) return date;

   if (typeof date === 'string') return new Date(date);

   return date;
};

const DateInput = (props: IDateInputProps) => {
   const theme = getTheme();

   const getDate = useMemo(() => (props.value ? new Date(props.value) : undefined), [props.value]);

   const dayPickerStringsDE: IDatePickerStrings = {
      months: [
         'Januar',
         'Februar',
         'März',
         'April',
         'Mai',
         'Juni',
         'Juli',
         'August',
         'September',
         'Oktober',
         'November',
         'Dezember'
      ],
      shortMonths: ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
      days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      goToToday: 'Gehen Sie zu heute'
   };

   const onFormatDate = (date?: Date): string => {
      if (!date) return '';

      const dt = DateTime.fromJSDate(date).setLocale(i18next.language);
      return dt.toLocaleString(DateTime.DATE_SHORT);
   };

   return (
      <>
         <DatePicker
            disabled={props.disabled}
            isRequired={props.isRequired}
            borderless={props.borderless}
            textField={{
               onRenderSuffix: props.nullable
                  ? () => (
                       <IconButton
                          iconProps={{ iconName: 'Clear' }}
                          onClick={() => props.onDateSelect(null)}
                          styles={{
                             root: {
                                color: theme.palette.neutralPrimaryAlt
                             },
                             rootHovered: {
                                background: 'transparent'
                             },
                             rootPressed: {
                                background: 'transparent'
                             },
                             icon: {
                                fontSize: '12px'
                             }
                          }}
                       />
                    )
                  : undefined,
               styles: styles(props, theme)
            }}
            formatDate={onFormatDate}
            highlightCurrentMonth
            highlightSelectedMonth
            placeholder={props.placeholder}
            onSelectDate={props.onDateSelect}
            strings={i18next.language === 'gb' ? undefined : dayPickerStringsDE}
            value={getDate}
            styles={{
               icon: {
                  left: '9px',
                  right: 'unset'
               }
            }}
            minDate={parseDate(props.minDate)}
            maxDate={parseDate(props.maxDate)}
            isMonthPickerVisible={false}
            label={props.label}
            showGoToToday={false}
            onFocus={props.onFocus}
         />
         {props.errorMessage && <StyledDateInputErrorMessage>{props.errorMessage}</StyledDateInputErrorMessage>}
      </>
   );
};

export default DateInput;
