import { IFile } from 'shared/types/file/IFile';
import { IUseFiles } from '../types/IUseFiles';
import { useState } from 'react';
import { IFileOptions } from '../types/IFileOptions';
import { isNullOrEmpty, isNullOrUndefined } from 'shared/utils/validation.utils';
import { IUpdateFileRequest } from '../types/IUpdateFileRequest';
import { FileStatus } from 'shared/enums/FileStatus.enum';

export function useFiles<TFile extends IFile>(options: IFileOptions<TFile>): IUseFiles<TFile> {
    const [fileList, setFileList] = useState<TFile[]>([]);
    const [persistedFileList, setPersistedFileList] = useState<TFile[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const maxFileSize = options?.maxFileUploadBytes ?? 20917520;

    async function setData(dataProvider: (() => Promise<TFile[] | null>) | TFile[]): Promise<void> {
        setIsLoading(true);

        let data = typeof dataProvider === 'function' ? await dataProvider() : dataProvider;

        if (isNullOrUndefined(data)) {
            setIsLoading(false);
            return;
        }

        setFileList(data);
        setPersistedFileList(data);
        setIsLoading(false);
    }

    /**
     * Adds a collection of files to the current file list.
     * @param files The files to add.
     */
    function addFiles(files: TFile[]): void {
        let newFileList = [...fileList];
        let notAllowed: string[] = [];

        files.forEach(file => {
            if (file.size > maxFileSize) {
                notAllowed.push(`${file.name} is over the 20MB file size limit`);
                return;
            }

            if (!isAllowedFileType(file)) {
                notAllowed.push(`${file.name} is not an allowed file type`);
                return;
            }

            newFileList.push(file);
        });

        setFileList(newFileList);

        if (notAllowed.length > 0 && !isNullOrUndefined(options?.onNotAllowed)) {
            options?.onNotAllowed(notAllowed);
        }
    }

    /**
     * Removes a collection of files from the current file list.
     * @param files The files to remove.
     * Note: If the removed file is new, it will remove it from the list, otherwise it will set the status as removed.
     */
    function removeFiles(files: TFile[]): void {
        let fileIdsToRemove = files.map(x => x.id);

        removeFilesByIds(fileIdsToRemove);
    }

    /**
     * Removes a collection of files from the current file list.
     * @param ids The identites of files to remove.
     * Note: If the removed file is new, it will remove it from the list, otherwise it will set the status as removed.
     */
    function removeFilesByIds(ids: string[]): void {

        let newFileList = [...fileList]
            .filter(x => !ids.includes(x.id) || x.fileStatus !== FileStatus.New)
            .map(x => {

                let newFile = { ...x };

                if (ids.includes(newFile.id)) newFile.fileStatus = FileStatus.Removed;

                return newFile;
            });
        
        setFileList(newFileList);
    }

    /**
     * Updates a collection of files in the current file list.
     * @param updates The update requests.
     */
    function updateFiles(updates: IUpdateFileRequest<TFile>[]): void {
        let newFileList: TFile[] = [...fileList];

        updates.forEach(update => {
            let existingFileIndex = fileList.findIndex(x => x.id === update.fileId);

            if (~existingFileIndex) {
                newFileList[existingFileIndex] = update.file;

                if (newFileList[existingFileIndex].fileStatus !== FileStatus.New)
                    newFileList[existingFileIndex].fileStatus = FileStatus.Updated;
            }
        });

        setFileList(newFileList);
    }

    function resetFiles(): void {
        setFileList(persistedFileList);
    }

    /**
     * Persists the changes to the file list, this will add new files, update changed files, and delete removed files.
     * @param folderName The name of the folder to upload new files to.
     */
    const syncFiles = async (folderName?: string, data?: any): Promise<boolean> => {
        const { addFile, updateFile, removeFile } = options;
        let newFileList = [...fileList];
        let wasSuccessful = true;

        if (!isNullOrUndefined(addFile)) {
            let newFiles = newFileList.filter(x => x.fileStatus === FileStatus.New);

            for (let i = 0; i < newFiles.length; i++) {
                const file = newFiles[i];

                try {
                    let fileIndex = newFileList.findIndex(x => x.id === file.id);
                    let newFile = await addFile(file, folderName, data);

                    if (~fileIndex) {
                        newFileList[fileIndex] = { ...newFile, fileStatus: FileStatus.Persisted };
                    }
                } catch (error) {
                    wasSuccessful = false;
                }
            }
        }

        if (!isNullOrUndefined(updateFile)) {
            let updatedFiles = newFileList.filter(x => x.fileStatus === FileStatus.Updated);

            for (let i = 0; i < updatedFiles.length; i++) {
                const file = updatedFiles[i];

                try {
                    let fileIndex = newFileList.findIndex(x => x.id === file.id);
                    await updateFile(file, data);

                    if (~fileIndex) {
                        newFileList[fileIndex] = { ...file, fileStatus: FileStatus.Persisted };
                    }
                } catch (error) {
                    wasSuccessful = false;
                }
            }
        }

        if (!isNullOrUndefined(removeFile)) {
            let removedFiles = newFileList.filter(x => x.fileStatus === FileStatus.Removed);

            for (let i = 0; i < removedFiles.length; i++) {
                const file = removedFiles[i];

                try {
                    let fileIndex = newFileList.findIndex(x => x.id === file.id);
                    await removeFile(file, data);

                    if (~fileIndex) {
                        newFileList = newFileList.filter(x => x.id !== file.id);
                    }
                } catch (error) {
                    wasSuccessful = false;
                }
            }
        }

        setFileList(prevState => {
            return [...newFileList];
        });
        setPersistedFileList([...newFileList]);

        return wasSuccessful;
    };

    /**
     * Returns whether the file has an allowed mime type.
     * @param file The file to check.
     */
    function isAllowedFileType(file: TFile) {
        return !isNullOrEmpty(file.mimeType) && options?.allowedMimeTypes.includes(file.mimeType);
    }

    return {
        files: fileList,
        addFiles,
        updateFiles,
        removeFiles,
        removeFilesByIds,
        syncFiles,
        setData,
        isLoading,
        resetFiles        
    };
}
