import React, { CSSProperties } from 'react';
import { IconButton as FluentIconButton, IContextualMenuItem } from '@fluentui/react';
import { isNullOrUndefined } from 'shared/utils/null.utils';

interface ButtonProps {
    title?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    iconName?: string;
    subMenuItems?: IContextualMenuItem[];
    disabled?: boolean;
    style?: CSSProperties;
    split?: boolean;
    isLoading?: boolean;
}

const IconButton = (props: ButtonProps) => {
    return (
        <FluentIconButton
            title={props.title}
            style={props.style}
            disabled={props.disabled || props.isLoading}
            split={props.split}
            menuProps={
                !isNullOrUndefined(props.subMenuItems)
                    ? {
                          items: props.subMenuItems ?? []
                      }
                    : undefined
            }
            onClick={props.onClick}
            iconProps={{
                iconName: props.iconName
            }}
        />
    );
};

export default IconButton;
