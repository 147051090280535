import { isNullOrUndefined } from "shared/utils/null.utils";
import immutableUpdate from 'immutability-helper';
import { GeneralAppSettingAction, initialiseAppSetting, resetChanges, saveChanges, setArchivePeriod, setInitialisationError, setIsLoading, setIsSaving, setValidation } from "./appsettings.actions";
import { GeneralAppSettingState } from "./appsettings.state.types";
import { cloneDeep } from "lodash";
import { GeneralAppSettingEnum } from "../enums/AppSetting.enum";

const handleSetIsLoading: ActionHandler<GeneralAppSettingState, typeof setIsLoading> = (state, action) => {
  if (isNullOrUndefined(action.payload)) return state;

  return immutableUpdate(state, {
    ui: {
      isLoading: { $set: action.payload }
    }
  });
};

const handleSetIsSaving: ActionHandler<GeneralAppSettingState, typeof setIsSaving> = (state, action) => {
  if (isNullOrUndefined(action.payload)) return state;

  return immutableUpdate(state, {
    ui: {
      isSaving: { $set: action.payload }
    }
  });
};

const handleSaveChanges: ActionHandler<GeneralAppSettingState, typeof saveChanges> = state => {
  return immutableUpdate(state, {
    ui: {
      hasChanged: { $set: false }
    },
    persistedAppSetting: { $set: cloneDeep(state.appSetting) }
  });
};

const handleResetChanges: ActionHandler<GeneralAppSettingState, typeof resetChanges> = state => {
  return immutableUpdate(state, {
    ui: {
      hasChanged: { $set: false }
    },
    appSetting: { $set: cloneDeep(state.persistedAppSetting) },
    appSettingValidation: {
      $set: {
        [GeneralAppSettingEnum.ArchivePeriod]: undefined
      }
    }
  });
};


const handleSetArchivePeriod: ActionHandler<GeneralAppSettingState, typeof setArchivePeriod> = (
  state,
  action
) => {
  return immutableUpdate(state, {
    ui: {
      hasChanged: { $set: true }
    },
    appSetting: {
      [GeneralAppSettingEnum.ArchivePeriod]: {
        value: { $set: action.payload ?? 0 }
      }
    }
  });
};


const handleSetValidation: ActionHandler<GeneralAppSettingState, typeof setValidation> = (state, action) => {
  if (isNullOrUndefined(action.payload)) return state;

  return immutableUpdate(state, {
    appSettingValidation: {
      $merge: action.payload
    }
  });
};

const handleInitialiseAppSetting: ActionHandler<GeneralAppSettingState, typeof initialiseAppSetting> = (
  state,
  action
) => {
  if (isNullOrUndefined(action.payload)) return state;

  return immutableUpdate(state, {
    appSetting: {
      [GeneralAppSettingEnum.ArchivePeriod]: {
        value: { $set: action.payload[GeneralAppSettingEnum.ArchivePeriod] }
      }
    },
    persistedAppSetting: {
      [GeneralAppSettingEnum.ArchivePeriod]: {
        value: { $set: action.payload[GeneralAppSettingEnum.ArchivePeriod] }
      }
    }
  });
};

const handleSetInitialisationError: ActionHandler<GeneralAppSettingState, typeof setInitialisationError> = (
  state,
  action
) => {
  return immutableUpdate(state, {
    errors: {
      initialisationError: { $set: action.payload }
    }
  });
};

export const generalAppSettingStateHandlers: ActionHandlerMapObject<
  GeneralAppSettingState,
  typeof GeneralAppSettingAction
> = {
  [GeneralAppSettingAction.SetIsLoading]: handleSetIsLoading,
  [GeneralAppSettingAction.SetIsSaving]: handleSetIsSaving,
  [GeneralAppSettingAction.SaveChanges]: handleSaveChanges,
  [GeneralAppSettingAction.ResetChanges]: handleResetChanges,
  [GeneralAppSettingAction.SetArchivePeriod]: handleSetArchivePeriod,
  [GeneralAppSettingAction.SetValidation]: handleSetValidation,
  [GeneralAppSettingAction.InitialiseAppSetting]: handleInitialiseAppSetting,
  [GeneralAppSettingAction.SetInitialisationError]: handleSetInitialisationError
};
