import { Persona } from "@fluentui/react";
import { IconButton } from "components/controls/Button";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { UserPage, userRouteParams } from "sections/configuration/routes/configuration-page.types";
import { theme } from "shared/themes/aplant.theme";
import { User } from "shared/types/configuration/UserModels";
import { isNullOrEmpty } from "shared/utils/null.utils";
import { StyledPersonaDiv, StylesUserListContainer } from "./user-list.styled";

interface UserListProps {
    userList: User[];
    removeUser: (user: User) => void;
}

const UserList: FunctionComponent<UserListProps> = props => {
    const history = useHistory();
    return (
        <>
            {props.userList &&
                props.userList.length > 0 &&
                props.userList.map(x => (
                    <StylesUserListContainer key={x.employeeId}>
                        <StyledPersonaDiv
                            onDoubleClick={() =>
                                history.push(
                                    userRouteParams[UserPage.ViewUser](
                                        x.userId.toString()
                                    )
                                )
                            }
                        >
                            <Persona
                                text={x.displayName}
                                onRenderSecondaryText={props => (
                                    <div>
                                        <div>{`${x.jobTitle ?? ''} ${isNullOrEmpty(x.department) ? '' : '@ ' + x.department
                                            }`}</div>
                                        <div>{x.email}</div>
                                        <div>{x.businessPhones.join()}</div>
                                    </div>
                                )}
                            />
                        </StyledPersonaDiv>
                        <div>
                            <IconButton
                                iconName={'Blocked2Solid'}
                                title={'Remove'}
                                style={{ color: theme.palette.error.main, fontSize: '25px' }}
                                onClick={() => props.removeUser(x)}
                            />
                        </div>
                    </StylesUserListContainer>
                ))}
        </>
    );
};

export default UserList;
