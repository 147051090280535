import React, { FunctionComponent, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { BasePermissionSetModel, ProfileModel } from 'shared/types/configuration/ProfileModels';
import { GetAllPermissionSets } from '../../ViewProfile/queries/view-profile-query';
import { ApolloErrorToastMessage } from 'modules/errors';
import { CreateNewProfile } from '../mutations/new-profile-mutation';
import { ConfigurationPage, configurationRouteBases } from 'sections/configuration/routes/configuration-page.types';
import { ApolloError } from 'apollo-client';
import Loading from 'components/layout/Loading';
import ProfileConfiguration from '../../ViewProfile/components/ProfileConfiguration';
import ConfigurationPageContainer from 'sections/configuration/components/ConfigurationPageContainer';

const NewProfileConfiguration: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileModel, setProfileModel] = useState<ProfileModel | undefined>(undefined);
  const client = useApolloClient();
  const { addToast } = useToasts();
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    client.query<{ applicationPermissionSets: BasePermissionSetModel[] }>({
      query: GetAllPermissionSets
    }).then(result => {

      if (result?.data?.applicationPermissionSets) {
        const permissionSetModel = result.data.applicationPermissionSets.map(x => {
          return {
            permissionSetId: x.permissionSetId,
            permissionSetName: x.permissionSetName,
            permissionSetDescription: x.permissionSetDescription,
            hasPermissionSet: false
          };
        });

        setProfileModel({
          users: [],
          profilePermissionSets: permissionSetModel,
          profile: { profileId: 0, profileName: '', description: '' },
          assignedUserCount: 0,
          usersToAdd: [],
          usersToRemove: []
        });
      }
    }).catch(err => {
      addToast(<ApolloErrorToastMessage error={err} />, { appearance: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  }, [client]);

  const saveProfile = async (profile: ProfileModel) => {
    try {
      setIsLoading(true);
      const result = await client.mutate<{ saveProfile: number }>({
        mutation: CreateNewProfile,
        variables: {
          deletedUsers: profile.usersToRemove.map(x => x.employeeId),
          newUsers: profile.usersToAdd.map(x => x.employeeId),
          profile: {
            profileName: profile.profile.profileName,
            description: profile.profile.description
          },
          permissionSets: profile.profilePermissionSets.filter(x => x.hasPermissionSet).map(x => x.permissionSetId)
        }
      });

      if (result?.data && result?.data.saveProfile > 0) {
        addToast(`${profile.profile.profileName} has been saved`, { appearance: 'success' });
        history.push(configurationRouteBases[ConfigurationPage.Profiles]);
      }
    } catch (err) {
      addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Loading isLoading={isLoading} message={'Loading'}>
      {profileModel && (
        <ConfigurationPageContainer>
          <ProfileConfiguration
            profileModel={profileModel}
            onSave={saveProfile}
            isNewProfile={true}
          />
        </ConfigurationPageContainer>
      )}
    </Loading>
  );
};

export default NewProfileConfiguration;
