import { buildRoute } from "shared/utils/route.utils";

export const baseRoute = '/configuration';

export enum ConfigurationPage {
  AppSettings = 0,
  NearMissCategory = 1,
  SheqManager = 2,
  Profiles = 3,
  Users = 4,
  Depots = 5
}

export enum NearMissCategoryPage {
  NewCategory = 1,
  ViewCategory = 0
}

export enum SheqManagerPage {
  NewSheqManager = 0,
  ViewSheqManager = 1
}

export enum ProfilePage {
  NewProfile = 0,
  ViewProfile = 1
}

export enum UserPage {
  ViewUser = 0
}

export enum DepotPage {
    NewDepot = 0,
    ViewDepot = 1
}


export const configurationRouteBases: { [key in ConfigurationPage]: string } = {
  [ConfigurationPage.AppSettings]: buildRoute(baseRoute, ['/nearmiss/AppSettings']),
  [ConfigurationPage.NearMissCategory]: buildRoute(baseRoute, ['/nearmiss/categories']),
  [ConfigurationPage.SheqManager]: buildRoute(baseRoute, ['/sheqmanagers']),
  [ConfigurationPage.Profiles]: buildRoute(baseRoute, ['/profiles']),
  [ConfigurationPage.Users]: buildRoute(baseRoute, ['/users']),
  [ConfigurationPage.Depots]: buildRoute(baseRoute, ['/depots']),
};

export const nearMissCategoryRouteParams: { [key in NearMissCategoryPage]: (id?: string) => string } = {
  [NearMissCategoryPage.NewCategory]: () => buildRoute(configurationRouteBases[ConfigurationPage.NearMissCategory], [`/New`]),
  [NearMissCategoryPage.ViewCategory]: id => buildRoute(configurationRouteBases[ConfigurationPage.NearMissCategory], [`/${id ?? ':id'}`]),
}

export const sheqManagerRouteParams: { [key in SheqManagerPage]: (id?: string) => string } = {
  [SheqManagerPage.NewSheqManager]: () => buildRoute(configurationRouteBases[ConfigurationPage.SheqManager], [`/New`]),
  [SheqManagerPage.ViewSheqManager]: id => buildRoute(configurationRouteBases[ConfigurationPage.SheqManager], [`/${id ?? ':id'}`]),
}

export const profileRouteParams: { [key in ProfilePage]: (id?: string) => string } = {
  [ProfilePage.NewProfile]: () => buildRoute(configurationRouteBases[ConfigurationPage.Profiles], [`/New`]),
  [ProfilePage.ViewProfile]: id => buildRoute(configurationRouteBases[ConfigurationPage.Profiles], [`/${id ?? ':id'}`]),
}

export const userRouteParams: { [key in UserPage]: (id?: string) => string } = {
  [UserPage.ViewUser]: id => buildRoute(configurationRouteBases[ConfigurationPage.Users], [`/${id ?? ':id'}`]),
}

export const depotRouteParams: { [key in DepotPage]: (id?: string) => string } = {
  [DepotPage.NewDepot]: () => buildRoute(configurationRouteBases[ConfigurationPage.Depots], [`/New`]),
  [DepotPage.ViewDepot]: id => buildRoute(configurationRouteBases[ConfigurationPage.Depots], [`/${id ?? ':id'}`]),
}
