import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { InputDialog } from 'components/layout/Dialog';
import { FilterSelectV2 } from 'components/controls/Select';

import { StyledControlContainer } from 'shared/styled/control.styled';
import { DepotEntity } from 'shared/types/domain/DepotEntity';

import { mapToDropdownOptions } from 'shared/utils/mapping.utils';
import { IDropdownOption } from '@fluentui/react';
import { DepotsNotLinkedToSheqManagerResponse } from 'shared/types/configuration/depot-search.queries.types';
import { GetDepotsNotLinkedToSheqManager } from 'shared/queries/depot-search.queries';

interface DepotSearchDialogProps {
    showDialog: boolean;
    onAddDepot: (depot: DepotEntity) => void;
    onHideDialog: () => void;
    includeDepots?: DepotEntity[];
    excludeDepots?: DepotEntity[];
}

export const DepotSearchDialog = ({
    showDialog,
    onAddDepot,
    onHideDialog,
    includeDepots,
    excludeDepots
}: DepotSearchDialogProps) => {

    const { data: depotData, loading } = useQuery<DepotsNotLinkedToSheqManagerResponse>(GetDepotsNotLinkedToSheqManager, {
        fetchPolicy: 'network-only'
    });

    const [selectedDepot, setSelectedDepot] = useState<DepotEntity>();
    const [depotSelectionErrorMessage, setDepotSelectionErrorMessage] = useState<string>();

    const selectDepot = (pcId: string | number | undefined): void => {

        const depot = getSortedCleansedDepotList().find(x => x.pcId == pcId);

        if (depot) {
            setSelectedDepot(depot);
        }
    }

    const hideAndResetDialog = () => {
        setSelectedDepot(undefined);
        setDepotSelectionErrorMessage(undefined);
        onHideDialog();
    }

    const onAddDepotConfirmed = () => {

        if (!selectedDepot) {
            setDepotSelectionErrorMessage('Select a depot from the list to continue');
            return;
        }

        onAddDepot(selectedDepot);
        hideAndResetDialog();
    }

    const getSortedCleansedDepotList = useCallback((): DepotEntity[] => {
        if (!depotData) return [];

        const dataWithoutExlusions = depotData.depotsNotLinkedToSHEQManager.filter(x => !excludeDepots?.any(d => d.pcId === x.pcId))

        const newDepotData = dataWithoutExlusions.concat(includeDepots ?? []);

        return newDepotData.sort((a: DepotEntity, b: DepotEntity) => {
            return a.pc - b.pc;
        });

    }, [depotData, includeDepots, excludeDepots])

    return (
        <InputDialog
            title='New Managed Depot'
            subText='Select from the list of currently un-linked depots to link to this user'
            show={showDialog}
            onDone={onAddDepotConfirmed}
            onCancel={hideAndResetDialog}
        >
            <StyledControlContainer>
                <FilterSelectV2
                    placeholder='Search Depot Name or Number'
                    selectedKey={selectedDepot?.pcId}
                    errorMessage={depotSelectionErrorMessage}
                    onChange={d => selectDepot(d?.key)}
                    options={mapToDropdownOptions<DepotEntity, IDropdownOption>(
                        getSortedCleansedDepotList(),
                        depot => depot.pcId,
                        depot => `${depot.pc} - ${depot.pcName}`
                    )}
                    isLoading={loading}
                />
            </StyledControlContainer>
        </InputDialog>
    );
};