import { IAppConfig } from 'modules/config/types/IAppConfig';
import { SecurityRoute } from '../enums/SecurityRoute.enum';

/** The auth route collection */
export const securityRoutes: { [key in SecurityRoute]: (settings?: IAppConfig) => string } = {
    [SecurityRoute.Issuer]: settings => `${settings?.OktaOrgUrl}/oauth2/${settings?.OktaAuthorisationServerId}`,
    [SecurityRoute.RedirectUrl]: () => `${window.location.origin}/implicit/callback`,
    [SecurityRoute.ImplicitCallback]: () => '/implicit/callback',
    [SecurityRoute.PostLogout]: () => '/postlogout'
};
