import { actionCreatorFactory } from 'shared/utils/redux.utils';
import { UserSearchStateActionEnum } from '../../enums/UserSearchStateAction.enum';
import { isUndefined } from 'shared/utils/validation.utils';
import { UserSearchState } from '../state.types';

export const setPage = actionCreatorFactory<typeof UserSearchStateActionEnum, number>(
    UserSearchStateActionEnum.SetPage
);

export const setPageHandler: ActionHandler<UserSearchState, typeof setPage> = (state, action) => {
    if (isUndefined(action.payload)) return state;

    const searchResults = [...state.searchResults];

    const nextPageSize =
        state.totalResultsCount - (state.currentPage + 1) * state.resultsPerPage < state.resultsPerPage
            ? state.totalResultsCount - (state.currentPage + 1) * state.resultsPerPage
            : state.resultsPerPage;

    for (let i = 0; i < nextPageSize; i++) searchResults.push(null);

    return {
        ...state,
        currentPage: action.payload,
        searchResults: searchResults
    };
};
