import React, { FunctionComponent, useRef, useState, FormEvent, useEffect } from 'react';
import { Modal } from 'components/controls/Modal';
import {
    StyledAssignUserBody,
    StyledAssignUserFlexColumn,
    StyledAssignUserFlexRow,
    StyledAssignUserFooter
} from 'sections/configuration/pages/Profile/components/AssignUser/components/AssignUser.styles';
import Button, { IconButton } from 'components/controls/Button';
import { ApplicationProfiles, ProfileListViewModel } from 'shared/types/configuration/ProfileModels';
import { useQuery } from '@apollo/react-hooks';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import GraphQlLoading from 'components/layout/Loading';
import Select from 'components/controls/Select';
import { IDropdownOption } from '@fluentui/react';
import { StyledAddUserPersona } from './AddUserModal.styles';
import { isNullOrEmpty } from 'shared/utils/validation.utils';
import { GetApplicationProfiles } from 'sections/configuration/pages/Profile/pages/ProfileList/queries/profile-list-query';
import { ApplicationUser } from '../queries/user-search.queries.types';

interface AddUserModalProps {
    isOpen: boolean;
    isSaving: boolean;
    userToAdd: ApplicationUser | undefined;
    onDismiss: () => void;
    onAdd: (userId: number, profileIds: number[]) => Promise<void>;
}

const AddUserModal: FunctionComponent<AddUserModalProps> = ({
    isOpen,
    isSaving,
    userToAdd,
    onDismiss,
    onAdd
}: AddUserModalProps) => {
    const [selectedProfiles, setSelectedProfiles] = useState<ProfileListViewModel[]>([]);
    const [errorMessages, setErrorMessages] = useState<{ userErr: string; profileErr: string }>({
        userErr: '',
        profileErr: ''
    });
    
    const modelRef = useRef<HTMLDivElement>(null);
    const { data, loading, error } = useQuery<ApplicationProfiles>(GetApplicationProfiles);

    useEffect(() => {
        if (!isOpen) {
            setErrorMessages({
                userErr: '',
                profileErr: ''
            });
            setSelectedProfiles([]);
        }
    }, [isOpen]);

    const handleProfileSelect = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
        const profiles = [...selectedProfiles];
        if (data && option?.selected) {
            profiles.push(data.applicationProfiles.find(x => x.profileId === option.key)!);
            setSelectedProfiles(profiles);
            setErrorMessages({ ...errorMessages, profileErr: '' });
            return;
        } else {
            setSelectedProfiles(profiles.filter(x => x.profileId !== option?.key)!);
            return;
        }
    };

    const addUser = async () => {
        if (!userToAdd) {
            setErrorMessages({ ...errorMessages, userErr: 'You must select a user' });
            return;
        }

        if (selectedProfiles.length === 0) {
            setErrorMessages({ ...errorMessages, profileErr: 'You must select at least one profile' });
            return;
        }

        const employeeId = userToAdd.employeeId;
        const profileIds = selectedProfiles.map(x => x.profileId);

        await onAdd(employeeId, profileIds);
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title='Add User' isBlocking={true}>
            <div ref={modelRef} style={{ display: 'flex' }}>
                <GraphQlLoading
                    isLoading={loading || isSaving}
                    message={isSaving ? 'Adding User' : 'Loading'}
                    noDelay={isSaving}
                >
                    <GraphQlErrorBoundary error={error}>
                        {data && (
                            <StyledAssignUserFlexColumn>
                                <StyledAssignUserBody>
                                    <div style={{ marginBottom: '15px' }}>
                                        {userToAdd && (
                                            
                                            <StyledAddUserPersona
                                                text={userToAdd.displayName}
                                                onRenderSecondaryText={() => (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ flex: 1 }}>
                                                            <div>{`${
                                                                isNullOrEmpty(userToAdd.serviceCentre)
                                                                    ? ''
                                                                    : '@ ' + userToAdd.serviceCentre
                                                            }`}</div>                                                            
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        )}
                                    </div>
                                    <Select
                                        label={'Select profile(s)'}
                                        options={data.applicationProfiles.map(x => {
                                            return { key: x.profileId, text: x.profileName };
                                        })}
                                        onChange={handleProfileSelect}
                                        selectedKeys={selectedProfiles.map(x => x.profileId)}
                                        multiSelect
                                        required={true}
                                        errorMessage={errorMessages.profileErr}
                                    />
                                </StyledAssignUserBody>
                                <StyledAssignUserFlexRow>
                                    <StyledAssignUserFooter>
                                        <Button text={'Add'} onClick={addUser} />
                                    </StyledAssignUserFooter>
                                </StyledAssignUserFlexRow>
                            </StyledAssignUserFlexColumn>
                        )}
                    </GraphQlErrorBoundary>
                </GraphQlLoading>
            </div>
        </Modal>
    );
};

export default AddUserModal;
