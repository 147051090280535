import { GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import Loading from "components/layout/Loading";
import { useEffect, useState } from "react";
import { LocationCoordinate } from "shared/types/location-cordinate";
import { mapContainerStyle, searchBoxStyle } from "../constants/location-map-styles";
import { useTranslation } from 'react-i18next';

interface LocationMapProps {
    apiKey: string;
    markerLocation?: LocationCoordinate | null;
    onSelectLocation: (newLocation: LocationCoordinate) => void;
    readOnly: boolean;
}

const mapLibraries: Libraries = [
    'places'
];

const defaultZoom: number = 5;
const knownLocationZoom: number = 10;
const defaultCentral: LocationCoordinate = { latitude: 55, longitude: -4 };

export const LocationMap = ({
    apiKey,
    markerLocation,
    onSelectLocation,
    readOnly
}: LocationMapProps) => {

    const [mapLoaded, setMapLoaded] = useState<boolean>(false);
    const [mapZoom, setMapZoom] = useState<number>(defaultZoom);
    const [mapCentre, setMapCentre] = useState({ lat: defaultCentral.latitude, lng: defaultCentral.latitude });

    const [mapCurrentCentre, setMapCurrentCentre] = useState<google.maps.LatLng>();

    const [mapRef, setMapRef] = useState<google.maps.Map>();
    const [searchBoxRef, setSearchBoxRef] = useState<google.maps.places.SearchBox>();

    const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey: apiKey, libraries: mapLibraries });

    const { t } = useTranslation();

    useEffect(() => {

        if (!mapLoaded) {

            setDefaultMapPosition();

            setMapLoaded(true);
        }

    }, [markerLocation, mapLoaded]);

    useEffect(() => {

        if (mapLoaded && mapCurrentCentre) {
            mapRef?.setCenter(mapCurrentCentre);
        }

    }, [markerLocation, mapCurrentCentre, mapLoaded]);

    const setDefaultMapPosition = (): void => {
        if (markerLocation) {
            setMapCentre({
                lat: markerLocation.latitude,
                lng: markerLocation.longitude
            });
            setMapZoom(knownLocationZoom);
        } else {
            setDefaultMapPositionIfUserAllows();
        }
    }

    const setDefaultMapPositionIfUserAllows = (): void => {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
            setMapCentre({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });
            setMapZoom(knownLocationZoom);
        });
    }

    const onPlacesChanged = (): void => {

        const places: google.maps.places.PlaceResult[] | undefined = searchBoxRef?.getPlaces();

        if (!places || !mapRef) return;

        const searchLatLng = places[0]?.geometry?.location;
        const searchViewport = places[0]?.geometry?.viewport;

        mapRef.setCenter(searchLatLng ?? mapRef.getCenter());

        if (searchViewport) mapRef.fitBounds(searchViewport);
    }

    const handleLocationSelection = (e: google.maps.MapMouseEvent): void => {

        const mapCentre = mapRef?.getCenter();

        if (mapCentre) setMapCurrentCentre(mapCentre);

        if (!readOnly) {
            onSelectLocation({
                latitude: e.latLng.lat(),
                longitude: e.latLng.lng()
            });
        }
    }

    return (
        <Loading isLoading={!isLoaded && !!mapZoom && !!mapCentre}>
            <GoogleMap
                onLoad={setMapRef}
                mapContainerStyle={mapContainerStyle}
                center={mapCentre}
                zoom={mapZoom}
                options={{
                    controlSize: 30,
                    fullscreenControlOptions: {
                        position: 12 // bottom-right so out the way of search bar
                    },
                    mapTypeControlOptions: {
                        position: 10
                    }
                }}
                onClick={handleLocationSelection}
            >
                <StandaloneSearchBox onLoad={setSearchBoxRef} onPlacesChanged={onPlacesChanged} >
                    <input
                        type="text"
                        placeholder={t("ObservationPage.LocationSearch")}
                        style={searchBoxStyle}
                    />
                </StandaloneSearchBox>
                {
                    markerLocation &&
                    <Marker
                        position={{
                            lat: markerLocation.latitude,
                            lng: markerLocation.longitude
                        }}
                        onDragEnd={handleLocationSelection}
                        opacity={0.8}
                        draggable={!readOnly}
                    />
                }
            </GoogleMap>
        </Loading>
    );
}