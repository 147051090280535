import { useApolloClient } from '@apollo/react-hooks';
import { IDropdownOption } from '@fluentui/react';
import { ApolloError } from 'apollo-client';
import Select from 'components/controls/Select';
import { FilterSelect } from 'components/controls/Select/components/FilterSelect';
import TextInput from 'components/controls/TextInput';
import ToggleGroup from 'components/controls/ToggleGroup';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import Loading from 'components/layout/Loading';
import { useForm } from 'modules/forms';
import { useGoogleMapsApiKey } from 'modules/mapping/hooks/useGoogleMapsApiKey';
import { UserPermission } from 'modules/permissions';
import { useState, useEffect, useCallback } from 'react';
import { ObservationLocationTypeEnum } from 'shared/enums/ObservationLocationType.enum';
import { StyledControlContainer } from 'shared/styled/control.styled';
import { StyledPanelTitle, StyledTitle } from 'shared/styled/panel.stayled';
import { ObservationCategoryEntity } from 'shared/types/domain/ObservationCategoryEntity';
import { GoodPracticeEntity } from 'shared/types/domain/GoodPracticeEntity';
import { LocationCoordinate } from 'shared/types/location-cordinate';
import { keysAreEqual } from 'shared/utils/equality.utils';
import { mapToDropdownOptions } from 'shared/utils/mapping.utils';
import { isNullOrUndefined, isUndefined } from 'shared/utils/null.utils';
import {
   GetObservationCategorisationLookups,
   GetServiceCentres
} from 'shared/queries/observation-categorisation.queries';
import {
   ICompaniesType,
   IDepot,
   IDepotsLookupData,
   IObservationCategory,
   IObservationCategoryLookupData,
   IObservationLocationType
} from 'shared/queries/observation-categorisation.queries.types';
import LocationMap from 'shared/components/LocationMap';
import { useTranslation } from 'react-i18next';
import { IAppConfig, useConfig } from 'modules/config';

interface GoodPracticeCategorisationProps {
   readOnly: boolean;
   editPermission?: UserPermission;
   selectedCategory?: ObservationCategoryEntity;
}

const GoodPracticeCategorisation = ({ readOnly, selectedCategory }: GoodPracticeCategorisationProps) => {
   const { formData, updateFields, formErrors } = useForm<GoodPracticeEntity>();

   const client = useApolloClient();
   const [loading, setLoading] = useState<boolean>(true);
   const [loadingError, setLoadingError] = useState<ApolloError>();

   const [categories, setCategories] = useState<IObservationCategory[]>();
   const [locationTypes, setLocationTypes] = useState<IObservationLocationType[]>();
   const [companies, setCompanies] = useState<ICompaniesType[]>();
   const [depots, setDepots] = useState<IDepot[]>();
   const { t, i18n } = useTranslation();
   const { apiKey, hasLoaded } = useGoogleMapsApiKey();
   const { settings } = useConfig<IAppConfig>(); 
   
   useEffect(() => {
      loadCategories();

   }, [selectedCategory]);

   const loadCategories = async () => {
      setLoading(true);
      try {
         let result = await client.query<IObservationCategoryLookupData>({
            query: GetObservationCategorisationLookups,
            fetchPolicy: 'network-only'
         });

         if (result?.data != null) {
            setCategories(addSelectedCategoryIfDisabled(result.data.nearMissCategories));
            setLocationTypes(result.data.nearMissLocationTypes);
            filterCompanies(result.data.companies);
         }
      } catch (e) {
         setLoadingError(e as ApolloError);
      } finally {
         setLoading(false);
      }
   };

   const filterCompanies = (companies: ICompaniesType[]) => {
      
      const newCompanies = [settings.addCompany, 
         ...companies.filter(x=> !settings.filterComps.any(c => c == x.companyId))]

      setCompanies(newCompanies);

      if(isNullOrUndefined(formData?.companyId))
      {
         const defaultCompany = companies?.find(x=>x.usersCompany == true)?.companyId;
         updateFields({
            companyId: { runValidation: true, value: defaultCompany }
         });
         loadServiceCentres(defaultCompany);     
      }
      else
      {
         loadServiceCentres(formData?.companyId);
      }
   }

   const loadServiceCentres = async (companyId: string | number | undefined) => {
      setLoading(true);

      try {
         let result = await client.query<IDepotsLookupData>({
            query: GetServiceCentres,
            variables: {
               companyId: companyId
            },
            fetchPolicy: 'network-only'
         });

         if (result?.data != null) {
            setDepots(result.data.depots);
         }
      } catch (e) {
         setLoadingError(e as ApolloError);
      } finally {
         setLoading(false);
      }

      updateFields({
         companyId: { runValidation: true, value: companyId }
      });
   };

   const addSelectedCategoryIfDisabled = (categoryList: IObservationCategory[]): IObservationCategory[] => {
      if (selectedCategory) {
         const selectedCategoryEnabled = categoryList.any(x => x.id == selectedCategory.observationCategoryId);

         if (!selectedCategoryEnabled) {
            categoryList.push({
               id: selectedCategory.observationCategoryId,
               name: selectedCategory.observationCategoryName,
               nameDe: selectedCategory.observationCategoryNameDe
            });
         }
      }

      return categoryList;
   };

   const getDepotLabel = useCallback(() => {
      if (keysAreEqual(formData?.locationType, ObservationLocationTypeEnum.SunbeltDepot)) {
         return t('ObservationPage.WhichDepotDidItOccur');
      }

      return t('ObservationPage.WhichDepotDoYouBelongTo');
   }, [formData]);

   const getLocationMarker = useCallback((): LocationCoordinate | null => {
      if (formData?.locationLatitude && formData?.locationLongitude) {
         return {
            latitude: formData.locationLatitude,
            longitude: formData.locationLongitude
         };
      }

      return null;
   }, [formData]);

   const allowCategoryDropDownPopUpwards = useCallback(() => {
      return (
         keysAreEqual(formData?.locationType, ObservationLocationTypeEnum.CustomerSite) ||
         keysAreEqual(formData?.locationType, ObservationLocationTypeEnum.Other)
      );
   }, [formData]);

   const onSelectLocation = (newLocation: LocationCoordinate): void => {
      updateFields({
         locationLatitude: { runValidation: false, value: newLocation.latitude },
         locationLongitude: { runValidation: false, value: newLocation.longitude }
      });
   };

   return (
      <>
         <StyledControlContainer>
            <StyledPanelTitle>{t('ObservationPage.LocationDetails')}</StyledPanelTitle>
            <ToggleGroup
               label={t('ObservationPage.WhereDidTheGoodPracticeHappen')}
               required
               options={mapToDropdownOptions<IObservationLocationType, IDropdownOption>(
                  locationTypes,
                  type => type.name,
                  type => t(`ObservationPage.${type.description}`),
                  type => t(`ObservationPage.${type.description}`)
               )}
               onChange={v =>
                  updateFields({
                     locationType: { runValidation: false, value: v as ObservationLocationTypeEnum }
                  })
               }
               selectedKey={formData?.locationType}
               disabled={readOnly}
               isLoading={loading}
            />
         </StyledControlContainer>

         <ConditionalRender condition={!!formData?.locationType}>
            <StyledControlContainer>
               <Select
                  label={t('ObservationPage.SelectSunbeltRentalsCompany')}
                  required
                  selectedKey={formData?.companyId}
                  errorMessage={formErrors?.companyId}
                  multiSelect={false}
                  onChange={(e, v) => loadServiceCentres(v?.key)}
                  options={mapToDropdownOptions<ICompaniesType, IDropdownOption>(
                     companies,
                     company => company.companyId,
                     company => company.companyName
                  )}
                  optionsError={!isUndefined(loadingError)}
                  disabled={readOnly}
                  isLoading={loading}
               />
            </StyledControlContainer>

            <StyledControlContainer>
               <FilterSelect
                  label={getDepotLabel()}
                  required={keysAreEqual(formData?.locationType, ObservationLocationTypeEnum.SunbeltDepot)}
                  selectedKey={formData?.depotPcId}
                  errorMessage={formErrors?.depotPcId}
                  multiSelect={false}
                  onChange={(e, v) =>
                     updateFields({
                        depotPcId: { runValidation: true, value: v?.key }
                     })
                  }
                  options={mapToDropdownOptions<IDepot, IDropdownOption>(
                     depots,
                     depot => depot.pcId,
                     depot => `${depot.pc} - ${depot.pcName}`
                  )}
                  optionsError={!isUndefined(loadingError)}
                  disabled={readOnly}
                  hidePersona={true}
                  isLoading={loading}
               />
            </StyledControlContainer>
         </ConditionalRender>

         <ConditionalRender condition={keysAreEqual(formData?.locationType, ObservationLocationTypeEnum.CustomerSite)}>
            <TextInput
               label={t('ObservationPage.CustomerSiteName')}
               required
               value={formData?.customerSiteName}
               errorMessage={formErrors?.customerSiteName}
               onChange={(e, v) =>
                  updateFields({
                     customerSiteName: { runValidation: true, value: v }
                  })
               }
               disabled={readOnly}
            />
         </ConditionalRender>

         <ConditionalRender
            condition={
               keysAreEqual(formData?.locationType, ObservationLocationTypeEnum.CustomerSite) ||
               keysAreEqual(formData?.locationType, ObservationLocationTypeEnum.Other)
            }
         >
            <TextInput
               label={t('ObservationPage.SiteLocation')}
               required={keysAreEqual(formData?.locationType, ObservationLocationTypeEnum.Other)}
               value={formData?.locationDescription}
               errorMessage={formErrors?.locationDescription}
               onChange={(e, v) =>
                  updateFields({
                     locationDescription: { runValidation: true, value: v }
                  })
               }
               disabled={readOnly}
            />
         </ConditionalRender>

         <ConditionalRender condition={keysAreEqual(formData?.locationType, ObservationLocationTypeEnum.Other)}>
            <StyledTitle>
               {t('ObservationPage.SelectOnTheMap')} <label style={{ color: 'darkred' }}>*</label>
            </StyledTitle>
            <Loading isLoading={hasLoaded}>
               <div style={{ paddingTop: '15px' }}>* {t('ObservationPage.EnsurePinDropped')}</div>
               <LocationMap
                  apiKey={apiKey ?? ''}
                  markerLocation={getLocationMarker()}
                  onSelectLocation={onSelectLocation}
                  readOnly={readOnly}
               />
            </Loading>
         </ConditionalRender>

         <StyledPanelTitle>{t('ObservationPage.Categorisation')}</StyledPanelTitle>
         <StyledControlContainer>
            <Select
               label={t('ObservationPage.GoodPracticeCategory')}
               required
               selectedKey={formData?.observationCategoryId}
               errorMessage={formErrors?.observationCategoryId}
               multiSelect={false}
               onChange={(e, v) =>
                  updateFields({
                     observationCategoryId: { runValidation: true, value: v?.key }
                  })
               }
               options={mapToDropdownOptions<IObservationCategory, IDropdownOption>(
                  categories,
                  status => status.id,
                  status => (i18n.language === 'de' ? status.nameDe : status.name)
               )}
               optionsError={!isUndefined(loadingError)}
               disabled={readOnly}
               isLoading={loading}
               directionalHintNotFixed={allowCategoryDropDownPopUpwards()}
            />
         </StyledControlContainer>
      </>
   );
};

export default GoodPracticeCategorisation;
