import { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { StyledNearMissListContainer } from './styled/NearMissList.styled';
import { NearMiss, NearMissListQuery, NearMissListResponse } from './queries/near-miss-list.queries.types';

import { GetNearMissList } from './queries/near-miss-list.queries';

import NearMissListHeader from './components/NearMissListHeader';
import NearMissFilterBar from './components/NearMissListFilterBar';
import NearMissListTable from './components/NearMissListTable';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import Loading from 'components/layout/Loading';
import { ApolloError } from 'apollo-client';
import { ObservationStatusEnum } from 'shared/enums/ObservationStatus.enum';
import { useTranslation } from 'react-i18next';

export const NearMissList = () => {
   const client = useApolloClient();
   const [loading, setLoading] = useState<boolean>(true);
   const [loadingError, setLoadingError] = useState<ApolloError>();
   const [filterDataLoaded, setFilterDataLoaded] = useState<boolean>(false);

   const [showFilterBar, setShowFilterBar] = useState<boolean>(false);

   const defaultFilter: NearMissListQuery = {
      dateFrom: undefined,
      dateTo: undefined,
      observationCategoryId: [],
      concernLevel: [],
      observationStatus: [ObservationStatusEnum.Open, ObservationStatusEnum.Closed],
      depotPcId: [],
      divisionId: [],
      regionId: [],
      sHEQManagerId: []
   };

   const [nearMissRequest, setNearMissRequest] = useState<NearMissListQuery>(defaultFilter);

   const [nearMissData, setNearMissData] = useState<NearMiss[]>();
   const { t } = useTranslation();

   const toggleFilterBar = () => {
      setShowFilterBar(!showFilterBar);
   };

   useEffect(() => {
      loadNearMissData();
   }, [nearMissRequest]);

   const resetNearMissRequest = () => {
      setNearMissRequest(defaultFilter);
   };

   const loadNearMissData = async () => {
      setLoading(true);

      try {
         let result = await client.query<NearMissListResponse>({
            query: GetNearMissList,
            variables: {
               request: nearMissRequest
            },
            fetchPolicy: 'network-only'
         });

         if (result?.data?.nearMissRecords != null) {
            setNearMissData(result.data.nearMissRecords);
         } else {
            setNearMissData(undefined);
         }

         result?.data?.nearMissRecords.forEach(data => {
            if (data.offSiteLocation != null) {
               data.offSiteLocationDescription = data.offSiteLocation.locationDescription;
               data.offSiteLocationCustomerSite = data.offSiteLocation.customerSiteName;
            } else {
               data.offSiteLocationDescription = ' ';
               data.offSiteLocationCustomerSite = ' ';
            }

            if (data.depotPcName == null) {
               data.depotPcName = ' ';
            }
         });

         if (filterDataLoaded === false) {
            setFilterDataLoaded(true);
         }
      } catch (e: any) {
         setLoadingError(e);
      } finally {
         setLoading(false);
      }
   };

   return (
      <StyledNearMissListContainer>
         <NearMissListHeader toggleFilterBar={toggleFilterBar} />
         <NearMissFilterBar
            showFilterBar={showFilterBar}
            toggleFilterBar={toggleFilterBar}
            nearMissData={nearMissData}
            filterDataLoaded={filterDataLoaded}
            filterRequest={nearMissRequest}
            setFilterRequest={setNearMissRequest}
            resetFilter={resetNearMissRequest}
         />

         <GraphQlErrorBoundary error={loadingError}>
            <Loading isLoading={loading} message={t('ObservationList.LoadingNearMissReports')}>
               <NearMissListTable data={nearMissData} />
            </Loading>
         </GraphQlErrorBoundary>
      </StyledNearMissListContainer>
   );
};
