import React, { FormEvent, FunctionComponent, useState } from 'react';
import { Modal } from 'components/controls/Modal';
import Select from 'components/controls/Select';
import { useQuery } from '@apollo/react-hooks';
import { ApplicationProfiles, ProfileListViewModel } from 'shared/types/configuration/ProfileModels';
import GraphQlLoading from 'components/layout/Loading';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import { IDropdownOption } from '@fluentui/react';
import Button from 'components/controls/Button';
import { sortByProperty } from 'shared/utils/array.utils';
import { GetApplicationProfiles } from 'sections/configuration/pages/Profile/pages/ProfileList/queries/profile-list-query';

interface AddProfileModalProps {
    isOpen: boolean;
    onDismiss: () => void;
    profileList: ProfileListViewModel[];
    onProfilesSelected: (selected: ProfileListViewModel[]) => void;
}

const AddProfileModal: FunctionComponent<AddProfileModalProps> = (props) => {
    const [selectedProfiles, setSelectedProfiles] = useState<ProfileListViewModel[]>([]);
    const { data, loading, error } = useQuery<ApplicationProfiles>(GetApplicationProfiles);

    const handleProfileSelect = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
        const profiles = [...selectedProfiles];
        if (data && option?.selected) {
            profiles.push(data.applicationProfiles.find(x => x.profileId === option.key)!);
            setSelectedProfiles(profiles);
            return;
        } else {
            setSelectedProfiles(profiles.filter(x => x.profileId !== option?.key)!);
            return;
        }
    };

    const addProfiles = () => {
        props.onProfilesSelected(selectedProfiles.map(x => {
            return { profileId: x.profileId, profileName: x.profileName, description: x.description }
        }))
    };

    return (
        <Modal
            title={'Assign Profiles'}
            isOpen={props.isOpen}
            onDismiss={() => {
                props.onDismiss();
            }}
            isBlocking={true}>
            <GraphQlLoading isLoading={loading} message="Loading">
                <GraphQlErrorBoundary error={error}>
                    {data && (
                        <div style={{ margin: "15px", minWidth: "400px" }}>
                            <Select
                                label={'Select profile(s)'}
                                options={sortByProperty(data.applicationProfiles.filter(value =>
                                    props.profileList.findIndex(x => x.profileId === value.profileId) === -1)
                                    .map(x => { return { key: x.profileId, text: x.profileName } }), "text")}
                                onChange={handleProfileSelect}
                                selectedKeys={selectedProfiles.map(x => x.profileId)}
                                multiSelect
                                required={true} />

                            <div style={{ marginTop: "15px", display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    text={'Add'}
                                    onClick={addProfiles} />
                            </div>
                        </div>)
                    }
                </GraphQlErrorBoundary>
            </GraphQlLoading>
        </Modal>
    );
};

export default AddProfileModal;