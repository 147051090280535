import { useState } from 'react';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { NewNearMissMain } from './components/NewNearMissMain/components/NewNearMissMain';
import { NewNearMissSuccess } from './components/NewNearMissSuccess/NewNearMissSuccess';

export const NewNearMiss = () => {

    const [createdSuccessfully, setCreatedSuccessfully] = useState<boolean>(false);

    const showSuccess = () => {
        setCreatedSuccessfully(true);
    }

    const hideSuccess = () => {
        setCreatedSuccessfully(false);
    }

    return (
        <>
            <ConditionalRender condition={createdSuccessfully} >
                <NewNearMissSuccess onCreateAnother={ hideSuccess } />
            </ConditionalRender>
            <ConditionalRender condition={!createdSuccessfully} >
                <NewNearMissMain onComplete={showSuccess} />
            </ConditionalRender>

        </>
    );
};