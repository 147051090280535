import { useCallback } from 'react';
import { usePermissions, UserPermission, WriteOn, ReadOn } from 'modules/permissions';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import { NearMissDisplayEnum } from '../../../enums/near-miss-display.enum';
import { NearMiss } from '../../../queries/view-near-miss.queries.types';
import {
   StyledStatusBarUpdatedBy,
   StyledSubHeader,
   StyledSubHeaderControl,
   StyledSubHeaderControlContainer,
   StyledSubHeaderGroup,
   StyledSubHeaderLabel,
   StyledSubHeaderSection,
   StyledSubHeaderTabContainer
} from 'shared/styled/SubHeader.styled';
import SubHeaderTab from 'components/SubHeaderTab';
import { useForm } from 'modules/forms';
import { NearMissEntity } from 'shared/types/domain/NearMissEntity';
import DateTimeInput from 'components/controls/DateTimeInput';
import { FontIcon } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

interface NearMissSubHeaderProps {
   nearMiss: NearMiss | undefined;
   displayMode: NearMissDisplayEnum;
   formHasErrors: boolean;
   goToOverview: () => void;
   goToCompletionDetails: () => void;
   goToAuditLog: () => void;
}

const NearMissSubHeader = ({
   nearMiss,
   displayMode,
   formHasErrors,
   goToOverview,
   goToCompletionDetails,
   goToAuditLog
}: NearMissSubHeaderProps) => {
   const { t } = useTranslation();
   const { updateFields, formErrors } = useForm<NearMissEntity>();
   const { hasPermission } = usePermissions();

   const lastUpdateText = (): string => {
      return `${t('ObservationPage.LastUpdatedOn')} ${t('AuditLog.Date', { date: nearMiss?.lastModified })}`;
   };

   const allowShowCompletionDetails = useCallback(() => {
      return nearMiss?.completion != undefined;
   }, [nearMiss]);

   const handleIncidentDateChange = (incidentDate: string | null | undefined) => {
      updateFields({
         incidentDate: { runValidation: true, value: incidentDate }
      });
   };

   return (
      <StyledSubHeader>
         <StyledSubHeaderSection style={{ maxWidth: '25%' }}>
            <StyledSubHeaderGroup>
               <StyledSubHeaderControlContainer>
                  <StyledSubHeaderLabel>{t('ObservationPage.IncidentDate')}</StyledSubHeaderLabel>
                  <StyledSubHeaderControl>
                     <ConditionalRender condition={hasPermission(WriteOn(UserPermission.IncidentDate))}>
                        <DateTimeInput
                           value={nearMiss?.incidentDate!.toString()}
                           onDateTimeSelect={handleIncidentDateChange}
                           disableFuture={true}
                           errorMessage={formErrors?.incidentDate}
                        />
                     </ConditionalRender>
                     <ConditionalRender condition={!hasPermission(WriteOn(UserPermission.IncidentDate))}>
                        {t('AuditLog.Date', { date: nearMiss?.incidentDate.toString() })}
                     </ConditionalRender>
                  </StyledSubHeaderControl>
               </StyledSubHeaderControlContainer>
            </StyledSubHeaderGroup>
            <StyledSubHeaderGroup>
               <StyledSubHeaderControlContainer>
                  <StyledSubHeaderLabel>{t('ObservationList.Status')}</StyledSubHeaderLabel>
                  <StyledSubHeaderControl>
                     {t(`ObservationFilter.${nearMiss?.observationStatus.toProperCase()}`)}
                  </StyledSubHeaderControl>
               </StyledSubHeaderControlContainer>
            </StyledSubHeaderGroup>
         </StyledSubHeaderSection>

         <StyledSubHeaderSection style={{ flex: '2 1 auto' }}>
            <StyledSubHeaderGroup style={{ justifyContent: 'flex-end' }}>
               <StyledStatusBarUpdatedBy>{lastUpdateText()}</StyledStatusBarUpdatedBy>
            </StyledSubHeaderGroup>

            <StyledSubHeaderGroup style={{ justifyContent: 'flex-end' }}>
               <StyledSubHeaderTabContainer>
                  <SubHeaderTab
                     onClick={goToOverview}
                     isActive={displayMode === NearMissDisplayEnum.Overview}
                     display={t('ObservationPage.Overview')}
                     highlightErrors={formHasErrors}
                  />
                  <ConditionalRender condition={allowShowCompletionDetails}>
                     <SubHeaderTab
                        onClick={goToCompletionDetails}
                        isActive={displayMode === NearMissDisplayEnum.Completion}
                        display={t('ObservationPage.Completion')}
                     />
                  </ConditionalRender>
                  <ConditionalRender condition={hasPermission(ReadOn(UserPermission.Audit))}>
                     <SubHeaderTab
                        onClick={goToAuditLog}
                        isActive={displayMode === NearMissDisplayEnum.Audit}
                        display={<FontIcon iconName='History' />}
                        title={t('ObservationPage.AuditLog')}
                     />
                  </ConditionalRender>
               </StyledSubHeaderTabContainer>
            </StyledSubHeaderGroup>
         </StyledSubHeaderSection>
      </StyledSubHeader>
   );
};

export default NearMissSubHeader;
