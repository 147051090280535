import { useEffect, useState } from "react";
import { ApolloError } from "apollo-client";
import { useApolloClient } from "@apollo/react-hooks";
import { GoogleMapsApiKeyContext } from "../contexts/google-maps-api-key.contexts";
import { GetGoogleMapsApiKey } from "../queries/google-maps-api-key.queries";
import { LocationMapApiKey } from "../queries/google-maps-api-key.queries.types";

interface GoogleMapsApiKeyProviderProps {
    children: React.ReactNode;
}

export const GoogleMapsApiKeyProvider = ({
    children
}: GoogleMapsApiKeyProviderProps) => {

    const client = useApolloClient();
    
    const [ loadingApiKey, setLoadingApiKey ] = useState<boolean>(true);
    const [ loadingApiKeyError, setLoadingApiKeyError ] = useState<ApolloError>();
    const [ mapsApiKey, setMapsApiKey ] = useState<string>('')

    useEffect(() => {

        getApiKey();

    }, []);

    const getApiKey = async () => {

        setLoadingApiKey(true);

        try {
            let result = await client.query<LocationMapApiKey>({
                query: GetGoogleMapsApiKey,
                fetchPolicy: 'network-only'
            });

            if (result?.data != null) {
                setMapsApiKey(result.data.googleApiKey);
            }
        } catch (e) {

            setLoadingApiKeyError(e);
        } finally {

            setLoadingApiKey(false);
        }
    }

    return (
        <GoogleMapsApiKeyContext.Provider
            value={{
                hasLoaded: loadingApiKey,
                loadingError: loadingApiKeyError,
                apiKey: mapsApiKey
            }}
        >
            {children}
        </GoogleMapsApiKeyContext.Provider>
    )
}