import styled from "styled-components";

export const StyledNearMissContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledScrollContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;