import { Row, Col, Grid } from "react-flexbox-grid";
import styled from "styled-components";

export const StyledContainer = styled.div`
    flex: 1;
`;

export const StyledPanelGrid = styled(Grid)`
    padding-left: 8px !important;
    padding-right: 8px !important;
`;

export const StyledPanelTitle = styled.h2`
    ${({ theme }) => `
        color: ${theme.palette.primary.main};
        padding-bottom: 25px;
        padding-top: 15px;
    `}
`;

export const StyledTitle = styled.h3`
    ${({ theme }) => `  
        color: ${theme.palette.gray[800]};
        font-weight: bold;
        font-size: 14px;
        padding-top: 15px;
    `}
`;

export const StyledPanelRow = styled(Row)``;

export const StyledPanelColumn = styled(Col)`
    padding: 0 25px 10px 25px !important;

    :not(:last-of-type) {
        border-right: 1px solid lightgray;
    }

    hr {
        border: none;
        border-top: 1px solid lightgray;
        margin: 15px 0;
    }
`;