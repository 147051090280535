import { IMapper } from 'shared/types/IMapper';
import { v4 as uuid } from 'uuid';
import { FileStatus } from 'shared/enums/FileStatus.enum';
import { documentTypes } from 'shared/constants/documentTypes';
import { DocumentType } from 'shared/enums/DocumentType.enum';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import { IDocumentType } from 'shared/types/file/IDocumentType';

export class AddFileMapper
    implements
    IMapper<File, ObservationFileEntity, { uploadedById: number; uploadedByName: string; applicationId?: number; emailEvidence?: boolean; }> {
    map(
        entity: File,
        data: { uploadedById: number; uploadedByName: string; applicationId?: number; type?: IDocumentType; emailEvidence?: boolean; }
    ): ObservationFileEntity {
        return {
            id: uuid(),
            fileStatus: FileStatus.New,
            mimeType: entity.type,
            name: entity.name,
            size: entity.size,
            uploadedById: data.uploadedById,
            uploadedByName: data.uploadedByName,
            uploadedDate: new Date().toISOString(),
            file: entity,
            type: data.type ?? documentTypes[DocumentType.Other],
            emailEvidence: data.emailEvidence
        };
    }
}
