import { FC } from 'react';
import { ISubMenuGroup } from '../types/ISubMenuGroup';
import { StyledConfigurationMenu, StyledConfigurationMenuHeader } from '../styled/SubMenu.styled';
import SubMenuGroup from './SubMenuGroup';

/**
 * Props interface for {@link SubMenu}
 */
interface ISubMenuProps {
    title: string;
    groups: ISubMenuGroup[];
}

/**
 * SubMenu component
 * @param props {@link ISubMenuProps}
 */
const SubMenu: FC<ISubMenuProps> = ({ groups, title }: ISubMenuProps) => {
    return (
        <StyledConfigurationMenu>
            <StyledConfigurationMenuHeader>{title}</StyledConfigurationMenuHeader>
            {groups.map(x => (
                <SubMenuGroup key={x.key} group={x} />
            ))}
        </StyledConfigurationMenu>
    );
};

export default SubMenu;
