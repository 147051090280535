import { tryParseInt } from "shared/utils/string.utils";
import { GeneralAppSettingEnum } from "../enums/AppSetting.enum";
import { UpdateGeneralAppSettingMutationRequest } from "../mutations/appsetting.mutations.types";
import { NearMissAppSettings } from "../queries/near-miss-appsettings.queries.types";
import { InitialiseAppSettingPayload } from "../store/appsettings.actions.types";
import { GeneralAppSettingEntity } from "../store/appsettings.state.types";

export const mapGetAppSettingQueryToInitialisationPayload = (
  entity: NearMissAppSettings[]
): InitialiseAppSettingPayload => {
  const archivePeriod =
    tryParseInt(
      entity.find(x => x.appSettingId === GeneralAppSettingEnum.ArchivePeriod)
        ?.appSettingValue as string | undefined
    ) ?? 200;

  return {
    [GeneralAppSettingEnum.ArchivePeriod]: archivePeriod
  };
};

export const mapAppSettingStateToUpdateRequest = (
  entity: GeneralAppSettingEntity
): UpdateGeneralAppSettingMutationRequest => {
  return {
    request: [
      {
        active: true,
        appSettingId: GeneralAppSettingEnum.ArchivePeriod,
        appSettingValue: entity[GeneralAppSettingEnum.ArchivePeriod].value.toString()
      }
    ]
  };
};