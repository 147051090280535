import React, { memo } from 'react';
import { Toggle as FabricToggle, IToggleStyles, IToggleStyleProps, Label, FontIcon } from '@fluentui/react';
import { RequiredPermission, usePermissions } from 'modules/permissions';

import { ReadOnlyField } from 'components/controls/ReadOnlyField';
import { StyledFieldIcon, StyledToggleContainer } from './Toggle.styles';

interface IToggleProps {
    name?: string;
    label?: string;
    onText?: string;
    offText?: string;
    onChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined, name?: string) => void;
    checked?: boolean;
    inlineLabel?: boolean;
    type?: ToggleType;
    disabled?: boolean;
    readPermissions?: RequiredPermission[];
    writePermissions?: RequiredPermission[];
    readonly?: boolean;
}

export enum ToggleType {
    Standard = 'standard',
    Warning = 'warning',
    Danger = 'danger'
}

const styles = ({ type }: Partial<IToggleProps>) => (toggleProps: IToggleStyleProps): Partial<IToggleStyles> => {
    const backgroundColor =
        type === ToggleType.Warning ? '#fed203' : type === ToggleType.Danger ? 'rgb(164, 38, 44)' : undefined;

    return {
        root: {
            marginBottom: 'none'
        },
        pill: {
            backgroundColor: toggleProps.checked ? backgroundColor : undefined,
            selectors: {
                ':hover': {
                    backgroundColor: toggleProps.checked ? backgroundColor : undefined
                }
            }
        },
        text: {
            lineHeight: '20px'
        }
    };
};

const Toggle = ({
    label,
    onText,
    offText,
    onChange,
    checked,
    inlineLabel,
    type = ToggleType.Standard,
    disabled,
    name,
    readPermissions,
    writePermissions,
    readonly
}: IToggleProps) => {
    const { hasPermissions } = usePermissions();

    function handleChange(event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined): void {
        onChange(event, checked, name);
    }

    const hasReadPermissions = hasPermissions(readPermissions);
    const hasWritePermissions = hasPermissions(writePermissions);

    return (
        <React.Fragment>
            {label && <Label>{label}</Label>}
            {hasReadPermissions ? (
                <StyledToggleContainer>
                    <FabricToggle
                        disabled={disabled || !hasWritePermissions || readonly}
                        styles={styles({ type })}
                        onText={onText}
                        offText={offText}
                        onChange={handleChange}
                        checked={checked}
                        inlineLabel={inlineLabel}
                    />
                    {(!hasWritePermissions || readonly) && (
                        <StyledFieldIcon>
                            <FontIcon
                                iconName={readonly ? 'RedEye' : 'Lock'}
                                title={readonly ? 'readonly' : 'insufficient permissions'}
                            />
                        </StyledFieldIcon>
                    )}
                </StyledToggleContainer>
            ) : (
                <ReadOnlyField text='************' locked />
            )}
        </React.Fragment>
    );
};

export default memo(Toggle);
