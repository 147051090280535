import styled from 'styled-components';
import { ReactComponent as Logo } from 'assets/images/logo-black.svg';

export const StyledMessagePageContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const StyledMessagePage = styled.div`
    text-align: center;
    width: 60vw;
    min-width: min-content;
    padding: 0 25px;
    box-shadow: ${({ theme }) => theme.shadow};
    border-radius: 10px;
`;

export const StyledMessagePageLogoContainer = styled.div`
    padding: 25px 0;
`;

export const StyledMessagePageLogo = styled(Logo)`
    height: 150px;
    max-height: 150px;
`;

export const StyledMessagePageTextContainer = styled.h1`
    display: block;
    font-size: 1.5em;
`;

export const StyledMessagePageText = styled.div`
    padding: 25px 0;
    text-align: center;
`;

export const StyledMessagedButton = styled.span`
    margin:0px 25px;
`;