import Button, { ButtonProps } from "components/controls/Button";
import { useCallback, useState } from "react";
import { StyledObservationHeader } from "shared/styled/ObservationHeader.styled";
import { StyledHeaderText } from "sections/configuration/styled/configuration.styles";
import { SheqManagerEntity } from "shared/types/configuration/SheqManagerEntity";
import { useTranslation } from "react-i18next";

interface SheqManagerHeaderProps {
  sheqManagerModel: SheqManagerEntity;
  allowSave: boolean;
  onSave: () => void;
  additionalButtons?: ButtonProps[];
}

const SheqManagerHeader = ({
  sheqManagerModel,
  allowSave,
  onSave,
  additionalButtons
}: SheqManagerHeaderProps) => {

  const handleSave = () => {
    onSave();
  };

  const displayAdditionalButtons = useCallback((): JSX.Element => {

    return (
      <>
        {
          !!additionalButtons &&
          additionalButtons
            .map((props, index) => (
              <Button {...props} style={{ marginLeft: '15px' }} key={index} />
            ))
        }
      </>
    );

  }, [additionalButtons])
  const { t } = useTranslation();
  return (
    <StyledObservationHeader>
      <div style={{ display: 'flex' }}>
        <div>
          <StyledHeaderText>{t('SheqManager.SheqManager')}: {sheqManagerModel?.displayName ?? ''}</StyledHeaderText>
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          <Button text='Save' onClick={handleSave} disabled={!allowSave} iconName='ReminderPerson' />
          {
            displayAdditionalButtons()
          }
        </div>
      </div>
    </StyledObservationHeader>
  );
}

export default SheqManagerHeader