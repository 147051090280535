import { FunctionComponent } from 'react';
import { Persona, PersonaSize } from '@fluentui/react';

import { AdUserEntity } from 'shared/types/configuration/AdUserEntity';
import { renderDate } from 'shared/utils/format.utils';
import { StyledAuditDateSpan, StyledAuditEmployeeCard } from '../styled/audit-employee-card.styled';

interface AuditEmployeeCardProps {
    employee: AdUserEntity | null | undefined;
    auditId?: number;
    employeeId?: number;
    date: string;
    selected: boolean;
    clicked: (employeeId: number, auditId: number) => void;
}

export const AuditEmployeeCard: FunctionComponent<AuditEmployeeCardProps> = (props: AuditEmployeeCardProps) => {
    return (
        <StyledAuditEmployeeCard
            selected={props.selected}
            onClick={() => {
                props.clicked(props.employeeId ?? 0, props.auditId ?? 0);
            }}
        >
            <Persona
                text={props.employee?.displayName ?? 'System Change'}
                secondaryText={props.employee?.jobTitle}
                size={PersonaSize.size48}
            />
            <StyledAuditDateSpan>{renderDate(props.date)}</StyledAuditDateSpan>
        </StyledAuditEmployeeCard>
    );
};