import { gql } from 'graphql.macro';

export const GetSheqManagers = gql`
  query sheqManagers {
    sheqManagers: sHEQManagers {
      employeeId
      givenName
      displayName
      department
      jobTitle
    }
  }
`;