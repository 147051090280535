import React from 'react';
import { StyledInputError } from '../styled/InputError.styled';

interface InputErrorProps {
  error?: string;
}

const InputError = (props: InputErrorProps) => {
  return (
    <StyledInputError>
      {props.error}
    </StyledInputError>
  );
}

export default InputError;