import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState, Dispatch, SetStateAction, useCallback } from 'react';
import { UnregisterCallback } from 'history';

/**
 * Handles block
 * @param event
 */
const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = 'Are you sure you want to leave?';
};

export type UnloadState = {
    /**
     * Whether navigate is currently blocked
     */
    blockUnload: boolean;

    /**
     * Set whether navigation should be blocked or not
     */
    setBlockUnload: Dispatch<SetStateAction<{ shouldBlock: boolean; message?: string }>>;

    /**
     * Navigates to a path via React Router, this also removes any blocks currently on navigation and so
     * acts as a forced path change
     * @param {string} path The path to navigate to
     * @param {object | null | undefined} state State passed with the route change
     */
    navigate: (path: string, state?: {} | null | undefined) => void;
};

/**
 * Hook to handle blocking navigation on page unload, and on React Router navigation.
 * @dependency Consuming component must be wrapped by a BrowserRouter from React Router
 * @param shouldBlock The default value for whether a page should block navigation
 * */
export const useUnload = (shouldBlock = false): UnloadState => {
    const history = useHistory();
    const blockHandler = useRef<UnregisterCallback | undefined>();
    const [shouldBlockUnload, setShouldBlockUnload] = useState<{ shouldBlock: boolean; message?: string }>({
        shouldBlock: shouldBlock
    });

    /**
     * Adds a blocker for react router navigation and on browser unload
     */
    const addUnloadBlock = useCallback(() => {
        blockHandler.current = history.block(shouldBlockUnload.message);
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, [history, shouldBlockUnload.message]);

    /**
     * Remove a blocker for react router navigation and on browser unload
     */
    const removeUnloadBlock = useCallback(() => {
        blockHandler.current?.call(undefined);
        window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);

    /**
     * Adds or removes a block on navigation based on the value of shouldBlockUnload
     */
    useEffect(() => {
        if (shouldBlockUnload.shouldBlock) addUnloadBlock();
        if (!shouldBlockUnload.shouldBlock) removeUnloadBlock();

        return removeUnloadBlock;
    }, [shouldBlockUnload.shouldBlock, addUnloadBlock, removeUnloadBlock]);

    /**
     * Navigates to a path via React Router, this also removes any blocks currently on navigation and so
     * acts as a forced path change
     * @param {string} path The path to navigate to
     * @param {object | null | undefined} state State passed with the route change
     */
    const navigate = useCallback(
        (path: string, state?: {} | null | undefined): void => {
            removeUnloadBlock();

            history.push(path, state);
        },
        [history, removeUnloadBlock]
    );

    return {
        blockUnload: shouldBlockUnload.shouldBlock,
        setBlockUnload: setShouldBlockUnload,
        navigate: navigate
    };
};
