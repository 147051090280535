import TextInput from 'components/controls/TextInput';
import { Toggle } from 'components/controls/Toggle';
import { cloneDeep } from 'lodash';
import { IconButton } from 'components/controls/Button';
import { FunctionComponent, useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useUnload } from 'shared/hooks/useUnload';
import {
   StyledBoldText,
   StyledGrayInfoText,
   StyledOptionContainer,
   StyledOptionLabel,
   StyledOptionTextContainer,
   StyledOptionToggleContainer,
   StyledSection,
   StyledSubHeader
} from 'sections/configuration/styled/configuration.styles';
import { NearMissCategoryCollectionModel } from 'shared/types/configuration/NearMissCategoryModel';
import { StyledContainer } from 'shared/styled/panel.stayled';
import { User } from 'shared/types/configuration/UserModels';
import { LinkedUserList } from '../pages/NewNearMissCategory/components/NearMissCategoryLinkUserList/components/LinkedUserList';
import AssignUser from '../../Profile/components/AssignUser/components/AssignUser';
import { useTranslation } from 'react-i18next';

interface NearMissCategoryConfigurationProps {
   categoryModel: NearMissCategoryCollectionModel;
   onChangeCategory: (category: NearMissCategoryCollectionModel) => void;
}

const NearMissCategoryConfiguration: FunctionComponent<NearMissCategoryConfigurationProps> = ({
   categoryModel,

   onChangeCategory
}) => {
   const { setBlockUnload } = useUnload();
   const [stateCategory, setStateCategory] = useState<NearMissCategoryCollectionModel>(
      cloneDeep<NearMissCategoryCollectionModel>(categoryModel)
   );
   const [showUserSearchDialog, setShowUserSearchDialog] = useState<boolean>(false);
   const { t } = useTranslation();

   const addNewAssignedUsers = (users: User[]) => {
      const newState = { ...stateCategory };
      for (const user of users) {
         if (newState.users.find(x => x.employeeId === user.employeeId) === undefined) {
            newState.users.push(user);
            if (!newState.usersToAdd) {
               newState.usersToAdd = [];
            }
            newState.usersToAdd.push(user);
            if (!newState.usersToRemove) {
               newState.usersToRemove = [];
            }
            if (newState.usersToRemove.find(x => x.employeeId === user.employeeId) !== undefined) {
               newState.usersToRemove = newState.usersToRemove.filter(x => x.employeeId !== user.employeeId);
            }
         }
      }
      setStateCategory(newState);
      setBlock();
      setShowUserSearchDialog(!showUserSearchDialog);
   };

   const removeUserFromCategory = (user: User) => {
      const newState = { ...stateCategory };
      newState.users = newState.users.filter(x => x.employeeId !== user.employeeId);
      if (!newState.usersToRemove) {
         newState.usersToRemove = [];
      }
      newState.usersToRemove.push(user);
      if (!newState.usersToAdd) {
         newState.usersToAdd = [];
      }
      if (newState.usersToAdd.find(x => x.employeeId === user.employeeId) !== undefined) {
         newState.usersToAdd = newState.usersToAdd.filter(x => x.employeeId !== user.employeeId);
      }
      setBlock();
      setStateCategory(newState);
   };

   const setBlock = () => {
      setBlockUnload({
         shouldBlock: true,
         message: t('AppSettings.AreYouSure')
      });
   };

   const handleNameChange = (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      propertyName: string,
      newValue?: string
   ) => {
      const newState = {
         ...stateCategory,
         [propertyName]: newValue
      };

      setBlock();
      setStateCategory(newState);
   };

   const handleActiveChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, newValue?: boolean | undefined) => {
      const newState = { ...stateCategory };
      newState.isActive = newValue ?? false;
      setBlock();
      setStateCategory(newState);
   };

   useEffect(() => {
      onChangeCategory(stateCategory);
   }, [stateCategory]);

   return (
      <>
         <StyledContainer style={{ marginTop: 20 }}>
            <Grid fluid>
               <Row>
                  <Col lg={6} xl={4}>
                     <StyledSection>
                        <StyledSubHeader>{t('Category.CategoryDetails')}</StyledSubHeader>
                        <TextInput
                           label={t('Category.CategoryName')}
                           onChange={(e, v) => handleNameChange(e, 'nearMissCategoryName', v)}
                           value={stateCategory.nearMissCategoryName}
                           required={true}
                           placeholder={t('Category.CategoryName')}
                        />

                        <br />

                        <TextInput
                           label={t('Category.CategoryNameDE')}
                           onChange={(e, v) => handleNameChange(e, 'nearMissCategoryNameDE', v)}
                           value={stateCategory.nearMissCategoryNameDE}
                           required={true}
                           placeholder={t('Category.CategoryName')}
                        />

                        <br />

                        <StyledOptionContainer key={1}>
                           <StyledOptionTextContainer>
                              <StyledOptionLabel>{t('Category.EnableCategory')}</StyledOptionLabel>
                           </StyledOptionTextContainer>
                           <StyledOptionToggleContainer>
                              <Toggle checked={stateCategory.isActive === true} onChange={handleActiveChange} />
                           </StyledOptionToggleContainer>
                        </StyledOptionContainer>
                     </StyledSection>
                  </Col>
                  <Col lg={6} xl={4} xlOffset={4}>
                     <StyledSection>
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                           <div>
                              <StyledSubHeader>{t('Category.ManageUsers')}</StyledSubHeader>
                           </div>
                           <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                              <IconButton iconName={'Add'} onClick={() => setShowUserSearchDialog(true)} />
                           </div>
                        </div>
                     </StyledSection>
                     <div style={{ height: 'calc(100vh - 175px)', display: 'flex', flexDirection: 'column' }}>
                        {stateCategory.users?.length === 0 && (
                           <div>
                              <StyledBoldText>{t('Category.NoUsersAssigned')}</StyledBoldText>
                              <StyledGrayInfoText>{t('Category.AssignUsersAbove')}</StyledGrayInfoText>
                           </div>
                        )}

                        {stateCategory.users?.length > 0 && (
                           <div style={{ flex: '1 1 auto', overflowY: 'scroll', overflowX: 'hidden' }}>
                              <LinkedUserList userList={stateCategory.users} onRemoveUser={removeUserFromCategory} />
                           </div>
                        )}
                     </div>
                  </Col>
               </Row>
            </Grid>

            <AssignUser
               isOpen={showUserSearchDialog}
               onDismiss={() => setShowUserSearchDialog(!showUserSearchDialog)}
               onAddUsers={addNewAssignedUsers}
            />
         </StyledContainer>
      </>
   );
};

export default NearMissCategoryConfiguration;
