import { isNullOrEmpty } from 'shared/utils/null.utils';
import { ErrorMessageGroup } from '../types/ErrorMessageGroup';
import { StyledErrorListContainer } from './ErrorListToastMessage.styles';

interface IErrorListToastMessageProps {
    baseMessage?: string;
    errors: (string | ErrorMessageGroup)[];
}

const isMessageGroup = (message: string | ErrorMessageGroup): message is ErrorMessageGroup => {
    return typeof message !== 'string';
};

export const ErrorListToastMessage = ({ baseMessage, errors }: IErrorListToastMessageProps) => {
    return (
        <StyledErrorListContainer>
            {!isNullOrEmpty(baseMessage) && <div style={{ paddingBottom: '10px' }}>{baseMessage}</div>}
            {errors.map((message, i) => {
                return isMessageGroup(message) ? (
                    <div key={i}>
                        {message.baseMessage}
                        <ul style={{ marginLeft: '20px' }}>
                            {message.messages.map((x, i) => (
                                <li key={i}>{x}</li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div key={i}>{message}</div>
                );
            })}
        </StyledErrorListContainer>
    );
};