import { gql } from 'graphql.macro';

export const GetObservationCategorisationLookups = gql`

    query {
        nearMissCategories: nearmisscategorylookup {
            id: nearMissCategoryId
            name: nearMissCategoryName
            nameDe: nearMissCategoryNameDE
        }

        nearMissLocationTypes: nearmissenumlookup(request: { type: LOCATIONTYPE }) {
            id
            name: type
            description
        }       
        
        companies: allCompanies {
            companyId
            companyName
            usersCompany
        }
    }
`;

export const GetServiceCentres = gql`
    query GetServiceCentres($companyId: Int){
        
        depots: allServiceCentre(companyId:$companyId, openDepotsOnly: true) {
            pcId: pCID
            pc: pC
            pcName: pCName
        }        
    }
`;
