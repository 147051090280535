import { Dialog } from "@fluentui/react";
import { CSSProperties } from "react";

interface ObservationPhotoDialogProps {
    showPhoto: boolean;
    onDismiss: () => void;
    imageUrl?: string;
}

export const ObservationPhotoDialog = ({
    showPhoto,
    onDismiss,
    imageUrl
}: ObservationPhotoDialogProps) => {

    const imageStyle: CSSProperties = {
        maxWidth: '80vw',
        height: 'auto',
        maxHeight: '80vh'
    }

    return (
        <Dialog
            hidden={!showPhoto}
            onDismiss={onDismiss}
            minWidth={'500px'}
            maxWidth={'80%'}
        >
            {imageUrl && <img src={imageUrl} style={imageStyle} />}
        </Dialog>
    )
};