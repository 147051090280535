import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import {
    StyledConfigurationContainer,
    StyledHeaderText,
    StyledHorizontalRule,
    StyledSubHeader
} from '../../../../../styled/configuration.styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import { useToasts } from 'react-toast-notifications';
import { userSearchStateReducer } from '../store/reducer';
import { defaultUserSearchState } from '../store/state';
import { UserSearchQueryEnum } from '../enums/UserSearchQuery.enum';
import { setSearchQuery } from '../store/actions/set-search-query.action';
import { setPage } from '../../UserList/store/actions/set-page.action';
import GraphQlErrorBoundary from 'components/layout/ErrorBoundary';
import Loading from 'components/layout/Loading';
import NoData from 'components/layout/NoData/components/NoData';
import VirtualTable from 'components/layout/VirtualTable/Table/components/VirtualTable';
import {
    ApplicationUser,
    ApplicationUserSearchRequest,
    ApplicationUserSearchResponse
} from '../queries/user-search.queries.types';
import {
    configurationRoutes
} from '../../../../../routes/configuration.routes';
import { StyledTableContainer, SearchInputContainer, StyledCommandBar } from './UserListConfiguration.styles';
import { getColumns } from '../options/user-list-configuration-table.options';
import { v4 as uuid } from 'uuid';
import { setFetchingResults } from '../store/actions/set-fetching-results.action';
import { ApplicationUserSearch } from '../queries/user-search.queries';
import { addSearchResults } from '../store/actions/add-search-results.action';
import { setSearchError } from '../store/actions/set-search-error.action';
import { SearchInput } from 'components/controls/SearchInput';
import { SearchEvent } from 'components/controls/SearchInput/components/SearchInput.types';
import { isNullOrUndefined } from 'shared/utils/validation.utils';
import { resetSearch } from '../store/actions/reset-search.action';
import { validateUserSearch } from '../utils/user-search.utils';
import AddUserModal from '../components/AddUserModal';
import { setSearchInputValue } from '../store/actions/set-search-input-value.action';
import { setSearchInputError } from '../store/actions/set-search-input-error.action';
import ApolloErrorToastMessage from 'shared/utils/errors/components/ApolloErrorToastMessage';
import { setIsSaving } from '../store/actions/set-is-saving.action';
import { setIsAddUserModalOpen } from '../store/actions/set-is-add-user-modal-open.action';
import { AddUserResponse, AddUserVariables } from '../mutations/user-list-configuration.mutations.types';
import { AddUser } from '../mutations/user-list-configuration.mutations';
import { userRouteParams, UserPage, ConfigurationPage, configurationRouteBases } from 'sections/configuration/routes/configuration-page.types';
import { ConfirmationDialog } from 'components/layout/Dialog';
import ConfigurationPageContainer from 'sections/configuration/components/ConfigurationPageContainer';
import { useTranslation } from 'react-i18next';

/**
 * UserList component
 */
export const UserList = () => {
    const location = useLocation();
    const apolloClient = useApolloClient();
    const history = useHistory();
    const { addToast } = useToasts();
    const [state, dispatch] = useReducer(userSearchStateReducer, defaultUserSearchState);
    const currentQuery = useRef<UID | null>();
    const [newUserToAdd, setNewUserToAdd] = useState<ApplicationUser | undefined>();
    const [showAddUserDialog, setShowAddUserDialog] = useState<boolean>(false);
    const { t } = useTranslation();

    /**
     * Loads users
     * @type {(searchQuery: string, currentPage: number, resultsPerPage: number) => void}
     */
    const userSearch = useCallback(
        (searchQuery: string, currentPage: number, resultsPerPage: number, reset: boolean) => {
            if (searchQuery.length === 0) {
                dispatch(setFetchingResults(false));
                return;
            }

            const validation = validateUserSearch(searchQuery);

            if (!validation.isValid) {
                dispatch(setFetchingResults(false));
                dispatch(setSearchInputError(validation.validationMessage));
                return;
            } else {
                dispatch(setSearchInputError(undefined));
            }

            dispatch(setFetchingResults(true));

            if (reset) dispatch(resetSearch());

            const query = uuid();
            currentQuery.current = query;

            apolloClient
                .query<ApplicationUserSearchResponse, ApplicationUserSearchRequest>({
                    query: ApplicationUserSearch,
                    variables: {
                        currentPage: currentPage,
                        displayName: searchQuery,
                        resultsPerPage: resultsPerPage
                    },
                    fetchPolicy: 'network-only'
                })
                .then(response => {
                    if (currentQuery.current !== query) return;

                    const searchResults = response.data.applicationUserSearch?.users ?? [];
                    const totalRecordCount = response.data.applicationUserSearch?.metaData.totalRecords ?? 0;

                    dispatch(
                        addSearchResults({
                            searchResults: searchResults,
                            totalCount: totalRecordCount
                        })
                    );
                })
                .catch(error => {
                    dispatch(setSearchError(error));
                })
                .finally(() => {
                    dispatch(setFetchingResults(false));
                });
        },
        [apolloClient]
    );

    /**
     * Set search values from query string
     */
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        if (searchParams.has(UserSearchQueryEnum.Query)) {
            const newSearchValue = searchParams.get(UserSearchQueryEnum.Query) ?? '';

            dispatch(setSearchInputValue(newSearchValue));
            dispatch(setSearchQuery(newSearchValue));

            userSearch(newSearchValue, 1, state.resultsPerPage, true);
        }
    }, [state.resultsPerPage, location, userSearch]);

    /**
     * Handles a table row being mounted, if the row is the last in the table, and there are more results
     * The next page of results will be loaded
     * @param {SearchResult} result The search result for the table row that has mounted
     * @param {number} index The index of the mounted table row
     */
    const handleMount = (result?: ApplicationUser, index?: number) => {
        if (
            index === state.searchResults.length - 1 &&
            state.totalResultsCount > state.searchResults.length &&
            !state.fetchingResults
        ) {
            dispatch(setPage(state.currentPage + 1));
            userSearch(state.searchQuery, state.currentPage + 1, state.resultsPerPage, false);
        }
    };

    /**
     * Handles viewing a users configuration
     * @param {ApplicationUser | undefined} item The user
     */
    const handleView = (item: ApplicationUser | undefined): void => {
        if (item?.dateAdded) {
            history.push(
                userRouteParams[UserPage.ViewUser](item?.userId.toString())
            );
        } else {
            setNewUserToAdd(item);
            setShowAddUserDialog(true);
        }
    };

    const handleAcceptAddUser = (): void => {
        setShowAddUserDialog(false);
        dispatch(setIsAddUserModalOpen(true));
    }

    const handleDeclineAddUser = (): void => {
        setNewUserToAdd(undefined);
        setShowAddUserDialog(false);
    }

    /**
     * Handles a user search being triggered
     * @param {SearchEvent} event The triggered event
     * @param {string} searchValue The search value
     */
    const handleSearch = (event: SearchEvent, searchValue?: string) => {
        if (isNullOrUndefined(searchValue)) {
            return;
        }

        const params = new URLSearchParams({
            [UserSearchQueryEnum.Query]: searchValue
        });

        history.push({
            pathname: configurationRouteBases[ConfigurationPage.Users],
            search: params.toString()
        });
    };

    /**
     * Handles the search input value being changed
     * @param {React.FormEvent<HTMLInputElement | HTMLTextAreaElement>} event The triggered event
     * @param {string} newValue The new search value
     */
    const handleSearchValueChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        const validation = validateUserSearch(newValue ?? '');

        if (!validation.isValid) dispatch(setSearchInputError(validation.validationMessage));
        else dispatch(setSearchInputError(undefined));

        dispatch(setSearchInputValue(newValue ?? ''));
    };

    const handleAddUserModal = (newUser: ApplicationUser) => {
        setNewUserToAdd(newUser);

        dispatch(setIsAddUserModalOpen(true));
    }

    const handleAddUser = async (userId: number, profileIds: number[]) => {
        dispatch(setIsSaving(true));

        try {
            await apolloClient.mutate<AddUserResponse, AddUserVariables>({
                mutation: AddUser,
                variables: {
                    employeeId: userId,
                    profileIds: profileIds
                }
            });

            addToast('User added successfully', { appearance: 'success' });

            dispatch(setIsAddUserModalOpen(false));
            userSearch(state.searchQuery, 1, state.resultsPerPage, true);
        } catch (error: any) {
            addToast(<ApolloErrorToastMessage error={error} baseMessage='Failed to add user' />, {
                appearance: 'error'
            });
        } finally {
            dispatch(setIsSaving(false));
        }
    };

    const handleDismissAddUserModal = () => {
        if (!state.isSaving) dispatch(setIsAddUserModalOpen(false));
    };

    return (
        <>
            <ConfigurationPageContainer>
                <StyledTableContainer>
                    <StyledHeaderText>{t('ConfigurationMenu.Users')}</StyledHeaderText>

                    <StyledCommandBar>
                        <SearchInputContainer>
                            <SearchInput
                                placeholder= {t('User.SearchForAUser')}
                                testIdentifier='user-search'
                                onSearch={handleSearch}
                                value={state.searchInputValue}
                                errorMessage={state.searchInputError}
                                onChange={handleSearchValueChange}
                            />
                        </SearchInputContainer>
                    </StyledCommandBar>
                    <StyledHorizontalRule />
                    <GraphQlErrorBoundary error={state.searchError}>
                        <Loading
                            isLoading={state.searchResults.length === 0 && state.fetchingResults}
                            message={`Searching for users like '${state.searchQuery}'`}
                            noDelay
                        >
                            <NoData
                                hasData={!(state.searchResults.length === 0 && !state.fetchingResults)}
                                label={t('User.NoResultsToDisplay')}
                                iconName='SearchIssue'
                            >
                                <StyledSubHeader>Search results</StyledSubHeader>

                                <VirtualTable<ApplicationUser>
                                    items={state.searchResults}
                                    columns={getColumns(handleView, handleAddUserModal)}
                                    onRowClick={handleView}
                                    keyFieldName='userId'
                                    onRowDidMount={handleMount}
                                />
                            </NoData>
                        </Loading>
                    </GraphQlErrorBoundary>
                </StyledTableContainer>
            </ConfigurationPageContainer>

            <AddUserModal
                isSaving={state.isSaving}
                isOpen={state.isAddUserModalOpen}
                userToAdd={newUserToAdd}
                onAdd={handleAddUser}
                onDismiss={handleDismissAddUserModal}
            />

            <ConfirmationDialog
                title='User not added'
                subText='This user has not yet been added, would you like to add them now?'
                hidden={true}
                show={showAddUserDialog}
                onAccept={handleAcceptAddUser}
                onDecline={handleDeclineAddUser}
                acceptButtonText='Yes'
                declineButtonText='No'
            />

        </>
    );
};
