import { useCallback, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { cloneDeep } from 'lodash';
import { IconButton } from 'components/controls/Button';
import {
    StyledBoldText,
    StyledGrayInfoText,
    StyledSection,
    StyledSubHeader
} from 'sections/configuration/styled/configuration.styles';

import { SheqManagerEntity } from 'shared/types/configuration/SheqManagerEntity';
import { DepotEntity } from 'shared/types/domain/DepotEntity';
import { useArrayState } from 'shared/hooks/useArrayState';
import { onLeaveSheqManagerMessage } from 'shared/constants/onLeaveInProgressMessages';
import LinkedDepotList from 'shared/components/LinkedDepotList';
import DepotSearchDialog from './DepotSearchDialog';
import { SheqManagerDetails } from './SheqManagerDetails/SheqManagerDetails';
import { StyledContainer } from 'shared/styled/panel.stayled';

interface SheqManagerConfigurationProps {
    sheqManager: SheqManagerEntity;
    onChangeNewDepotLinks: (newDepotLinks: DepotEntity[]) => void;
    onChangeSurplusDepotLinks: (surplusDepotLinks: DepotEntity[]) => void;
}

export const SheqManagerConfiguration = ({
    sheqManager,
    onChangeNewDepotLinks,
    onChangeSurplusDepotLinks
}: SheqManagerConfigurationProps) => {
    const [showDepotSearchDialog, setShowDepotSearchDialog] = useState<boolean>(false);

    const [
        displayDepotLinks,
        setDisplayDepotLinks,
        addToDisplayDepotLinks,
        removeFromDisplayDepotLinks
    ] = useArrayState<DepotEntity>({ key: 'pcId' });

    const [
        newDepotLinks,
        setNewDepotLinks,
        addToNewDepotLinks,
        removeFromNewDepotLinks
    ] = useArrayState<DepotEntity>({ key: 'pcId' });

    const [
        surplusDepotLinks,
        setSurplusDepotLinks,
        addToSurplusDepotLinks,
        removeFromSurplusDepotLinks
    ] = useArrayState<DepotEntity>({ key: 'pcId' });

    useEffect(() => {
        resetAllDepotLists();
    }, [sheqManager])

    const resetAllDepotLists = () => {
        setDisplayDepotLinks(cloneDeep<DepotEntity[]>(sheqManager.managedDepots));
        setNewDepotLinks([]);
        setSurplusDepotLinks([]);
    }

    const handleAddDepotLink = (depot: DepotEntity) => {
        addToDisplayDepotLinks(depot);
        if (!depotIsAssigned(depot)) {
            addToNewDepotLinks(depot);
        }
        removeFromSurplusDepotLinks(depot);
    };

    const handleRemoveDepotLink = (depot: DepotEntity) => {
        removeFromDisplayDepotLinks(depot);
        removeFromNewDepotLinks(depot);
        if (depotIsAssigned(depot)) addToSurplusDepotLinks(depot);
    };

    const hasChanges = useCallback((): boolean => {

        return anyDepotLinkChanges();
    }, [newDepotLinks, surplusDepotLinks])

    function depotIsAssigned(depot: DepotEntity): boolean {
        return !!(sheqManager.managedDepots.find(x => x.pcId == depot.pcId));
    }

    function anyDepotLinkChanges(): boolean {
        return newDepotLinks?.length > 0 || surplusDepotLinks?.length > 0
    }

    useEffect(() => {
        onChangeNewDepotLinks(newDepotLinks)
    }, [newDepotLinks]);

    useEffect(() => {
        onChangeSurplusDepotLinks(surplusDepotLinks)
    }, [surplusDepotLinks]);

    return (
        <StyledContainer style={{ marginTop: 20 }}>
            <Prompt
                when={hasChanges()}
                message={onLeaveSheqManagerMessage}
            />
            <Grid fluid>
                <Row>
                    <Col lg={6} xl={4}>
                        <SheqManagerDetails sheqManager={sheqManager} />
                    </Col>
                    <Col lg={6} xl={4} xlOffset={4}>
                        <StyledSection>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <div>
                                    <StyledSubHeader>Managed Depots</StyledSubHeader>
                                </div>
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                    <IconButton iconName={'Add'} onClick={() => setShowDepotSearchDialog(true)} />
                                    <IconButton iconName={'Undo'} onClick={resetAllDepotLists} disabled={!hasChanges()} />
                                </div>
                            </div>
                        </StyledSection>
                        <div style={{ height: 'calc(100vh - 175px)', display: 'flex', flexDirection: 'column' }}>
                            {displayDepotLinks.length === 0 && (
                                <div>
                                    <StyledBoldText>There are no depots assigned to this SHEQ Manager</StyledBoldText>
                                    <StyledGrayInfoText>You can assign depots by clicking the '+' above</StyledGrayInfoText>
                                </div>
                            )}

                            {displayDepotLinks.length > 0 && (
                                <div style={{ flex: '1 1 auto', overflowY: 'scroll', overflowX: 'hidden' }}>
                                    <LinkedDepotList depotList={displayDepotLinks} onRemoveDepot={handleRemoveDepotLink} />
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Grid>

            <DepotSearchDialog
                showDialog={showDepotSearchDialog}
                onAddDepot={handleAddDepotLink}
                onHideDialog={() => setShowDepotSearchDialog(false)}
                includeDepots={surplusDepotLinks}
                excludeDepots={newDepotLinks}
            />
        </StyledContainer>
    );
};
