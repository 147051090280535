import { useEffect, useRef, useState } from 'react';
import { useSecurity } from 'modules/security';
import Button, { IconButton } from 'components/controls/Button';
import { StyledControlContainer } from 'shared/styled/control.styled';
import { ObservationFileEntity } from 'shared/types/file/ObservationFileEntity';
import FileUploadZone, { IFileUploadZone } from 'shared/utils/files/components/FileUploadZone';
import { IAddFiles } from 'shared/utils/files/types/IAddFiles';
import { isNullOrUndefined } from 'shared/utils/validation.utils';
import { AddFileMapper } from 'shared/mappers/AddFileMapper';
import VirtualTable, { IVirtualTableColumn } from 'components/layout/VirtualTable/Table/components/VirtualTable';
import { Toggle } from 'components/controls/Toggle';
import { StyledPanelTitle } from 'shared/styled/panel.stayled';
import Loading from 'components/layout/Loading';
import { StyledImageContainer } from 'components/ObservationPhoto/styled/observation-photos.styled';
import { ConditionalRender } from 'components/layout/ConditionalRender';
import InputError from 'components/controls/InputError';
import { ApolloError } from 'apollo-client';
import ToggleGroup from 'components/controls/ToggleGroup';
import { includeAttachmentOptions } from 'sections/near-miss-management/pages/ViewAmmendNearMiss/components/NearMissHeader/components/CompleteDialog/constants/evidence-attachments.constants';
import { ConfirmationDialog } from 'components/layout/Dialog';
import { DocumentType } from 'shared/enums/DocumentType.enum';
import { documentTypes } from 'shared/constants/documentTypes';
import { IUpdateFiles } from 'shared/utils/files/types/IUpdateFiles';
import { theme } from 'shared/themes/aplant.theme';
import { IRemoveFiles } from 'shared/utils/files/types/IRemoveFiles';
import { GetObservationFileUrl } from 'shared/queries/observation-file.queries';
import { IGetFileUrlByIdResponse, IGetFileUrlByIdVariables } from 'shared/types/file/observation-file.type';
import { useApolloClient } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { IDropdownOption } from '@fluentui/react';
import i18next from 'i18next';

interface NewEvidenceDocumentsProps {
   handleAddEvidence: IAddFiles<ObservationFileEntity>;
   handleUpdateFiles: IUpdateFiles<ObservationFileEntity>;
   handleDeleteEvidence: IRemoveFiles<ObservationFileEntity>;
   files: ObservationFileEntity[];
   isReadonly: boolean;
   selectedAttachmentOption: string | number;
   setSelectedAttachmentOption: (selectedOption: string | number) => void;
   observationUniqueId: string;
}

export const NewEvidenceDocuments = ({
   handleAddEvidence,
   handleUpdateFiles,
   handleDeleteEvidence,
   files,
   isReadonly,
   selectedAttachmentOption,
   setSelectedAttachmentOption,
   observationUniqueId
}: NewEvidenceDocumentsProps) => {
   const graphClient = useApolloClient();
   const fileRef = useRef<IFileUploadZone>(null);
   const { user } = useSecurity();
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [loadingError, setLoadingError] = useState<ApolloError>();
   const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

   const [attachmentToRemove, setAttachmentToRemove] = useState<ObservationFileEntity>();
   const showRemoveConfirmation = (item: ObservationFileEntity) => {
      setAttachmentToRemove(item);
      setShowConfirmation(true);
   };
   const { t } = useTranslation();
   const getAttachmentColumns = (
      onView: (fileId: number | undefined | null) => void
   ): IVirtualTableColumn<ObservationFileEntity>[] => {
      const attachmentColumns: IVirtualTableColumn<ObservationFileEntity>[] = [
         {
            key: 'column1',
            name: t('NewEvidenceDocuments.Name'),
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 700,
            isResizable: false,
            data: 'string',
            isPadded: false
         },
         {
            key: 'column1.1',
            name: '',
            minWidth: 30,
            maxWidth: 30,
            data: 'icon',
            isPadded: false,
            onRender: (item: ObservationFileEntity) => (
               <IconButton
                  iconName={'Download'}
                  title={t('NewEvidenceDocuments.Download')}
                  style={
                     isNullOrUndefined(item.fileId) ? { display: 'none' } : { fontSize: '25px', margin: '-5px 0px' }
                  }
                  onClick={() => onView(item?.fileId)}
               />
            )
         },
         {
            key: 'column2',
            name: t('NewEvidenceDocuments.Size'),
            fieldName: 'size',
            minWidth: 60,
            maxWidth: 60,
            isResizable: true,
            data: 'string',
            isPadded: false
         },
         {
            key: 'column3',
            name: t('NewEvidenceDocuments.Email'),
            fieldName: 'emailEvidence',
            minWidth: 60,
            maxWidth: 60,
            isResizable: true,
            data: 'boolean',
            isPadded: false,
            onRender: (item: ObservationFileEntity) => (
               <Toggle
                  name={'emailEvidence'}
                  checked={item.emailEvidence}
                  onChange={() => handleEmailChange(item)}
                  disabled={isReadonly}
               />
            )
         }
      ];

      if (!isReadonly) {
         attachmentColumns.push({
            key: 'column4',
            name: t('NewEvidenceDocuments.Delete'),
            fieldName: 'remove',
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            data: 'boolean',
            isPadded: false,
            onRender: (item: ObservationFileEntity) => (
               <IconButton
                  iconName={'Blocked2Solid'}
                  title={t('NewEvidenceDocuments.Remove')}
                  style={{ color: theme.palette.error.main, fontSize: '25px', margin: '-5px 0px' }}
                  onClick={() => showRemoveConfirmation(item)}
               />
            )
         });
      }

      return attachmentColumns;
   };

   function prepairTableColumns(): IVirtualTableColumn<ObservationFileEntity>[] {
      let columns = getAttachmentColumns(fileId => handleViewFile(fileId));
      columns.forEach((column: IVirtualTableColumn<ObservationFileEntity>) => {
         column.name = t(column.name);
      });
      return columns;
   }
   const [displayColumns, setDisplayColumns] = useState<IVirtualTableColumn<ObservationFileEntity>[]>(
      prepairTableColumns()
   );

   useEffect(() => {
      let newColumns = prepairTableColumns();
      setDisplayColumns(newColumns);
   }, [i18next.language]);

   const handleEmailChange = (item: ObservationFileEntity) => {
      const currentFile = files.find(x => x.id === item.id);
      if (!isNullOrUndefined(currentFile)) {
         const newFile: ObservationFileEntity = {
            ...currentFile,
            emailEvidence: !item.emailEvidence
         };
         handleUpdateFiles([{ fileId: newFile.id, file: newFile }]);
      }
   };

   const handleEvidenceAdded = (files: File[]): void => {
      const fileMapper = new AddFileMapper();
      const newFiles = files.map(file => {
         return fileMapper.map(file, {
            uploadedById: parseInt(user?.employeeId!),
            uploadedByName: user?.name!,
            emailEvidence: true,
            type: documentTypes[DocumentType.Evidence]
         });
      });

      handleAddEvidence(newFiles);
   };

   function handleRemoveFile(referenceId: UID): void {
      const currentFile = files.find(x => x.id === referenceId);
      if (isNullOrUndefined(currentFile)) return;

      handleDeleteEvidence([currentFile]);
   }

   const cancelRemoveAttachment = () => {
      setShowConfirmation(false);
      setAttachmentToRemove(undefined);
   };

   const confirmRemoveAttachment = () => {
      if (attachmentToRemove) {
         handleRemoveFile(attachmentToRemove.id);
      }
      setShowConfirmation(false);
      setAttachmentToRemove(undefined);
   };

   async function handleViewFile(fileId: number | undefined | null): Promise<void> {
      if (isNullOrUndefined(fileId)) {
         return;
      }

      const response = await graphClient.query<IGetFileUrlByIdResponse, IGetFileUrlByIdVariables>({
         query: GetObservationFileUrl,
         variables: {
            fileId: fileId,
            referenceId: observationUniqueId
         },
         fetchPolicy: 'network-only'
      });

      if (!response.errors) {
         const url = response.data.retrieveSASForFile;
         window.open(url);
      }
   }

   return (
      <>
         <StyledControlContainer>
            <StyledPanelTitle>{t('NewEvidenceDocuments.Evidence')}</StyledPanelTitle>
            <StyledControlContainer>
               <ToggleGroup
                  label={t('NewEvidenceDocuments.IncludeAttachment')}
                  options={includeAttachmentOptions}
                  selectedKey={selectedAttachmentOption}
                  onChange={setSelectedAttachmentOption}
                  disabled={isReadonly}
               />
            </StyledControlContainer>
            <ConditionalRender condition={!!selectedAttachmentOption}>
               <ConditionalRender condition={!isReadonly}>
                  <FileUploadZone
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                     }}
                     ref={fileRef}
                     onFilesAdded={handleEvidenceAdded}
                     dragAndDrop={true}
                  >
                     <Button
                        onClick={() => fileRef.current?.showDialog()}
                        text={t('NewEvidenceDocuments.AddEvidence')}
                        iconName='Add'
                        disabled={false}
                        style={{ width: '160px', alignSelf: 'flex-end' }}
                     />
                     <StyledControlContainer style={{ paddingTop: '5px' }}>
                        {t('NewEvidenceDocuments.Appropriateness')}
                     </StyledControlContainer>
                  </FileUploadZone>
               </ConditionalRender>
               <StyledControlContainer>
                  <Loading isLoading={isLoading} message={`Loading evidence associated with this Near Miss`} noDelay>
                     <StyledImageContainer>
                        <ConditionalRender condition={!files && !loadingError}>
                           {t('NewEvidenceDocuments.NoEvidence')}
                        </ConditionalRender>
                        <ConditionalRender condition={!files && !!loadingError}>
                           <InputError error={loadingError?.message} />
                        </ConditionalRender>
                     </StyledImageContainer>
                  </Loading>
               </StyledControlContainer>
               <ConditionalRender condition={files.length > 0}>
                  <VirtualTable<ObservationFileEntity> columns={displayColumns} items={files} keyFieldName='id' />
               </ConditionalRender>
            </ConditionalRender>
         </StyledControlContainer>
         <ConfirmationDialog
            show={showConfirmation}
            title={t('NewEvidenceDocuments.RemoveAttachment')}
            subText={t('NewEvidenceDocuments.AreYouSure')}
            onDecline={cancelRemoveAttachment}
            onAccept={confirmRemoveAttachment}
            declineButtonText={t('ConfirmationDialog.Close')}
            acceptButtonText={t('ConfirmationDialog.Proceed')}
         />
      </>
   );
};
