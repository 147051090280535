import { gql } from 'graphql.macro';

export const GetDepotsLinkedToManager = gql`
  query GetObservationDepotsLinkedToManager($employeeId: Int!) {
    depot: observationDepotsLinkedToManager(employeeId: $employeeId) {
        pcId: pCID
        pc: pC
        pcName
        profitCentreManagerId: profitCentreManagerId
        }
    }
`;