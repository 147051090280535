import { actionCreatorFactory } from 'shared/utils/redux.utils';
import { UserSearchStateActionEnum } from '../../enums/UserSearchStateAction.enum';
import { ApplicationUser } from '../../queries/user-search.queries.types';
import { isNull, isUndefined } from 'shared/utils/validation.utils';
import { UserSearchState } from '../../store/state.types';

export const addSearchResults = actionCreatorFactory<
    typeof UserSearchStateActionEnum,
    { searchResults: ApplicationUser[]; totalCount: number }
>(UserSearchStateActionEnum.AddSearchResults);

export const addSearchResultsHandler: ActionHandler<UserSearchState, typeof addSearchResults> = (state, action) => {
    if (isUndefined(action.payload)) return state;

    const searchResults: (ApplicationUser | null)[] = [...state.searchResults.filter(x => !isNull(x))];
    searchResults.push(...action.payload.searchResults);

    return {
        ...state,
        searchResults: searchResults,
        totalResultsCount: action.payload.totalCount
    };
};
