import React, { FunctionComponent } from 'react';
import { ProfileListViewModel } from 'shared/types/configuration/ProfileModels';
import { IconButton } from 'components/controls/Button';
import { Label } from '@fluentui/react';
import { theme } from 'shared/themes/aplant.theme';
import { AssignedProfileListContainer } from './AssignedProfileList.styled';

interface AssignedProfileListProps {
    profileList: ProfileListViewModel[];
    removeProfile : (profile: ProfileListViewModel) => void;
}

const AssignedProfileList: FunctionComponent<AssignedProfileListProps> = (props) => {

    return (
        <>
            {props.profileList.length > 0 &&
                props.profileList.map(x => (
                    <AssignedProfileListContainer key={x.profileId}>
                        <div style={{display:"flex", alignItems: "center"}}>
                            <div style={{padding:"10px 15px", flex: 1}}>
                                <Label>{x.profileName}</Label>
                            </div>
                            <div>
                                <IconButton
                                    iconName={"Blocked2Solid"}
                                    title={"Remove"}
                                    style={{color: "red", fontSize: "25px"}}
                                    onClick={() => props.removeProfile(x)}/>
                            </div>
                        </div>
                        <div style={{ borderBottom: `1px solid ${theme.palette.gray['200']}` }}/>
                    </AssignedProfileListContainer>
                ))
            }
        </>
    );
};

export default AssignedProfileList;