import styled from 'styled-components';

export const StyledSelectContainer = styled.div`
    position: relative;
`;

export const StyledSelectOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    padding: 0 5px;

    ${({ theme }) => `
        border: 1px solid ${theme.palette.gray[500]};
        background: ${theme.palette.common.white};
    `}
`;

export const StyledLoadingMessage = styled.span`
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${({ theme }) => `
    color: ${theme.palette.gray[600]};
  `}
`;

export const StyledSelectFieldIcon = styled.div`
    ${({ theme }) => `
        position: absolute;
        top: 8px;
        right: 30px;
        font-size: 16px;
        height: 16px;
        width: 16px;
        color: ${theme.palette.gray[500]};
    `}
`;