import { createContext } from 'react';
import { SecurityContextType } from '../types/SecurityContextType';
import { AuthStateEnum } from '../enums/AuthStateEnum';

const defaultSecurityContext: SecurityContextType = {
    accessToken: null,
    authState: AuthStateEnum.Pending,
    user: null,
    signOut: () => Promise.resolve()
};

export const SecurityContext = createContext<SecurityContextType>(defaultSecurityContext);
