import styled from 'styled-components'; 

const StyledAddress = styled.div`
  font-size: 11px;
  color: gray;
  line-height: 15px;
`;

const StyledNoResults = styled.h2`
  text-align: center;
  margin: 50px 0;
`;

export { StyledAddress, StyledNoResults };