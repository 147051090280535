import {isNullOrEmpty} from "./null.utils";

export function getCookie(cname: string): string | undefined {
    let name = cname + "=";
        let filter = document.cookie.split('; ').filter(row => row.startsWith(name)).map(c => c.split('=')[1])[0];
    
        if(isNullOrEmpty(filter))
        {
         return undefined;   
        }
        return filter;
}
    
export function setDateFromCookie(cname: string | null | undefined): Date | undefined {
    if(isNullOrEmpty(cname))
    {
        return undefined
    }
    return new Date(cname)
}

export function setCookie(cname: string ,cvalue: any): string {
    return document.cookie = cname + "=" + cvalue + ";";
}