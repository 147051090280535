import { AppRoute } from "modules/routing/types/AppRoute";
import { NearMissList } from "../pages/NearMissList/NearMissList";
import { ViewAmmendNearMiss } from "../pages/ViewAmmendNearMiss/ViewAmmendNearMiss";

export const nearMissManagementHome = '/nearmiss';

export const viewAmmendNearMissRoute = (id?: string): string => {
    return `/nearmiss/${id ?? ':id'}`
}

export const nearMissManagementRoutes: AppRoute[] = [
    {
        name: 'Near Miss List Home',
        path: nearMissManagementHome,
        exact: true,
        component: NearMissList
    },
    {
        name: 'View/Ammend Near Miss',
        path: viewAmmendNearMissRoute(),
        exact: true,
        component: ViewAmmendNearMiss
    }
];