export enum DocumentType {
    PersonalIdentification = 1,
    CompanyLetterhead = 2,
    CreditReport = 3,
    InsurancePolicyDocument = 4,
    DeedofGuarantee = 5,
    ApplicationforRentalInsurance = 6,
    ConsolidatedApplicationPack = 7,
    Other = 8,
    IDVerificationDocument = 9,
    OriginalApplication = 10,
    Evidence = 11
}
