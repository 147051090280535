import { isNullOrEmpty } from './null.utils';

const FormatCurrency = (symbol: string, amount: number): string => {
    if (amount === null) amount = 0;
    return symbol + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const StringFormat = (str: string, ...values: any[]): string => {
    for (let i = 0; i < values.length; i++) {
        const lookVal = '{' + i + '}';
        str = str.replace(lookVal, values[i]);
    }
    return str;
};

export const tryParseInt = (value: string | null | undefined): number | undefined => {
    if (isNullOrEmpty(value)) return undefined;

    const parsedValue = parseInt(value);

    return isNaN(parsedValue) ? undefined : parsedValue;
};

export const tryParseFloat = (value: string | null | undefined): number | undefined => {
    if (isNullOrEmpty(value)) return undefined;

    const parsedValue = parseFloat(value);

    return isNaN(parsedValue) ? undefined : parsedValue;
};

export const stringToBoolean = (value: string | undefined, defaultValue = false) => {
    return Boolean(tryParseInt(value) ?? defaultValue);
};

export { FormatCurrency, StringFormat };