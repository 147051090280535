import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Callout, DirectionalHint } from '@fluentui/react';
import { Persona } from 'office-ui-fabric-react/lib/Persona';
import { AllAzureADUsersSearchModal, NextLinkOptionsType } from '../../AssignUser/types/AllAzureADUsersSearchModal';
import { GetAzureADUsers } from 'shared/queries/ad-user-search.queries';

import { useToasts } from 'react-toast-notifications';
import { useApolloClient } from '@apollo/react-hooks';
import { UserListItemResultsContainer, UserListResultsContainer } from './UserList.styles';
import { User } from 'shared/types/configuration/UserModels';
import { useElementSize } from 'shared/hooks/useElementSize';
import { SearchEvent } from 'components/controls/SearchInput/components/SearchInput.types';
import { ApolloErrorToastMessage } from 'modules/errors';
import { ApolloError } from 'apollo-client';
import { SearchInput } from 'components/controls/SearchInput';
import Loading from 'components/layout/Loading';
import { isNullOrEmpty } from 'shared/utils/null.utils';
import { useTranslation } from 'react-i18next';

interface UserSearchInputProps {
   onUserSelect: (user: User) => void;
   modelContentRef?: React.RefObject<HTMLDivElement>;
   onBlur?: () => void;
   label: string;
   required?: boolean;
   errorMessage?: string;
   autoFocus?: boolean;
   placeHolder?: string;
}

const UserSearchInput: FunctionComponent<UserSearchInputProps> = props => {
   const containerRef = useRef<HTMLDivElement>(null);
   const userListScrollContainer = useRef<HTMLDivElement>(null);
   const { width: inputWidth } = useElementSize<HTMLDivElement>(containerRef?.current);
   const { height: modelHeight } = useElementSize<any>(props.modelContentRef?.current);
   const [value, setValue] = useState<string>('');
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [errMsg, setErrMsg] = useState<string | undefined>(undefined);
   const [userList, setUserList] = useState<User[]>([]);
   const [nextUserResultList, setNextUserResultList] = useState<NextLinkOptionsType[]>([]);
   const [isCalloutOpen, setIsCalloutOpen] = useState(false);
   const { addToast } = useToasts();
   const client = useApolloClient();
   const { t } = useTranslation();

   useEffect(() => {
      setErrMsg(props.errorMessage);
   }, [props.errorMessage]);

   const userSearch = async (event: SearchEvent, searchValue?: string) => {
      const search = searchValue ?? '';
      if (search.length > 1) {
         setErrMsg('');
         setIsLoading(true);
         try {
            const result = await client.query<AllAzureADUsersSearchModal>({
               query: GetAzureADUsers,
               variables: {
                  displayName: search,
                  nextLinkOptions: [{ key: '', value: '' }],
                  resultsPerPage: 10
               }
            });

            if (result.data.allAzureADUsers) {
               setUserList(result.data.allAzureADUsers.users);
            }
            if (result.data.allAzureADUsers.nextLinkOptions) {
               setNextUserResultList(
                  result.data.allAzureADUsers.nextLinkOptions.map(x => {
                     return { key: x.key, value: x.value };
                  })
               );
            }
         } catch (err) {
            addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
         } finally {
            setIsLoading(false);
            setIsCalloutOpen(true);
         }
         return;
      }
      setErrMsg('You need to add a name to search');
   };

   const handleUserScroll = async () => {
      if (userListScrollContainer.current) {
         if (
            userListScrollContainer.current.scrollHeight - userListScrollContainer.current.scrollTop ===
            userListScrollContainer.current.clientHeight
         ) {
            try {
               const result = await client.query<AllAzureADUsersSearchModal>({
                  query: GetAzureADUsers,
                  variables: {
                     nextLinkOptions: nextUserResultList
                  }
               });

               if (result.data.allAzureADUsers) {
                  setUserList([...userList, ...result.data.allAzureADUsers.users]);
               }
               if (result.data.allAzureADUsers.nextLinkOptions) {
                  setNextUserResultList(
                     result.data.allAzureADUsers.nextLinkOptions.map(x => {
                        return { key: x.key, value: x.value };
                     })
                  );
               }
            } catch (err) {
               addToast(<ApolloErrorToastMessage error={err as ApolloError} />, { appearance: 'error' });
            } finally {
               setIsLoading(false);
               setIsCalloutOpen(true);
            }
         }
      }
   };

   const handleUserSelect = (user: User) => {
      setValue('');
      setIsCalloutOpen(false);
      props.onUserSelect(user);
   };

   return (
      <>
         <div ref={containerRef}>
            <SearchInput
               testIdentifier={``}
               value={value}
               onChange={(e, v) => setValue(v || '')}
               onSearch={userSearch}
               disabled={isLoading}
               errorMessage={errMsg}
               label={props.label}
               required={props.required ?? false}
               onBlur={props.onBlur}
               placeholder={props.placeHolder ? props.placeHolder : t('User.Search')}
               autoFocus={props.autoFocus}
            />
         </div>
         {isCalloutOpen && (
            <Callout
               isBeakVisible={false}
               calloutWidth={inputWidth}
               target={containerRef}
               directionalHint={DirectionalHint.bottomLeftEdge}
               directionalHintFixed={true}
               onDismiss={() => {
                  if (!isLoading) setIsCalloutOpen(false);
               }}
               hideOverflow={true}
            >
               <Loading isLoading={isLoading} noDelay>
                  <UserListResultsContainer
                     height={modelHeight > 0 ? modelHeight : 300}
                     ref={userListScrollContainer}
                     onScroll={handleUserScroll}
                  >
                     {userList.map(x => (
                        <UserListItemResultsContainer key={x.employeeId} onClick={() => handleUserSelect(x)}>
                           <Persona
                              text={x.displayName}
                              onRenderSecondaryText={props => (
                                 <div>
                                    <div>{`${x.jobTitle ?? ''} ${
                                       isNullOrEmpty(x.department) ? '' : '@ ' + x.department
                                    }`}</div>
                                    <div>{x.email}</div>
                                    <div>{x.businessPhones.join()}</div>
                                 </div>
                              )}
                           />
                        </UserListItemResultsContainer>
                     ))}
                  </UserListResultsContainer>
               </Loading>
            </Callout>
         )}
      </>
   );
};

export default UserSearchInput;
