import { FontIcon } from '@fluentui/react';
import styled from 'styled-components';

export const StyledFilterBar = styled.div`
  display: flex;
  align-items: center;
  height:50px;
  margin: 0 25px;
  padding: 0 15px;

  ${({theme}) => `
    background-color: ${theme.palette.background.level1};
    border-radius: ${theme.shape.borderRadius};
  `}

> span {
    font-weight: 600;
  }

  > div {
    :not(:first-child) {
      margin-left: 5px;
    }

    :not(:last-child) {
      margin-right: 15px;
    }
  }
`;

export const StyledFilterClearIcon = styled(FontIcon)`
    font-size: 10px;
    cursor: pointer;
    margin-left: 20px;
    position:absolute;
    right: 60px
`;