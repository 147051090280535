import Button, { ButtonProps } from "components/controls/Button";
import { StyledObservationHeader } from "shared/styled/ObservationHeader.styled";
import { StyledHeaderText } from "sections/configuration/styled/configuration.styles";
import { DepotCollectionModel } from "shared/types/configuration/DepotModels";
import { useTranslation } from "react-i18next";

interface DepotHeaderProps {
  depotModel: DepotCollectionModel;
  allowSave: boolean;
  onSave: () => void;
}

const DepotHeader = ({
  depotModel,
  allowSave,
  onSave,
}: DepotHeaderProps) => {

  const handleSave = () => {
    onSave();
  };
  const { t } = useTranslation();

  return (
    <StyledObservationHeader>
      <div style={{ display: 'flex' }}>
        <div>
          <StyledHeaderText>{t('Depot.Depot')}: {depotModel?.pcName ?? ''}</StyledHeaderText>
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          <Button text={t('Depot.Save')} onClick={handleSave} disabled={!allowSave} iconName='ReminderPerson' />
        </div>
      </div>
    </StyledObservationHeader>
  );
}

export default DepotHeader