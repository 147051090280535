import { actionCreatorFactory } from 'shared/utils/redux.utils';
import { UserSearchStateActionEnum } from '../../enums/UserSearchStateAction.enum';
import { UserSearchState } from '../../store/state.types';
import { isNullOrUndefined } from 'shared/utils/validation.utils';
import immutableUpdate from 'immutability-helper';

export const setIsSaving = actionCreatorFactory<typeof UserSearchStateActionEnum, boolean>(
    UserSearchStateActionEnum.SetIsSaving
);

export const setIsSavingHandler: ActionHandler<UserSearchState, typeof setIsSaving> = (state, action) => {
    if (isNullOrUndefined(action.payload)) return state;

    return immutableUpdate(state, {
        isSaving: { $set: action.payload }
    });
};
