import styled from 'styled-components';

export const StyledAuditEmployeeCard = styled.div<any>`
    position: relative;
    cursor: pointer;
    padding: 15px 5px;
    background-color: ${({ selected, theme }) => (selected ? theme.palette.gray['300'] : theme.palette.gray['100'])}    
    border: 1px solid ${({ theme }) => theme.palette.gray['300']};
     
    :not(:last-child) { 
      border-bottom: none};
    }
    
    &:hover{
        background-color: ${({ theme }) => theme.palette.gray['200']};
    }
`;

export const StyledAuditDateSpan = styled.span`
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: #323130;
`;