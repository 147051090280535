import { Persona, PersonaSize } from '@fluentui/react';

import { AuditRecord } from 'shared/types/audit/AuditRecord';
import { FieldChange } from 'shared/types/audit/FieldChange';
import {
   StyledFieldChangesContainer,
   StyledFieldChangesHeading,
   StyledFieldChangesTable,
   StyledFieldChangesTableHeader
} from '../styled/field-changes-display.styled';
import { useTranslation } from 'react-i18next';

interface FieldChangesDisplayProps {
   auditRecord?: AuditRecord;
   dateFields: string[];
}

export const FieldChangesDisplay = ({ auditRecord, dateFields }: FieldChangesDisplayProps) => {
   const { t } = useTranslation();

   function getAuditTypeString(type: string | undefined): string {
      switch (type) {
         case 'INSERT':
            return 'Inserted';
         case 'UPDATE':
            return 'Updated';
         case 'READ':
            return 'Read';
         case 'DELETE':
            return 'Deleted';
         default:
            return '';
      }
   }

   const secondaryTextString = (): string => {
      if (!auditRecord) return '';

      const emailNotification = auditRecord?.fieldChanges.find(x => x.fieldName == 'EmailType');

      if (!emailNotification) {
         return `${t(getAuditTypeString(auditRecord?.auditType?.toString()))} ${t('AuditLog.TheBelowFields')}`;
      }

      return `${emailNotification.newValue} Sent`;
   };

   const getValue = (value: string, fieldName: string): string => {
      if (value && dateFields.indexOf(fieldName) > -1) {
         var date = new Date(value);
         let dateString = date.toLocaleDateString().split('/').reverse().join('-');
         let timeString = date.toLocaleTimeString();
         return new Date(`${dateString}T${timeString}Z`).toLocaleString();
      }
      return value;
   };

   return (
      <div>
         <StyledFieldChangesHeading>
            <Persona
               text={auditRecord?.employee?.displayName ?? t('AuditLog.SystemChange')}
               secondaryText={secondaryTextString()}
               size={PersonaSize.size40}
            />
            {auditRecord && <span>{t('AuditLog.Date', { date: auditRecord?.date ?? new Date() })}</span>}
         </StyledFieldChangesHeading>

         <StyledFieldChangesContainer margin={'0 1.5%'}>
            <StyledFieldChangesTable>
               <thead>
                  <tr>
                     <StyledFieldChangesTableHeader width={'20%'}>{t('AuditLog.Field')}</StyledFieldChangesTableHeader>
                     <StyledFieldChangesTableHeader width={'20%'}>{t('AuditLog.To')}</StyledFieldChangesTableHeader>
                     <StyledFieldChangesTableHeader width={'20%'}>{t('AuditLog.From')}</StyledFieldChangesTableHeader>
                  </tr>
               </thead>
               <tbody>
                  {auditRecord?.fieldChanges?.map((val: FieldChange) => {
                     if (val.newValue === val.oldValue) {
                        return;
                     }
                     return (
                        <tr key={val.changeLogId}>
                           <td>
                              <span>{val.friendlyName}</span>
                           </td>
                           <td>
                              <span>{getValue(val.newValue, val.fieldName)}</span>
                           </td>
                           <td>
                              <span>{getValue(val.oldValue, val.fieldName)}</span>
                           </td>
                        </tr>
                     );
                  })}
               </tbody>
            </StyledFieldChangesTable>
         </StyledFieldChangesContainer>
      </div>
   );
};
