import React, { useState, useEffect, useCallback } from 'react';
import {
    IColumn,
    DetailsListLayoutMode,
    SelectionMode,
    IRenderFunction,
    IDetailsRowProps,
    ConstrainMode,
    ShimmeredDetailsList
} from '@fluentui/react';
import { StyledTableContainer } from '../styled/VirtualTable.styled';
import { isNullOrUndefined } from 'shared/utils/null.utils';
import { v4 as uuid } from 'uuid';

interface IVirtualTableProps<T> {
    items: (T | null)[];
    compactMode?: boolean;
    keyFieldName?: keyof T;
    columns: IVirtualTableColumn<T>[];
    onRowClick?: (item?: T, index?: number, ev?: Event) => void;
    onRenderRow?: IRenderFunction<IDetailsRowProps>;
    onRenderCustomPlaceholder?: (
        rowProps: IDetailsRowProps,
        index?: number,
        defaultRender?: (props: IDetailsRowProps) => React.ReactNode
    ) => React.ReactNode;
    onRowDidMount?: (item?: any, index?: number) => void;
    enableShimmer?: boolean;
}

export interface IVirtualTableColumn<T> extends IColumn {
    sortValueResolver?: (item: T) => any;
}

const VirtualTable = <T,>({
    items,
    compactMode,
    keyFieldName,
    columns,
    onRowClick,
    onRenderRow,
    onRenderCustomPlaceholder,
    onRowDidMount,
    enableShimmer
}: IVirtualTableProps<T>) => {
    const [localColumns, setLocalColumns] = useState<IVirtualTableColumn<T>[]>([]);
    const [localItems, setLocalItems] = useState<(T | null)[]>([]);

    useEffect(() => {
        setLocalColumns(columns);
        setLocalItems(items);
    }, [columns, items]);

    const getKey = useCallback(
        (item: T | null): string => {
            if (isNullOrUndefined(item) || isNullOrUndefined(keyFieldName)) return uuid();

            const key = item[keyFieldName];

            switch (typeof key) {
                case 'string':
                    return key;
                case 'number':
                    return key.toString();
                default:
                    return '';
            }
        },
        [keyFieldName]
    );

    return (
        <StyledTableContainer>
            <ShimmeredDetailsList
                onRenderRow={onRenderRow}
                items={localItems}
                compact={compactMode}
                columns={localColumns}
                selectionMode={SelectionMode.none}
                getKey={getKey}
                onRowDidMount={onRowDidMount}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onItemInvoked={onRowClick}
                constrainMode={ConstrainMode.horizontalConstrained}
                onRenderCustomPlaceholder={onRenderCustomPlaceholder}
                listProps={{ renderedWindowsAhead: 1, renderedWindowsBehind: 1 }}
                enableShimmer={enableShimmer}
            />
        </StyledTableContainer>
    );
};

export default VirtualTable;
